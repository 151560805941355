import { Segmented } from "antd";
import { SegmentedValue } from "antd/lib/segmented";
import { useState } from "react";
import FCleanSymbolAndMidbiasSettings from "./FCleanSymbolAndMidBiasSettings";
import TimeThresholdPage from "./TimeThresholdPage";
import BlackListPage from "./BlackListPage";
import PanelContainer from "../../PanelContainer";

export interface MidBiasAlarmSettingsPageProps { }

const MidBiasAlarmSettingsPage = (props: MidBiasAlarmSettingsPageProps) => {
    const [segmentVal, setSegmentVal] = useState<string>("Future Clean Symbol & MidBias Param Setting");
    return (
        <PanelContainer title="Mid Bias Alarm Settings">
            <div className="global-setting-container">
                <div className="top-panel">
                    <Segmented
                        options={["Future Clean Symbol & MidBias Param Setting", "Time Threshold", "Black List"]}
                        value={segmentVal}
                        onChange={(value: SegmentedValue) => setSegmentVal(value as string)}
                    />
                </div>
                <div className="container">
                    {segmentVal === "Future Clean Symbol & MidBias Param Setting" && <FCleanSymbolAndMidbiasSettings />}
                    {segmentVal === "Time Threshold" && <TimeThresholdPage />}
                    {segmentVal === "Black List" && <BlackListPage />}
                </div>
            </div>
        </PanelContainer>
    );
};

export default MidBiasAlarmSettingsPage;
