import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.less";
//import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import AppRoute from "./routes";
import { store } from "./store";
import { useEffect } from "react";
import { clarity } from "react-microsoft-clarity";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import ErrorBoundary from "./components/Error/ErrorBoundary";

const queryClient = new QueryClient();

const WrappedApp = () => {
    useEffect(() => {
        if (process.env.REACT_APP_MICROSOFT_CLARITY_ID !== undefined) {
            clarity.init(process.env.REACT_APP_MICROSOFT_CLARITY_ID);
        }
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <BrowserRouter>
                    <ErrorBoundary>
                        <AppRoute />
                    </ErrorBoundary>
                </BrowserRouter>
            </Provider>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    );
};

ReactDOM.render(<WrappedApp />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
