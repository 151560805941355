import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIs } from "../services/apis";
import { plainAxiosInstance } from "../services/axiosSetup";

const useRCMetaTraderMonitors = () => {
    const { data, refetch, isLoading, error, isFetching, dataUpdatedAt } = useQuery({
        queryKey: ["rcMetaTraderMonitor"],
        queryFn: () =>
            plainAxiosInstance.get(`${process.env.REACT_APP_API_URL}${APIs.RC_META_TRADER.GET_MONITORS}`, {
                headers: {
                    Accept: "application/json",
                },
            }),
        // staleTime: 60 * 1000, //(10 x 1000ms = 10 seconds)
        refetchInterval: 60 * 1000, //(10 x 1000ms = 10 seconds)
    });

    const rcMetaTraderMonitors = data?.data?.status ? null : data?.data || null;
    // @ts-ignore
    const rcMetaTraderMonitorsError: string | null = data?.data?.message || error?.message || error || null;

    const rcMetaTraderMonitorsDisconnectedCount: number = (rcMetaTraderMonitors?.filter((x: any) => x.status !== 0) ?? []).length;

    return {
        rcMetaTraderMonitors,
        rcMetaTraderMonitorsError,
        rcMetaTraderMonitorsDisconnectedCount,
        refetchRcMetaTraderMonitors: refetch,
        isFetching,
        isLoading,
        dataUpdatedAt,
    };
};

export const useRCMetaTraderMonitorChecked = () => {
    // const token = getToken();
    const queryClient = useQueryClient();
    const { mutateAsync, isLoading, error } = useMutation(
        async (sno: number[]): Promise<any> => {
            // Add return type annotation
            if (!sno) {
                return Promise.reject("should check at least one monitor");
            }
            // send payload as formdata
            const formData = new FormData();
            formData.append("sno", sno.join(","));
            return plainAxiosInstance.post(`${APIs.RC_META_TRADER.POST_MONITOR_CHECK}`, formData, {
                headers: {
                    Accept: "application/json",
                },
            });
            // return axios.post(`${process.env.REACT_APP_API_URL}${APIs.RC_META_TRADER.POST_MONITOR_CHECK}`, formData, {
            //     headers: {
            //         // Accept: "application/json",
            //         Authorization: `Bearer ${token}`,
            //         // "Content-Type": "multipart/form-data",
            //     },
            // });
        },
        {
            onSuccess: res => {
                queryClient.invalidateQueries(["rcMetaTraderMonitor"]);
                // console.log(`Checking monitor response 200`, res);
                return res;
            },
            onError: error => {
                queryClient.invalidateQueries(["rcMetaTraderMonitor"]);
                console.error(`Error on checking monitor:`, error);
                return error;
            },
        }
    );
    return {
        checkMonitor: mutateAsync,
        isLoading,
        error,
    };
};
export const useRCMetaTraderMonitorDone = () => {
    // const token = getToken();
    const queryClient = useQueryClient();
    const { mutateAsync, isLoading, error } = useMutation(
        async ({ sno, doneLog }: { sno: number[]; doneLog: string }): Promise<any> => {
            // Add return type annotation
            if (!sno) {
                return Promise.reject("should check at least one monitor");
            }
            // send payload as formdata
            const formData = new FormData();
            formData.append("sno", sno.join(","));
            formData.append("doneLog", doneLog);
            return plainAxiosInstance.post(`${APIs.RC_META_TRADER.POST_MONITOR_DONE}`, formData, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                },
            });
            // return axios.post(`${process.env.REACT_APP_API_URL}${APIs.RC_META_TRADER.POST_MONITOR_DONE}`, formData, {
            //     headers: {
            //         Accept: "application/json",
            //         Authorization: `Bearer ${token}`,
            //         "Content-Type": "multipart/form-data",
            //         // "Content-Type": "application/json",
            //     },
            // });
        },
        {
            onSuccess: res => {
                queryClient.invalidateQueries(["rcMetaTraderMonitor"]);
                // console.log(`Checking monitor response 200`, res);
                return res;
            },
            onError: error => {
                queryClient.invalidateQueries(["rcMetaTraderMonitor"]);
                console.error(`Error on checking monitor:`, error);
                return error;
            },
        }
    );
    return {
        doneMonitor: mutateAsync,
        isLoading,
        error,
    };
};

export const useRCMetaTraderMonitorComment = () => {
    // const token = getToken();
    const queryClient = useQueryClient();
    const { mutateAsync, isLoading, error } = useMutation(
        async ({ sno, comment }: { sno: number; comment: string }): Promise<any> => {
            if (!sno) {
                return Promise.reject("need sno to comment on monitor");
            }
            return plainAxiosInstance.post(
                `${APIs.RC_META_TRADER.POST_MONITOR_COMMENT}`,
                {
                    comment,
                    sno,
                },
                {
                    headers: {
                        Accept: "application/json",
                    },
                }
            );
            // return axios.post(
            //     `${process.env.REACT_APP_API_URL}${APIs.RC_META_TRADER.POST_MONITOR_COMMENT}`,
            //     {
            //         comment,
            //         sno,
            //     },
            //     {
            //         headers: {
            //             Accept: "application/json",
            //             Authorization: `Bearer ${token}`,
            //         },
            //     }
            // );
        },
        {
            onSuccess: res => {
                queryClient.invalidateQueries(["rcMetaTraderMonitor"]);
                // console.log(`Checking monitor response 200`, res);
                return res;
            },
            onError: error => {
                queryClient.invalidateQueries(["rcMetaTraderMonitor"]);
                console.error(`Error on checking monitor:`, error);
                return error;
            },
        }
    );
    return {
        adjustComment: mutateAsync,
        isLoading,
        error,
    };
};

export default useRCMetaTraderMonitors;
