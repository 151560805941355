import FlexiDataTable from "@/components/FlexiDataTable";
import { CALLBACK_KEY, SUCCESS_FAILED } from "@/constants";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps } from "@/constants/type";
import useRCRejectOrders from "@/hooks/useRCRejectOrders";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { isEmptyOrNull } from "@/utils/string";
import { Tag, Button, message } from "antd";
import moment from "moment";
import { useState, useMemo, useEffect } from "react";
import DownloadRejectOrderModal from "./DownloadRejectOrderModal";
import soundRejectOrder from "@/assets/audios/rc/rejectOrder.mp3";
import { CheckCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useAudioPlayer } from "@/helpers/audioHelper";

interface markupDataProps {
    data: any[];
    activeAlarm: boolean;
    totalWarning: number;
    totalCount: number;
}

const TabRejectOrder = () => {
    const MIN_ALARM_COUNT_TO_ALERT = 10;
    const { playSound } = useAudioPlayer(soundRejectOrder);
    const { rcRejectOrders, refetchRcRejectOrders, isLoading, isFetching } = useRCRejectOrders();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);

    const markupData: markupDataProps = useMemo(() => {
        let tmpSources = isEmptyOrNull(rcRejectOrders) ? [] : rcRejectOrders;
        return {
            data: tmpSources.map((x: any, idx: number) => ({ ...x, key: `${x.mainServerId}-${idx}` })),
            activeAlarm:
                !isLoading && tmpSources.length > 0 ? tmpSources.some((x: any) => x.status === 1 || x.count >= MIN_ALARM_COUNT_TO_ALERT) : false,
            totalCount: tmpSources.reduce((acc: number, x: any) => acc + x.count, 0),
            totalWarning: tmpSources.filter((x: any) => x.status === 1).length,
        };
    }, [rcRejectOrders, isLoading]);

    const titleTemplate = useMemo(() => {
        if (markupData.totalWarning === 0 && markupData.totalCount === 0) {
            return (
                <Tag icon={<CheckCircleOutlined />} color="success" style={{ borderRadius: "30px", fontSize: "1.5rem", padding: "0.707vh 1vw" }}>
                    All Good
                </Tag>
            );
        } else if (markupData.totalWarning > 0 || markupData.totalCount > 0) {
            return (
                <Tag
                    icon={<ExclamationCircleOutlined />}
                    color="warning"
                    style={{ borderRadius: "30px", fontSize: "1.5rem", padding: "0.707vh 1vw" }}
                >
                    {markupData.totalWarning} Warning(s) / {markupData.totalCount} Reject Orders
                </Tag>
            );
        }

        return <></>;
    }, [markupData]);

    const columns: any[] = [
        {
            title: "Server",
            dataIndex: "mainServerId",
            key: "mainServerId",
            sorter: (a: any, b: any) => {
                // sort alphabetically and length
                if (a.mainServerId?.toLowerCase() < b.mainServerId?.toLowerCase()) {
                    return -1;
                }
                if (a.mainServerId?.toLowerCase() > b.mainServerId?.toLowerCase()) {
                    return 1;
                }
                return 0;
            },
        },
        DTColProps.Middle(
            {
                title: "Status",
                dataIndex: "status",
                key: "status",
                render: (status: number, rowData: any) => (status === 0 ? <Tag color="success">Normal</Tag> : <Tag color="warning">Abnormal</Tag>),
                sorter: (a: any, b: any) => a.status - b.status,
            },
            ["text-center"]
        ),
        DTColProps.Middle(
            {
                title: "Reject Count",
                dataIndex: "count",
                key: "count",
                render: (count: number) => (count > 0 ? count : "-"),
                sorter: (a: any, b: any) => a.count - b.count,
            },
            ["text-center"]
        ),
        DTColProps.Middle({
            title: "Update Time",
            dataIndex: "lastUpdate",
            key: "lastUpdate",
            render: (dateTime: Date) => moment(dateTime).format("YYYY-MM-DD HH:mm:ss"),
            sorter: (a: any, b: any) => moment(a.lastUpdate).valueOf() - moment(b.lastUpdate).valueOf(),
        }),
    ];

    const options: FlexiDataTableOptionsProps = useMemo(
        () => ({
            enableFilter: false,
            showHideColumns: false,
            export: { text: "Download" },
            customExtraActionButton: (record: any, callback: Function) => {
                return (
                    <Button
                        type="primary"
                        size="small"
                        onClick={() => callback(CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK, { key: "resolved", value: record.mainServerId })}
                        disabled={record.count < 1}
                    >
                        Solved
                    </Button>
                );
            },
        }),
        []
    );

    const componentCallback: FlexiDataTableCallbackProps = (type, FormData) => {
        switch (type) {
            case CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK:
                if (FormData.key === "resolved") {
                    handleResolve(FormData.value);
                }
                break;
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                setIsModalOpen(true);
                break;
            default:
                break;
        }
    };

    const handleResolve = (server: string) => {
        plainAxiosInstance
            .put(
                `${APIs.RC_REJECT_ORDER.PUT_REJECT_ORDER_SOLVED}/${server}`,
                {},
                {
                    headers: {
                        Accept: "application/json",
                    },
                }
            )
            .then((res: any) => {
                if (res.data === 0) {
                    ErrorMessageHandler(`Reject orders from server (${server}) solved successfully.`, SUCCESS_FAILED.OTHERS_SUCCESS);
                } else {
                    const msg =
                        res.data === 1
                            ? "Reject order data of server not found."
                            : res.data === 2
                                ? "Save record data error."
                                : res.data === 3
                                    ? "Reset reject order data error."
                                    : "Unknown error";
                    ErrorMessageHandler(`Solved reject orders of server ${server} failed. ${msg}.`, SUCCESS_FAILED.OTHERS_FAILED);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) =>
                    ErrorMessageHandler(`Solved reject orders of server ${server} failed`, SUCCESS_FAILED.OTHERS_FAILED, err)
                )
            )
            .finally(() => refetchRcRejectOrders());
    };

    useEffect(() => {
        if (!isFetching) {
            if (markupData.activeAlarm) {
                playSound();
            };
        }
    }, [isFetching]);

    return (
        <>
            <div className="system-monitor-reject-order-container">
                <div className="top-panel"></div>
                <div className="main-panel">
                    <FlexiDataTable
                        bordered
                        rowKeyProperty="key"
                        tableProps={{
                            rowClassName: (record: any) => (record.status === 1 ? "error-row" : record.count > 10 ? "warning-row" : ""),
                        }}
                        title={titleTemplate}
                        columns={columns}
                        options={options}
                        dataSource={markupData.data}
                        callback={componentCallback}
                        loading={isLoading}
                        exporting={isDownloading}
                    />
                </div>
            </div>
            <DownloadRejectOrderModal
                open={isModalOpen}
                handleModalOpen={(open: boolean) => setIsModalOpen(open)}
                loading={isDownloading}
                handleLoading={(loading: boolean) => setIsDownloading(loading)}
            />
        </>
    );
};

export default TabRejectOrder;
