import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { TimeProps } from ".";

export interface TinyTimeProps {
    showDate?: boolean;
    showTime: TimeProps;
    showTimeZone?: boolean;
    showLocalTime?: boolean;
    stopInterval?: boolean;
}

const TinyTime = (props: TinyTimeProps) => {
    const [dummySet, setDummySet] = useState<boolean>(false);
    const dateFormatText = useMemo(
        () => `${props.showDate ? "YYYY-MM-DD " : ""}HH:mm:ss${props.showTimeZone ? "Z" : ""}`,
        [props.showDate, props.showTimeZone]
    );

    const getLocalTimeTemplate = useCallback(() => <span>{`Local Time: ${moment().format(dateFormatText)}`}</span>, [dateFormatText]);

    useEffect(() => {
        const secondInterval = setInterval(() => setDummySet(prev => !prev), 1000);

        return () => {
            clearInterval(secondInterval);
        };
    }, []);

    return (
        <>
            <div className="tinyTime">
                <span>
                    {props.showTime.name} Time :{" "}
                    {(props.showTime?.hasOwnProperty("utcOffset")
                        ? moment().utcOffset(props.showTime.utcOffset as number)
                        : moment().tz(props.showTime.tz)
                    ).format(dateFormatText)}
                </span>
                {props.showLocalTime && getLocalTimeTemplate()}
            </div>
            <span className="hide">{`${dummySet}`}</span>
        </>
    );
};

export default TinyTime;
