import React, { useState, useMemo, useCallback } from "react";
import * as d3 from "d3";
import useMeasure from "react-use-measure";
import { colorMap } from "../../SystemMonitor/StatusMonitor/statusUtils";
import { motion } from "framer-motion";
import { IHourlyStats } from "../../../store/useRCPriceOutageStore";
import { Badge, Button, Modal, Segmented, Space, Tag, Typography } from "antd";
import TimeDiff from "../../SystemMonitor/components/TimeDiff";
import { IPriceOutage } from "@/hooks/useRCPriceOutage";
import CodeBlock from "@/pages/CentralizedSettingsPanel/components/PriceMonitor/PriceBackendOptionSettings/CodeBlock";
import { CodeOutlined, PlayCircleOutlined } from "@ant-design/icons";

const lineColor = `rgba(0,0,0,0.25)`;
type TDisplayChartBy = "Clean Symbol" | "Total Affected";

const Bar = React.memo(
    ({
        stat,
        index,
        xScale,
        yScale,
        chartHeight,
        margin,
        setHoveredBar,
        hoveredBar,
        selectedData,
        setSelectedData,
        isLatest,
        displayChartBy,
        latestTickTimestamp,
    }: {
        stat: {
            timestamp: number;
            totalIncidents: number;
            uniqueCleanSymbols: { [symbol: string]: number };
            uniqueServers: { [server: string]: number };
        };
        index: number;
        xScale: d3.ScaleBand<string>;
        yScale: d3.ScaleLinear<number, number>;
        chartHeight: number;
        margin: { top: number; right: number; bottom: number; left: number };
        setHoveredBar: (index: number | null) => void;
        hoveredBar: number | null;
        selectedData: number | null;
        setSelectedData: (data: number | null) => void;
        isLatest: boolean;
        displayChartBy: TDisplayChartBy;
        latestTickTimestamp: number;
    }) => {
        // make a color scale from totalIncidents
        // @ts-ignore
        const colorScale = d3.scaleLinear().domain([0, 10]).range([colorMap.warning, colorMap.error]);
        // if hovered, set zIndex 5, else 1
        const value = displayChartBy === "Clean Symbol" ? Object.keys(stat.uniqueCleanSymbols).length : stat?.totalIncidents;

        return (
            <g style={{ position: "relative" }}>
                <motion.rect
                    // x={xScale(stat?.timestamp.toString())}
                    x={isLatest ? xScale(latestTickTimestamp.toString()) : xScale(stat?.timestamp.toString())}
                    // y={yScale(value)}
                    width={xScale.bandwidth()}
                    initial={{ height: 0, opacity: 0, y: yScale(0) }}
                    animate={{
                        height: value === 0 ? 0 : chartHeight - margin.bottom - yScale(value),
                        opacity: 0.75,
                        y: yScale(value),
                        transition: {
                            duration: 0.5,
                            ease: "easeInOut",
                        },
                    }}
                    height={value === 0 ? 0 : chartHeight - margin.bottom - yScale(value)}
                    // @ts-ignore
                    fill={
                        // if selectedData has the timestamp, then use the selected color
                        selectedData === stat?.timestamp ? colorMap.primary : colorScale(value)
                    }
                    style={{ zIndex: 1, opacity: 0.75 }}
                />

                <motion.rect
                    animate={{
                        opacity:
                            selectedData === stat?.timestamp
                                ? 0.5
                                : // if nothing is selected, and this is the last bar, then set opacity to 0.5
                                selectedData === null && isLatest
                                ? 0.3
                                : 0,
                    }}
                    whileHover={{ opacity: selectedData === stat?.timestamp ? 0.5 : 0.3, transition: { duration: 0.1 } }}
                    onHoverStart={() => setHoveredBar(index)}
                    onHoverEnd={() => setHoveredBar(null)}
                    onClick={() => {
                        if (isLatest) {
                            setSelectedData(null);
                        } else {
                            if (selectedData === stat?.timestamp) {
                                setSelectedData(null);
                            } else {
                                setSelectedData(stat?.timestamp);
                            }
                        }
                    }}
                    height={chartHeight - margin.bottom - margin.top}
                    // x={xScale(stat?.timestamp.toString())}
                    x={isLatest ? xScale(latestTickTimestamp.toString()) : xScale(stat?.timestamp.toString())}
                    y={margin.top}
                    width={xScale.bandwidth()}
                    fill={colorMap.info}
                    style={{ zIndex: 2, position: "relative", cursor: "pointer" }}
                />
                {(hoveredBar === index || selectedData === stat?.timestamp || (selectedData === null && isLatest)) && (
                    <motion.g style={{ zIndex: 3, position: "relative", pointerEvents: "none" }} key={stat?.timestamp + "-total"}>
                        <motion.circle
                            // cx={xScale(stat?.timestamp.toString())! + xScale.bandwidth() / 2}
                            cx={
                                isLatest
                                    ? xScale(latestTickTimestamp.toString())! + xScale.bandwidth() / 2
                                    : xScale(stat?.timestamp.toString())! + xScale.bandwidth() / 2
                            }
                            cy={yScale(value) - 13}
                            r={10}
                            fill={selectedData === stat?.timestamp || (selectedData === null && isLatest) ? colorMap.warning : colorMap.info}
                            style={{ zIndex: 1, position: "relative", opacity: 1 }}
                        />
                        <motion.polygon
                            // points={`${xScale(stat?.timestamp.toString())! + xScale.bandwidth() / 2 - 5},${yScale(value) - 6} ${
                            //     xScale(stat?.timestamp.toString())! + xScale.bandwidth() / 2 + 5
                            // },${yScale(value) - 6} ${xScale(stat?.timestamp.toString())! + xScale.bandwidth() / 2},${yScale(value) + 1}`}
                            points={`${
                                isLatest
                                    ? xScale(latestTickTimestamp.toString())! + xScale.bandwidth() / 2 - 5
                                    : xScale(stat?.timestamp.toString())! + xScale.bandwidth() / 2 - 5
                            },${yScale(value) - 6} ${
                                isLatest
                                    ? xScale(latestTickTimestamp.toString())! + xScale.bandwidth() / 2 + 5
                                    : xScale(stat?.timestamp.toString())! + xScale.bandwidth() / 2 + 5
                            },${yScale(value) - 6} ${
                                isLatest
                                    ? xScale(latestTickTimestamp.toString())! + xScale.bandwidth() / 2
                                    : xScale(stat?.timestamp.toString())! + xScale.bandwidth() / 2
                            },${yScale(value) + 1}`}
                            fill={selectedData === stat?.timestamp || (selectedData === null && isLatest) ? colorMap.warning : colorMap.info}
                            style={{ zIndex: 1, position: "relative" }}
                        />
                        <motion.text
                            // x={xScale(stat?.timestamp.toString())! + xScale.bandwidth() / 2}
                            x={
                                isLatest
                                    ? xScale(latestTickTimestamp.toString())! + xScale.bandwidth() / 2
                                    : xScale(stat?.timestamp.toString())! + xScale.bandwidth() / 2
                            }
                            y={yScale(value) - 10}
                            textAnchor="middle"
                            dominantBaseline="baseline"
                            fontSize="10"
                            style={{
                                userSelect: "none",
                                zIndex: 2,
                                position: "relative",
                            }}
                        >
                            {value}
                        </motion.text>
                    </motion.g>
                )}
            </g>
        );
    }
);

const XAxis = React.memo(
    ({
        xScale,
        chartHeight,
        margin,
        chartWidth,
    }: {
        xScale: d3.ScaleBand<string>;
        chartHeight: number;
        chartWidth: number;
        margin: { top: number; right: number; bottom: number; left: number };
    }) => {
        return (
            <g transform={`translate(0,${chartHeight - margin.bottom})`}>
                <line x1={margin.left} x2={chartWidth - margin.right} y1={0} y2={0} stroke={lineColor} />
                {xScale
                    .domain()
                    .filter((_, i) => !(i % Math.ceil(xScale.domain().length / 5)))
                    .map(timestamp => (
                        <g key={timestamp} transform={`translate(${xScale(timestamp.toString())! + xScale.bandwidth() / 2},0)`}>
                            <line x1={0} x2={0} y1={0} y2={5} stroke={lineColor} />
                            <text x={0} y={20} textAnchor="middle" dominantBaseline="hanging" fontSize="10" style={{ userSelect: "none" }}>
                                {new Date(Number(timestamp)).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}
                            </text>
                        </g>
                    ))}
            </g>
        );
    }
);

const YAxis = React.memo(
    ({
        yScale,
        margin,
        chartHeight,
    }: {
        yScale: d3.ScaleLinear<number, number>;
        margin: { top: number; right: number; bottom: number; left: number };
        chartHeight: number;
    }) => {
        return (
            <g transform={`translate(${margin.left},0)`}>
                <line x1={0} x2={0} y1={margin.top} y2={chartHeight - margin.bottom} stroke={lineColor} />
                {yScale.ticks(5).map(tick => (
                    <g key={tick} transform={`translate(-10,${yScale(tick)})`}>
                        <line x1={10} x2={0} y1={0} y2={0} stroke={lineColor} />
                        <text x={-5} y={0} textAnchor="end" dominantBaseline="middle" fontSize="10" style={{ userSelect: "none" }}>
                            {tick}
                        </text>
                    </g>
                ))}
            </g>
        );
    }
);

const Tooltip = React.memo(
    ({
        hoveredBar,
        hourlyStats,
        chartWidth,
        displayChartBy,
    }: {
        hoveredBar: number | null;
        chartWidth: number;
        hourlyStats: {
            timestamp: number;
            totalIncidents: number;
            uniqueCleanSymbols: { [symbol: string]: number };
            uniqueServers: { [server: string]: number };
        }[];
        displayChartBy: TDisplayChartBy;
    }) => {
        if (hoveredBar === null) return null;
        const stat = hourlyStats[hoveredBar];
        const { Text } = Typography;
        const value = displayChartBy === "Clean Symbol" ? Object.keys(stat?.uniqueCleanSymbols).length : stat?.totalIncidents;
        return (
            <div style={{ position: "absolute", maxWidth: chartWidth, zIndex: 100 }}>
                <div
                    style={{
                        background: "white",
                        border: "1px solid black",
                        borderColor: lineColor,
                        padding: "5px",
                        fontSize: "12px",
                    }}
                >
                    <Space direction="vertical" size={1}>
                        <Text>Time: {new Date(stat?.timestamp).toLocaleTimeString()}</Text>
                        <Text strong>Total Affected: {value}</Text>
                        <Text>Unique Symbols: {Object.keys(stat?.uniqueCleanSymbols).length}</Text>
                        <Text>Unique Servers: {Object.keys(stat?.uniqueServers).length}</Text>
                        <Text>Symbols:</Text>
                        <Space wrap size={1}>
                            {Object.entries(stat?.uniqueCleanSymbols)
                                .sort((a, b) => b[1] - a[1])
                                // .slice(0, 3)
                                .map(([symbol, count]) => (
                                    <Tag
                                        key={symbol}
                                        style={{ borderRadius: 30, margin: 0, display: "flex", alignItems: "center", gap: 4, paddingRight: 3 }}
                                    >
                                        <span>{symbol}</span>
                                        <Badge count={count} size="small" key={symbol} />
                                    </Tag>
                                ))}
                        </Space>
                        <Text>Servers:</Text>
                        <Space wrap size={1}>
                            {Object.entries(stat?.uniqueServers)
                                .sort((a, b) => b[1] - a[1])
                                // .slice(0, 3)
                                .map(([server, count]) => (
                                    <Tag
                                        key={server}
                                        style={{ borderRadius: 30, margin: 0, display: "flex", alignItems: "center", gap: 4, paddingRight: 3 }}
                                    >
                                        <span>{server}</span>
                                        <Badge count={count} size="small" key={server} />
                                    </Tag>
                                ))}
                        </Space>
                    </Space>
                </div>
            </div>
        );
    }
);

const PriceOutageTimeline = React.memo(
    ({
        data,
        selectedData,
        setSelectedData,
        refetch,
        isFetching,
        dataUpdatedAt,
        latestData,
        latestRawData,
    }: {
        data: IHourlyStats[];
        selectedData: number | null;
        setSelectedData: (data: number | null) => void;
        refetch: () => void;
        isFetching: boolean;
        dataUpdatedAt: number;
        latestData: IHourlyStats | null;
        latestRawData: IPriceOutage[];
    }) => {
        const [ref, bounds] = useMeasure();
        const chartHeight = 200;
        const chartWidth = bounds.width || 300;
        const margin = { top: 40, right: 20, bottom: 30, left: 40 };
        const [hoveredBar, setHoveredBar] = useState<number | null>(null);
        const [displayChartBy, setDisplayChartBy] = useState<TDisplayChartBy>("Clean Symbol");

        const hourlyStats = data
            // sort the data by timestamp, oldest to newest
            .sort((a, b) => a.timestamp - b.timestamp);

        const xScale = useMemo(() => {
            return d3
                .scaleBand()
                .domain(hourlyStats.map(d => d.timestamp.toString()))
                .range([margin.left, chartWidth - margin.right])
                .padding(0.1);
        }, [hourlyStats, chartWidth, margin.left, margin.right]);

        const yScale = useMemo(() => {
            // maxValue should be the maxValue of hourlyStats and latestData
            const maxValue = Math.max(
                d3.max(hourlyStats, d =>
                    d ? (displayChartBy === "Clean Symbol" ? Object.keys(d.uniqueCleanSymbols).length : d.totalIncidents) : 0
                ) || 0,
                d3.max([latestData], d =>
                    d ? (displayChartBy === "Clean Symbol" ? Object.keys(d.uniqueCleanSymbols).length : d.totalIncidents) : 0
                ) || 0
            );

            // minimum value is 5
            const finalValue = Math.max(maxValue, 5);
            return d3
                .scaleLinear()
                .domain([0, finalValue])
                .range([chartHeight - margin.bottom, margin.top]);
        }, [hourlyStats, chartHeight, margin.top, margin.bottom, displayChartBy]);

        const setHoveredBarCallback = useCallback((index: number | null) => {
            setHoveredBar(index);
        }, []);
        const { Title } = Typography;

        const [openModal, setOpenModal] = useState(false);
        return (
            <>
                <div style={{ display: "flex", gap: 8, alignItems: "center", justifyContent: "flex-start", marginBottom: "0.5rem", width: "100%" }}>
                    <Title level={5} style={{ marginBottom: 0 }}>
                        Price Outage Timeline
                    </Title>
                    <TimeDiff
                        timestamp={dataUpdatedAt}
                        refetch={refetch}
                        isFetching={isFetching}
                        styles={{
                            borderRadius: 16,
                        }}
                    />
                    <Button
                        size="small"
                        onClick={() => {
                            setOpenModal(true);
                        }}
                        shape="round"
                        style={{ marginLeft: "auto", display: "inline-flex", justifyContent: "center", alignItems: "center" }}
                        icon={
                            // code icon
                            <CodeOutlined style={{ marginRight: 4 }} />
                        }
                    >
                        Latest Raw Data
                    </Button>
                </div>
                <div ref={ref} style={{ background: "rgba(0,0,0,0.05)", borderRadius: "0.25rem", position: "relative" }}>
                    <div style={{ padding: "0.25rem" }}>
                        <Segmented
                            options={["Clean Symbol", "Total Affected"]}
                            value={displayChartBy}
                            onChange={(value: any) => setDisplayChartBy(value)}
                            size="small"
                        />
                    </div>
                    <svg width={chartWidth} height={chartHeight}>
                        <g style={{ position: "relative", zIndex: 1 }}>
                            {hourlyStats.map((stat, index) => {
                                // if it's not last stick, render the bar, else, take from the latest data and render, don't take from hourlyStats
                                if (index !== hourlyStats.length - 1) {
                                    return (
                                        <Bar
                                            key={stat?.timestamp}
                                            stat={stat}
                                            index={index}
                                            xScale={xScale}
                                            yScale={yScale}
                                            chartHeight={chartHeight}
                                            margin={margin}
                                            setHoveredBar={setHoveredBarCallback}
                                            hoveredBar={hoveredBar}
                                            selectedData={selectedData}
                                            setSelectedData={setSelectedData}
                                            isLatest={false}
                                            displayChartBy={displayChartBy}
                                            latestTickTimestamp={hourlyStats[hourlyStats.length - 1].timestamp}
                                        />
                                    );
                                } else {
                                    // the last bar, take from the latest data
                                    return (
                                        <Bar
                                            key={latestData?.timestamp}
                                            stat={latestData!}
                                            index={index}
                                            xScale={xScale}
                                            yScale={yScale}
                                            chartHeight={chartHeight}
                                            margin={margin}
                                            setHoveredBar={setHoveredBarCallback}
                                            hoveredBar={hoveredBar}
                                            selectedData={selectedData}
                                            setSelectedData={setSelectedData}
                                            isLatest={true}
                                            displayChartBy={displayChartBy}
                                            latestTickTimestamp={hourlyStats[hourlyStats.length - 1].timestamp}
                                        />
                                    );
                                }
                            })}
                        </g>
                        <XAxis xScale={xScale} chartHeight={chartHeight} chartWidth={bounds.width} margin={margin} />
                        <YAxis yScale={yScale} margin={margin} chartHeight={chartHeight} />
                    </svg>
                    {selectedData !== null && (
                        <Button
                            size="small"
                            style={{
                                position: "absolute",
                                right: 4,
                                bottom: 4,
                                fontSize: "0.65rem",
                                padding: "0.25rem",
                                lineHeight: 1,
                                height: "auto",
                                borderRadius: 4,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: 2,
                            }}
                            onClick={() => setSelectedData(null)}
                            icon={<PlayCircleOutlined />}
                            color={colorMap.primary}
                            type="primary"
                        >
                            LIVE
                        </Button>
                    )}
                </div>
                <Tooltip hoveredBar={hoveredBar} hourlyStats={hourlyStats} chartWidth={bounds.width} displayChartBy={displayChartBy} />
                <CodeBlockModal open={openModal} onClose={() => setOpenModal(false)} code={JSON.stringify(latestRawData, null, 2)} />
            </>
        );
    }
);

const CodeBlockModal = ({ open, onClose, code }: { open: boolean; onClose: () => void; code: string }) => {
    return (
        <Modal open={open} onCancel={onClose} onOk={onClose} title="Latest Raw Data" footer={false} width={800}>
            <CodeBlock code={code} />
        </Modal>
    );
};

export default PriceOutageTimeline;
