import React, { Component, ErrorInfo, ReactNode } from "react";
import { Result, Button } from "antd";
import { Link } from "react-router-dom";

interface ErrorBoundaryProps {
    children: ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
    errorUrl: string;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false, errorUrl: "" };
    }

    static getDerivedStateFromError(_: Error): ErrorBoundaryState {
        return { hasError: true, errorUrl: window.location.href };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error("ErrorBoundary caught an error:", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <Result
                    status="500"
                    title="Sorry, something went wrong."
                    subTitle={this.state.errorUrl}
                    extra={[
                        <Button type="primary">
                            <a href="/">Go Insight Homepage</a>
                        </Button>,
                        <Button type="ghost" onClick={() => window.location.reload()}>
                            Refresh
                        </Button>,
                    ]}
                />
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
