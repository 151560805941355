import { FormComponent } from "@/components/FormComponent";
import LoadingComponent from "@/components/Loading";
import { ComponentType, SUCCESS_FAILED } from "@/constants";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { Button, Col, Form, message, Row } from "antd";
import { useState, useEffect } from "react";

export interface FCleanSymbolAndMidbiasSettingsProps { }

interface ResponseSettingProps {
    type: string;
    value: string;
}

const FCleanSymbolAndMidbiasSettings = (props: FCleanSymbolAndMidbiasSettingsProps) => {
    const availableKey: string[] = [
        "future_symbol",
        "spread_ratio_threshold",
        "future_spread_ratio_threshold",
        "wave_ratio",
        "default_score_max",
        "default_score_sum",
        "future_score_max",
    ];
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<any>({});
    const [settingForm] = Form.useForm();

    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.CENTRALIZED_SETTINGS_MID_BIAS_ALARM_SETTINGS_EDIT);

    const updateSettings = (values: any) => {
        if (!enableUpdate) {
            return;
        }

        setIsLoading(true);
        plainAxiosInstance
            .post(
                APIs.RC_MIDBIAS.POST_MIDBIAS_SETTINGS,
                Object.keys(values).reduce((arr: ResponseSettingProps[], key: string) => {
                    arr.push({ type: key, value: key === "future_symbol" ? values[key].join(",") : values[key] });
                    return arr;
                }, [])
            )
            .then((res: any) => {
                if (res.data > 0) {
                    ErrorMessageHandler(`MidBias Settings updated successfully.`, SUCCESS_FAILED.OTHERS_SUCCESS);
                } else {
                    ErrorMessageHandler(`MidBias Settings updated failed.`, SUCCESS_FAILED.OTHERS_FAILED);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("MidBias settings", SUCCESS_FAILED.FAILED_UPDATE_DATA, err))
            )
            .finally(() => {
                setIsLoading(false);
                getMidBiasAlarmSettings();
            });
    };

    const getMidBiasAlarmSettings = () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(APIs.RC_MIDBIAS.GET_MIDBIAS_SETTINGS)
            .then((res: any) => {
                if (res.data && res.data.length > 0) {
                    let tmp = res.data.reduce((obj: any, x: ResponseSettingProps) => {
                        if (availableKey.includes(x.type)) {
                            obj[x.type] = x.type === "future_symbol" ? x.value.split(",") : x.value;
                        }
                        return obj;
                    }, {});
                    settingForm.setFieldsValue(tmp);
                    setData(tmp);
                }
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => console.log("Failed to get midbias param settings ", err)))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getMidBiasAlarmSettings();
    }, []);

    return (
        <div className="fclean-symbol-and-midbias-setting-container">
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Loading..." />
                </div>
            ) : (
                <>
                    <div className="alert-container">
                        <span>{`Please remember to restart the 【 Price Calculate Tool 】 by going to "Price Monitor -> Price Backend Opt" after making any changes to the settings. Thank you!`}</span>
                    </div>
                    <div className="main-container">
                        <Form form={settingForm} layout="vertical" initialValues={{}} onFinish={updateSettings}>
                            <div className="panel">
                                <div className="title">Future Clean Symbol</div>
                                <div>
                                    <FormComponent
                                        label={""}
                                        name="future_symbol"
                                        extra={{
                                            type: ComponentType.dropdown,
                                            value: [],
                                            inputProps: {
                                                mode: "tags",
                                                tokenSeparators: [","],
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="panel">
                                <div className="title">MidBias Param Setting</div>
                                <div>
                                    <Row gutter={16}>
                                        <Col span={8}>
                                            <FormComponent
                                                label={"Spread Ratio Threshold"}
                                                name="spread_ratio_threshold"
                                                extra={{
                                                    type: ComponentType.text,
                                                    value: "",
                                                }}
                                            />
                                        </Col>
                                        <Col span={8}>
                                            <FormComponent
                                                label={"Future Spread Ratio Threshold"}
                                                name="future_spread_ratio_threshold"
                                                extra={{
                                                    type: ComponentType.text,
                                                    value: "",
                                                }}
                                            />
                                        </Col>
                                        <Col span={8}></Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={8}>
                                            <FormComponent
                                                label={"Score Max"}
                                                name="default_score_max"
                                                extra={{
                                                    type: ComponentType.text,
                                                    value: "",
                                                }}
                                            />
                                        </Col>
                                        <Col span={8}>
                                            <FormComponent
                                                label={"Score Sum"}
                                                name="default_score_sum"
                                                extra={{
                                                    type: ComponentType.text,
                                                    value: "",
                                                }}
                                            />
                                        </Col>
                                        <Col span={8}>
                                            <FormComponent
                                                label={"Future Score Max"}
                                                name="future_score_max"
                                                extra={{
                                                    type: ComponentType.text,
                                                    value: "",
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={8}>
                                            <FormComponent
                                                label={"Wave Ratio"}
                                                name="wave_ratio"
                                                extra={{
                                                    type: ComponentType.text,
                                                    value: "",
                                                }}
                                            />
                                        </Col>
                                        <Col span={16}></Col>
                                    </Row>
                                </div>
                            </div>
                            {enableUpdate && (
                                <div className="footer-container">
                                    <Button type="primary" htmlType="submit">
                                        Update Settings
                                    </Button>
                                </div>
                            )}
                        </Form>
                    </div>
                </>
            )}
        </div>
    );
};

export default FCleanSymbolAndMidbiasSettings;
