import { useState, useMemo, useCallback, useEffect } from "react";
import { FormComponent } from "@/components/FormComponent";
import LoadingComponent from "@/components/Loading";
import { SUCCESS_FAILED, ComponentType, InnerPageActionMode } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { KeyValuePair, ProfileProps, User, RoleType, CreateEditInlineFormBased } from "@/constants/type";
import { apiRequest } from "@/services/apiConfig";
import { APIs } from "@/services/apis";
import { getProfile } from "@/services/localStorage";
import { SortList } from "@/utils/array";
import { ErrorMessageHandler, ErrorCatchValidator } from "@/utils/Common";
import { Form, message, Row, Col } from "antd";
import SitePageHeaderInline, { SitePageHeaderInlineActionType } from "@/components/PageHeader/inlineIndex";

interface SelectioOptsProps {
    users: KeyValuePair[];
    roles: KeyValuePair[];
    teams: KeyValuePair[];
}

export interface UserCreateEditPageProps extends CreateEditInlineFormBased {
    callback: (action: InnerPageActionMode, value?: any) => void;
    resetState: number;
}

const UserCreatePage = (props: UserCreateEditPageProps) => {
    const currentUser: ProfileProps = getProfile() as ProfileProps;

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<User[]>([]);
    const [roleList, setRoleList] = useState<RoleType[]>([]);
    const [teamList, setTeamList] = useState<string[]>([]);
    const [userForm] = Form.useForm();
    const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

    const processBackAndRefetch = () => setTimeout(() => props.callback && props.callback(InnerPageActionMode.BACK, { refreshMainList: true }), 400);

    const selectionOpts: SelectioOptsProps = useMemo(() => {
        return {
            users: SortList(data.length > 0 ? data.map(x => ({ text: x.username, value: x.uuid })) : [], "text"),
            roles: SortList(
                roleList.map(x => ({ text: x.role, value: x.id })),
                "text"
            ),
            teams: teamList.map(x => ({ text: x, value: x })),
        };
    }, [data, roleList, teamList]);

    const onSubmit = (values: any) => {
        try {
            setIsLoadingState(true);
            values["roleIds"] = (values["roleIds"] as number[]).join(",");

            apiRequest(APIs.CREATE_USER_ACCOUNT, { ...values })
                .then(() => {
                    ErrorMessageHandler("New user", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                    processBackAndRefetch();
                })
                .catch(error => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("new user", SUCCESS_FAILED.FAILED_CREATE_DATA, err)))
                .finally(() => setIsLoadingState(false));
        } catch (err: any) {
            ErrorMessageHandler(`Error: ${err.toString()}`, SUCCESS_FAILED.OTHERS_FAILED);
        }
    };

    const onValueChange = useCallback(
        (changedComponent: any, allvalue: any) => {
            Object.keys(changedComponent).map(x => {
                const value = changedComponent[x];

                switch (x) {
                    case "uuid":
                        let u = data.find(x => `${x.uuid}` === `${value}`);
                        if (u !== undefined) {
                            userForm.setFieldsValue({ ...allvalue, firstName: u.firstName, lastName: u.lastName, email: u.email });
                        }
                        break;
                }

                return false;
            });
        },
        [data]
    );

    const getUserTeams = () => {
        apiRequest(APIs.GET_SETTING_LIST, { keys: ["UserTeams"] })
            .then((res: any) => {
                const userTeamsValue = JSON.parse(res.find((item: { key: string }) => item.key === "UserTeams")?.value || "null");
                setTeamList(userTeamsValue);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => console.log("Failed to get latest user teams: ", err));
            });
    };

    const getUserRoles = useCallback(() => {
        apiRequest(APIs.GET_USER_ROLE_LIST, { userId: currentUser.id })
            .then((response: any) => {
                let roles = response as RoleType[];
                setRoleList(roles);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => console.log(`Error: ${err}`));
            });
    }, [currentUser]);

    const getUnbindedUsers = () => {
        apiRequest(APIs.GET_USER_PROFILES, { isBinded: false })
            .then((res: User[]) => {
                setData(res && res.length > 0 ? res.filter(x => `${x.uuid}` !== `${currentUser.id}`) : []);
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => console.log(err.message)))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getUnbindedUsers();
        getUserRoles();
        getUserTeams();
    }, []);

    return (
        <SitePageHeaderInline
            enableSubmit
            title={"Bind User Account"}
            callback={(action: SitePageHeaderInlineActionType) => {
                switch (action) {
                    case SitePageHeaderInlineActionType.OnBack:
                    case SitePageHeaderInlineActionType.OnCancel:
                        props.callback && props.callback(InnerPageActionMode.BACK);
                        return;
                    case SitePageHeaderInlineActionType.OnSubmit:
                        userForm
                            .validateFields()
                            .then(res => onSubmit(res))
                            .catch(err => console.log("form-error:", err));
                        return;
                    default:
                        break;
                }
            }}
            isSubmitting={isLoadingState}
        >
            {isLoadingState ? (
                <div className="loading-container">
                    <LoadingComponent tip="Submitting..." />
                </div>
            ) : (
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={userForm}
                    layout="horizontal"
                    initialValues={{}}
                    onValuesChange={onValueChange}
                    onFinish={onSubmit}
                >
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Username"
                                name="uuid"
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: selectionOpts.users,
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                                isLoading={isLoading}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="First Name"
                                name="firstName"
                                extra={{
                                    type: ComponentType.labelOnly,
                                    value: "",
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Last Name"
                                name="lastName"
                                extra={{
                                    type: ComponentType.labelOnly,
                                    value: "",
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Email"
                                name="email"
                                extra={{
                                    type: ComponentType.labelOnly,
                                    value: "",
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="User Role"
                                name={"roleIds"}
                                extra={{
                                    type: ComponentType.dropdown,
                                    inputProps: {
                                        style: { width: "100%" },
                                        mode: "multiple",
                                        allowClear: true,
                                    },
                                    value: selectionOpts.roles,
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Team"
                                name={"team"}
                                extra={{
                                    type: ComponentType.dropdown,
                                    inputProps: {
                                        style: { width: "100%" },
                                        allowClear: true,
                                    },
                                    value: selectionOpts.teams,
                                }}
                            />
                        </Col>
                    </Row>
                </Form>
            )}
        </SitePageHeaderInline>
    );
};

export default UserCreatePage;
