import FlexiDataTable from "@/components/FlexiDataTable";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps, KeyValuePair } from "@/constants/type";
import { useCallback, useEffect, useMemo, useState } from "react";
import BridgeConfigApiModal, { BridgeConfigApiModalCallbackKey } from "./BridgeConfigApiModal";
import { isEmptyOrNull } from "@/utils/string";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { APIs } from "@/services/apis";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "@/constants";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { Modal } from "antd";
import PanelContainer from "../../PanelContainer";

export interface BridgeConfigApiPageProps { }

interface BridgeConfigApiSettingProps {
    bridgeId: string;
    bridgeName: string;
    configApiKey: string;
    configType: number;
    inUse: boolean;
    isUse: number;
    pkey: string;
    updateTime: string;
    updateUser: number;
}

export const BridgeConfigApiConfigType = [
    { text: "OZSBE Maker", value: 1 },
    { text: "Maker", value: 2 },
];

const BridgeConfigApiPage = (props: BridgeConfigApiPageProps) => {
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [editData, setEditData] = useState<any | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<BridgeConfigApiSettingProps[]>([]);
    const [bridgeOptions, setBridgeOptions] = useState<KeyValuePair[]>([]);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.CENTRALIZED_SETTINGS_BRIDGE_CONDIF_API_CONFIGURATION_EDIT);

    const columns: any[] = useMemo(
        () => [
            DTColProps.Large({
                title: "Bridge Name",
                dataIndex: "bridgeName",
                key: "bridgeName",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: bridgeOptions,
                        callback: (filterValue: number, rowData: BridgeConfigApiSettingProps) => {
                            if (filterValue === 0) return true;
                            else {
                                const thisBridge = bridgeOptions.find(x => x.value === filterValue);
                                return isEmptyOrNull(thisBridge) ? false : rowData["bridgeName"] === thisBridge?.text;
                            }
                        },
                    },
                },
            }),
            {
                title: "Setting Name",
                dataIndex: "configApiKey",
                key: "configApiKey",
            },
            DTColProps.Middle({
                title: "Config Type",
                dataIndex: "configType",
                key: "configType",
                render: (text: number) => (`${text}` === "1" ? "OZSBE Maker" : "Maker"),
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: BridgeConfigApiConfigType,
                    },
                },
            }),
        ],
        [bridgeOptions]
    );

    const options: FlexiDataTableOptionsProps = {
        separateActionButton: true,
        ...(enableUpdate && {
            add: true,
            edit: true,
            delete: true,
        }),
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, FormData) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                setIsModalVisible(true);
                setEditData(undefined);
                break;
            case CALLBACK_KEY.DO_EDIT:
                setIsModalVisible(true);
                setEditData(FormData);
                break;
            case CALLBACK_KEY.DO_DELETE:
                deleteBridgeConfigApi(FormData);
                break;
            default:
                break;
        }
    };

    const handleSubmit = useCallback(
        (values: any) => {
            setIsSubmitting(true);
            if (isEmptyOrNull(editData)) {
                plainAxiosInstance
                    .post(APIs.RC_BRIDGE_CONFIG_API.GET_BRIDGE_API_LIST, values)
                    .then((res: any) => {
                        ErrorMessageHandler("bridge config api", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                        getBridgeConfigApiList();
                    })
                    .catch((error: any) =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("bridge config api", SUCCESS_FAILED.FAILED_CREATE_DATA, err))
                    )
                    .finally(() => {
                        setIsSubmitting(false);
                    });
            } else {
                plainAxiosInstance
                    .put(APIs.RC_BRIDGE_CONFIG_API.GET_BRIDGE_API_LIST, values)
                    .then((res: any) => {
                        ErrorMessageHandler("bridge config api", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                        getBridgeConfigApiList();
                    })
                    .catch((error: any) =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("bridge config api", SUCCESS_FAILED.FAILED_UPDATE_DATA, err))
                    )
                    .finally(() => {
                        setEditData(undefined);
                        setIsSubmitting(false);
                    });
            }
        },
        [editData]
    );

    const deleteBridgeConfigApi = (record: any) => {
        Modal.confirm({
            title: `Are you sure want to delete Setting Name【${record["configApiKey"]}】?`,
            onOk: () => {
                setIsLoading(true);
                plainAxiosInstance
                    .delete(`${APIs.RC_BRIDGE_CONFIG_API.GET_BRIDGE_API_LIST}/${record["pkey"]}`)
                    .then((res: any) => {
                        ErrorMessageHandler("bridge config api", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                    })
                    .catch((error: any) =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("bridge config api", SUCCESS_FAILED.FAILED_DELETE_DATA, err))
                    )
                    .finally(() => {
                        setIsLoading(false);
                        getBridgeConfigApiList();
                    });
            },
        });
    };

    const getBridgeConfigApiList = () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(APIs.RC_BRIDGE_CONFIG_API.GET_BRIDGE_API_LIST)
            .then((res: any) => {
                setData(res.data && res.data.length > 0 ? res.data : []);
            })
            .finally(() => setIsLoading(false));
    };

    const getConfig = () => {
        plainAxiosInstance
            .get(APIs.RC_BRIDGE_CONFIG_API.GET_BRIDGE_CONFIG)
            .then((res: any) => {
                setBridgeOptions(res.data && res.data.length > 0 ? res.data.map((x: any) => ({ text: x.bridgeName, value: x.bridgeId })) : []);
            })
            .finally(() => getBridgeConfigApiList());
    };

    useEffect(() => {
        getConfig();
    }, []);

    return (
        <PanelContainer title="Bridge Config Api Configuration">
            <div className="bridge-config-api-container">
                <FlexiDataTable
                    bordered
                    rowKeyProperty="pkey"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={data}
                    callback={componentCallback}
                    loading={isLoading}
                />
            </div>
            <BridgeConfigApiModal
                isModalVisible={isModalVisible}
                modalTitle={isEmptyOrNull(editData) ? "Create New Bridge Config Api" : "Edit Bridge Config Api"}
                callback={(type: number, data: any) => {
                    switch (type) {
                        case BridgeConfigApiModalCallbackKey.Close:
                            setIsModalVisible(false);
                            break;
                        case BridgeConfigApiModalCallbackKey.Submit:
                            handleSubmit(data);
                            setIsLoading(true);
                            setIsModalVisible(false);
                            break;
                        default:
                            break;
                    }
                }}
                data={editData}
                bridgeOptions={bridgeOptions}
                isLoading={isSubmitting}
            />
        </PanelContainer>
    );
};

export default BridgeConfigApiPage;
