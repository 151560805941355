import {
    RCIssueLogType,
    useRCIssuesLogHistory,
    useRCIssuesLogMT5History,
    useRCIssuesLogMainServers,
    mapIssueLogBackToEnum,
} from "@/hooks/useRCIssuesLog";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { defaultIfEmptyOrNull, isEmptyOrNull } from "@/utils/string";
import { Space, Tag, message, notification } from "antd";
import moment from "moment";
import { useMemo, useCallback, useState, useEffect } from "react";
import FlexiDataTable from "@/components/FlexiDataTable";
import { DefaultIfEmpty } from "@/utils/object";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { FlexiDataTableCallbackProps, FlexiDataTableOptionsProps } from "@/constants/type";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler, getFileNameFromResponseHeader } from "@/utils/Common";

interface CommonIssuesLogHistoryTableTypeInfoProps {
    isMT5TypeOrder: boolean;
    typeNumber: number;
    tableColumns: any[];
}

const CommonIssuesLogHistoryTable = ({ type }: { type: RCIssueLogType }) => {
    const { rcIssuesLogMainServers } = useRCIssuesLogMainServers();
    const [runRefetchDataList, setRunRefetchDataList] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [data, setData] = useState<any[]>([]);
    const [filterParams, setFilterParams] = useState({ start: moment().format("YYYY-MM-DD"), end: moment().format("YYYY-MM-DD") });

    const typeInfo: CommonIssuesLogHistoryTableTypeInfoProps = useMemo(() => {
        let serverObj = defaultIfEmptyOrNull(rcIssuesLogMainServers, []).reduce((acc: any, x: any) => {
            acc[`${x.oldServerId}`] = x;
            return acc;
        }, {});

        return {
            isMT5TypeOrder: type === "MT5_ORDER_TYPE",
            typeNumber: defaultIfEmptyOrNull(mapIssueLogBackToEnum(type), 0),
            tableColumns:
                type === "MT5_ORDER_TYPE"
                    ? [
                          DTColProps.Small({
                              title: "Server",
                              dataIndex: "serverUno",
                              key: "serverUno",
                              render: (serverUno: any) => DefaultIfEmpty(serverObj, `${serverUno}`, { serverName: `${serverUno}` }).serverName,
                          }),
                          {
                              title: "Order ID",
                              dataIndex: "orderId",
                              key: "orderId",
                          },
                          DTColProps.Small({
                              title: "Symbol",
                              dataIndex: "symbol",
                              key: "symbol",
                          }),
                          DTColProps.Small(
                              {
                                  width: "6vw",
                                  title: "Type",
                                  dataIndex: "type",
                                  key: "type",
                                  render: (text: any, checkState: any) => {
                                      const { type, state } = checkState;
                                      if (state === "1") {
                                          if (type === "0") {
                                              return "Buy";
                                          } else if (type === "1") {
                                              return "Sell";
                                          }
                                      }
                                      return "Any";
                                  },
                              },
                              ["text-center"]
                          ),
                          DTColProps.Small({
                              title: "Open Time",
                              dataIndex: "openTime",
                              key: "openTime",
                              render: (text: any) => moment(text).format("YYYY-MM-DD HH:mm:ss"),
                          }),
                          DTColProps.Small(
                              {
                                  title: "Volume",
                                  dataIndex: "volume",
                                  key: "volume",
                              },
                              ["text-right"]
                          ),
                          {
                              title: "State",
                              dataIndex: "state",
                              key: "state",
                              render: (state: any) => {
                                  switch (state) {
                                      case "0":
                                          return "ORDER_STATE_STARTED";
                                      case "1":
                                          return "ORDER_STATE_PLACED";
                                      case "7":
                                          return "ORDER_STATE_REQUEST_ADD";
                                      case "8":
                                          return "ORDER_STATE_REQUEST_MODIFY";
                                      case "9":
                                          return "ORDER_STATE_REQUEST_CANCEL";
                                      default:
                                          return "UNKNOWN_STATE";
                                  }
                              },
                          },
                          DTColProps.Small({
                              title: "First Time",
                              dataIndex: "firstTime",
                              key: "firstTime",
                              render: (text: any) => moment(text).format("YYYY-MM-DD HH:mm:ss"),
                          }),
                          DTColProps.Small({
                              title: "Last Time",
                              dataIndex: "lastTime",
                              key: "lastTime",
                              render: (dateTime: any) => (dateTime ? moment(dateTime).format("YYYY-MM-DD HH:mm:ss") : "-"),
                          }),
                          {
                              title: "Date Range",
                              dataIndex: "dateRange",
                              key: "dateRange",
                              options: {
                                  visible: false,
                                  filter: {
                                      type: ComponentType.daterange,
                                      value: "",
                                      rules: [{ required: true, message: REQUIRED_FIELD }],
                                      dateFormat: "YYYY-MM-DD",
                                      inputProps: {
                                          allowClear: false,
                                          showTime: false,
                                          dateOnly: true,
                                          disabledDate: (current: any) => current && current > moment().endOf("day"),
                                      },
                                  },
                              },
                          },
                      ]
                    : [
                          DTColProps.Small({
                              title: "Server",
                              dataIndex: "server",
                              key: "server",
                              sorter: (a: any, b: any) => {
                                  // sort alphabetically and length
                                  if (a.server?.toLowerCase() < b.server?.toLowerCase()) {
                                      return -1;
                                  }
                                  if (a.server?.toLowerCase() > b.server?.toLowerCase()) {
                                      return 1;
                                  }
                                  return 0;
                              },
                          }),
                          DTColProps.Small(
                              {
                                  title: "Date Time",
                                  dataIndex: "time",
                                  key: "time",
                                  sorter: (a: any, b: any) => moment(a.time).unix() - moment(b.time).unix(),
                                  render: (text: any) => (
                                      <div style={{ display: "flex", flexDirection: "column" }}>
                                          <span>{moment(text).format("YYYY-MM-DD")}</span>
                                          <span>{moment(text).format("HH:mm:ss")}</span>
                                      </div>
                                  ),
                              },
                              ["text-center"]
                          ),
                          DTColProps.Small({
                              title: "IP",
                              dataIndex: "ip",
                              key: "ip",
                              sorter: (a: any, b: any) => {
                                  // sort alphabetically and length
                                  if (a.ip?.toLowerCase() < b.ip?.toLowerCase()) {
                                      return -1;
                                  }
                                  if (a.ip?.toLowerCase() > b.ip?.toLowerCase()) {
                                      return 1;
                                  }
                                  return 0;
                              },
                          }),
                          {
                              title: "Message",
                              dataIndex: "msg",
                              key: "msg",
                              sorter: (a: any, b: any) => {
                                  // sort alphabetically and length
                                  if (a.msg?.toLowerCase() < b.msg?.toLowerCase()) {
                                      return -1;
                                  }
                                  if (a.msg?.toLowerCase() > b.msg?.toLowerCase()) {
                                      return 1;
                                  }
                                  return 0;
                              },
                          },
                          DTColProps.Small({
                              title: "Checked User",
                              dataIndex: "checkUser",
                              key: "checkUser",
                              sorter: (a: any, b: any) => {
                                  // sort alphabetically and length
                                  if (a.checkUser?.toLowerCase() < b.checkUser?.toLowerCase()) {
                                      return -1;
                                  }
                                  if (a.checkUser?.toLowerCase() > b.checkUser?.toLowerCase()) {
                                      return 1;
                                  }
                                  return 0;
                              },
                          }),
                          DTColProps.Small(
                              {
                                  title: "Checked Date Time",
                                  dataIndex: "checkTime",
                                  key: "checkTime",
                                  // data example 2024-05-23T14:31:54
                                  sorter: (a: any, b: any) => {
                                      return moment(a.checkTime).unix() - moment(b.checkTime).unix();
                                  },
                                  render: (text: any) => (
                                      <div style={{ display: "flex", flexDirection: "column" }}>
                                          <span>{moment(text).format("YYYY-MM-DD")}</span>
                                          <span>{moment(text).format("HH:mm:ss")}</span>
                                      </div>
                                  ),
                              },
                              ["text-center"]
                          ),
                          DTColProps.Middle({
                              title: "Comments",
                              dataIndex: "comment",
                              key: "comment",
                              sorter: (a: any, b: any) => {
                                  // sort alphabetically and length
                                  if (a.comment?.toLowerCase() < b.comment?.toLowerCase()) {
                                      return -1;
                                  }
                                  if (a.comment?.toLowerCase() > b.comment?.toLowerCase()) {
                                      return 1;
                                  }
                                  return 0;
                              },
                          }),
                          {
                              title: "Date Range",
                              dataIndex: "dateRange",
                              key: "dateRange",
                              options: {
                                  visible: false,
                                  filter: {
                                      type: ComponentType.daterange,
                                      value: "",
                                      rules: [{ required: true, message: REQUIRED_FIELD }],
                                      dateFormat: "YYYY-MM-DD",
                                      inputProps: {
                                          allowClear: false,
                                          showTime: false,
                                          dateOnly: true,
                                          disabledDate: (current: any) => current && current > moment().endOf("day"),
                                      },
                                  },
                              },
                          },
                      ],
        };
    }, [type, rcIssuesLogMainServers]);

    const massagedData = useMemo(
        () =>
            typeInfo.isMT5TypeOrder
                ? defaultIfEmptyOrNull(data, [])
                      .map((x: any) => ({ ...x, key: `${x.serverUno}-${x.orderId}` }))
                      .sort((a: any, b: any) => new Date(a.openTime).getTime() - new Date(b.openTime).getTime())
                : defaultIfEmptyOrNull(data, [])
                      .map((x: any) => ({ ...x, key: `${x.id}` }))
                      .sort((a: any, b: any) => new Date(a.time).getTime() - new Date(b.time).getTime()),
        [typeInfo, data]
    );

    const handleDownload = useCallback(async () => {
        setIsDownloading(true);
        if (typeInfo.isMT5TypeOrder) {
            plainAxiosInstance
                .post(
                    `${APIs.RC_ISSUES_LOG.POST_ISSUES_LOG_MT5_DOWNLOAD}`,
                    {
                        startTime: filterParams.start,
                        endTime: filterParams.end,
                    },
                    {
                        headers: {
                            Accept: "application/octet-stream,text/csv, */*",
                        },
                        responseType: "blob",
                    }
                )
                .then(response => {
                    const contentType = response.headers["content-type"];
                    const fileName = getFileNameFromResponseHeader(response, `issuelog_history_${type}.csv`);
                    if (
                        contentType === "application/octet-stream" ||
                        contentType === "text/csv" ||
                        contentType === "text/csv;charset=UTF-8" ||
                        contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    ) {
                        // Handle the file download response
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", fileName);
                        document.body.appendChild(link);
                        link.click();
                        // Clean up
                        window.URL.revokeObjectURL(url);
                        notification.success({
                            message: "Downloaded",
                            description: `${type} History downloaded successfully`,
                        });
                    } else {
                        notification.error({
                            message: "Error",
                            description: `Received non-file response. Error: ${response}`,
                        });
                        console.log("Received non-file response:", response);
                    }
                })
                .catch(err => {
                    notification.error({
                        message: "Error",
                        description: `Download error: ${err}`,
                    });
                    console.log("download error", err);
                })
                .finally(() => setIsDownloading(false));
        } else {
            plainAxiosInstance
                .get(
                    `${APIs.RC_ISSUES_LOG.GET_DOWNLOAD_ISSUES_LOG}/${typeInfo.typeNumber}?startDate=${filterParams.start}&endDate=${filterParams.end}`,
                    {
                        headers: {
                            Accept: "application/octet-stream,text/csv, */*",
                        },
                        responseType: "blob",
                    }
                )
                .then(response => {
                    const contentType = response.headers["content-type"];
                    const fileName = getFileNameFromResponseHeader(response, `issuelog_history_${type}.csv`);
                    if (
                        contentType === "application/octet-stream" ||
                        contentType === "text/csv" ||
                        contentType === "text/csv;charset=UTF-8" ||
                        contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    ) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", fileName);
                        document.body.appendChild(link);
                        link.click();

                        window.URL.revokeObjectURL(url);
                        notification.success({
                            message: "Downloaded",
                            description: `${type} History downloaded successfully`,
                        });
                    } else {
                        notification.error({
                            message: "Error",
                            description: `Received non-file response. Error: ${response}`,
                        });
                        console.log("Received non-file response:", response);
                    }
                })
                .catch(err => {
                    notification.error({
                        message: "Error",
                        description: `Download error: ${err}`,
                    });
                    console.log("download error", err);
                })
                .finally(() => setIsDownloading(false));
        }
    }, [typeInfo, filterParams, type]);

    const options: FlexiDataTableOptionsProps = useMemo(
        () => ({
            serverFiltering: true,
            refresh: {
                timer: false,
            },
            ...(!typeInfo.isMT5TypeOrder && {
                export: {
                    text: "Download",
                },
            }),
        }),
        [typeInfo]
    );

    const componentCallback: FlexiDataTableCallbackProps = (type, FormData) => {
        switch (type) {
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                setFilterParams(
                    Object.keys(FormData).reduce((acc: any, key: string) => {
                        if (key === "dateRange") {
                            acc["start"] = moment(FormData[key][0]).format("YYYY-MM-DD");
                            acc["end"] = moment(FormData[key][1]).format("YYYY-MM-DD");
                        } else {
                            acc[key] = FormData[key];
                        }
                        return acc;
                    }, {})
                );
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                handleDownload();
                break;
            case CALLBACK_KEY.REFRESH:
                setRunRefetchDataList(true);
                break;
            default:
                break;
        }
    };

    const getLogs = useCallback(() => {
        setIsLoading(true);
        if (typeInfo.isMT5TypeOrder) {
            const formData = new FormData();
            formData.append("startTime", filterParams.start);
            formData.append("endTime", filterParams.end);
            plainAxiosInstance
                .post(APIs.RC_ISSUES_LOG.POST_ISSUES_LOG_MT5, formData)
                .then((res: any) => setData(res.data && res.data.length > 0 ? res.data : []))
                .catch((error: any) =>
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("error retrieve records", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
                )
                .finally(() => setIsLoading(false));
        } else {
            plainAxiosInstance
                .get(
                    `${APIs.RC_ISSUES_LOG.GET_ISSUES_LOG_HISTORY}/${mapIssueLogBackToEnum(type)}?startDate=${filterParams.start}&endDate=${
                        filterParams.end
                    }`
                )
                .then((res: any) => setData(res.data && res.data.length > 0 ? res.data : []))
                .catch((error: any) =>
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("error retrieve records", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
                )
                .finally(() => setIsLoading(false));
        }
    }, [typeInfo, type, filterParams]);

    useEffect(() => {
        if (runRefetchDataList) {
            getLogs();
            setRunRefetchDataList(false);
        }
        return () => {};
    }, [runRefetchDataList, getLogs]);

    return (
        <>
            <Space direction="vertical" style={{ width: "100%", padding: "1rem" }}>
                <FlexiDataTable
                    bordered
                    rowKeyProperty="key"
                    title={""}
                    columns={typeInfo.tableColumns}
                    dataSource={massagedData}
                    options={options}
                    loading={isLoading}
                    callback={componentCallback}
                    filterInitialValue={{
                        dateRange: [moment(), moment()],
                    }}
                    pagination={{
                        pageSizeOptions: [10, 20, 50, 100, 1000],
                    }}
                    exporting={isDownloading}
                />
            </Space>
            {/* <BasicFilter>
                <Row gutter={[8, 8]}>
                    <Col span={16} xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 8 }}>
                        <Text>Date Range *</Text>
                        <RangePicker
                            style={{ width: "100%" }}
                            format="YYYY-MM-DD"
                            onChange={(dates, dateStrings) => {
                                // console.log(dateStrings);
                                // console.log("updated");
                                if (dateStrings?.[0] && dateStrings?.[1]) {
                                    setFilter({ [type]: { start: dateStrings[0], end: dateStrings[1] } });
                                }
                            }}
                            // disallow user to clear date
                            allowClear={false}
                            // disable selecting the day after today, and 20 days before today
                            disabledDate={current => {
                                return (
                                    current && current > moment().endOf("day")
                                    //    || current < moment().subtract(20, "days").startOf("day")
                                );
                            }}
                            // read start and end from store
                            value={[moment(filter.start), moment(filter.end)]}
                        />
                    </Col>
                </Row>
            </BasicFilter>
            <Space direction="vertical" style={{ width: "100%", padding: "1rem" }}>
                {type === "MT5_ORDER_TYPE" && (
                    <>
                        {isLoadingMT5 ? (
                            <MessageCard type="info">Loading...</MessageCard>
                        ) : rcIssuesLogMT5Error ? (
                            <MessageCard type="error">{rcIssuesLogMT5Error}</MessageCard>
                        ) : massagedData?.length === 0 ? (
                            <MessageCard type="info">
                                No record from <span style={{ color: colorMap.primary }}>{filter.start}</span> to{" "}
                                <span style={{ color: colorMap.primary }}>{filter.end}</span>
                            </MessageCard>
                        ) : (
                            <>
                                <Title level={5}>{capsToTitleCase(type)} History</Title>
                                <MessageCard type={"info"}>
                                    {massagedData?.length} record{massagedData?.length >= 2 ? "s" : ""}
                                </MessageCard>
                                <Space style={{ width: "100%" }}>
                                    <Button disabled={massagedData?.length <= 0} onClick={() => handleDownload()}>
                                        Download
                                    </Button>
                                </Space>
                                <Table
                                    columns={typeInfo.tableColumns}
                                    dataSource={massagedData}
                                    size="small"
                                    pagination={{ defaultPageSize: 50, pageSizeOptions: ["10", "20", "50", "100", "500", "1000"] }}
                                    // scroll={{ y: 500 }}
                                />
                            </>
                        )}
                    </>
                )}
                {type !== "MT5_ORDER_TYPE" && (
                    <>
                        {isLoading || isLoadingMainServers ? (
                            <MessageCard type="info">Loading...</MessageCard>
                        ) : rcIssuesLogHistoryError ? (
                            <MessageCard type="error">{rcIssuesLogHistoryError}</MessageCard>
                        ) : massagedData?.length === 0 ? (
                            <MessageCard type="info">
                                No record from <span style={{ color: colorMap.primary }}>{filter.start}</span> to{" "}
                                <span style={{ color: colorMap.primary }}>{filter.end}</span>
                            </MessageCard>
                        ) : (
                            <>
                                <Title level={5}>{capsToTitleCase(type)} History</Title>
                                <MessageCard type={"info"}>
                                    {massagedData?.length} record{massagedData?.length >= 2 ? "s" : ""}
                                </MessageCard>
                                <Space style={{ width: "100%" }}>
                                    <Button disabled={massagedData?.length <= 0} onClick={() => handleDownload()}>
                                        Download
                                    </Button>
                                </Space>
                                <Table
                                    columns={typeInfo.tableColumns}
                                    dataSource={massagedData}
                                    size="small"
                                    pagination={{ defaultPageSize: 50 }}
                                    // scroll={{ y: 500 }}
                                />
                            </>
                        )}
                    </>
                )}
            </Space> */}
        </>
    );
};

export default CommonIssuesLogHistoryTable;
