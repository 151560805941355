import { useMemo, useState } from "react";
import { useGetSpreadAlarmListQuery } from "../../../../store/apis/pricemonitor/spreadalarm";
import { CheckOutlined, CopyOutlined, LineChartOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Modal, notification, Tooltip } from "antd";
import LoadingComponent from "../../../../components/Loading";
import { FlexiDataTableOptionsProps, PriceMonitorSpreadAlarmDetailsProps, PriceMonitorSpreadAlarmProps } from "../../../../constants/type";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler, currencyRender } from "../../../../utils/Common";
import FlexiDataTable from "../../../../components/FlexiDataTable";
import { plainAxiosInstance } from "../../../../services/axiosSetup";
import { APIs } from "../../../../services/apis";
import { SUCCESS_FAILED } from "../../../../constants";
import SpreadLineChart from "./spreadLineChart";

export interface PriceMonitorSpreadAlarm30MinsProps { }

const PriceMonitorSpreadAlarm30Mins = (props: PriceMonitorSpreadAlarm30MinsProps) => {
    const { data, isError, isFetching, refetch } = useGetSpreadAlarmListQuery({ url: "/30/1/0" }, { pollingInterval: 15000 });
    const [modalTitle, setModalTitle] = useState<string>("");
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [spreadLineData, setSpreadLineData] = useState<PriceMonitorSpreadAlarmDetailsProps | undefined>(undefined);

    const markupData = useMemo(() => {
        if (isError) {
            return { currentServerTime: "", data: [] as PriceMonitorSpreadAlarmProps[] };
        }
        return {
            currentServerTime: data?.serverTime,
            data:
                data?.data
                    .filter(x => x.symbolType === 0)
                    .map(x => ({
                        ...x,
                        detail: x.detail.map(y => ({
                            ...y,
                            rowKey: `${y.server}_${y.symbol}_${y.dateTimeStr}`,
                            showSpread: currencyRender(y.spread.toFixed(0)),
                            showSpreadThreshold: currencyRender(y.spreadThreshold.toFixed(0)),
                            showLowThreshold: currencyRender(y.lowThreshold.toFixed(0)),
                        })),
                    })) || [],
            exchangeData:
                data?.data
                    .filter(x => x.symbolType === 1)
                    .map(x => ({
                        ...x,
                        detail: x.detail.map(y => ({
                            ...y,
                            rowKey: `${y.server}_${y.symbol}_${y.dateTimeStr}`,
                            showSpread: currencyRender(y.spread.toFixed(0)),
                            showSpreadThreshold: currencyRender(y.spreadThreshold.toFixed(0)),
                            showLowThreshold: currencyRender(y.lowThreshold.toFixed(0)),
                        })),
                    })) || [],
        };
    }, [isError, data]);

    const tableCols: any = [
        {
            title: "Our Symbol",
            dataIndex: "symbol",
            key: "symbol",
        },
        {
            title: "First Appear",
            dataIndex: "firstAppearStr",
            key: "firstAppearStr",
        },
        {
            title: "Our Servers",
            dataIndex: "ourServerListStr",
            key: "ourServerListStr",
        },
        DTColProps.XSmall(
            {
                title: "Count",
                dataIndex: "count",
                key: "count",
            },
            ["text-center"]
        ),
        DTColProps.XSmall(
            {
                title: "Solved",
                dataIndex: "symbol",
                key: "symbol",
                render: (text: string, record: PriceMonitorSpreadAlarmProps) => {
                    return <Button type="primary" icon={<CheckOutlined />} className="shadow-light" onClick={() => processResolveBySymbol(record)} />;
                },
            },
            ["text-center"]
        ),
        DTColProps.XXSmall(
            {
                title: "",
                dataIndex: "copyAll",
                key: "copyAll",
                render: (text: string, record: PriceMonitorSpreadAlarmProps) => {
                    return <Tooltip title="Copy All" placement="top">
                        <Button type="text" icon={<CopyOutlined />} onClick={() => handleCopyAll(record)} />
                    </Tooltip>;
                },
            },
            ["text-center"]
        ),
    ];

    const tableOpts: FlexiDataTableOptionsProps = {
        enableFilter: false,
        expandable: {
            expandedRowRender: (record: PriceMonitorSpreadAlarmProps) => {
                return (
                    <div className="expandable-row-container">
                        <FlexiDataTable
                            bordered
                            title={false}
                            rowKeyProperty="rowKey"
                            columns={[
                                DTColProps.XXSmall(
                                    {
                                        title: "Line",
                                        dataIndex: "Action",
                                        key: "Action",
                                        render: (text: string, record: PriceMonitorSpreadAlarmDetailsProps) => {
                                            return <Button type="primary" icon={<LineChartOutlined />} onClick={() => showSpreadLine(record)} />;
                                        },
                                    },
                                    ["text-center"]
                                ),
                                DTColProps.DateTime({
                                    title: "Date Time",
                                    dataIndex: "dateTimeStr",
                                    key: "dateTimeStr",
                                }),
                                {
                                    title: "Server",
                                    dataIndex: "server",
                                    key: "server",
                                },
                                {
                                    title: "Symbol",
                                    dataIndex: "symbol",
                                    key: "symbol",
                                },
                                DTColProps.Small(
                                    {
                                        title: "Spread",
                                        dataIndex: "showSpread",
                                        key: "showSpread",
                                    },
                                    ["text-right"]
                                ),
                                DTColProps.Small(
                                    {
                                        title: "Spread Threshold",
                                        dataIndex: "showSpreadThreshold",
                                        key: "showSpreadThreshold",
                                    },
                                    ["text-right"]
                                ),
                                DTColProps.Small(
                                    {
                                        title: "Low Threshold",
                                        dataIndex: "showLowThreshold",
                                        key: "showLowThreshold",
                                    },
                                    ["text-right"]
                                ),
                                DTColProps.XSmall(
                                    {
                                        title: "Solved",
                                        dataIndex: "symbol",
                                        key: "symbol",
                                        render: (text: string, record: PriceMonitorSpreadAlarmDetailsProps) => {
                                            return (
                                                <Button
                                                    type="primary"
                                                    icon={<CheckOutlined />}
                                                    className="shadow-light"
                                                    onClick={() => processResolveById(record)}
                                                />
                                            );
                                        },
                                    },
                                    ["text-center"]
                                ),
                                DTColProps.XXSmall(
                                    {
                                        title: "",
                                        dataIndex: "copy",
                                        key: "copy",
                                        render: (text: string, record: PriceMonitorSpreadAlarmDetailsProps) => {
                                            let copyText: string = "", copyTextPrefix: string = "";
                                            if (record.alarmType === 1) {
                                                copyTextPrefix = "Max";
                                            } else if (record.alarmType === 2) {
                                                copyTextPrefix = "Min";
                                            } else if (record.alarmType === 3) {
                                                copyTextPrefix = "Both";
                                            };
                                            copyText = `(${copyTextPrefix}) Please note that spread alarm of ${record.symbol} on ${record.server}`;

                                            return <Tooltip title="Copy" placement="top">
                                                <Button
                                                    type="text"
                                                    icon={<CopyOutlined />}
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(copyText);
                                                        notification.success({
                                                            message: "Copied to clipboard",
                                                            description: `${copyText}`,
                                                        });
                                                    }} />
                                            </Tooltip>;
                                        },
                                    },
                                    ["text-center"]
                                ),
                            ]}
                            dataSource={record.detail}
                            options={{
                                enableFilter: false,
                                showHideColumns: false,
                            }}
                            loading={false}
                        />
                    </div>
                );
            },
        },
    };

    const handleCopyAll = (currRow: PriceMonitorSpreadAlarmProps) => {
        let copyText: string = "", copyTextPrefix: string = "";
        const alarmTypes = currRow.alarmTypeStr.split(",").map(Number);
        const alarmTypeMap: Record<number, string> = {
            1: "Max",
            2: "Min",
            3: "Both",
        };
        copyTextPrefix = alarmTypes.map((num: number) => alarmTypeMap[num]).join("/");
        copyText = `(${copyTextPrefix}) Please note that spread alarm of ${currRow.symbolListStr.replaceAll(/,/g, ", ")} on ${currRow.ourServerListStr.replaceAll(/,/g, ", ")}`;

        navigator.clipboard.writeText(copyText);
        notification.success({
            message: "Copied to clipboard",
            description: `${copyText}`,
        });
    };

    const processResolveBySymbol = (record: PriceMonitorSpreadAlarmProps) => {
        const formData = new FormData();
        formData.append("keyStr", record.symbol);

        plainAxiosInstance
            .post(`${APIs.RC_PRICE_MONITOR.RESOLVE_SPREAD_ALARM_ITEM}/${300000}/0`, formData)
            .then(resp => {
                if (resp.data > 0) {
                    refetch();
                }
            })
            .catch(error => {
                ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler(`Failed to resolve spread alarm${err.response.data.message ? `: ${err.response.data.message}` : `: ${err.message}`}`, SUCCESS_FAILED.OTHERS_FAILED);
                });
            });
    };

    const processResolveById = (record: PriceMonitorSpreadAlarmDetailsProps) => {
        const formData = new FormData();
        formData.append("keyStr", `${record.dateTimeStr},${record.serverUno},${record.symbol}`);

        plainAxiosInstance
            .post(APIs.RC_PRICE_MONITOR.RESOLVE_SPREAD_ALARM_BY_ID, formData)
            .then(resp => {
                if (resp.data > 0) {
                    refetch();
                }
            })
            .catch(error => {
                ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler(`Failed to resolve spread alarm${err.response.data.message ? `: ${err.response.data.message}` : `: ${err.message}`}`, SUCCESS_FAILED.OTHERS_FAILED);
                });
            });
    };

    const showSpreadLine = (data: PriceMonitorSpreadAlarmDetailsProps | undefined) => {
        if (data === undefined) {
            setSpreadLineData(data);
            setModalTitle("");
            setIsModalVisible(false);
        } else {
            setModalTitle(`${data.server}-${data.symbol} ${data.dateTimeStr}`);
            setSpreadLineData(data);
            setIsModalVisible(true);
        }
    };

    return (
        <>
            <div className="alarm-30mins-container">
                <div className="top-panel">
                    <div className="resync-div">
                        <span className="time-panel">
                            Last Refresh Time(Server): {isFetching ? <LoadingComponent /> : `${markupData.currentServerTime}`}
                        </span>
                        <Button type="primary" icon={<SyncOutlined />} loading={isFetching} onClick={() => refetch()} />
                    </div>
                </div>
                <div className="general-panel">
                    <FlexiDataTable
                        bordered
                        rowKeyProperty="symbol"
                        title={<span className="flexi-table-small-title-style">General</span>}
                        columns={tableCols}
                        options={tableOpts}
                        dataSource={markupData.data}
                        loading={false}
                    />
                </div>
                <div className="future-panel">
                    <FlexiDataTable
                        bordered
                        rowKeyProperty="symbol"
                        title={<span className="flexi-table-small-title-style">Futures</span>}
                        columns={tableCols}
                        options={tableOpts}
                        dataSource={markupData.exchangeData}
                        loading={false}
                    />
                </div>
            </div>
            <Modal
                width={800}
                style={{ top: 5 }}
                open={isModalVisible}
                title={modalTitle}
                okButtonProps={{ style: { display: "none" } }}
                cancelText="Close"
                onCancel={() => showSpreadLine(undefined)}
            >
                <SpreadLineChart data={spreadLineData} />
            </Modal>
        </>
    );
};

export default PriceMonitorSpreadAlarm30Mins;
