import { useState, useEffect, useMemo, useCallback } from "react";
import { FormComponent } from "@/components/FormComponent";
import LoadingComponent from "@/components/Loading";
import { SUCCESS_FAILED, ComponentType, InnerPageActionMode } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { TreeItemProps, BrandsList, Brands2, Module, CreateEditInlineFormBased } from "@/constants/type";
import { apiRequest } from "@/services/apiConfig";
import { APIs } from "@/services/apis";
import { ErrorMessageHandler, ErrorCatchValidator } from "@/utils/Common";
import { objectRemoveProps } from "@/utils/object";
import { Form, Popconfirm, Button, Row, Col } from "antd";
import Permission from "./Components/permissions";
import { getTreeList } from "./ViewRolePage";
import { defaultIfEmptyOrNull } from "@/utils/string";
import SitePageHeaderInline, { SitePageHeaderInlineActionType } from "@/components/PageHeader/inlineIndex";

export interface RoleCreateEditPageProps extends CreateEditInlineFormBased {
    callback: (action: InnerPageActionMode, value?: any) => void;
    resetState: number;
};

interface CreateRoleMenuProps {
    roleId: number;
    roleName: string;
    description: string;
    modules: number[];
};

const initialValues = (): CreateRoleMenuProps => ({ roleId: 0, roleName: "", description: "", modules: [] });
const moduleKeyPrefix: string = "rce-mcb-";

function RoleCreateEditPage(props: RoleCreateEditPageProps) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [modules, setModules] = useState<TreeItemProps[]>([]);
    const [oriMenulist, setOriMenulist] = useState<Module[]>([]);
    const [brands, setBrands] = useState<BrandsList[]>([]);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [roleForm] = Form.useForm();

    const stateInfo = useMemo(() => {
        return {
            isAddAction: props.mode === InnerPageActionMode.CREATE_NEW,
            data: defaultIfEmptyOrNull(props.id, ""),
        };
    }, [props.mode, props.id]);

    const processBackAndRefetch = () => setTimeout(() => props.callback && props.callback(InnerPageActionMode.BACK, { refreshMainList: true }), 400);

    const onSubmit = (values: any) => {
        setIsLoading(true);
        setIsSubmitting(true);

        let submitData = objectRemoveProps(
            { ...values },
            Object.keys(values).filter((x) => x.indexOf(moduleKeyPrefix) > -1)
        );
        submitData["modules"] = Object.keys(values)
            .filter((x) => x.indexOf(moduleKeyPrefix) > -1 && values[x])
            .map((x) => x.replace(moduleKeyPrefix, ""));
        // let menuOnly = oriMenulist.filter((x) => x.type === 1).map((x) => x.id);
        // submitData.modules = submitData.modules.map((x: string) => parseInt(x)).filter((x: number) => !menuOnly.includes(x));
        if (stateInfo.isAddAction) {
            apiRequest(APIs.CREATE_ROLE_MENU, submitData)
                .then(() => {
                    ErrorMessageHandler("New role", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                    processBackAndRefetch();
                    setIsSubmitting(false);
                })
                .catch((error) => {
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("new role", SUCCESS_FAILED.FAILED_CREATE_DATA, err));
                    setIsLoading(false);
                    setIsSubmitting(false);
                });
        } else {
            apiRequest(APIs.UPDATE_ROLE_MENU, submitData)
                .then((data) => {
                    ErrorMessageHandler("Existing role", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                    processBackAndRefetch();
                    setIsSubmitting(false);
                })
                .catch((error) =>
                    ErrorCatchValidator(error, (err: any) => {
                        ErrorMessageHandler("existing role", SUCCESS_FAILED.FAILED_UPDATE_DATA, err);
                        setIsLoading(false);
                        setIsSubmitting(false);
                    })
                );
        }
    };

    const getConfigInfo = useCallback(() => {
        setIsLoading(true);
        // apiRequest(APIs.GET_BRAND_SERVER_LIST, {})
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["brand"] })
            .then((data: any) => {
                setBrands(data.brands);

                apiRequest(APIs.GET_MENU_LISTING, {})
                    .then((data: any) => {
                        let sortedData: Module[] = data as Module[];
                        sortedData.sort((a: Module, b: Module) => a.order - b.order);

                        setModules(getTreeList(sortedData));
                        setOriMenulist(sortedData);

                        if (!stateInfo.isAddAction) {
                            apiRequest(APIs.GET_ROLE_MENU_LISTING, { roleId: stateInfo.data, withModules: true })
                                .then((data: any) => {
                                    let tmp = data[0];
                                    let moduleObj: any = tmp.modules
                                        .filter((x: any) => (x.status as number) === 1)
                                        .map((x: any) => `${moduleKeyPrefix}${x.menuId}`)
                                        .reduce((obj: any, x: string) => {
                                            obj[x] = true;
                                            return obj;
                                        }, {});

                                    let objValue = Object.assign(
                                        {},
                                        initialValues(),
                                        {
                                            roleId: tmp.roleId,
                                            roleName: tmp.role,
                                            description: tmp.description,
                                            brands: tmp.brands.map((x: Brands2) => x.brandId),
                                            //modules: tmp.modules.filter((x: any) => (x.status as number) === 1).map((x: any) => x.menuId.toString()),
                                        },
                                        moduleObj
                                    );

                                    roleForm.setFieldsValue(objValue);
                                    setIsLoading(false);
                                })
                                .catch((error: any) => {
                                    ErrorCatchValidator(error, (err: any) =>
                                        ErrorMessageHandler("role details", SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                                    );
                                    processBackAndRefetch();
                                });
                        } else {
                            setIsLoading(false);
                        }
                    })
                    .catch((error: any) => {
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("role listing", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                        processBackAndRefetch();
                    });
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("brand listing", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                processBackAndRefetch();
            });
    }, [stateInfo, roleForm]);

    const deleteProfile = (roleId: string) => {
        setIsLoading(true);
        apiRequest(APIs.DELETE_ROLE_MENU, { roleId })
            .then((data) => {
                ErrorMessageHandler("The role detail", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                processBackAndRefetch();
            })
            .catch((error) => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("role detail", SUCCESS_FAILED.FAILED_DELETE_DATA, err)))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getConfigInfo();
        return () => { };
    }, [getConfigInfo, stateInfo]);

    return (
        <SitePageHeaderInline
            title={stateInfo.isAddAction ? "Create New Role" : "Edit Role"}
            callback={(action: SitePageHeaderInlineActionType) => {
                switch (action) {
                    case SitePageHeaderInlineActionType.OnBack:
                    case SitePageHeaderInlineActionType.OnCancel:
                        props.callback && props.callback(InnerPageActionMode.BACK);
                        return;
                    default:
                        break;
                }
            }}
            extraProps={{
                extra: [
                    <Button key={"regular-submit"} type="primary" htmlType="submit" loading={isSubmitting} onClick={() => {
                        roleForm
                            .validateFields()
                            .then((res) => onSubmit(res))
                            .catch((err) => console.log("form-error:", err));
                    }}>
                        Submit
                    </Button>,
                    <Button key={"cancel"} onClick={() => {
                        roleForm.resetFields();
                        props.callback && props.callback(InnerPageActionMode.BACK)
                    }}>
                        Cancel
                    </Button>,
                    stateInfo.isAddAction ? []
                        : [
                            <Popconfirm
                                key={"cetmp-del"}
                                title="Confirm to delete?"
                                onConfirm={() => deleteProfile(stateInfo.data.toString())}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="primary" danger>
                                    Delete Role
                                </Button>
                            </Popconfirm>,
                        ],
                ],
            }}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Submitting..." />
                </div>
            ) : (
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={roleForm}
                    layout="horizontal"
                    initialValues={initialValues}
                    onFinish={onSubmit}
                >
                    <Row>
                        <Col span={15}>
                            <FormComponent label={""} name={"roleId"} extra={{ type: ComponentType.hidden, value: "" }} />
                            <FormComponent
                                label="Role Name"
                                name="roleName"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Description"
                                name="description"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15}>
                            <FormComponent
                                label="Brands"
                                name="brands"
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: brands.map((x) => ({ text: x.brand, value: x.id })),
                                    inputProps: {
                                        mode: "multiple",
                                        allowClear: true,
                                    },
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={5} style={{ textAlign: "right", paddingRight: "0.651vw" }}>
                            Modules :
                        </Col>
                        <Col span={10}>
                            {/* <FormComponent
                                label="Modules"
                                name="modules"
                                extra={{
                                    type: ComponentType.tree,
                                    value: modules,
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            /> */}
                            <Permission moduleList={modules} selectedList={[]} keyPrefix={moduleKeyPrefix} />
                        </Col>
                    </Row>
                </Form>
            )}
        </SitePageHeaderInline>
    );
}

export default RoleCreateEditPage;
