import { FormComponent } from "@/components/FormComponent";
import { ComponentType, SUCCESS_FAILED } from "@/constants";
import { apiRequest, APIs } from "@/services/apiConfig";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { Button, Col, Form, message, Row, Typography } from "antd"

const { Title } = Typography;

interface LPEquityChangeDepositWithdrawalFormProps {
    title: string;
    marketGroupId: number;
}

const LPEquityChangeDepositWithdrawalForm = (props: LPEquityChangeDepositWithdrawalFormProps) => {
    const [form] = Form.useForm();

    const handleSubmit = (values: any) => {
        if (!values.deposit && !values.withdrawal) {
            message.warning("Please fill up deposit or withdrawal.");
            return;
        }

        apiRequest(APIs.INSERT_PNL_DEPOSIT_WITHDRAWAL, {
            marketGroupId: props.marketGroupId,
            deposit: values.deposit,
            withdrawal: values.withdrawal,
        }).then(res => {
            ErrorMessageHandler("lp equity change insert deposit withdrawal", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
            form.resetFields()
        }).catch(error =>
            ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("lp equity change insert deposit withdrawal", SUCCESS_FAILED.FAILED_CREATE_DATA, err))
        );
    }

    return (
        <Form
            style={{
                display: 'flex',
                alignItems: 'center',
                gap: 10
            }}
            form={form}
            onFinish={handleSubmit}
        >
            <Title level={5} style={{ marginBottom: 0, flexBasis: '100px' }}>
                {props.title}
            </Title>
            <FormComponent
                label="Deposit"
                name="deposit"
                extra={{
                    type: ComponentType.number,
                    value: "",
                    itemProps: {
                        colon: false,
                        style: { marginBottom: 0, flexGrow: 1 }
                    },
                    inputProps: {
                        style: { width: '100%' },
                        min: 0,
                        controls: false,
                    }
                }}
            />
            <FormComponent
                label="Withdrawal"
                name="withdrawal"
                extra={{
                    type: ComponentType.number,
                    value: "",
                    itemProps: {
                        colon: false,
                        style: { marginBottom: 0, flexGrow: 1 }
                    },
                    inputProps: {
                        style: { width: '100%' },
                        min: 0,
                        controls: false,
                    }
                }}
            />
            <Button htmlType="submit">
                Add
            </Button>
        </Form>
    )
}

export default LPEquityChangeDepositWithdrawalForm