import { Col, Form, Row, Space } from "antd";
import { APIs, apiRequest } from "../../../services/apiConfig";
import { DimensionLevelObjectConfig, ErrorCatchValidator, ErrorMessageHandler, genMetricSelectorsByComponent } from "../../../utils/Common";
import { DIMENSION_LEVEL, INTRADAY_COMPONENTS_BY_ID, SUCCESS_FAILED } from "../../../constants";
import { useEffect, useMemo, useState } from "react";
import Statistic from "./Components/Statistics";
import CompanyPNLSummary from "./Components/CompanyPNLSummary";
import SymbolSummary from "./Components/SymbolSummary";
import IntradayFilterComponent from "./Components/filter/filterComponent";
import { MimMetricsExtra } from "../../../constants/type";
import LoadingComponent from "../../../components/Loading";
import FixedTimeCountDown from "../../../components/Common/CountDown/FixedTimeCountDown";
import PnlChartSummary from "../Summary/Components/pnlChartSummary";
import WorldMapData from "../../../assets/data/worldmap.json";
import TopBottomTableSummary from "./Components/TopBottomTableSummary";
import AlarmSummaryForSearch from "./Components/AlarmSummaryForSearch";
import { ToObjectWithKey } from "../../../utils/array";
import { useLocation, useNavigate } from "react-router-dom";
import { isEmptyOrNull } from "../../../utils/string";

export interface DimemsionFilterType {
    dimensionLevel: number;
    filterIn: string;
}

export interface SelectedMetricType {
    componentID: number;
    metricName: string;
}

export interface UserList {
    id: string;
    name: string;
}

const transformResult = (result: any) => ({
    ...result,
    Account: result.Account ? result.Account.map((item: string) => item.split("|").slice(0, 2).join("|")) : result.Account,
    AccGroup: result.AccGroup ? result.AccGroup.map((item: string) => item.split("|").slice(0, 2).join("|")) : result.AccGroup,
    IB: result.IB ? result.IB.map((item: string) => item.split("|").slice(0, 2).join("|")) : result.IB,
});

export const mapFilterParams = (filterParams: any) => {
    const modifiedFilterParams = transformResult(filterParams);
    const result: { dimensionLevel: DIMENSION_LEVEL; filterIn: any }[] = [];

    for (const [key, values] of Object.entries(modifiedFilterParams)) {
        const dimensionLevel: DIMENSION_LEVEL | undefined = DIMENSION_LEVEL[key.toUpperCase() as keyof typeof DIMENSION_LEVEL];

        if (dimensionLevel !== undefined && modifiedFilterParams[key] !== undefined && modifiedFilterParams[key] !== null) {
            const filterIn = Array.isArray(modifiedFilterParams[key])
                ? modifiedFilterParams[key].map((value: any) => value)
                : [modifiedFilterParams[key]];

            if (filterIn && filterIn.length > 0) {
                const existingItem = result.find(item => item.dimensionLevel === dimensionLevel);

                if (existingItem) {
                    existingItem.filterIn = filterIn;
                } else {
                    result.push({
                        dimensionLevel,
                        filterIn,
                    });
                }
            }
        }
    }

    return result;
};

const IntradayMonitorSearch = () => {
    const [filterOptList, setFilterOptList] = useState<any>({
        Account: [],
        AccGroup: [],
        Symbol: [],
        SymbolAssetType: [],
        Server: [],
        Brand: [],
        Country: [],
        IB: [],
    });
    const [filterForm] = Form.useForm();
    const [filterParams, setFilterParams] = useState<any>({});
    const [refetchData, setRefetchData] = useState<boolean>(false);
    const [MIMMetrics, setMIMMetrics] = useState<MimMetricsExtra[]>([]);
    const [userList, setUserList] = useState<UserList[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [PKLastBucketTimestamp, setPKLastBucketTimestamp] = useState<string>("");
    const [refetch, setRefetch] = useState<number>(0);
    let navigate = useNavigate();
    let location = useLocation();

    const mappingDataObj = useMemo(() => {
        return {
            dimension: ToObjectWithKey(DimensionLevelObjectConfig("IntradayDimensionArr"), "value"),
            country: filterOptList.Country && filterOptList.Country.length > 0 ? ToObjectWithKey(filterOptList.Country, "value") : {},
            metricName:
                MIMMetrics && MIMMetrics.length > 0
                    ? ToObjectWithKey(
                        MIMMetrics.map((x: any) => ({
                            value: x["metricName"],
                            text: x["metricDisplayName"],
                            type: x["metricDataType"],
                        })),
                        "value"
                    )
                    : {},
            symbol: filterOptList.Symbol && filterOptList.Symbol.length > 0 ? ToObjectWithKey(filterOptList.Symbol, "value") : {},
            symbolAssetType:
                filterOptList.SymbolAssetType && filterOptList.SymbolAssetType.length > 0
                    ? ToObjectWithKey(filterOptList.SymbolAssetType, "value")
                    : {},
            server: filterOptList.Server && filterOptList.Server.length > 0 ? ToObjectWithKey(filterOptList.Server, "value") : {},
            brand: filterOptList.Brand && filterOptList.Brand.length > 0 ? ToObjectWithKey(filterOptList.Brand, "value") : {},
        };
    }, [filterOptList, MIMMetrics]);

    const toQueryString = (params: any) => {
        return Object.keys(params)
            .filter(key => !isEmptyOrNull(params[key]))
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(params[key]))
            .join("&");
    };

    const onCallBackSelectedFilterParams = (data: any) => {
        setFilterParams(data);
        Object.keys(data).forEach(key => {
            filterForm.setFieldValue(key, data[key]);
        });

        data ? navigate(`/intradaymonitor/search?` + toQueryString(data)) : navigate(`/intradaymonitor/search`);
    };

    const getAccountList = (searchText: string) => {
        if (searchText && searchText.length > 0) {
            return new Promise(resolve => {
                apiRequest(APIs.GET_MIM_ACCOUNT_SEARCH, { accountId: searchText }).then((res: any) => {
                    let returnResult: any[] = [];
                    if (res && res.length > 0) {
                        returnResult = [
                            {
                                label: <span>Accounts</span>,
                                options: res.map((x: any) => ({
                                    label: (
                                        <div className="els-account-container">
                                            <div>
                                                <span>ID: {x.accountId}</span>
                                                <span>Server: {x.server}</span>
                                            </div>
                                        </div>
                                    ),
                                    value: `${x.accountId}|${x.serverId}|${x.server}`,
                                })),
                            },
                        ];
                    }
                    resolve(returnResult);
                });
            });
        }
        return Promise.resolve([]);
    };

    const getConfigList = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["brand", "cleansymbol", "mimmetric", "server", "symbolassettype", "user"] })
            .then(res => {
                setFilterOptList({
                    Account: [],
                    AccGroup: [],
                    Symbol: res.cleanSymbols.map((x: any) => ({ text: x.name.toUpperCase(), value: x.name.toUpperCase() })),
                    SymbolAssetType: res.symbolAssetTypes.map((x: any) => ({ text: x.name, value: x.id.toString() })),
                    Server: res.servers.map((x: any) => ({ text: x.server, value: x.id.toString() })),
                    Brand: res.brands.map((x: any) => ({ text: x.brand, value: x.id.toString() })),
                    Country: WorldMapData.features
                        .map((x: any) => ({ text: x.properties.name, value: x.properties.iso_alpha_2_code }))
                        .sort((a, b) => a.text.toLowerCase().localeCompare(b.text.toLowerCase())),
                    IB: [],
                });
                setUserList(res.users.map((x: any) => ({ text: x.name, value: x.id })));
                setMIMMetrics(res.mimMetrics);
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("config info", SUCCESS_FAILED.FAILED_LOAD_DATA, err)))
            .finally(() => setIsLoading(false));
    };

    const getLatestBucketTimestamp = () => {
        apiRequest(APIs.GET_SETTING_LIST, { keys: ["PKLastBucketTimestamp"] })
            .then((res: any) => {
                setPKLastBucketTimestamp(res[0].value.replace("T", " "));
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => console.log("Failed to get latest bucket timestamp: ", err));
            });
    };

    const getDataFromUrl = () => {
        const urlParams = location.search.includes("?") && location.search;
        if (urlParams) {
            const queryString = urlParams.split("?")[1];
            const params = new URLSearchParams(queryString);
            const entries = Object.fromEntries(params.entries());

            const filteredParams: any = {
                ...(entries.IB && { IB: entries.IB.split(",") }),
                ...(entries.Country && { Country: entries.Country.split(",") }),
                ...(entries.Brand && { Brand: entries.Brand.split(",") }),
                ...(entries.Server && { Server: entries.Server.split(",") }),
                ...(entries.SymbolAssetType && { SymbolAssetType: entries.SymbolAssetType.split(",") }),
                ...(entries.Symbol && { Symbol: entries.Symbol.split(",") }),
                ...(entries.AccGroup && { AccGroup: entries.AccGroup.split(",") }),
                ...(entries.Account && { Account: entries.Account.split(",") }),
            };

            setFilterParams(filteredParams);

            Object.keys(filteredParams).forEach(key => {
                filterForm.setFieldValue(key, filteredParams[key]);
            });
        } else {
            setFilterParams([]);
            filterForm.resetFields();
        }
    };

    useEffect(() => {
        getLatestBucketTimestamp();
        getDataFromUrl();
        getConfigList();
    }, []);

    useEffect(() => {
        if (location) {
            setIsLoading(true);
            getLatestBucketTimestamp();
            getDataFromUrl();
            getConfigList();
            setRefetchData(false);
        } else {
            setRefetchData(true);
        }
    }, [location]);

    useEffect(() => {
        if (refetchData) {
            setIsLoading(true);
            getLatestBucketTimestamp();
            getDataFromUrl();
            getConfigList();
            setRefetchData(false);
        }
        return () => { };
    }, [refetchData]);

    useEffect(() => getLatestBucketTimestamp(), [refetch]);

    return (
        <div className="intraday-search-container">
            <div className="header-row">
                <div className="left">
                    <div className="main-title">Multi-Level Intraday Monitor</div>
                </div>
                <div className="right">
                    <Space>
                        <FixedTimeCountDown onFinish={() => setRefetch(prev => prev + 1)} />
                        <span className="last-refresh-time">Last Updated Time (Server): {PKLastBucketTimestamp}</span>
                    </Space>
                </div>
            </div>
            <div className="main-content">
                {isLoading ? (
                    <div className="loading-container" style={{ width: "100%" }}>
                        <LoadingComponent tip="Loading..." />
                    </div>
                ) : (
                    <>
                        <IntradayFilterComponent
                            form={filterForm}
                            filterOptions={filterOptList}
                            getAccountList={getAccountList}
                            onFilterSubmit={(values: any) => {
                                onCallBackSelectedFilterParams(values);
                            }}
                            filterParams={filterParams}
                        />
                        <Row className="row-class">
                            <Col span={10} className="left-panel">
                                <Statistic
                                    metrics={genMetricSelectorsByComponent(MIMMetrics, INTRADAY_COMPONENTS_BY_ID.SUMMARY_STATS, "search")}
                                    selectedParams={filterParams}
                                    resetState={refetch}
                                />
                                <AlarmSummaryForSearch
                                    mappingData={mappingDataObj}
                                    onCallBack={onCallBackSelectedFilterParams}
                                    selectedParams={filterParams}
                                    resetState={refetch}
                                    userList={userList}
                                />
                            </Col>
                            <Col span={14} className="right-panel">
                                <div className="chart-summary-container">
                                    <PnlChartSummary
                                        metrics={genMetricSelectorsByComponent(MIMMetrics, INTRADAY_COMPONENTS_BY_ID.PNL_CHART, "search")}
                                        title="Client Daily & Intraday P&L With Turnover Trend Chart"
                                        isSearch={true}
                                        selectedParams={filterParams}
                                        resetState={refetch}
                                    />
                                </div>
                                <TopBottomTableSummary
                                    mimMetrics={MIMMetrics}
                                    filterOptList={{
                                        Server: filterOptList.Server,
                                        Brand: filterOptList.Brand,
                                        Country: filterOptList.Country,
                                        SymbolAssetType: filterOptList.SymbolAssetType,
                                    }}
                                    isSearch={true}
                                    selectedParams={filterParams}
                                    resetState={refetch}
                                />
                            </Col>
                        </Row>
                        <Row className="row-class">
                            <Col span={12} className="left-panel">
                                <CompanyPNLSummary
                                    metrics={genMetricSelectorsByComponent(MIMMetrics, INTRADAY_COMPONENTS_BY_ID.WORLD_MAP)}
                                    selectedParams={filterParams}
                                    resetState={refetch}
                                />
                            </Col>
                            <Col span={12} className="right-panel">
                                <SymbolSummary
                                    metrics={genMetricSelectorsByComponent(MIMMetrics, INTRADAY_COMPONENTS_BY_ID.SYMBOL_SUMMARY)}
                                    isSearch={true}
                                    selectedParams={filterParams}
                                    resetState={refetch}
                                />
                            </Col>
                        </Row>
                    </>
                )}
            </div>
        </div>
    );
};

export default IntradayMonitorSearch;
