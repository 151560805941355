import { useCallback, useEffect, useMemo, useState } from "react";
import FlexiDataTable from "@/components/FlexiDataTable";
import { FlexiDataTableCallbackProps, FlexiDataTableOptionsProps } from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { Button, Col, Form, message, Modal, Row, Select, Tooltip, Upload } from "antd";
import { CALLBACK_KEY, ComponentType, InnerPageActionMode, SUCCESS_FAILED } from "@/constants";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler, getFileNameFromResponseHeader } from "@/utils/Common";
import { DownloadOutlined, ExclamationCircleOutlined, RedoOutlined, SaveOutlined, SyncOutlined, UndoOutlined, UploadOutlined } from "@ant-design/icons";
import { RCPriceCompareServer } from "@/hooks/useRCPriceCompareServers";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { APIs } from "@/services/apis";
import { FormComponent } from "@/components/FormComponent";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { defaultIfEmptyOrNull, isEmptyOrNull } from "@/utils/string";
import { differenceWith, isEqual } from "lodash";
import moment from "moment";
import PanelContainer from "../../PanelContainer";

interface MidPriceSettingOtherComparedData {
    compServerName?: string;
    compServerUno: number;
    compSymbol: string;
    exSpit: number;
    midPriceTimes: number;
    otherSymbolId?: any;
    primarySymbolId?: any;
    serverName?: string;
    serverUno?: number;
    symbol: string;
    isCreateEdit?: InnerPageActionMode.CREATE_NEW | InnerPageActionMode.EDIT | undefined;
    newKey?: string;
    isDeleted?: boolean;
};

const initialSetting = {
    symbol: "",
    compServerUno: null,
    compSymbol: "",
    exSpit: 1,
    midPriceTimes: 30,
};

const MidPriceSetting = () => {
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.CENTRALIZED_SETTINGS_MID_PRICE_SETTINGS_EDIT);

    const [oriData, setOriData] = useState<MidPriceSettingOtherComparedData[]>([]);
    const [data, setData] = useState<MidPriceSettingOtherComparedData[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [currSelectedServer, setCurrSelectedServer] = useState<any>("");
    const [currEdit, setCurrEdit] = useState<InnerPageActionMode.CREATE_NEW | MidPriceSettingOtherComparedData | null>(null);
    const [midPriceSettingForm] = Form.useForm();
    const [ableToResetSave, setAbleToResetSave] = useState<boolean>(false);
    const [isResetting, setIsResetting] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isExporting, setIsExporting] = useState<boolean>(false);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [ownServers, setOwnServers] = useState<RCPriceCompareServer[]>([]);
    const [competitorServers, setCompetitorServers] = useState<RCPriceCompareServer[]>([]);
    const [isFetching, setIsFetching] = useState<boolean>(false);

    const columns = [
        DTColProps.Small({
            title: "Server",
            dataIndex: "serverName",
            key: "serverName",
            sorter: (a: any, b: any) => a.serverName.localeCompare(b.serverName),
        }),
        {
            title: "Symbol",
            dataIndex: "symbol",
            key: "symbol",
            sorter: (a: any, b: any) => a.symbol.localeCompare(b.symbol),
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        },
        DTColProps.Middle({
            title: "Competitor Server",
            dataIndex: "compServerName",
            key: "compServerName",
            sorter: (a: any, b: any) => a.compServerName.localeCompare(b.compServerName),
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Middle({
            title: "Competitor Symbol",
            dataIndex: "compSymbol",
            key: "compSymbol",
            sorter: (a: any, b: any) => a.compSymbol.localeCompare(b.compSymbol),
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Middle(
            {
                title: "Expire Spread",
                dataIndex: "exSpit",
                key: "exSpit",
                sorter: (a: any, b: any) => a.exSpit - b.exSpit,
            },
            ["text-right"]
        ),
        DTColProps.Middle(
            {
                title: "Mid Price Times",
                dataIndex: "midPriceTimes",
                key: "midPriceTimes",
                sorter: (a: any, b: any) => a.midPriceTimes - b.midPriceTimes,
            },
            ["text-right"]
        ),
    ];

    const options: FlexiDataTableOptionsProps = useMemo(() => {
        return {
            add: enableUpdate,
            edit: enableUpdate,
            ...(enableUpdate && {
                delete: (record: MidPriceSettingOtherComparedData, deleteElement: React.ReactNode) => {
                    if (record.isDeleted) {
                        return undefined;
                    }
                    return deleteElement;
                },
            }),
            ...(enableUpdate && {
                rowExtra: (record: MidPriceSettingOtherComparedData) =>
                    record.isDeleted
                        ? [
                            { icon: <RedoOutlined />, text: "Revert", value: "revert" },
                        ]
                        : [],
            }),
            separateActionButton: true,
            extraButtons: () => (
                <div className="extra-header-buttons" key={"ps-mps-extra-buttons"}>
                    <Button
                        icon={<DownloadOutlined />}
                        loading={isExporting}
                        disabled={isUploading}
                        onClick={() => componentCallback(CALLBACK_KEY.EXPORT_CSV_EXCEL, {})}
                    >
                        Download
                    </Button>
                    <Button
                        icon={<DownloadOutlined />}
                        loading={isExporting}
                        disabled={isUploading}
                        onClick={() => componentCallback(CALLBACK_KEY.OTHERS, "export-all")}
                    >
                        Download All
                    </Button>
                    {enableUpdate && (
                        <Upload
                            key={`btn-upload-${Math.random()}`}
                            name="file"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            multiple={false}
                            showUploadList={false}
                            onChange={(info: any) => {
                                if (info.file.status === "error") {
                                    ErrorMessageHandler(`${info.file.name} file upload failed.`, SUCCESS_FAILED.OTHERS_FAILED);
                                }
                            }}
                            customRequest={({ file, onSuccess }: any) =>
                                setTimeout(() => {
                                    onSuccess("ok");
                                }, 0)
                            }
                            beforeUpload={(file: any) => handleUpload(file)}
                        >
                            <Button htmlType="button" icon={<UploadOutlined style={{ fontSize: "0.875rem" }} />} loading={isUploading} disabled={isExporting}>
                                Upload
                            </Button>
                        </Upload>
                    )}
                </div>
            ),
            recordRowClassName: (record: any) => {
                return record.isDeleted
                    ? "deleted-row"
                    : record.isCreateEdit === InnerPageActionMode.CREATE_NEW
                        ? "new-row"
                        : record.isCreateEdit === InnerPageActionMode.EDIT
                            ? "modified-row"
                            : "";
            },
        };
    }, [enableUpdate, isExporting, isUploading]);

    const componentCallback: FlexiDataTableCallbackProps = useCallback((type, FormData) => {
        let copyData = JSON.parse(JSON.stringify(data));
        switch (type) {
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                handleExport();
                break;
            case CALLBACK_KEY.CREATE_NEW:
                midPriceSettingForm.setFieldsValue(initialSetting);
                setCurrEdit(InnerPageActionMode.CREATE_NEW);
                break;
            case CALLBACK_KEY.DO_EDIT:
                midPriceSettingForm.setFieldsValue(FormData);
                setCurrEdit(FormData);
                break;
            case CALLBACK_KEY.DO_DELETE:
                let updatedData = copyData
                    .map((x: MidPriceSettingOtherComparedData) => {
                        let tmp =
                            FormData && x.newKey === FormData.newKey
                                ? FormData.isCreateEdit === InnerPageActionMode.CREATE_NEW
                                    ? null
                                    : {
                                        ...FormData,
                                        isDeleted: true,
                                        isCreateEdit: x.isCreateEdit === InnerPageActionMode.CREATE_NEW
                                            ? InnerPageActionMode.CREATE_NEW
                                            : x.isCreateEdit === InnerPageActionMode.EDIT
                                                ? InnerPageActionMode.EDIT
                                                : undefined,
                                    }
                                : { ...x };
                        return tmp;
                    })
                    .filter((x: MidPriceSettingOtherComparedData) => x !== null);
                setData(updatedData);
                break;
            case CALLBACK_KEY.OTHERS:
                if (FormData === "export-all") {
                    handleExport(true);
                };
                break;
            case CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK:
                let currRow = FormData.data;
                if (FormData.key === "revert") {
                    let updatedData = copyData
                        .map((x: MidPriceSettingOtherComparedData) => {
                            let tmp =
                                x.newKey === currRow.newKey
                                    ? {
                                        ...currRow,
                                        isCreateEdit: currRow.isCreateEdit === InnerPageActionMode.CREATE_NEW
                                            ? InnerPageActionMode.CREATE_NEW
                                            : currRow.isCreateEdit === InnerPageActionMode.EDIT
                                                ? InnerPageActionMode.EDIT
                                                : undefined,
                                        isDeleted: false,
                                    }
                                    : { ...x };
                            return tmp;
                        })
                    setData(updatedData);
                };
                break;
            default:
                break;
        };
    }, [data, midPriceSettingForm]);

    const handleExport = (downloadAll: boolean = false) => {
        setIsExporting(true);
        plainAxiosInstance
            .get(`${APIs.RC_PRICE_SETTINGS.GET_MID_PRICE_SETTINGS_DOWNLOAD}${downloadAll ? "" : `?serverUno=${currSelectedServer}`}`, {
                headers: { Accept: "application/octet-stream, */*" },
                responseType: "blob",
            })
            .then(res => {
                const fileName = getFileNameFromResponseHeader(
                    res,
                    `Mid_price_setting${downloadAll ? "_all" : `_${currSelectedServer}`}-${moment().format("YYYYMMDD_HHmmss")}.xlsx`
                );
                const contentType = res.headers["content-type"];
                if (
                    contentType === "application/octet-stream" ||
                    contentType === "text/csv" ||
                    contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ) {
                    // Handle the file download response
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", fileName); // or any other extension
                    link.setAttribute("type", "hidden");
                    document.body.appendChild(link);
                    link.click();
                    if (link.parentNode) {
                        link.parentNode.removeChild(link); // Clean up and remove the link
                    } else document.body.removeChild(link);
                    // Clean up
                    window.URL.revokeObjectURL(url);
                } else {
                    ErrorMessageHandler(`Received non-file response. Error: ${res}`, SUCCESS_FAILED.OTHERS_FAILED);
                }
            })
            .catch(err => {
                ErrorMessageHandler(`Download error: ${err}.`, SUCCESS_FAILED.OTHERS_FAILED);
            })
            .finally(() => setIsExporting(false));
    };

    const handleUpload = (fileToUpload: any) => {
        try {
            let fileExtension: string[] = defaultIfEmptyOrNull(/\.[^\.]+/.exec(fileToUpload.name), [""]),
                isLt5M = fileToUpload.size / 1024 / 1024 < 10;

            if (fileExtension[0] !== ".xlsx") {
                ErrorMessageHandler("Please check file type. Only .xlsx files are allowed.", SUCCESS_FAILED.OTHERS_FAILED);
                return;
            } else if (!isLt5M) {
                ErrorMessageHandler(`Please check file size less than 10 MB.`, SUCCESS_FAILED.OTHERS_FAILED);
                return;
            }

            Modal.confirm({
                icon: <ExclamationCircleOutlined />,
                title: "Are you sure you want to upload this file?",
                width: "30%",
                onOk() {
                    var formData = new FormData();
                    formData.append("file", fileToUpload);

                    setIsUploading(true);
                    setIsLoading(true);
                    plainAxiosInstance
                        .post(APIs.RC_PRICE_SETTINGS.POST_MID_PRICE_SETTINGS_UPLOAD, formData)
                        .then(res => {
                            if (res.status === 200) {
                                ErrorMessageHandler("Mid price setting(s)", SUCCESS_FAILED.SUCCESS_UPLOAD_DATA);
                                setRunRefetchDataList(true);
                            } else {
                                ErrorMessageHandler(`${res.data}.`, SUCCESS_FAILED.OTHERS_FAILED);
                                setIsLoading(false);
                            }
                        })
                        .catch((error: any) => {
                            ErrorMessageHandler(`File upload failed: (${error.response.data.message}).`, SUCCESS_FAILED.OTHERS_FAILED);
                            setIsLoading(false);
                        })
                        .finally(() => setIsUploading(false));
                },
                onCancel() { },
            });
        } catch (error) {
            ErrorMessageHandler(`Error during uploading file. Please try again.`, SUCCESS_FAILED.OTHERS_FAILED);
        }
    };

    const onFormSubmit = () => {
        midPriceSettingForm
            .validateFields()
            .then(values => {
                setIsLoading(true);
                if (currEdit === InnerPageActionMode.CREATE_NEW) {
                    let existingRow: any = null;
                    data.find((currRow) => {
                        if (currRow
                            && currRow.symbol && currRow.symbol.toLowerCase().trim() === values.symbol.toLowerCase().trim()
                            && currRow.compServerUno === values.compServerUno
                            && currRow.compSymbol && currRow.compSymbol.toLowerCase().trim() === values.compSymbol.toLowerCase().trim()
                        ) {
                            existingRow = currRow;
                        };
                    });

                    if (existingRow) {
                        // Update existing row
                        const keysToCheck: string[] = ["compServerUno", "compSymbol", "symbol", "exSpit", "midPriceTimes"];
                        const allKeys = new Set([...keysToCheck, ...Object.keys(values)]);
                        const allMatch = [...allKeys].every((currKey) => existingRow[currKey as keyof MidPriceSettingOtherComparedData] === values[currKey as keyof MidPriceSettingOtherComparedData]
                        );

                        let updatedExistingRow: MidPriceSettingOtherComparedData = allMatch ? {
                            ...existingRow,
                        } : {
                            ...existingRow,
                            ...values,
                            isCreateEdit: InnerPageActionMode.EDIT,
                        };
                        allMatch && message.info("Nothing has been changed. Data already exists!");

                        let copyData = JSON.parse(JSON.stringify(data));
                        const idx = copyData.findIndex((currDataObj: MidPriceSettingOtherComparedData) =>
                            currDataObj.symbol.toLowerCase().trim() === updatedExistingRow.symbol.toLowerCase().trim() &&
                            currDataObj.compServerUno === updatedExistingRow.compServerUno &&
                            currDataObj.compSymbol.toLowerCase().trim() === updatedExistingRow.compSymbol.toLowerCase().trim()
                        );
                        if (idx !== -1) {
                            copyData[idx] = { ...copyData[idx], ...updatedExistingRow };
                        };
                        setData(copyData);
                        setIsLoading(false);
                        midPriceSettingForm.resetFields();
                        setCurrEdit(null);
                    } else {
                        // add new row
                        let currServer = ownServers.find((x: any) => x.serverUno === currSelectedServer);
                        let currCompServer = competitorServers.find((x: any) => x.serverUno === values.compServerUno);
                        let copyData = JSON.parse(JSON.stringify(data)),
                            addedData = [];
                        addedData.push(
                            {
                                ...values,
                                serverUno: currSelectedServer,
                                serverName: currServer?.name || "",
                                compServerName: isEmptyOrNull(currCompServer) ? "Unknown" : currCompServer?.name,
                                isCreateEdit: InnerPageActionMode.CREATE_NEW,
                                newKey: `${currSelectedServer}|${values.symbol}|${values.compServerUno}|${values.compSymbol}`,
                            },
                            ...copyData
                        );
                        setData(addedData);
                        setIsLoading(false);
                        midPriceSettingForm.resetFields();
                        setCurrEdit(null);
                    };
                } else {
                    let copyData = JSON.parse(JSON.stringify(data));
                    let newData = copyData
                        .map((x: MidPriceSettingOtherComparedData) => {
                            let editedData =
                                currEdit && x.newKey === currEdit.newKey
                                    ? {
                                        ...x,
                                        ...values,
                                        isCreateEdit:
                                            x.isCreateEdit === InnerPageActionMode.CREATE_NEW
                                                ? InnerPageActionMode.CREATE_NEW
                                                : InnerPageActionMode.EDIT,
                                    }
                                    : { ...x };
                            return editedData;
                        })
                    setData(newData);
                    setIsLoading(false);
                    midPriceSettingForm.resetFields();
                    setCurrEdit(null);
                };
            })
            .catch(errorInfo => {
                console.error("Failed to submit form: ", errorInfo);
            });
    };

    const onSaveAllData = useCallback(() => {
        let tmpAddedData: any[] = [],
            tmpEditedData: any[] = [],
            tmpDeletedData: any[] = [];
        data.forEach((currRow: MidPriceSettingOtherComparedData) => {
            if (currRow.isCreateEdit === InnerPageActionMode.CREATE_NEW) {
                if (currRow.isDeleted === undefined || currRow.isDeleted === false) {
                    tmpAddedData.push(currRow);
                }
            } else if (currRow.isCreateEdit === InnerPageActionMode.EDIT) {
                if (currRow.isDeleted === true) {
                    tmpDeletedData.push(currRow);
                } else {
                    tmpEditedData.push(currRow);
                }
            } else if (currRow.isDeleted === true) {
                tmpDeletedData.push(currRow);
            };
        });
        const addedData = tmpAddedData.map(({ isCreateEdit, newKey, ...rest }) => rest);
        const editedData = tmpEditedData.map(({ isCreateEdit, newKey, ...rest }) => rest);
        const deletedData = tmpDeletedData.map(({ isCreateEdit, newKey, isDeleted, ...rest }) => rest);

        Modal.confirm({
            title: "Are you sure you want to save all of these modified settings ?",
            icon: <ExclamationCircleOutlined />,
            content: (
                <div>
                    <ul>
                        {addedData.length > 0 && <li key={"create"}>Creating {addedData.length} new setting(s).</li>}
                        {editedData.length > 0 && <li key={"update"}>Updating {editedData.length} existing setting(s).</li>}
                        {deletedData.length > 0 && <li key={"delete"}>Deleting {deletedData.length} existing setting(s).</li>}
                    </ul>
                    <p>Note: All your changes will affect the price alarm outage once saved.</p>
                </div>
            ),
            okText: "Confirm",
            okButtonProps: { loading: isSubmitting },
            onOk: () => {
                let promises: any[] = [];
                if (addedData.length + editedData.length > 0)
                    promises.push(
                        plainAxiosInstance.post(`${APIs.RC_PRICE_SETTINGS.POST_SAVE_UPDATE_ADD_MID_PRICE_SETTINGS}`, [...addedData, ...editedData])
                    );
                if (deletedData.length > 0)
                    promises.push(plainAxiosInstance.delete(`${APIs.RC_PRICE_SETTINGS.DELETE_MID_PRICE_SETTINGS}`, { data: deletedData }));
                setIsLoading(true);
                setIsSubmitting(true);
                Promise.all(promises)
                    .then(() => {
                        if (addedData.length + editedData.length > 0) ErrorMessageHandler("Mid price setting(s)", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                        if (deletedData.length > 0) ErrorMessageHandler("Mid price setting(s)", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                        setRunRefetchDataList(true);
                    })
                    .catch((error: any) =>
                        ErrorCatchValidator(error, (err: any) => {
                            ErrorMessageHandler("mid price setting(s) list", SUCCESS_FAILED.FAILED_UPDATE_DATA, err);
                            console.log("Failed to update mid price settings list: ", err);
                            setIsLoading(false);
                        })
                    )
                    .finally(() => setIsSubmitting(false));
            },
            onCancel: () => { },
        });
    }, [data]);

    const getOtherComparedDataTable = () => {
        plainAxiosInstance
            .get(`${APIs.RC_PRICE_SETTINGS.GET_MID_PRICE_SETTING_LIST}?serverUno=${currSelectedServer}`)
            .then((res: any) => {
                if (res.status === 200) {
                    let newData: any = res.data.map((x: MidPriceSettingOtherComparedData) => ({
                        ...x,
                        newKey: `${x.serverUno}|${x.symbol}|${x.compServerUno}|${x.compSymbol}`,
                    }));
                    setOriData(newData);
                    setData(newData);
                } else setData([]);
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler("mid price setting - other compared data table", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                    setOriData([]);
                    setData([]);
                })
            )
            .finally(() => setIsLoading(false));
    };

    const getServers = () => {
        setIsFetching(true);
        plainAxiosInstance
            .get(`${APIs.RC_PRICE_COMPARE.GET_SERVERS}`)
            .then((res: any) => {
                if (res.status === 200) {
                    if (res.data.length > 0) {
                        let newServersList = res.data.filter((x: RCPriceCompareServer) => (x.type & 0x09) > 0)
                            .sort((a: any, b: any) => a.name.localeCompare(b.name));
                        setOwnServers(newServersList);
                        setCurrSelectedServer(newServersList[0].serverUno);

                        let newCompetitorServersList = res.data.filter((server: any) => (server.type & 0x09) === 0)
                            .sort((a: any, b: any) => a.name.localeCompare(b.name));
                        setCompetitorServers(newCompetitorServersList);
                        setRunRefetchDataList(true);
                    } else {
                        setOwnServers([]);
                        setCompetitorServers([]);
                        setCurrSelectedServer("");
                    };
                };
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler("servers", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                    setOwnServers([]);
                    setCompetitorServers([]);
                    setCurrSelectedServer("");
                })
            )
            .finally(() => setIsFetching(false));
    };

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getOtherComparedDataTable();
            setRunRefetchDataList(false);
        }
        return () => { };
    }, [runRefetchDataList]);

    useEffect(() => {
        getServers();
        return () => { };
    }, []);

    useEffect(() => {
        if (data.length !== oriData.length || differenceWith(oriData, data, isEqual).length !== 0) {
            setAbleToResetSave(true);
        }
        return () => {
            setAbleToResetSave(false);
        };
    }, [data, oriData]);

    return (
        <PanelContainer title="Mid Price Settings">
            <div className="mid-price-setting-container">
                <div className="top-nav">
                    <div className="left-filter">
                        <span style={{ paddingRight: "0.651vw" }}>Server :</span>
                        <Select
                            showSearch
                            placeholder="Please select server"
                            value={currSelectedServer}
                            options={ownServers?.map((x: any) => ({ label: x.name, value: x.serverUno })) || []}
                            filterOption={(input, option) => (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())}
                            style={{ width: 200 }}
                            onChange={(value: any) => {
                                setRunRefetchDataList(true);
                                setCurrSelectedServer(value);
                            }}
                            disabled={isLoading || isFetching}
                        />
                        <Tooltip title="Refresh servers">
                            <Button
                                type="primary"
                                // shape="circle"
                                // size="small"
                                icon={<SyncOutlined spin={isFetching} />}
                                disabled={isLoading || isFetching}
                                onClick={getServers}
                                style={{ marginLeft: 0 }}
                            />
                        </Tooltip>
                    </div>
                    <div className="right-action" style={ableToResetSave ? {} : { display: "none" }}>
                        <Button
                            icon={<UndoOutlined />}
                            disabled={!ableToResetSave}
                            loading={isResetting}
                            onClick={() => {
                                setIsLoading(true);
                                setIsResetting(true);
                                setTimeout(() => {
                                    setIsLoading(false);
                                    setIsResetting(false);
                                    setData(oriData);
                                    setAbleToResetSave(false);
                                }, 1000);
                            }}
                        >
                            Reset Changes
                        </Button>
                        <Button icon={<SaveOutlined />} type="primary" disabled={!ableToResetSave} loading={isSubmitting} onClick={() => onSaveAllData()}>
                            Save
                        </Button>
                    </div>
                </div>
                <FlexiDataTable
                    bordered
                    rowKeyProperty="newKey"
                    title={""}
                    columns={columns}
                    options={options}
                    dataSource={data}
                    callback={componentCallback}
                    loading={isLoading}
                />
                <Modal
                    width={700}
                    title={`${currEdit === InnerPageActionMode.CREATE_NEW ? "Create New" : "Edit"} Mid Price Setting`}
                    open={currEdit !== null}
                    onCancel={() => {
                        setCurrEdit(null);
                        midPriceSettingForm.resetFields();
                    }}
                    footer={[
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: 8 }} key={"footer"}>
                            {/* reset form */}
                            <Button
                                key="reset"
                                type="ghost"
                                onClick={() => {
                                    if (currEdit === InnerPageActionMode.CREATE_NEW) {
                                        midPriceSettingForm.resetFields();
                                    } else {
                                        let oriCurrRow = currEdit && oriData.find((x: MidPriceSettingOtherComparedData) => x.newKey === currEdit?.newKey);
                                        if (currEdit && currEdit?.isCreateEdit === InnerPageActionMode.CREATE_NEW) {
                                            midPriceSettingForm.setFieldsValue({
                                                ...currEdit,
                                                exSpit: 1,
                                                midPriceTimes: 30,
                                            });
                                        } else midPriceSettingForm.setFieldsValue(oriCurrRow);
                                    };
                                }}
                                style={{ marginRight: "auto" }}
                            >
                                Reset
                            </Button>
                            <Button
                                key="cancel"
                                type="ghost"
                                onClick={() => {
                                    setCurrEdit(null);
                                    midPriceSettingForm.resetFields();
                                }}
                            >
                                Cancel
                            </Button>
                            <Button key="submit" type="primary" htmlType="submit" onClick={onFormSubmit} loading={isSubmitting}>
                                Submit
                            </Button>
                        </div>
                    ]}
                >
                    <Form
                        form={midPriceSettingForm}
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        layout="horizontal"
                        initialValues={initialSetting}
                        requiredMark={true}
                        onFinish={onFormSubmit}
                    >
                        <Row>
                            <Col span={22}>
                                <FormComponent
                                    label="Symbol"
                                    name={"symbol"}
                                    extra={{
                                        type: ComponentType.text,
                                        value: "",
                                        rules: [{ required: true, message: REQUIRED_FIELD }],
                                        inputProps: { disabled: currEdit !== InnerPageActionMode.CREATE_NEW },
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={22}>
                                <FormComponent
                                    label="Competitor Server"
                                    name={"compServerUno"}
                                    extra={{
                                        type: ComponentType.dropdown,
                                        value: competitorServers
                                            ? competitorServers?.map((x: any) => ({
                                                text: x.name,
                                                value: x.serverUno,
                                            }))
                                            : [],
                                        rules: [{ required: true, message: REQUIRED_FIELD }],
                                        inputProps: { disabled: currEdit !== InnerPageActionMode.CREATE_NEW },
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={22}>
                                <FormComponent
                                    label="Competitor Symbol"
                                    name={"compSymbol"}
                                    extra={{
                                        type: ComponentType.text,
                                        value: "",
                                        rules: [{ required: true, message: REQUIRED_FIELD }],
                                        inputProps: { disabled: currEdit !== InnerPageActionMode.CREATE_NEW },
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={22}>
                                <FormComponent
                                    label="Expire Spread"
                                    name={"exSpit"}
                                    extra={{
                                        type: ComponentType.number,
                                        value: "",
                                        inputProps: { precision: 0 },
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={22}>
                                <FormComponent
                                    label="Mid Price Times"
                                    name={"midPriceTimes"}
                                    extra={{
                                        type: ComponentType.number,
                                        value: "",
                                        inputProps: { precision: 0 },
                                    }}
                                />
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </div>
        </PanelContainer>
    );
};
export default MidPriceSetting;
