import { Segmented } from "antd";
import { SegmentedValue } from "antd/lib/segmented";
import { useState } from "react";
import CommonIssuesLogExcludeAccountSettingsTable from "./components/CommonIssuesLogExcludeAccountSettingsTable";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import CommonIssuesLogTypeDownload from "./components/CommonIssuesLogTypeDownload";
import BalanceAdjustmentSettings from "./components/balanceAdjustment/BalanceAdjustmentSettings";
import IssueLogNoMoneySettingsPanel from "./components/noMoney";
import RequestTimeoutSettings from "./components/RequestTimeoutSettings";
import PanelContainer from "../../PanelContainer";

export interface IssuesLogSettingsPanelProps { }

type SegmentedOptionType =
    | "No Money"
    | "Request Timeout"
    | "Close Order"
    | "Modify Open Order"
    | "Manager Account Intercepted"
    | "Account Monitor"
    | "Restore Order"
    | "Update Order Urgent"
    | "Balance Adjustment Monitor";

const IssuesLogSettingsPanel = (props: IssuesLogSettingsPanelProps) => {
    const [segmentVal, setSegmentVal] = useState<SegmentedOptionType>("No Money");
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.CENTRALIZED_SETTINGS_ISSUES_LOG_SETTINGS_EDIT);
    const maiCanUpload = authHp.isAuthorized(AuthKeys.CENTRALIZED_SETTINGS_ISSUES_LOG_SETTINGS_EDIT);
    const amCanUpload = authHp.isAuthorized(AuthKeys.CENTRALIZED_SETTINGS_ISSUES_LOG_SETTINGS_EDIT);
    const bamEnableUpdate = authHp.isAuthorized(AuthKeys.CENTRALIZED_SETTINGS_ISSUES_LOG_SETTINGS_EDIT);

    return (
        <PanelContainer title="Issues Log Settings">
            <div className="issues-log-settings-panel-container">
                <div className="settings-panel-main-segment-panel-container">
                    <div className="top-panel">
                        <Segmented
                            options={[
                                "No Money",
                                "Request Timeout",
                                "Close Order",
                                "Modify Open Order",
                                "Manager Account Intercepted",
                                "Account Monitor",
                                "Restore Order",
                                "Update Order Urgent",
                                "Balance Adjustment Monitor",
                            ]}
                            value={segmentVal}
                            onChange={(value: SegmentedValue) => setSegmentVal(value as SegmentedOptionType)}
                        />
                    </div>
                </div>
                <div className="container">
                    {segmentVal === "No Money" && <IssueLogNoMoneySettingsPanel enableUpdate={enableUpdate} />}
                    {segmentVal === "Request Timeout" && <RequestTimeoutSettings enableUpdate={enableUpdate} />}
                    {segmentVal === "Close Order" && <CommonIssuesLogExcludeAccountSettingsTable type="CLOSED_ORDER" enableUpdate={enableUpdate} />}
                    {segmentVal === "Modify Open Order" && (
                        <CommonIssuesLogExcludeAccountSettingsTable type="MODIFY_OPEN_ORDER" enableUpdate={enableUpdate} />
                    )}
                    {segmentVal === "Manager Account Intercepted" && (
                        <CommonIssuesLogTypeDownload type="MANAGER_ACCOUNT_INTERCEPTED" canUpload={maiCanUpload} />
                    )}
                    {segmentVal === "Account Monitor" && <CommonIssuesLogTypeDownload type="ACCOUNT_MONITOR" canUpload={amCanUpload} />}
                    {segmentVal === "Restore Order" && <CommonIssuesLogExcludeAccountSettingsTable type="RESTORE_ORDER" enableUpdate={enableUpdate} />}
                    {segmentVal === "Update Order Urgent" && (
                        <CommonIssuesLogExcludeAccountSettingsTable type="UPDATE_ORDER_URGENT" enableUpdate={enableUpdate} />
                    )}
                    {segmentVal === "Balance Adjustment Monitor" && <BalanceAdjustmentSettings enableUpdate={bamEnableUpdate} />}
                </div>
            </div>
        </PanelContainer>
    );
};

export default IssuesLogSettingsPanel;
