import { FormComponent } from "@/components/FormComponent";
import { ComponentType } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { KeyValuePair } from "@/constants/type";
import { Form, Modal, Row, Col } from "antd";
import { useEffect } from "react";
import { BridgeConfigApiConfigType } from ".";

export interface BridgeConfigApiModalProps {
    isModalVisible: boolean;
    modalTitle: string;
    callback: (type: number, data: any) => void;
    data: any | undefined;
    bridgeOptions: KeyValuePair[];
    isLoading: boolean;
}

export enum BridgeConfigApiModalCallbackKey {
    Close = 0,
    Submit = 1,
}

const BridgeConfigApiModal = (props: BridgeConfigApiModalProps) => {
    const [executeForm] = Form.useForm();

    useEffect(() => {
        if (props.isModalVisible) {
            if (props.data !== undefined) {
                executeForm.setFieldsValue(props.data);
            } else {
                executeForm.resetFields();
            }
        }
    }, [props.isModalVisible, props.data]);

    return (
        <Modal
            style={{ top: 10 }}
            width={"40vw"}
            title={props.modalTitle}
            open={props.isModalVisible}
            okText="Submit"
            okButtonProps={{ loading: props.isLoading }}
            onOk={() => {
                executeForm
                    .validateFields()
                    .then((values: any) => {
                        props.callback(BridgeConfigApiModalCallbackKey.Submit, values);
                    })
                    .catch((err: any) => console.log(err));
            }}
            onCancel={() => props.callback(BridgeConfigApiModalCallbackKey.Close, null)}
            maskClosable={false}
        >
            <Form form={executeForm} layout="horizontal" labelCol={{ span: 8 }} labelAlign="left">
                <FormComponent label="" name="pkey" extra={{ type: ComponentType.hidden, value: "" }} />
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Bridge Name"
                            name="bridgeId"
                            extra={{
                                type: ComponentType.dropdown,
                                value: props.bridgeOptions,
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Setting Name"
                            name="configApiKey"
                            extra={{
                                type: ComponentType.text,
                                value: "",
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Config Type"
                            name="configType"
                            extra={{
                                type: ComponentType.dropdown,
                                value: BridgeConfigApiConfigType,
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                            }}
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default BridgeConfigApiModal;
