import { Segmented } from "antd";
import { SegmentedValue } from "antd/lib/segmented";
import { useState } from "react";
import MRGroupConfig from "./MRGroupSettings";
import MRServerSettings from "./MRServerSettings";
import PanelContainer from "../PanelContainer";

interface MRGroupingSettingsPageProps { }

const MRGroupingSettingsPage = (props: MRGroupingSettingsPageProps) => {
    const [segmentVal, setSegmentVal] = useState<string>("MR Group");

    return (
        <PanelContainer title="MR Group Settings">
            <div className="settings-panel-main-segment-panel-container">
                <div className="top-panel">
                    <Segmented
                        options={["MR Group", "Report(s) Servers"]}
                        value={segmentVal}
                        onChange={(value: SegmentedValue) => setSegmentVal(value as string)}
                    />
                </div>
                <div className="container">
                    {segmentVal === "MR Group" && <MRGroupConfig />}
                    {segmentVal === "Report(s) Servers" && <MRServerSettings />}
                </div>
            </div>
        </PanelContainer>
    );
};

export default MRGroupingSettingsPage;
