import { Form, Modal } from "antd";
import { HTHListItemProps, LabelTypes } from "./hthlist";
import { FormComponent } from "@/components/FormComponent";
import { ComponentType, SUCCESS_FAILED } from "@/constants";
import { useEffect } from "react";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { APIs } from "@/services/apis";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";

interface HTHListEditModalProps {
    isModalVisible: boolean;
    callback: (type: number, data: any) => void;
    data: undefined | HTHListItemProps;
}

export enum HTHListEditModalCallbackKey {
    Close = 0,
    Refetch = 1,
}

const HTHListEditModal = (props: HTHListEditModalProps) => {
    const [createForm] = Form.useForm();

    const submitChanges = (values: any) => {
        plainAxiosInstance
            .put(APIs.RISK_TOOL.GET_HUB_TO_HUB_LISTING_EDIT, [values])
            .then((res: any) => {
                if (res.data.status === 0) {
                    ErrorMessageHandler("HTH Login", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                    props.callback(HTHListEditModalCallbackKey.Refetch, null);
                } else {
                    ErrorMessageHandler("HTH Login", SUCCESS_FAILED.FAILED_UPDATE_DATA);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("HTH Login", SUCCESS_FAILED.FAILED_UPDATE_DATA, err))
            );
    };

    useEffect(() => {
        if (props.isModalVisible) {
            createForm.resetFields();
            createForm.setFieldsValue(props.data !== undefined ? props.data : {});
        }
    }, [props.isModalVisible]);

    return (
        <Modal
            style={{ top: 10 }}
            width={"40vw"}
            title={"Edit HTH Login"}
            open={props.isModalVisible}
            okText="Submit"
            onOk={() => {
                props.callback(HTHListEditModalCallbackKey.Close, null);

                createForm
                    .validateFields()
                    .then((values: any) => submitChanges(values))
                    .catch((err: any) => console.log(err));
            }}
            onCancel={() => props.callback(HTHListEditModalCallbackKey.Close, null)}
            maskClosable={false}
        >
            <div className="hth-edit-container">
                <Form form={createForm} layout="horizontal" labelCol={{ span: 5 }} labelAlign="left" wrapperCol={{ span: 16 }}>
                    <FormComponent label="" name="processDate" extra={{ type: ComponentType.hidden, value: "" }} />
                    <FormComponent label="" name="serverUno" extra={{ type: ComponentType.hidden, value: "" }} />
                    <FormComponent label="" name="bridgeId" extra={{ type: ComponentType.hidden, value: "" }} />
                    <FormComponent label="" name="login" extra={{ type: ComponentType.hidden, value: "" }} />
                    <FormComponent label="" name="loginGroup" extra={{ type: ComponentType.hidden, value: "" }} />
                    <FormComponent label="" name="symbol" extra={{ type: ComponentType.hidden, value: "" }} />
                    <FormComponent
                        label="Comment"
                        name="comment"
                        extra={{
                            type: ComponentType.text,
                            value: "",
                        }}
                    />
                    <FormComponent
                        label="Note"
                        name="note"
                        extra={{
                            type: ComponentType.text,
                            value: "",
                        }}
                    />
                    <FormComponent
                        label="Label"
                        name="label"
                        extra={{
                            type: ComponentType.dropdown,
                            value: LabelTypes,
                        }}
                    />
                </Form>
            </div>
        </Modal>
    );
};

export default HTHListEditModal;
