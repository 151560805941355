import FlexiDataTable from "@/components/FlexiDataTable";
import { ComponentType, STATUS_TYPE, CALLBACK_KEY, SUCCESS_FAILED, InnerPageActionMode } from "@/constants";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps, CreateEditInlineFormBased } from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { apiRequest } from "@/services/apiConfig";
import { APIs } from "@/services/apis";
import { ToOptionTypeList } from "@/utils/array";
import { DTColProps, ErrorMessageHandler, ErrorCatchValidator } from "@/utils/Common";
import moment from "moment";
import { useEffect, useState } from "react";
import CreateEditNotificationChannel from "./createEditNotificationChannel";
import PanelContainer from "../../PanelContainer";

export interface NotificationChannelManagementProps { }

const NotificationChannelManagement = (props: NotificationChannelManagementProps) => {
    const [isFirstOpt, setIsFirstOpt] = useState<boolean>(true);
    const [formObject, setFormObject] = useState<CreateEditInlineFormBased>({ mode: InnerPageActionMode.CREATE_NEW });
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<any[]>([]);
    const [refetch, setRefetch] = useState<number>(0);

    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.CENTRALIZED_SETTINGS_NOTIFICATION_CHANNEL_MANAGEMENT_EDIT);

    const columns: any[] = [
        DTColProps.Large({
            title: "Channel Name",
            dataIndex: "name",
            key: "name",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
        },
        DTColProps.Status({
            title: "Status",
            dataIndex: "status",
            key: "status",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: ToOptionTypeList(STATUS_TYPE),
                },
            },
        }),
    ];

    const options: FlexiDataTableOptionsProps = {
        separateActionButton: true,
        add: enableUpdate,
        edit: enableUpdate,
        delete: enableUpdate,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, ServerData) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                setIsFirstOpt(prev => !prev);
                setFormObject({ mode: InnerPageActionMode.CREATE_NEW, id: undefined });
                setRefetch(prev => prev + 1);
                break;
            case CALLBACK_KEY.DO_EDIT:
                setIsFirstOpt(prev => !prev);
                setFormObject({ mode: InnerPageActionMode.EDIT, id: ServerData });
                setRefetch(prev => prev + 1);
                break;
            case CALLBACK_KEY.DO_DELETE:
                setIsLoading(true);
                apiRequest(APIs.UPDATE_NOTIFICATION_CHANNEL, { channelUid: ServerData["channelUid"], status: 2, name: "" })
                    .then(data => {
                        ErrorMessageHandler(`Channel: "${ServerData["name"]}"`, SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                        getNotificationChannelListing();
                    })
                    .catch(error =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("notification channel", SUCCESS_FAILED.FAILED_DELETE_DATA, err))
                    )
                    .finally(() => {
                        setIsLoading(false);
                    });
                break;
            default:
                break;
        }
    };

    const getNotificationChannelListing = () => {
        apiRequest(APIs.GET_NOTIFICATION_CHANNEL_LIST, {})
            .then((res: any) => {
                let defData = res as any[];
                defData.sort((a: any, b: any) => (moment(a.createdDateUtc) > moment(b.createdDateUtc) ? -1 : 1));
                setData(defData);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("notification channel", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getNotificationChannelListing();
        return () => { };
    }, []);

    return (
        <PanelContainer title="Notification Channel Management">
            <div className="single-page">
                <div className={`${isFirstOpt ? "active" : ""}`}>
                    <FlexiDataTable
                        bordered
                        rowKeyProperty="channelUid"
                        title=""
                        columns={columns}
                        options={options}
                        dataSource={data || []}
                        callback={componentCallback}
                        loading={isLoading}
                    />
                </div>
                <div className={`${isFirstOpt ? "" : "active"}`}>
                    <CreateEditNotificationChannel
                        {...formObject}
                        callback={(action: InnerPageActionMode, value: any) => {
                            switch (action) {
                                case InnerPageActionMode.BACK:
                                    setIsFirstOpt(prev => !prev);
                                    if (value?.hasOwnProperty("refreshMainList") && value["refreshMainList"]) {
                                        getNotificationChannelListing();
                                    }
                                    break;
                            }
                        }}
                        resetState={refetch}
                    />
                </div>
            </div>
        </PanelContainer>
    );
};

export default NotificationChannelManagement;
