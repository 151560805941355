import { BackTop, Badge, Button, Col, Dropdown, Layout, Row } from "antd";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import NavigationBar from "../components/NavigationBar";
import { ArrowRightOutlined, LeftOutlined, RightOutlined, SendOutlined, SettingOutlined, ToolOutlined, UpOutlined } from "@ant-design/icons";
import { userInfo } from "../services/api";
import { useAppDispatch, useAppSelector } from "../store/hook";
import { setSideBarCollapse } from "../store/reducers/system";
import Notifications from "../components/Notifications";
import { GlobalProvider } from "../GlobalProvider";
import SettingButton from "../components/SettingButton";
import { useEffect, useMemo, useRef } from "react";
import { KeyValuePair } from "../constants/type";
import { getMonitorProfilePrefetchList } from "../pages/RealTime/Trade/TradeMonitoring";
import { getRefreshToken, getToken, updateAppSettings } from "../services/localStorage";
import { cancelPreviousPageRequests } from "../services/axiosSetup";
import WorldTime from "../components/WorldTime";
import RC_Logo from "../assets/images/rc_logo.png";
import { KBarProvider } from "kbar";
import { useKBarActions } from "@/services/kbar/kbaractions";
import UserManualDrawer from "@/components/UserManualDrawer";
import { setCurrentKey } from "@/store/reducers/centralizedsettings";

const { Content } = Layout;

const AuthLayout = () => {
    let navigate = useNavigate();

    const systemState = useAppSelector((state: any) => state.system);
    const dispatch = useAppDispatch();

    const rcURL: string = process.env.REACT_APP_RC_APP || "";
    const rtURL: string = process.env.REACT_APP_RISK_TOOL_APP || "";

    let previousPath = "";
    let currentPath = "";
    const location = useLocation();
    const prevPathRef = useRef(previousPath);
    const currPathRef = useRef(currentPath);

    const btnGroups: React.ReactNode = useMemo(() => {
        let items: any[] = [];
        if (rcURL.length > 0) {
            items.push({
                key: "1",
                label: (
                    <a target="_self" href={`${rcURL}?token=${getToken()}&refreshToken=${getRefreshToken()}`}>
                        Risk Center
                    </a>
                ),
                icon: <img src={RC_Logo} alt="Risk Centre" style={{ width: "15px", height: "15px", marginRight: "5px" }} />,
            });
        }
        if (rtURL.length > 0) {
            items.push({
                key: "2",
                label: (
                    <a target="_blank" href={`${rtURL}`} rel="noreferrer">
                        Risk Tool
                    </a>
                ),
                icon: <ToolOutlined />,
            });
        }

        return (
            <>
                {items.length > 0 && (
                    <Col className="navigation">
                        <div className="btn-group">
                            <Dropdown menu={{ items }} placement="bottomLeft">
                                <Button icon={<SendOutlined />}>Navigate To</Button>
                            </Dropdown>
                        </div>
                    </Col>
                )}
            </>
        );
    }, [rcURL, rtURL]);

    useEffect(() => {
        if (!prevPathRef.current && !currPathRef.current) {
            prevPathRef.current = location.pathname;
            currPathRef.current = location.pathname;
        } else {
            prevPathRef.current = currPathRef.current;
            currPathRef.current = location.pathname;
        }
        if (prevPathRef.current !== currPathRef.current) {
            cancelPreviousPageRequests(prevPathRef.current);
        }
        return () => {};
    }, [location.pathname]);

    useEffect(() => {
        getMonitorProfilePrefetchList().then((res: any) => {
            updateAppSettings({
                RELATIVE_TIME_RANGE: res["RELATIVE_TIME_RANGE"] as KeyValuePair[],
                RELATIVE_DATE_RANGE: res["RELATIVE_DATE_RANGE"] as KeyValuePair[],
                SYMBOL_TYPE: res["SYMBOL_TYPE"] as KeyValuePair[],
            });
        });
        return () => {};
    }, []);

    const actions = useKBarActions();

    return (
        <GlobalProvider>
            <KBarProvider actions={actions}>
                <Layout className="main-layout" hasSider>
                    <NavigationBar />
                    <Layout
                        style={{
                            marginInlineStart: systemState.sideBarCollapsed ? 80 : 200,
                            transition: "margin-inline-start 0.2s ease-in-out",
                        }}
                    >
                        {/** HEADER */}
                        <div className="header">
                            <div className="left-panel">
                                <div className="collapse-btn" onClick={() => dispatch(setSideBarCollapse())}>
                                    <Button
                                        type="primary"
                                        shape="circle"
                                        size="small"
                                        icon={systemState.sideBarCollapsed ? <RightOutlined /> : <LeftOutlined />}
                                    />
                                </div>
                                <WorldTime />
                            </div>
                            <div className="right-panel">
                                <Row>
                                    <Col className="centralized-settings">
                                        <Button
                                            icon={<SettingOutlined />}
                                            className="btn-settings"
                                            onClick={() => {
                                                dispatch(setCurrentKey(undefined));
                                                window.location.pathname !== "/settingspanel" && navigate("/settingspanel");
                                            }}
                                        >
                                            System Settings
                                        </Button>
                                    </Col>
                                    <Col className="notification">
                                        <span>
                                            <Notifications />
                                        </span>
                                    </Col>
                                    <Col className="notification">
                                        <span>
                                            <UserManualDrawer />
                                        </span>
                                    </Col>
                                    {btnGroups}
                                    <Col className="profile-dropdown">
                                        <Badge dot color={systemState.isWsConnected ? "green" : "red"} className="profile-dot">
                                            <SettingButton />
                                        </Badge>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <Content>
                            <div className="main-content-div">
                                <Outlet />
                            </div>
                            <BackTop className="back-to-top-container">
                                <UpOutlined />
                            </BackTop>
                        </Content>
                    </Layout>
                </Layout>
            </KBarProvider>
        </GlobalProvider>
    );
};

export default AuthLayout;
