import { useMemo, useState } from "react";
import CardBox from "@/components/Common/CardBox";
import FlexiDataTable from "@/components/FlexiDataTable";
import { FlexiDataTableCallbackProps, FlexiDataTableOptionsProps } from "@/constants/type";
import { ComponentType } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { DTColProps } from "@/utils/Common";
import { CarryOutOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Row, Upload, UploadFile, UploadProps } from "antd";
import { FormComponent } from "@/components/FormComponent";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";

const ArchiveAccounts = () => {
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.MANAGEMENT_TOOL_ARCHIVE_ACCOUNTS_EDIT);

    const [data, setData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [file, setFile] = useState<File | null>(null);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [previewForm] = Form.useForm();

    const columns: any[] = [
        DTColProps.Small({
            title: "Login",
            dataIndex: "login",
            key: "login",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Large({
            title: "Message",
            dataIndex: "message",
            key: "message",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
    ];

    const options: FlexiDataTableOptionsProps = useMemo(() => {
        return {
            defaultCollapseFilterPanel: true,
            ...(enableUpdate && {
                extraButtons: [
                    { text: "Apply All", value: "applyAll", icon: <CarryOutOutlined /> },
                ],
            }),
        }
    }, [enableUpdate]);

    const componentCallback: FlexiDataTableCallbackProps = () => { };

    const onFormSubmit = (values: any) => {
        console.log("onFormSubmit: ", values);
    };

    const uploadProps: UploadProps = {
        onRemove: () => {
            setFile(null);
            setFileList([]);
        },
        beforeUpload: file => {
            setFile(file);
            setFileList([
                {
                    uid: "-1",
                    name: file.name,
                    status: "done",
                    url: URL.createObjectURL(file),
                },
            ]);
            return false;
        },
        fileList,
        maxCount: 1,
    };

    return (
        <div className="archive-accounts-container">
            <CardBox title="Archive Accounts">
                <div className="preview-form-div">
                    <Form
                        form={previewForm}
                        layout="horizontal"
                        initialValues={{}}
                        onFinish={onFormSubmit}
                    >
                        <Row gutter={[12, 12]}>
                            <Col span={6}>
                                <FormComponent
                                    label="Server"
                                    name="server"
                                    extra={{
                                        type: ComponentType.dropdown,
                                        value: [],
                                        rules: [{ required: true, message: REQUIRED_FIELD }],
                                    }}
                                />
                            </Col>
                            <Col span={5}>
                                <Form.Item
                                    label="Login(s)"
                                    name={"logins"}
                                    rules={[{ required: true, message: REQUIRED_FIELD }]}
                                    labelCol={{ span: 6 }}
                                    wrapperCol={{ span: 18 }}
                                >
                                    <Upload  {...uploadProps} >
                                        <Button icon={<UploadOutlined />} style={{ width: 150 }}>Upload CSV File</Button>
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        loading={isLoading}
                                        htmlType="submit"
                                    >
                                        Preview
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <Divider />
                <FlexiDataTable
                    bordered
                    rowKeyProperty="server"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={data ?? []}
                    callback={componentCallback}
                    loading={isLoading}
                />
            </CardBox>
        </div>
    );
};

export default ArchiveAccounts;