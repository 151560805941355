import { PlusOutlined, CloseOutlined, DeleteOutlined, EditOutlined, CheckOutlined } from "@ant-design/icons";
import { Button, Col, Form, FormInstance, message, Popconfirm, Row, Space, Tag } from "antd";
import EmptyData from "../../../../../../components/Common/Empty";
import { FormComponent } from "../../../../../../components/FormComponent";
import { ComponentType } from "../../../../../../constants";
import { REQUIRED_FIELD } from "../../../../../../constants/errorMessage";
import { useEffect, useState } from "react";
import { APIs } from "@/services/apis";
import { apiRequest } from "@/services/apiConfig";
import { PKMarketBrand } from "@/constants/type";

interface MRBrandsPKServerPanelProps {
    form: FormInstance;
    configData: any;
    isAddAction: boolean;
}

interface GroupMRBrands {
    marketBrandId?: number;
    marketBrandName?: string;
}

const MRBrandPKServerPanel = (props: MRBrandsPKServerPanelProps) => {
    const [editIndex, setEditIndex] = useState<number | string | null>(null);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [MRBrandsList, setMRBrandsList] = useState<GroupMRBrands[]>([]);
    const [pkMarketBrand, setPKMarketBrand] = useState<PKMarketBrand[]>([]);

    const getPKMarketBrands = () => {
        apiRequest(APIs.GET_MR_PK_LIST_V2, {})
            .then(data => {
                if (data && data?.length > 0) {
                    setPKMarketBrand(data.map((x: any) => ({
                        ...x,
                        pkServers: {
                            showName: x.pkServers
                        }
                    })))
                }
            })
            .catch(err =>
                message.error('Market brand failed to retrieve')
            );
    }

    const onAddEditClicked = (index: number) => {
        //cancel the adding brand field while move to edit
        const targetEditIndex = editIndex === 0 && !isEdit ? index - 1 : index

        setEditIndex(targetEditIndex);
        setIsEdit(true);
    };

    const handleSave = (index: number) => {
        const { marketBrand } = props.form.getFieldValue(["mrBrands", index]);

        let updatedMRBrands = MRBrandsList && MRBrandsList.length > 0 ? [...MRBrandsList] : [];

        if (!marketBrand) {
            updatedMRBrands = updatedMRBrands.filter((_, i) => i !== index);
        } else {
            updatedMRBrands[index] = {
                marketBrandId: marketBrand,
                marketBrandName: pkMarketBrand.find((x: any) => x.marketBrandId === marketBrand)?.marketBrandName,
            };
        }

        props.form.setFieldsValue({
            ["mrBrands"]: updatedMRBrands.map(x => ({
                marketBrand: x.marketBrandId
            }))
        });
        setMRBrandsList(updatedMRBrands);
        setEditIndex(null);
        setIsEdit(false);
    };

    const handleCancel = (index: number) => {
        if (isEdit) {
            setMRBrandsList([...MRBrandsList]);
            props.form.setFieldsValue({ mrBrands: [...MRBrandsList.map(x => ({ marketBrand: x.marketBrandId }))] });
        } else {
            const newMRBrandsList = MRBrandsList.filter((obj, i) => i !== index);
            setMRBrandsList(newMRBrandsList);
            props.form.setFieldsValue({ mrBrands: newMRBrandsList.map(x => ({ marketBrand: x.marketBrandId })) });
        }

        setEditIndex(null);
        setIsEdit(false);
    };

    const handleRemove = (index: number) => {
        setMRBrandsList(prevMRBrands => prevMRBrands.filter((_, i) => i !== index));
    };

    const handleAddCancel = () => {
        //prevent add new brand field before save the current one
        if (editIndex == null || isEdit) {
            setEditIndex(0);
            setIsEdit(false);
            const updatedMRBrands = MRBrandsList && MRBrandsList.length > 0 ? [...MRBrandsList] : [];
            updatedMRBrands.unshift({ marketBrandId: undefined, marketBrandName: undefined });
            setMRBrandsList(updatedMRBrands);
            props.form.setFieldsValue({
                ["mrBrands"]: updatedMRBrands.map(x => ({
                    marketBrand: x.marketBrandId
                }))
            });
        }
    };

    const filterOutServer = (servers: any) => {
        if (servers && servers.length > 0) {
            return servers.filter((server: any) => props.configData.mrPKServers.some((configServer: any) => configServer.text === server)) ?? []
        }

        return []
    }

    useEffect(() => {
        getPKMarketBrands()
    }, [])

    useEffect(() => {
        if (props.isAddAction) {
            setMRBrandsList([]);
            props.form.setFieldsValue({ ["mrBrands"]: [] });
        } else {
            const data = props.form.getFieldValue("mrBrands").map((x: any) => ({
                marketBrandId: x.marketBrand,
                marketBrandName: pkMarketBrand.find((pkMB: any) => pkMB.marketBrandId === x.marketBrand)?.marketBrandName,
            }))
            setMRBrandsList(data)
        }
        return () => { };
    }, [props.isAddAction, props.form, pkMarketBrand]);

    return (
        <>
            <div className="mrBrands-container">
                <Row>
                    <Col className="title-div" span={24}>
                        <h1>MR Brand (s)</h1>
                        <div>
                            <Button onClick={handleAddCancel} type="dashed" icon={<PlusOutlined />}>
                                Add Brand
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <div className="list-item-div">
                            <Form.List name={"mrBrands"}>
                                {(fields, { add, remove }) => {
                                    return fields.length > 0 ? (
                                        <>
                                            {fields.map((field, index) => {
                                                const fieldValue = props.form.getFieldValue(["mrBrands", index]).marketBrand;
                                                const fieldMarketBrand: any = pkMarketBrand.find((x: any) => x.marketBrandId === fieldValue);
                                                const fieldServers = filterOutServer(fieldMarketBrand?.pkServers?.showName)

                                                return (
                                                    <div key={field.key} className="item-container">
                                                        <div className="content-panel">
                                                            {editIndex === index ? (
                                                                <div className="editable-content" key={`brand-${field.name}`}>
                                                                    <div className="left">
                                                                        <FormComponent
                                                                            label="Brand Name"
                                                                            name={[field.name, "marketBrand"]}
                                                                            extra={{
                                                                                type: ComponentType.dropdown,
                                                                                value: pkMarketBrand.map((x: any) => ({
                                                                                    text: x.marketBrandName,
                                                                                    value: x.marketBrandId,
                                                                                })),
                                                                                rules: [{ required: true, message: REQUIRED_FIELD }],
                                                                                inputProps: {
                                                                                    allowClear: true,
                                                                                },
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="right">
                                                                        <Space>
                                                                            <Button
                                                                                type="default"
                                                                                icon={<CheckOutlined />}
                                                                                onClick={() => handleSave(index)}
                                                                                style={{ marginRight: "5px", color: "#004b57" }}
                                                                            />

                                                                            <Button
                                                                                type="default"
                                                                                icon={<CloseOutlined />}
                                                                                onClick={() => handleCancel(index)}
                                                                                style={{ marginRight: "5px", color: "#f00f00" }}
                                                                            />
                                                                        </Space>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                MRBrandsList && MRBrandsList.length > 0 && (
                                                                    <div className="display-content" key={`brand-${field.name}`}>
                                                                        <div className="left">
                                                                            <div className="title">{MRBrandsList[field.name].marketBrandName}</div>
                                                                            {fieldServers && fieldServers.length > 0 && (
                                                                                <div className="subtitle">
                                                                                    {fieldServers.map((server: string) => (
                                                                                        <Tag className="pkServerColor">{server}</Tag>
                                                                                    ))}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        <div className="right">
                                                                            <Space>
                                                                                <Button
                                                                                    type="link"
                                                                                    icon={<EditOutlined />}
                                                                                    onClick={async () => {
                                                                                        if (editIndex !== null) {
                                                                                            await handleCancel(editIndex as any)
                                                                                        }
                                                                                        onAddEditClicked(field.name)
                                                                                    }}
                                                                                />
                                                                                <Popconfirm
                                                                                    key={"mrbrandpk-del"}
                                                                                    title="Confirm to delete?"
                                                                                    onConfirm={() => {
                                                                                        remove(field.name);
                                                                                        handleRemove(field.name);
                                                                                    }}
                                                                                    okText="Yes"
                                                                                    cancelText="No"
                                                                                >
                                                                                    <Button type="link" danger icon={<DeleteOutlined />} />
                                                                                </Popconfirm>
                                                                            </Space>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </>
                                    ) : (
                                        <EmptyData />
                                    );
                                }}
                            </Form.List>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default MRBrandPKServerPanel;
