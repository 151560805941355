import FlexiDataTable from "@/components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "@/constants";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps, KeyValuePair } from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { APIs } from "@/services/apiConfig";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler, getFileNameFromResponseHeader } from "@/utils/Common";
import { isEmptyOrNull } from "@/utils/string";
import { HistoryOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import HubToHubActivityLogModal, { HubToHubActivityLogModalCallbackKey } from "./hthActivityLogModal";
import { Badge } from "antd";
import HTHListEditModal, { HTHListEditModalCallbackKey } from "./hthlistEditModal";

interface HubToHubListingPageProps {}

export interface HTHListItemProps {
    id?: string;
    processDate: number; // yyyyMMdd
    serverUno: number;
    serverName: string;
    login: string;
    bridgeId: string;
    loginGroup: string;
    symbol: string;
    hthStatus: number;
    comment: string;
    note: string;
    label: string;
    inspector: string;
}

export const LabelTypes: KeyValuePair[] = [
    { text: "Problematic Client", value: "Problematic Client" },
    { text: "Suspected Toxic Client", value: "Suspected Toxic Client" },
    { text: "High Return Client", value: "High Return Client" },
];

const HubToHubListingPage = (props: HubToHubListingPageProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<HTHListItemProps[]>([]);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [modalDetails, setModalDetails] = useState<HTHListItemProps | undefined>(undefined);
    const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
    const [editModalDetails, setEditModalDetails] = useState<HTHListItemProps | undefined>(undefined);

    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.RISK_TOOLS_HUB_TO_HUB_EDIT);

    const columns = [
        DTColProps.XSmall({
            title: "Server",
            dataIndex: "serverName",
            key: "serverName",
        }),
        DTColProps.XSmall({
            title: "Login",
            dataIndex: "login",
            key: "login",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        {
            title: "Bridge",
            dataIndex: "bridgeId",
            key: "bridgeId",
        },
        {
            title: "Group",
            dataIndex: "loginGroup",
            key: "loginGroup",
        },
        DTColProps.XSmall({
            title: "Symbol",
            dataIndex: "symbol",
            key: "symbol",
        }),
        {
            title: "Inspector",
            dataIndex: "inspector",
            key: "inspector",
        },
        DTColProps.XXSmall({
            width: "4.5vw",
            title: "Date",
            dataIndex: "processDate",
            key: "processDate",
            render: (text: number) => `${text}`.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3"),
        }),
        {
            title: "Comment",
            dataIndex: "comment",
            key: "comment",
        },
        {
            title: "Note",
            dataIndex: "note",
            key: "note",
        },
        {
            title: "Label",
            dataIndex: "label",
            key: "label",
        },
        DTColProps.XSmall({
            title: "Action",
            dataIndex: "hthStatus",
            key: "hthStatus",
            render: (text: number) =>
                text === 1 ? (
                    <Badge status="success" text="Enable" className="badge-blinking" />
                ) : (
                    <Badge status="default" text={<span style={{ color: "#a5a5a5" }}>Disable</span>} />
                ),
        }),
    ];

    const options: FlexiDataTableOptionsProps = {
        separateActionButton: true,
        edit: enableUpdate,
        export: {
            text: "Export to Excel",
        },
        customExtraActionButton: [{ text: "Activity Log", icon: <HistoryOutlined />, value: "historicallist" }],
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: number, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK:
                if (FormData.key === "historicallist") {
                    setModalDetails(FormData.data);
                    setIsModalVisible(true);
                }
                break;
            case CALLBACK_KEY.DO_EDIT:
                setIsEditModalVisible(true);
                setEditModalDetails(FormData);
                break;
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                exportToExcel(FormData);
                break;
            default:
                break;
        }
    };

    const exportToExcel = (filterParam: any) => {
        try {
            plainAxiosInstance
                .get(
                    `${APIs.RISK_TOOL.GET_HUB_TO_HUB_LISTING_EXPORT_TO_EXCEL}${
                        isEmptyOrNull(filterParam["login"]) ? "" : `?login=${encodeURIComponent(filterParam["login"])}`
                    }`,
                    {
                        headers: {
                            Accept: "application/octet-stream, */*",
                        },
                        responseType: "blob",
                    }
                )
                .then(response => {
                    const contentType = response.headers["content-type"];
                    const fileName = getFileNameFromResponseHeader(response, `HubtoHubList.xlsx`);
                    if (
                        contentType === "application/octet-stream" ||
                        contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    ) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", fileName);
                        document.body.appendChild(link);
                        link.click();
                        // Clean up
                        window.URL.revokeObjectURL(url);
                    } else {
                        ErrorMessageHandler(`Received non-file response. Error: ${response}`, SUCCESS_FAILED.OTHERS_FAILED);
                    }
                })
                .catch((error: any) => ErrorMessageHandler(`Error occured during download: "${error.message}"`, SUCCESS_FAILED.OTHERS_FAILED));
        } catch (e: any) {
            ErrorMessageHandler(`Error occured during download: "${e.message}"`, SUCCESS_FAILED.OTHERS_FAILED);
        }
    };

    const getListing = () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(APIs.RISK_TOOL.GET_HUB_TO_HUB_LISTING)
            .then((res: any) => {
                setData(
                    res.data.status === 0 && res.data.data.length > 0
                        ? res.data.data.map((x: any, idx: number) => ({
                              ...x,
                              id: `${x.serverUno}-${x.login}-${x.bridgeId}-${x.symbol}-${x.loginGroup}${idx}`,
                          }))
                        : []
                );
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("hub to hub", SUCCESS_FAILED.FAILED_LOAD_DATA, err)))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getListing();
    }, []);

    return (
        <>
            <div className="hth-listing-page-container">
                <FlexiDataTable
                    bordered
                    rowKeyProperty="id"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={data}
                    callback={componentCallback}
                    loading={isLoading}
                />
            </div>
            <HubToHubActivityLogModal
                isModalVisible={isModalVisible}
                callback={(type: number, data: any) => {
                    switch (type) {
                        case HubToHubActivityLogModalCallbackKey.Close:
                            setIsModalVisible(false);
                            break;
                        default:
                            break;
                    }
                }}
                data={modalDetails}
            />
            <HTHListEditModal
                isModalVisible={isEditModalVisible}
                callback={(type: number, data: any) => {
                    switch (type) {
                        case HTHListEditModalCallbackKey.Close:
                            setIsEditModalVisible(false);
                            break;
                        case HTHListEditModalCallbackKey.Refetch:
                            getListing();
                            break;
                        default:
                            break;
                    }
                }}
                data={editModalDetails}
            />
        </>
    );
};

export default HubToHubListingPage;
