import React, { useState } from "react";
import CardBox from "../../components/Common/CardBox";
import { Space, Segmented, Row, Col, Button, Modal } from "antd";
import SpreadAnalysisPage from "./SpreadAnalysisPage";
// import DailySpreadAverageDownloadPage from "./DailySpreadAverageDownloadPage";
import AverageSpreadAnalysisPage from "./AverageSpreadAnalysisPage";
import { DownloadOutlined } from "@ant-design/icons";
import DailySpreadAverageDownloadPage from "./DailySpreadAverageDownloadPage";
type pageProps = "spreadAnalysis" | "averageSpreadAnalysis"; // | "spreadDailyAvgDownload";
const SpreadReportPage: React.FC = () => {
    const [page, setPage] = useState<pageProps>("spreadAnalysis");
    const [showDownloadModal, setShowDownloadModal] = useState(false);
    return (
        <div>
            <CardBox title="Spread Analysis">
                <Space style={{ padding: "1rem 0", width: "100%" }} direction="vertical" size="large">
                    <div style={{ padding: "0 1rem" }}>
                        <Row gutter={[8, 8]}>
                            <Col xs={{ flex: 24 }} lg={{ flex: 16 }}>
                                <Segmented
                                    options={[
                                        { label: "Spread Analysis", value: "spreadAnalysis" },
                                        { label: "Average Spread Distribution", value: "averageSpreadAnalysis" },
                                        // { label: "Daily Spread Average Download", value: "spreadDailyAvgDownload" },
                                    ]}
                                    onChange={value => {
                                        // console.log(value); // string
                                        setPage(value as pageProps);
                                    }}
                                />
                            </Col>
                            <Col xs={{ flex: 24 }} lg={{ flex: "none" }} style={{ marginLeft: "auto" }}>
                                <Button icon={<DownloadOutlined />} onClick={() => setShowDownloadModal(true)}>
                                    Download Daily Spread Average
                                </Button>
                            </Col>
                        </Row>
                    </div>
                    {page === "spreadAnalysis" && <SpreadAnalysisPage />}
                    {page === "averageSpreadAnalysis" && <AverageSpreadAnalysisPage />}
                    <Modal
                        title="Download Daily Spread Average"
                        open={showDownloadModal}
                        onOk={() => { }}
                        footer={null}
                        onCancel={() => setShowDownloadModal(false)}
                    >
                        <DailySpreadAverageDownloadPage callback={() => {
                            setShowDownloadModal(false);
                        }} />
                    </Modal>
                </Space>
            </CardBox>
        </div>
    );
};

export default SpreadReportPage;
