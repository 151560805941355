import { Space } from "antd";
import DailySpreadAverageDownloadFilters from "./DailySpreadAverageDownloadFilters";

interface DailySpreadAverageDownloadPageProps {
    callback: () => void;
};

const DailySpreadAverageDownloadPage = (props: DailySpreadAverageDownloadPageProps) => {
    return (
        <Space style={{ width: "100%" }} direction="vertical" size="large">
            <DailySpreadAverageDownloadFilters callback={props.callback} />
        </Space>
    );
};

export default DailySpreadAverageDownloadPage;
