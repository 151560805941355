import { useEffect, useMemo, useState } from "react";
import FlexiDataTable from "@/components/FlexiDataTable";
import { ComponentType, TOXIC_CLIENT_CHECK_CRM_MAPPING_MODE_ENUM, TOXIC_CLIENT_CHECK_ENTRY_CRM_MAPPING_MODE } from "@/constants";
import { BrandsList, ToxicClientCheckUploadEntryProps } from "@/constants/type";
import { DTColProps } from "@/utils/Common";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Form, FormInstance, Switch, Tooltip } from "antd";
import { FormComponent } from "@/components/FormComponent";
import { isEmptyOrNull } from "@/utils/string";

interface UnconfirmedBrandsTabProps {
    data: ToxicClientCheckUploadEntryProps[];
    form: FormInstance;
    servers: any[];
    updateUntouchedRowsCount: (selectedRowsCount: number) => void;
    updateApplyAllRows: (updatedObj: any) => void;
    vantageBrands: BrandsList[];
};

interface ToxicClientCheckUploadEntryPropsExtra extends ToxicClientCheckUploadEntryProps {
    accServerKey: string;
};

const UnconfirmedBrandsTab = (props: UnconfirmedBrandsTabProps) => {
    const [selectedRows, setSelectedRows] = useState<string[]>([]); // to track rows that have been edited

    const data = useMemo(() => {
        return props.data.map((x: ToxicClientCheckUploadEntryProps) => {
            let currServerObj: any = props.servers.find((y: any) => y.text === x.server);
            return {
                ...x,
                accServerKey: `${x.accountId}_${isEmptyOrNull(currServerObj) ? 0 : currServerObj?.value}`,
            };
        });
    }, [props.data, props.servers]);

    const previewColumns = useMemo(
        () => [
            DTColProps.XSmall({
                title: "Row",
                dataIndex: "rowNum",
                key: "rowNum",
            }),
            DTColProps.XSmall({
                title: "Account ID",
                dataIndex: "accountId",
                key: "accountId",
            }),
            DTColProps.XSmall({
                title: "Server",
                dataIndex: "server",
                key: "server",
            }),
            DTColProps.XSmall({
                title: "CRM Mapping Mode",
                dataIndex: "crmMappingMode",
                key: "crmMappingMode",
                render: (_: number, rowData: ToxicClientCheckUploadEntryPropsExtra) => {
                    if (
                        rowData.brand === null &&
                        rowData.clientId === null &&
                        rowData.clientName === null &&
                        rowData.crmBrand === null &&
                        rowData.regulator === null
                    ) {
                        return (
                            <div
                                className={`tccl-mode-tag tccl-mode-tag-${TOXIC_CLIENT_CHECK_ENTRY_CRM_MAPPING_MODE[TOXIC_CLIENT_CHECK_CRM_MAPPING_MODE_ENUM.Auto]
                                    }`}
                            >
                                {TOXIC_CLIENT_CHECK_ENTRY_CRM_MAPPING_MODE[TOXIC_CLIENT_CHECK_CRM_MAPPING_MODE_ENUM.Auto]}
                                <Tooltip placement="top" title={"Client data and brand mapping populated from pipeline"}>
                                    <span>
                                        <InfoCircleOutlined />
                                    </span>
                                </Tooltip>
                            </div>
                        );
                    } else {
                        return (
                            <div
                                className={`tccl-mode-tag tccl-mode-tag-${TOXIC_CLIENT_CHECK_ENTRY_CRM_MAPPING_MODE[TOXIC_CLIENT_CHECK_CRM_MAPPING_MODE_ENUM.Manual]
                                    }`}
                            >
                                {TOXIC_CLIENT_CHECK_ENTRY_CRM_MAPPING_MODE[TOXIC_CLIENT_CHECK_CRM_MAPPING_MODE_ENUM.Manual]}
                                <Tooltip placement="top" title={"Self populated client data and brand mapping by users"}>
                                    <span>
                                        <InfoCircleOutlined />
                                    </span>
                                </Tooltip>
                            </div>
                        );
                    }
                },
            }),
            DTColProps.Small({
                title: "Toxic Client Label",
                dataIndex: "toxicClientLabel",
                key: "toxicClientLabel",
            }),
            DTColProps.XLarge({
                title: (
                    <div className="header-title-container">
                        <div className="header-title-text">Brand</div>
                        <Tooltip title="Apply to all accounts">
                            <Switch
                                onChange={(checked: boolean) => handleColSwitchChange(checked, "brandId")}
                                checkedChildren="Vantage"
                                unCheckedChildren="Vantage APAC"
                            />
                        </Tooltip>
                    </div>
                ),
                dataIndex: "brandId",
                key: "brandId",
                render: (_: any, rowData: ToxicClientCheckUploadEntryPropsExtra) => (
                    <FormComponent
                        label=""
                        name={["previewTable", rowData.accServerKey, "brandId"]}
                        extra={{
                            type: ComponentType.radio,
                            value: props.vantageBrands.map((x: BrandsList) => ({ text: x.brand, value: x.id })),
                            inputProps: {
                                onChange: () => handleRadioChange(rowData.accServerKey),
                            },
                        }}
                    />
                ),
            }),
        ],
        []
    );

    const handleRadioChange = (key: string) => {
        setSelectedRows(prev => {
            const newSelectedRows = prev.includes(key) ? prev : [...prev, key];

            props.updateUntouchedRowsCount(newSelectedRows.length);

            return newSelectedRows;
        });
    };

    // handle apply brand to all accounts feature
    const handleColSwitchChange = (checked: boolean, colName: string) => {
        let clonedPrevTableObj = props.form.getFieldValue("previewTable");
        let thisAccServerKeys = Object.keys(clonedPrevTableObj);
        let tmpObj: any = {};

        // update the previewTable object with the new values
        thisAccServerKeys.forEach((x: string) => {
            tmpObj[x] = {};
            if (checked) {
                tmpObj[x][colName] = props.vantageBrands && props.vantageBrands.find((y: BrandsList) => y.brand.toLowerCase() === "vantage")?.id;
            } else {
                tmpObj[x][colName] = props.vantageBrands && props.vantageBrands.find((y: BrandsList) => y.brand.toLowerCase() === "vantage apac")?.id;
            };
        });
        props.updateApplyAllRows(tmpObj);

        // update selectedRows state - send all keys to selectedRows
        // update the untouchedRowsCount
        setSelectedRows(thisAccServerKeys);
        props.updateUntouchedRowsCount(thisAccServerKeys.length);
    };

    useEffect(() => {
        let tmpObj: any = {};

        // setting initial values for previewTable so that it will not come out undefined
        data.forEach((x: ToxicClientCheckUploadEntryPropsExtra) => {
            tmpObj[x.accServerKey] = { brandId: null };
        });
        props.form.setFieldsValue({ previewTable: tmpObj });
    }, []);

    return (
        <div className="preview-upload-unconfirmed-brands-tab">
            <Form form={props.form}>
                <FlexiDataTable
                    bordered
                    rowKeyProperty="rowNum"
                    columns={previewColumns}
                    dataSource={data ?? []}
                    title={"Please confirm if the brands fall under Vantage or Vantage APAC."}
                    options={{
                        showHideColumns: false,
                        enableFilter: false,
                    }}
                    loading={false}
                />
            </Form>
        </div>
    );
};

export default UnconfirmedBrandsTab;
