import { FormComponent } from "@/components/FormComponent";
import { SUCCESS_FAILED, ComponentType } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { ErrorMessageHandler } from "@/utils/Common";
import { defaultIfEmptyOrNull } from "@/utils/string";
import { UploadOutlined } from "@ant-design/icons";
import { Form, Modal, Row, Col, Upload, Button } from "antd";
import moment from "moment";
import { useState, useCallback, useEffect } from "react";

interface SymbolSwapUploadFileModalProps {
    isModalVisible: boolean;
    callback: (type: number, data: any) => void;
}

export enum SymbolSwapUploadFileModalCallbackKey {
    Close = 0,
    SubmitSuccess = 1,
    SubmitFailed = 2,
    Submitting = 3,
}

const SymbolSwapUploadFileModal = (props: SymbolSwapUploadFileModalProps) => {
    const [fileList, setFileList] = useState<any[]>([]);
    const [executeForm] = Form.useForm();

    const confirmExecute = useCallback(
        (uploadDate: string) => {
            if (fileList.length < 1) {
                ErrorMessageHandler(`Please upload any file.`, SUCCESS_FAILED.OTHERS_FAILED);
                return;
            }

            props.callback(SymbolSwapUploadFileModalCallbackKey.Close, null);

            Modal.confirm({
                title: "Are you sure you want to import?",
                onOk: () => {
                    props.callback(SymbolSwapUploadFileModalCallbackKey.Submitting, null);

                    var formData = new FormData();
                    fileList.forEach((file: any) => {
                        formData.append("uploadFile", file);
                    });
                    formData.append("uploadDate", uploadDate);

                    plainAxiosInstance
                        .post(APIs.RISK_TOOL.GET_SYMBOL_SWAP_UPLOAD_UPLOAD, formData)
                        .then(res => {
                            if (res.status === 200 && res.data === "SUCCESS") {
                                ErrorMessageHandler("File upload successfully.", SUCCESS_FAILED.OTHERS_SUCCESS);
                                props.callback && props.callback(SymbolSwapUploadFileModalCallbackKey.SubmitSuccess, res.data);
                            } else {
                                ErrorMessageHandler(`${res.data}.`, SUCCESS_FAILED.OTHERS_FAILED);
                                props.callback && props.callback(SymbolSwapUploadFileModalCallbackKey.SubmitFailed, null);
                            }
                        })
                        .catch((error: any) => {
                            ErrorMessageHandler(`File upload failed:`, SUCCESS_FAILED.OTHERS_FAILED, error);
                            props.callback && props.callback(SymbolSwapUploadFileModalCallbackKey.SubmitFailed, null);
                        });
                },
            });
        },
        [fileList, props]
    );

    const handleSubmit = (fileList: any) => {
        for (let index = 0, count = fileList.length; index < count; index++) {
            const file = fileList[index];
            var result = defaultIfEmptyOrNull(/\.[^\.]+/.exec(file.name), [""]);
            const isLt5M = file.size / 1024 / 1024 < 5;

            if (result[0] !== ".xlsx") {
                ErrorMessageHandler("Please check file type. Only .xlsx files are allowed.", SUCCESS_FAILED.OTHERS_FAILED);
                return;
            } else if (!isLt5M) {
                ErrorMessageHandler("Please check file size less than 5 MB.", SUCCESS_FAILED.OTHERS_FAILED);
                return;
            }
        }

        setFileList(fileList);
    };

    useEffect(() => {
        if (props.isModalVisible) {
            setFileList([]);
            executeForm.setFieldsValue({ uploadDate: "" });
        }
    }, [props.isModalVisible]);

    return (
        <Modal
            style={{ top: 10 }}
            width={"25vw"}
            title="Upload Symbol Swap File"
            open={props.isModalVisible}
            okText="Execute"
            onOk={() => {
                executeForm
                    .validateFields()
                    .then((values: any) => {
                        confirmExecute(moment(values.uploadDate).format("YYYY-MM-DD"));
                    })
                    .catch((err: any) => console.log(err));
            }}
            onCancel={() => props.callback(SymbolSwapUploadFileModalCallbackKey.Close, null)}
            maskClosable={false}
        >
            <Form form={executeForm} layout="horizontal" labelCol={{ span: 8 }}>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Upload Date"
                            name="uploadDate"
                            extra={{
                                type: ComponentType.date,
                                value: "",
                                dateFormat: "YYYY-MM-DD",
                                itemProps: {
                                    rules: [{ required: true, message: "Please select date" }],
                                },
                                inputProps: {
                                    disabledDate: false, // following the same logic as RC's
                                },
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={8} style={{ textAlign: "right", paddingRight: "8px", display: "flex", justifyContent: "flex-end", marginTop: "4px" }}>
                        Upload File:
                    </Col>
                    <Col span={14}>
                        <Upload
                            {...{
                                name: "file",
                                accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                                multiple: true,
                                maxCount: 30,
                                fileList: fileList,
                                showUploadList: {
                                    showRemoveIcon: false,
                                    showPreviewIcon: false,
                                    showDownloadIcon: false,
                                },
                                onChange: (info: any) => {
                                    if (info.file.status === "error") {
                                        ErrorMessageHandler(`${info.file.name} file upload failed.`, SUCCESS_FAILED.OTHERS_FAILED);
                                    }
                                },
                                customRequest: ({ file, onSuccess }: any) =>
                                    setTimeout(() => {
                                        onSuccess("ok");
                                    }, 0),
                                beforeUpload: (file: any, fileList: any[]) => {
                                    return new Promise((resolve, reject) => {
                                        if (file.uid === fileList[fileList.length - 1].uid) {
                                            handleSubmit(fileList);
                                            reject();
                                        } else {
                                            reject();
                                        }
                                    });
                                },
                            }}
                        >
                            <Button htmlType="button" icon={<UploadOutlined style={{ fontSize: "0.875rem" }} />} style={{ width: "auto" }}>
                                Upload File
                            </Button>
                        </Upload>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default SymbolSwapUploadFileModal;
