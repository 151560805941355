import { Segmented } from "antd";
import { SegmentedValue } from "antd/lib/segmented";
import { useState } from "react";
import LabelSetting from "./LabelSetting";
import WhitelistSetting from "./WhitelistSetting";
import PanelContainer from "../../PanelContainer";

export interface CIDSettingsPageProps { }

const CIDSettingsPage = (props: CIDSettingsPageProps) => {
    const [segmentVal, setSegmentVal] = useState<string>("Label Configuration");

    return (
        <PanelContainer title="CID Settings">
            <div className="CID-setting-container">
                <div className="top-panel">
                    <Segmented
                        options={["Label Configuration", "Whitelist Setting"]}
                        value={segmentVal}
                        onChange={(value: SegmentedValue) => setSegmentVal(value as string)}
                    />
                </div>
                <div className="container">
                    {segmentVal === "Label Configuration" && <LabelSetting />}
                    {segmentVal === "Whitelist Setting" && <WhitelistSetting />}
                </div>
            </div>
        </PanelContainer>
    );
};

export default CIDSettingsPage;
