import { useEffect, useState } from "react";
import FlexiDataTable from "@/components/FlexiDataTable";
import { ComponentType, SUCCESS_FAILED } from "@/constants";
import { APIs } from "@/services/apis";
import { DTColProps, ErrorMessageHandler, ErrorCatchValidator } from "@/utils/Common";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { DCVersionMonitorData } from "@/constants/type";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

const VersionMonitorTab = () => {
    const [data, setData] = useState<DCVersionMonitorData[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isFirstOpt, setIsFirstOpt] = useState<boolean>(true);

    const columns: any[] = [
        DTColProps.Small({
            title: "Version",
            dataIndex: "version",
            key: "version",
            sorter: (a: any, b: any) => {
                if (!a.version && !b.version) return 0;
                if (!a.version) return 1;
                if (!b.version) return -1;
                return a.version.localeCompare(b.version);
            },
            options: {
                filter: {
                    type: ComponentType.text,
                },
            },
        }),
        DTColProps.Small({
            title: "Server",
            dataIndex: "server",
            key: "server",
        }),
        DTColProps.Middle({
            title: "DC Name",
            dataIndex: "dcName",
            key: "dcName",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.XXSmall({
            title: "Enabled",
            dataIndex: "isEnable",
            key: "isEnable",
            render: (isEnable: number) => (
                <span>
                    {isEnable === 1 ? (
                        <CheckCircleOutlined style={{ color: "#0ab76e", fontSize: "1.375rem" }} />
                    ) : (
                        <CloseCircleOutlined style={{ color: "#f00f00", fontSize: "1.375rem" }} />
                    )}
                </span>
            ),
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: [
                        { text: "Enabled", value: 1 },
                        { text: "Disabled", value: 0 },
                    ],
                },
            },
        },
            ["text-center"]
        ),
        DTColProps.Middle({
            title: "DC URL",
            dataIndex: "dcUrl",
            key: "dcUrl",
            sorter: (a: any, b: any) => a.dcUrl.localeCompare(b.dcUrl),
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
    ];

    const getDcVersionMonitoringData = () => {
        plainAxiosInstance
            .get(`${APIs.RC_DATA_CENTER.GET_DATA_CENTER_VERSION_MONITOR}`,)
            .then((res: any) => {
                let data = res.data.data;
                if (data.length > 0) {
                    let newData = data.map((x: DCVersionMonitorData, i: number) => ({ ...x, newKey: i }));
                    setData(newData);
                } else {
                    setData([]);
                };
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => {
                    console.log("err: ", err);
                    ErrorMessageHandler(`Failed to load data center version monitor data${err.response.data.message ? `: ${err.response.data.message}` : `: ${err.message}`}`, SUCCESS_FAILED.OTHERS_FAILED);
                    setData([]);
                })
            )
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getDcVersionMonitoringData();
    }, []);

    return (
        <div className="single-page with-background">
            <div className={`${isFirstOpt ? "active" : ""}`}>
                <FlexiDataTable
                    bordered
                    rowKeyProperty="newKey"
                    title=""
                    columns={columns}
                    options={{}}
                    dataSource={data ?? []}
                    callback={() => { }}
                    loading={isLoading}
                />
            </div>
        </div>
    );
};

export default VersionMonitorTab;