import { useState, useEffect } from "react";
import { message, Button, Form, Row, Col, Modal, Popconfirm } from "antd";
import { Link, NavigateFunction, useNavigate, useParams } from "react-router-dom";
import {
    CustomPaginationProps,
    ReportResponseData,
    SelectedMetrics,
    FlexiDataTableOptionsProps,
    FlexiDataTableCallbackProps,
} from "../../constants/type";
import FlexiDataTable from "../../components/FlexiDataTable";
import { ColumnsType } from "antd/es/table";
import SitePageHeader from "../../components/PageHeader";
import { HomeOutlined } from "@ant-design/icons";
import { FormComponent } from "../../components/FormComponent";
import { DATA_TYPES_ENUM, METRIC_TYPES_NAMES, CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../constants";
import { DataTableColumnRender, DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../utils/Common";
import AuthHelper, { AuthKeys } from "../../helpers/authHelper";
import { apiRequest, APIs } from "../../services/apiConfig";
import { SOMETHING_WENT_WRONG } from "../../constants/errorMessage";

interface ViewReportInstancesPageProps {
    userName: string;
    reportHistoryId: string;
    templateName: string;
    reportName: string;
    sharingRules: string;
    createdDateUtc: string;
}

const getReportInfo = (viewState: string): ViewReportInstancesPageProps | undefined => {
    let rInfo = atob(viewState).split("|");
    return rInfo.length === 6
        ? ({
            reportHistoryId: rInfo[0],
            reportName: rInfo[1],
            templateName: rInfo[2],
            userName: rInfo[3],
            sharingRules: rInfo[4],
            createdDateUtc: rInfo[5],
        } as ViewReportInstancesPageProps)
        : undefined;
};

const ViewReportInstancesPage = () => {
    let { id } = useParams();
    const reportHistoryInfo = getReportInfo(id || "") || {
        reportHistoryId: "",
        reportName: "",
        templateName: "",
        userName: "",
        sharingRules: "",
        createdDateUtc: "",
    };

    const initialDataMarkUp = (data: ViewReportInstancesPageProps) => {
        let tmp = { ...data };
        tmp.createdDateUtc = DataTableColumnRender.DateTime(tmp.createdDateUtc);
        return tmp;
    };

    const [reportData, setReportData] = useState<any>([]);
    const [metrics, setMetrics] = useState([]);
    const [selectedReport] = useState<ViewReportInstancesPageProps>(initialDataMarkUp(reportHistoryInfo));
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
    const [reportForm] = Form.useForm();
    const [reportInfo, setReportInfo] = useState<ReportResponseData>();

    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);

    let navigate = useNavigate();

    const authHp = new AuthHelper();

    useEffect(() => {
        getInitialData();
        return () => { };
    }, []);

    const getInitialData = () => {
        setIsLoading(true);

        apiRequest(APIs.GET_REPORT_HISTORY_BY_ID, {
            current: pagination.current,
            limit: pagination.pageSize,
            reportHistoryId: reportHistoryInfo.reportHistoryId,
        })
            .then((data: any) => {
                if (data?.total === 0) {
                    if (data?.isExceedRowCountLimit === true) {
                        Modal.info({
                            title: "This is a notification message",
                            content: (
                                <div>
                                    <p>The report has exceeded the row count limit. Hence, it is unable to display in the table below.</p>
                                    <p>However, you may proceed to download it.</p>
                                </div>
                            ),
                            onOk() { },
                        });
                    } else {
                        ErrorMessageHandler(`No records found when generating this report template.`, SUCCESS_FAILED.OTHERS_SUCCESS);
                    }
                }
                setReportInfo(data);
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (res: any) => ErrorMessageHandler("report history data", SUCCESS_FAILED.FAILED_LOAD_DATA, res))
            )
            .finally(() => setIsLoading(false));
    };

    const columns = [
        DTColProps.Small({
            title: "Symbol",
            key: "symbol",
            dataIndex: "symbol",
            fixed: "left",
            render: (symbol: any) => (symbol ? symbol : "-"),
        }),

        DTColProps.Small({
            title: "Server",
            dataIndex: "server",
            key: "server",
            fixed: "left",
            render: (server: any) => (server ? server : "-"),
        }),

        DTColProps.Small({
            title: "Brand",
            dataIndex: "brand",
            key: "brand",
            fixed: "left",
            render: (brand: any) => (brand ? brand : "-"),
        }),

        // DTColProps.Small({
        //     title: "Account Type",
        //     dataIndex: "account_type",
        //     key: "account_type",
        //     fixed: "left",
        //     render: (account_type: any) => (account_type ? account_type : "-"),
        // }),

        DTColProps.Small({
            title: "Login ID",
            dataIndex: "login",
            key: "login",
            // ...((authHp.isAuthorized(AuthKeys.ANALYSIS_ACCOUNT_PROFILE_VIEW) ||
            //     authHp.isAuthorized(AuthKeys.ANALYSIS_ACCOUNT_WATCHLIST_VIEW) ||
            //     authHp.isAuthorized(AuthKeys.TAGGING_LIST_VIEW)) && {
            //     render: (login: number, rowData: any) =>
            //         login ? (
            //             <Link
            //                 to={getNavigationUrl(
            //                     ACCOUNT_PROFILE_FROM_MODULE.VIEW_REPORT_INSTANCES,
            //                     getAccountProfileParams(
            //                         rowData.server_id,
            //                         rowData.login,
            //                         ACCOUNT_PROFILE_FROM_MODULE.VIEW_REPORT_INSTANCES,
            //                         id as string,
            //                         "",
            //                         "",
            //                         "",
            //                         ""
            //                     ) || ""
            //                 )}
            //                 style={{ color: "#0e65cc" }}
            //                 target="_blank"
            //             >
            //                 {login}
            //             </Link>
            //         ) : (
            //             "-"
            //         ),
            // }),
            fixed: "left",
            // sorter: (a: any, b: any) => a.login - b.login,
        }),
    ];

    if (reportInfo?.metricTypeName === METRIC_TYPES_NAMES.ACCOUNT) columns.splice(0, 1);
    if (reportInfo?.metricTypeName === METRIC_TYPES_NAMES.SYMBOL) columns.splice(3, 1);

    if (metrics) {
        metrics.forEach((metric: SelectedMetrics, index: number) => {
            let isLastItem = index == metrics.length - 1;
            if (metric.dataType === DATA_TYPES_ENUM.Double || metric.dataType === DATA_TYPES_ENUM.Int) {
                columns.splice(
                    0,
                    0,
                    DTColProps.Currency({
                        title: metric.displayName,
                        key: metric.name,
                        dataIndex: metric.name,
                    })
                );
            } else {
                columns.splice(
                    0,
                    0,
                    DTColProps.Middle({
                        title: metric.displayName,
                        key: metric.name,
                        dataIndex: metric.name,
                    })
                );
            }
        });
    }

    const options: FlexiDataTableOptionsProps = {
        add: false,
        edit: false,
        delete: false,
        enableFilter: false,
        export: {
            text: "Download Report",
        },
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, data) => {
        switch (type) {
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                setPagination(prev => ({ ...prev, current: data.pagination.current, pageSize: data.pagination.pageSize }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                setIsBtnLoading(true);
                apiRequest(APIs.DOWNLOAD_REPORT, { reportHistoryId: selectedReport?.reportHistoryId }, "POST", "arraybuffer")
                    .then((res: any) => {
                        const fileName = res.headers["x-filename"];
                        const url = window.URL.createObjectURL(new Blob([res.data], { type: "application/zip" })); // Create blob link to download
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", fileName); // or any other extension
                        document.body.appendChild(link); // Append to html link element page
                        link.click(); // start download
                        document.body.removeChild(link); // Clean up and remove the link
                    })
                    .catch(error => ErrorCatchValidator(error, (res: any) => ErrorMessageHandler(SOMETHING_WENT_WRONG, SUCCESS_FAILED.OTHERS_FAILED)))
                    .finally(() => setIsBtnLoading(false));
                break;
        }
    };

    const deleteReport = (reportHistoryId: string, navigator: NavigateFunction) => {
        apiRequest(APIs.DELETE_REPORT_HISTORY, { reportHistoryId })
            .then(data => {
                ErrorMessageHandler("The report history", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                navigator("/report/result");
            })
            .catch(error =>
                ErrorCatchValidator(error, (res: any) => ErrorMessageHandler("report history", SUCCESS_FAILED.FAILED_DELETE_DATA, error))
            );
    };

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getInitialData();
            setRunRefetchDataList(false);
        }
        return () => { };
    }, [runRefetchDataList]);

    return (
        <SitePageHeader
            title={"View Report Instances"}
            routes={[
                {
                    path: "/report/result",
                    breadcrumbName: "Report Result",
                    icon: <HomeOutlined />,
                },
                { path: "", breadcrumbName: "View Report Instances" },
            ]}
            onBack={() => navigate("/report/result")}
            {...authHp.isAuthorized(AuthKeys.TEMPLATE_VIEW, {
                extraProps: {
                    extra: reportInfo?.viewTemplate
                        ? [
                            reportInfo.editable && (
                                <Popconfirm
                                    key={"cetmp-del"}
                                    title="Confirm to delete?"
                                    onConfirm={() => deleteReport(reportHistoryInfo.reportHistoryId, navigate)}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button type="primary" danger>
                                        Delete Report
                                    </Button>
                                </Popconfirm>
                            ),
                            <Button
                                key={"vt-dup"}
                                type="primary"
                                onClick={() => {
                                    let viewState = `${reportInfo?.reportId}|${selectedReport.templateName}|result|${id}`;
                                    navigate(`/report/result/viewtemplate/${btoa(viewState)}`);
                                }}
                            >
                                View Template
                            </Button>,
                        ]
                        : [],
                },
            })}
        >
            <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} layout="horizontal" form={reportForm} initialValues={selectedReport}>
                <Row>
                    <Col span={15}>
                        <FormComponent label={""} name={"reportHistoryId"} extra={{ type: ComponentType.hidden, value: "" }} />
                        <FormComponent
                            label="Template Name"
                            name="templateName"
                            extra={{
                                type: ComponentType.text,
                                value: "",
                                disabled: true,
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={15}>
                        <FormComponent
                            label="Sharing"
                            name="sharingRules"
                            extra={{
                                type: ComponentType.dropdown,
                                // value: ToOptionTypeList(SHARING_RULES),
                                value: [
                                    { text: "Private", value: "1" },
                                    { text: "Public", value: "2" },
                                ],
                                disabled: true,
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={15}>
                        <FormComponent
                            label="Created By"
                            name="userName"
                            extra={{
                                type: ComponentType.text,
                                value: "",
                                disabled: true,
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={15}>
                        <FormComponent
                            label="Created At (Local)"
                            name="createdDateUtc"
                            extra={{
                                type: ComponentType.text,
                                value: "",
                                disabled: true,
                            }}
                        />
                    </Col>
                </Row>
                <div className="step-btns-group"></div>

                <FlexiDataTable
                    title={`Report Name: ${selectedReport?.reportName || "N/A"}`}
                    columns={columns.reverse()}
                    options={options}
                    dataSource={reportData ?? []}
                    callback={componentCallback}
                    rowKeyProperty={"key"}
                    loading={isLoading}
                    pagination={pagination}
                    serverSide={true}
                    exporting={isBtnLoading}
                />
            </Form>
        </SitePageHeader>
    );
};

export default ViewReportInstancesPage;
