import { Tag, Timeline } from "antd";
import "../../../index.less";
import { ProblematicClientAuditLogResponse } from "../../../constants/type";
import { formatDistance } from "date-fns";
import { DataTableColumnRender } from "../../../utils/Common";

interface ClientAuditLogProps {
    problematicClientAuditLogData: ProblematicClientAuditLogResponse[];
    statusKeyValue: any;
}

const ClientAuditLog = (props: ClientAuditLogProps) => {
    return (
        <Timeline>
            {props.problematicClientAuditLogData.map((currData: ProblematicClientAuditLogResponse, i: number) => (
                <Timeline.Item key={i}>
                    <div className="timeline-box">
                        <div className="timeline-head">
                            <div className={`timeline-title`}>
                                <Tag className={`prb-blockTypeColor prb-blockColor-active`}>{currData.blockType}</Tag>
                                {currData.team && <Tag className={`prb-team`}>{currData.team}</Tag>}
                                <b>{currData.createdByName}</b> {currData.crudAction.toLowerCase()} status{" "}
                                <span
                                    className={
                                        currData.oldStatus
                                            ? `problematic-sts-${props.statusKeyValue?.hasOwnProperty(currData.oldStatus)
                                                ? props.statusKeyValue[currData.oldStatus]
                                                : ""
                                            }-text`
                                            : ""
                                    }
                                >
                                    <b>{currData.oldStatus}</b>
                                </span>{" "}
                                to{" "}
                                <span
                                    className={
                                        currData.newStatus
                                            ? `problematic-sts-${props.statusKeyValue?.hasOwnProperty(currData.newStatus)
                                                ? props.statusKeyValue[currData.newStatus]
                                                : ""
                                            }-text`
                                            : ""
                                    }
                                >
                                    <b>{currData.newStatus}</b>
                                </span>
                                .{" "}
                                {currData.accountId && currData.server && (
                                    <>
                                        {" "}
                                        (
                                        {currData.accountId && (
                                            <span>
                                                {" "}
                                                Account ID :{" "}
                                                {currData.accountId}
                                            </span>
                                        )}
                                        {currData.server && <span> | Server : {currData.server} </span>} )
                                    </>
                                )}
                            </div>
                        </div>
                        <div className={`timeline-comment`}>{currData.currentComment && <div>Comments : {currData.currentComment}</div>}</div>
                        <div className={`timeline-description`}>
                            {formatDistance(new Date(currData.createdDateUtc), new Date(), {
                                includeSeconds: true,
                            })}{" "}
                            ago - {DataTableColumnRender.DateTime(currData.createdDateUtc)}
                        </div>
                    </div>
                </Timeline.Item>
            ))}
        </Timeline>
    );
};

export default ClientAuditLog;
