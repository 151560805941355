import { useEffect, useMemo, useState } from "react";
import FlexiDataTable from "@/components/FlexiDataTable";
import { Modal } from "antd";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { AdminFeeProcessData, AdminFeeTradesData } from "@/constants/type";
import { SUCCESS_FAILED } from "@/constants";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { isEmptyOrNull } from "@/utils/string";

interface ViewTradesModalProps {
    currTradeView: AdminFeeProcessData | null;
    onClose: () => void;
};

const ViewTradesModal = (props: ViewTradesModalProps) => {
    const [tradesData, setTradesData] = useState<AdminFeeTradesData[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const isBtc = useMemo(() => {
        if (!isEmptyOrNull(props.currTradeView)) {
            return props.currTradeView?.group.includes("_BTC");
        };
        return false;
    }, [props.currTradeView]);

    const tradeColumns = useMemo(() => [
        DTColProps.XSmall({
            title: "Ticket",
            dataIndex: "ticket",
            key: "ticket",
            fixed: "left",
            sorter: (a: any, b: any) => a.ticket - b.ticket,
        }),
        DTColProps.XSmall({
            title: "Symbol",
            dataIndex: "symbol",
            key: "symbol",
            sorter: (a: any, b: any) => a.symbol.localeCompare(b.symbol),
        }),
        DTColProps.XSmall({
            title: "Clean Symbol",
            dataIndex: "cleanSymbol",
            key: "cleanSymbol",
            sorter: (a: any, b: any) => a.cleanSymbol.localeCompare(b.cleanSymbol),
        }),
        DTColProps.XSmall({
            title: "Volume",
            dataIndex: "volume",
            key: "volume",
            sorter: (a: any, b: any) => a.volume - b.volume,
            render: (volume: number) => volume !== null ? volume.toFixed(2) : volume,
        },
            ["text-right"]
        ),
        DTColProps.XXSmall({
            title: "CMD",
            dataIndex: "cmd",
            key: "cmd",
            sorter: (a: any, b: any) => a.cmd - b.cmd,
            render: (cmd: number) => cmd === 0 ? "Buy" : "Sell",
        }),
        DTColProps.XSmall({
            title: "Exchange Rate",
            dataIndex: "eodPrice",
            key: "eodPrice",
            sorter: (a: any, b: any) => a.eodPrice - b.eodPrice,
            render: (eodPrice: number) => eodPrice !== null ? eodPrice.toFixed(5) : eodPrice,
        },
            ["text-right"]
        ),
        DTColProps.XSmall(
            {
                title: "Contract Size",
                dataIndex: "contractSize",
                key: "contractSize",
                sorter: (a: any, b: any) => a.contractSize - b.contractSize,
            },
            ["text-right"]
        ),
        DTColProps.XSmall({
            title: "Point Size",
            dataIndex: "pointSize",
            key: "pointSize",
            sorter: (a: any, b: any) => a.pointSize - b.pointSize,
            render: (pointSize: number) => pointSize !== null ? pointSize.toFixed(5) : pointSize,
        },
            ["text-right"]
        ),
        DTColProps.XSmall({
            title: "Swap Point",
            dataIndex: "swapPoint",
            key: "swapPoint",
            sorter: (a: any, b: any) => a.swapPoint - b.swapPoint,
            render: (swapPoint: number) => swapPoint !== null ? swapPoint.toFixed(2) : swapPoint,
        },
            ["text-right"]
        ),
        DTColProps.XSmall(
            {
                title: "EOD Price",
                dataIndex: "closePrice",
                key: "closePrice",
                sorter: (a: any, b: any) => a.closePrice - b.closePrice,
                render: (closePrice: number) => closePrice !== null ? closePrice.toFixed(5) : closePrice,
            },
            ["text-right"]
        ),
        DTColProps.XSmall({
            title: "Swap",
            dataIndex: "swap",
            key: "swap",
            sorter: (a: any, b: any) => a.swap - b.swap,
            render: (swap: number) => swap !== null
                ? isBtc
                    ? swap.toFixed(8)
                    : swap.toFixed(2)
                : swap,
        },
            ["text-right"]
        ),
    ], [isBtc]);

    useEffect(() => {
        if (!isEmptyOrNull(props.currTradeView)) {
            setIsLoading(true);
            plainAxiosInstance
                .get(`${APIs.RC_ADMINFEE.GET_AF_TRADES_DATA}?serverId=${props.currTradeView?.serverId}&receiveDate=${props.currTradeView?.receiveDate}&login=${props.currTradeView?.login}`)
                .then((res: any) => {
                    if (res.data.length > 0) {
                        setTradesData(res.data);
                    } else {
                        setTradesData([]);
                    };
                })
                .catch((error: any) => ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler(`${props.currTradeView?.server}-${props.currTradeView?.receiveDate}-${props.currTradeView?.login} admin fee trades`, SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                    setTradesData([]);
                }))
                .finally(() => setIsLoading(false));
        };
        return () => { };
    }, [props.currTradeView]);

    return (
        <div>
            <Modal
                width={"65%"}
                title={`${props.currTradeView?.server}-${props.currTradeView?.receiveDate}-${props.currTradeView?.login} Admin Fee Trades`}
                open={props.currTradeView !== null}
                onOk={props.onClose}
                onCancel={props.onClose}
                cancelText="Close"
                okButtonProps={{ style: { display: "none" } }}
            >
                <FlexiDataTable
                    bordered
                    rowKeyProperty="ticket"
                    title={false}
                    columns={tradeColumns}
                    options={{
                        enableFilter: false,
                        showHideColumns: false,
                    }}
                    dataSource={tradesData || []}
                    callback={() => { }}
                    loading={isLoading}
                />
            </Modal>
        </div>
    );
};

export default ViewTradesModal;