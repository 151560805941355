import { Button, Col, Form, Modal, Row, Tabs } from "antd";
import { useCallback, useState } from "react";
import PriceMonitorSpreadAlarm30Mins from "./components/alarm30mins";
import PriceMonitorSpreadAlarmHistorical from "./components/historicalSpreadAlarm";
import { DownloadOutlined } from "@ant-design/icons";
import { FormComponent } from "../../../components/FormComponent";
import { ComponentType, SUCCESS_FAILED } from "../../../constants";
import { REQUIRED_FIELD } from "../../../constants/errorMessage";
import moment from "moment";
import { APIs } from "../../../services/apis";
import { plainAxiosInstance } from "../../../services/axiosSetup";
import PriceMonitorBreadCrumbs from "../components/PriceMonitorBreadCrumbs";
import { ErrorCatchValidator, ErrorMessageHandler, getFileNameFromResponseHeader } from "@/utils/Common";

export interface PriceMonitorSpreadAlarmProps { }

const PriceMonitorSpreadAlarm = (props: PriceMonitorSpreadAlarmProps) => {
    const [currentTab, setCurrentTab] = useState<string>("1");
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [downloadForm] = Form.useForm();
    const [isDownloading, setIsDownloading] = useState<boolean>(false);

    const downloadReport = useCallback(
        (startDate: string, endDate: string) => {
            setIsDownloading(true);
            plainAxiosInstance
                .get(`${plainAxiosInstance.defaults.baseURL}${APIs.RC_PRICE_MONITOR.GET_REPORT_DOWNLOAD}?startdate=${startDate}&enddate=${endDate}`, {
                    headers: {
                        Accept: "application/octet-stream, */*",
                    },
                    responseType: "blob",
                })
                .then(response => {
                    const contentType = response.headers["content-type"];
                    const fileName = getFileNameFromResponseHeader(response, `spread_${startDate}_${endDate}.csv`);
                    if (
                        contentType === "application/octet-stream" ||
                        contentType === "text/csv" ||
                        contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    ) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", fileName);
                        document.body.appendChild(link);
                        link.click();
                        // Clean up
                        window.URL.revokeObjectURL(url);
                        ErrorMessageHandler(`Spread Report downloaded successfully.`, SUCCESS_FAILED.OTHERS_SUCCESS);
                    } else {
                        ErrorMessageHandler(`Received non-file response. Error: ${response}`, SUCCESS_FAILED.OTHERS_FAILED);
                    }
                })
                .catch(error => {
                    ErrorCatchValidator(error, (err: any) => {
                        ErrorMessageHandler(`Error occured during download${err.response.statusText ? `: ${err.response.statusText}` : `: ${err.message}`}`, SUCCESS_FAILED.OTHERS_FAILED);
                    });
                })
                .finally(() => {
                    setIsDownloading(false);
                    setIsModalVisible(false);
                });
        },
        []
    );

    return (
        <>
            <PriceMonitorBreadCrumbs currMenu="Spread Alarm" />
            <div className="pricemonitor-spread-alarm-container">
                <Tabs
                    activeKey={currentTab}
                    onChange={(activeKey: string) => setCurrentTab(activeKey)}
                    type="card"
                    tabBarStyle={{ marginBottom: "0px" }}
                    items={[
                        {
                            label: "Alarm in 30 min",
                            key: "1",
                            children: <PriceMonitorSpreadAlarm30Mins />,
                        },
                        {
                            label: "Historical Spread Alarm",
                            key: "2",
                            children: <PriceMonitorSpreadAlarmHistorical />,
                        },
                    ]}
                    tabBarExtraContent={{
                        right: (
                            <Button
                                icon={<DownloadOutlined />}
                                onClick={() => {
                                    downloadForm.setFieldsValue({ reportDate: [moment(), moment()] });
                                    setIsModalVisible(true);
                                }}
                                loading={isDownloading}
                            >
                                Download Report
                            </Button>
                        ),
                    }}
                />
            </div>

            <Modal
                width={500}
                title="Download Report"
                open={isModalVisible}
                cancelButtonProps={{ style: { display: "none" } }}
                onCancel={() => setIsModalVisible(false)}
                onOk={() => {
                    downloadForm
                        .validateFields()
                        .then((values: any) => {
                            let startDate = moment(values["reportDate"][0]).format("YYYY-MM-DD");
                            let endDate = moment(values["reportDate"][1]).format("YYYY-MM-DD");
                            downloadReport(startDate, endDate);
                            // setIsModalVisible(false);
                        })
                        .catch(errorInfo => console.log(errorInfo));
                }}
                okButtonProps={{ loading: isDownloading }}
            >
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={downloadForm}
                    layout="horizontal"
                    initialValues={{}}
                >
                    <Row>
                        <Col span={24}>
                            <FormComponent
                                label="Download Date"
                                name="reportDate"
                                extra={{
                                    type: ComponentType.daterange,
                                    value: [],
                                    dateFormat: "YYYY-MM-DD",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                    inputProps: {
                                        ranges: {
                                            Today: [moment(), moment()],
                                            "This Week": [moment().startOf("week"), moment().endOf("day")],
                                            "This Month": [moment().startOf("month"), moment().endOf("day")],
                                        },
                                    },
                                }}
                            />
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

export default PriceMonitorSpreadAlarm;
