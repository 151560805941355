import { List, Space, Tag } from "antd";
import "../../../index.less";
import { AccountProblematicDataResponse, ServersList } from "../../../constants/type";

interface ClientAccountModalProps {
    accountProblematicData: AccountProblematicDataResponse[];
    servers: ServersList[];
}

const ClientAccountModal = (props: ClientAccountModalProps) => {
    const groupedData: { [key: number]: AccountProblematicDataResponse[] } = {};

    props.accountProblematicData.forEach(item => {
        const accountId = item.accountId;
        if (!groupedData[accountId]) {
            groupedData[accountId] = [item];
        } else {
            groupedData[accountId].push(item);
        }
    });

    return (
        <List
            pagination={{
                pageSize: 10,
            }}
            grid={{ gutter: 16, column: 4 }}
            dataSource={Object.values(groupedData)}
            renderItem={group => (
                <div className="acc-list-container">
                    <List.Item className="list-box">
                        <div className="description">
                            <span style={{ fontWeight: "bold", fontSize: 14 }}>
                                {group[0].accountId}
                            </span>
                            <div>{props.servers.find(sv => sv.id === group[0].serverId)?.server ?? "-"}</div>
                        </div>
                    </List.Item>
                    {group.map(item => (
                        <List.Item className="list-box" key={item.id}>
                            <Space.Compact>
                                {item.crmStatus.toUpperCase() === "BLOCKED" ? (
                                    <Tag className={`prb-blockTypeColor prb-blockColor-active`}>{item.blockType}</Tag>
                                ) : (
                                    <Tag className={`prb-blockTypeColor prb-blockColor-inactive`}>{item.blockType}</Tag>
                                )} <div className="description">{item.statusType}</div>
                            </Space.Compact>
                        </List.Item>
                    ))}
                </div>
            )}
        />
    );
};

export default ClientAccountModal;
