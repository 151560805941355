import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { useCallback, useEffect, useMemo, useState } from "react";
import FlexiDataTable from "@/components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, InnerPageActionMode, SUCCESS_FAILED } from "@/constants";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps, KeyValuePair, CreateEditInlineFormBased } from "@/constants/type";
import { apiRequest } from "@/services/apiConfig";
import { APIs } from "@/services/apis";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { SortList } from "@/utils/array";
import { Tag } from "antd";
import CreateEditDataCenterPage from "./CreateEditDataCenter";
import PanelContainer from "../../PanelContainer";

export interface DataCenterPageSettingsProps { }

interface DataCenterSettingProps {
    comment: string | null;
    dcDn: string;
    dcId: number;
    dcUrl: string;
    enable: boolean;
    serverDn: string;
    serverId: number;
    typeFlag: number;
    typeFlagArr?: number[];
}

export interface DataCenterConfigBaseProps {
    servers: KeyValuePair[];
    dataCenters: KeyValuePair[];
    dataCenterCombinationDic: { [number: number]: number[] };
}

interface DataCenterConfigProps extends DataCenterConfigBaseProps {
    dataCenterKeyValueObj: { [number: number]: string };
}

export const getDataCenterCombinations = (arr: number[]) => {
    let sumCombinations: { [number: number]: number[] } = { 0: [] };

    for (let i = 0, icount = arr.length; i < 1 << icount; i++) {
        // Loop from 0 to 2^n - 1
        let sum = 0;
        let parts = [];

        for (let j = 0; j < icount; j++) {
            // Check if the j-th bit of i is set (i.e., include arr[j] in the sum)
            if (i & (1 << j)) {
                sum += arr[j];
                parts.push(arr[j]);
            }
        }

        if (parts.length > 0) {
            sumCombinations[sum] = parts; // Store the array of parts with the sum as the key
        }
    }
    return sumCombinations;
};

const DataCenterPageSettings = (props: DataCenterPageSettingsProps) => {
    const [isFirstOpt, setIsFirstOpt] = useState<boolean>(true);
    const [formObject, setFormObject] = useState<CreateEditInlineFormBased>({ mode: InnerPageActionMode.CREATE_NEW });
    const [refetch, setRefetch] = useState<number>(0);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<DataCenterSettingProps[]>([]);
    const [configState, setConfigState] = useState<DataCenterConfigProps>({
        servers: [],
        dataCenters: [],
        dataCenterCombinationDic: {},
        dataCenterKeyValueObj: {},
    });
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.CENTRALIZED_SETTINGS_DATA_CENTER_MANAGEMENT_EDIT);

    const columns: any[] = useMemo(
        () => [
            DTColProps.Small({
                title: "Server",
                dataIndex: "serverId",
                key: "serverId",
                render: (text: string, record: DataCenterSettingProps) => record.serverDn,
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: configState.servers,
                    },
                },
            }),
            DTColProps.Middle({
                title: "Data Center Name",
                dataIndex: "dcDn",
                key: "dcDn",
                options: {
                    filter: {
                        type: ComponentType.text,
                        value: "",
                    },
                },
            }),
            {
                title: "Type",
                dataIndex: "typeFlagArr",
                key: "typeFlagArr",
                render: (text: number[], rowData: DataCenterSettingProps) => {
                    if (text.length === 0) return "";

                    return text.map((x: number, idx: number) => (
                        <Tag key={`${rowData.dcId}-${rowData.typeFlag}-${idx}`}>{configState.dataCenterKeyValueObj[x]}</Tag>
                    ));
                },
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: configState.dataCenters,
                    },
                },
            },
            DTColProps.Middle({
                title: "Data Center Url",
                dataIndex: "dcUrl",
                key: "dcUrl",
                options: {
                    filter: {
                        type: ComponentType.text,
                        value: "",
                    },
                },
            }),
            DTColProps.Status({
                title: "Status",
                dataIndex: "enable",
                key: "enable",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: [
                            { text: "Active", value: true },
                            { text: "Inactive", value: false },
                        ],
                    },
                },
            }),
            {
                title: "Comment",
                dataIndex: "comment",
                key: "comment",
            },
        ],
        [configState]
    );

    const options: FlexiDataTableOptionsProps = {
        separateActionButton: true,
        add: enableUpdate,
        edit: enableUpdate,
        delete: enableUpdate,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, DCData) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                setIsFirstOpt(prev => !prev);
                setFormObject({ mode: InnerPageActionMode.CREATE_NEW, id: undefined });
                setRefetch(prev => prev + 1);
                break;
            case CALLBACK_KEY.DO_EDIT:
                setIsFirstOpt(prev => !prev);
                setFormObject({ mode: InnerPageActionMode.EDIT, id: DCData.dcId });
                setRefetch(prev => prev + 1);
                break;
            case CALLBACK_KEY.DO_DELETE:
                deleteDataCenter(DCData.dcId);
                break;
            default:
                break;
        }
    };

    const deleteDataCenter = (dcId: number) => {
        setIsLoading(true);
        apiRequest(APIs.DELETE_DATA_CENTER_V2, { dcId })
            .then(() => {
                ErrorMessageHandler("data center setting", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                getDataCenterList();
            })
            .catch(error =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("data center setting", SUCCESS_FAILED.FAILED_DELETE_DATA, err))
            )
            .finally(() => setIsLoading(false));
    };

    const getDataCenterList = useCallback(() => {
        setIsLoading(true);
        apiRequest(APIs.GET_DATA_CENTER_LIST_V2, { current: 1, limit: 9999999 })
            .then((res: any) => {
                setData(
                    res.result && res.result.length > 0
                        ? res.result.map((x: DataCenterSettingProps) => ({ ...x, typeFlagArr: configState.dataCenterCombinationDic[x.typeFlag] }))
                        : []
                );
            })
            .catch(error => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("data center list", SUCCESS_FAILED.FAILED_LOAD_DATA, err)))
            .finally(() => setIsLoading(false));
    }, [configState]);

    const getConfig = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["riskinsightserver", "riskinsightdatacentertype"] })
            .then((res: any) => {
                setConfigState({
                    servers: SortList(
                        res.riskInsightServers.map((x: any) => ({ text: x.serverDisplayName, value: x.serverId })),
                        "text"
                    ),
                    dataCenters: SortList(
                        res.riskInsightDataCenterTypes.map((x: any) => ({ text: x.name, value: x.type })),
                        "text"
                    ),
                    dataCenterCombinationDic: getDataCenterCombinations(res.riskInsightDataCenterTypes.map((x: any) => x.type)),
                    dataCenterKeyValueObj: res.riskInsightDataCenterTypes.reduce((acc: any, x: any) => {
                        acc[x.type] = x.name;
                        return acc;
                    }, {}),
                });
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => console.log("data center page config", err)))
            .finally(() => setRunRefetchDataList(true));
    };

    useEffect(() => {
        if (runRefetchDataList) {
            getDataCenterList();
            setRunRefetchDataList(false);
        }
    }, [runRefetchDataList]);

    useEffect(() => {
        getConfig();
    }, []);

    return (
        <PanelContainer title="Data Center Management">
            <div className="single-page">
                <div className={`${isFirstOpt ? "active" : ""}`}>
                    <div className="data-center-page-settings-container">
                        <FlexiDataTable
                            bordered
                            rowKeyProperty="dcId"
                            title=""
                            columns={columns}
                            options={options}
                            dataSource={data}
                            callback={componentCallback}
                            loading={isLoading}
                        />
                    </div>
                </div>
                <div className={`${isFirstOpt ? "" : "active"}`}>
                    <CreateEditDataCenterPage
                        {...formObject}
                        callback={(action: InnerPageActionMode, value: any) => {
                            switch (action) {
                                case InnerPageActionMode.BACK:
                                    setIsFirstOpt(prev => !prev);
                                    if (value?.hasOwnProperty("refreshMainList") && value["refreshMainList"]) {
                                        getDataCenterList();
                                    }
                                    break;
                            }
                        }}
                        resetState={refetch}
                    />
                </div>
            </div>
        </PanelContainer>
    );
};

export default DataCenterPageSettings;
