import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useRCDataCenterTypes } from "../../../hooks/useRCDataCenter";
import type { IRCDataCenter, IRCDataCenterData } from "../../../hooks/useRCDataCenter";
import { Badge, Col, Input, Row, Space, Switch, Table, Tag, Tooltip, Typography } from "antd";
import { colorMap } from "../StatusMonitor/statusUtils";
import { CheckCircleOutlined, WarningOutlined } from "@ant-design/icons";
import MessageCard from "../components/MessageCard";
import BasicFilter from "../../../components/BasicFilter";
import DownloadDataCenterButton from "./DownloadDataCenterButton";
import soundFile from "../../../assets/audios/rc/dc-monitor-alert.mp3";
import DownloadDataCenterHistoryButton from "./DownloadDataCenterHistoryButton";
import useRCDataCenter from "../../../hooks/useRCDataCenter";
import { isNumberValue } from "@/utils/Common";
import { useAudioPlayer } from "@/helpers/audioHelper";
import { isEmptyOrNull } from "@/utils/string";
import { filterAllColsClientTable } from "@/utils/array";

const { Title, Text } = Typography;

const StatusWrap = ({ children }: { children: React.ReactNode }) => (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>{children}</div>
);

const StatusHeaderWrap = ({
    label,
    shorten = false,
    warningNumbers = 0,
    isWarningStyle = true,
}: {
    label: string;
    shorten?: boolean;
    warningNumbers?: number;
    isWarningStyle?: boolean;
}) => {
    const final = shorten ? label.slice(0, 3) : label;
    const moreThanThree = label.length > 3;

    return (
        <Tooltip title={label} placement="bottom">
            <Badge style={{ padding: 0 }} count={warningNumbers} {...(isWarningStyle ? { color: "#f0a500" } : {})}>
                <Text
                    style={{
                        color: warningNumbers >= 1 ? (isWarningStyle ? colorMap.warning : colorMap.error) : colorMap.success,
                        display: "block",
                        paddingRight: "0.25rem",
                    }}
                >
                    {final}
                    {shorten && moreThanThree && "."}
                </Text>
            </Badge>
        </Tooltip>
    );
};

const DataCenterMonitorListMarkup = (list: IRCDataCenter | null) => {
    let tmp = list?.data ?? [];
    if (tmp.length > 0) {
        let sortedObj: any = tmp.reduce(
            (finalObj: any, curr: IRCDataCenterData) => {
                if (
                    (isNumberValue(curr.lastUpdateWarning) && curr.lastUpdateWarning > 0) ||
                    (isNumberValue(curr.loginError) && curr.loginError === 2)
                ) {
                    finalObj.err.push(curr);
                } else {
                    finalObj.warn.push(curr);
                }
                return finalObj;
            },
            { err: [], warn: [] }
        );

        return [
            ...sortedObj.err.sort((a: IRCDataCenterData, b: IRCDataCenterData) =>
                (a.loginError << 1) + a.lastUpdateWarning - ((b.loginError << 1) + b.lastUpdateWarning) > 0 ? -1 : 1
            ),
            ...sortedObj.warn,
        ];
    }
    return [];
};

export const DataCenterMonitorListOverviewCounting = (list: IRCDataCenter | null) =>
    DataCenterMonitorListMarkup(list).filter((x: IRCDataCenterData) => x.loginError > 1 || x.freeDiskWarning > 0).length;

const DataCenterMonitorTable = () => {
    const { playSound } = useAudioPlayer(soundFile);
    const { rcDataCenterTypes } = useRCDataCenterTypes();
    const { rcDataCenter, isLoading, isFetching } = useRCDataCenter();
    const [showFullStatusName, setShowFullStatusName] = useState<boolean>(false);
    const [filteredColumns, setFilteredColumns] = useState<string[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>("");

    const statusWidth = useMemo(() => (showFullStatusName ? 100 : 80), [showFullStatusName]);
    const dataDataCenterTypes = useMemo(() => rcDataCenterTypes ?? [], [rcDataCenterTypes]);

    const DCList: IRCDataCenterData[] = useMemo(() => DataCenterMonitorListMarkup(rcDataCenter), [rcDataCenter]);

    const queryResult = useMemo(() => (isEmptyOrNull(searchQuery) ? DCList : filterAllColsClientTable(DCList, searchQuery)), [DCList, searchQuery]);

    const dcStatusSummary = useMemo(
        () =>
            DCList.reduce(
                (acc: any, curr: IRCDataCenterData) => {
                    acc.loginError += curr.loginError > 1 ? 1 : 0;
                    acc.cpuWarning += curr.cpuWarning > 0 ? 1 : 0;
                    acc.freeMemoryWarning += curr.freeMemoryWarning > 0 ? 1 : 0;
                    acc.freeDiskWarning += curr.freeDiskWarning > 0 ? 1 : 0;
                    acc.nicWarning += curr.nicWarning > 0 ? 1 : 0;
                    acc.pingTime += curr.pingTime < 0 ? 1 : 0;

                    return acc;
                },
                { loginError: 0, cpuWarning: 0, freeMemoryWarning: 0, freeDiskWarning: 0, nicWarning: 0, pingTime: 0 }
            ),
        [DCList]
    );

    const getNamesFromValue = useCallback(
        (value: number): string[] =>
            value === 0 ? ["General"] : [...dataDataCenterTypes]?.filter(x => (value & x.type) === x.type).map(item => item.name),
        [dataDataCenterTypes]
    );

    const columns = useMemo(
        () =>
            [
                {
                    title: "Server",
                    dataIndex: "serverId",
                    key: "serverId",
                    sorter: (a: IRCDataCenterData, b: IRCDataCenterData) => a.serverId.localeCompare(b.serverId),
                },
                {
                    title: "Data Center",
                    dataIndex: "dcName",
                    render: (text: string, record: IRCDataCenterData) => {
                        return (
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <Text style={{ lineHeight: 1 }} strong>
                                    {text}
                                </Text>
                                <Text style={{ lineHeight: 0.8 }} type="secondary">
                                    <small>{record.dcUrl}</small>
                                </Text>
                            </div>
                        );
                    },
                    sorter: (a: IRCDataCenterData, b: IRCDataCenterData) => a.dcName.localeCompare(b.dcName),
                },
                {
                    title: "Server Type",
                    dataIndex: "typeFlag",
                    key: "serverType",
                    render: (text: number) => getNamesFromValue(text).map((x: string, idx: number) => <Tag key={idx}>{x}</Tag>),
                    sorter: (a: IRCDataCenterData, b: IRCDataCenterData) => a.typeFlag - b.typeFlag,
                },
                {
                    title: (
                        <StatusHeaderWrap
                            label="Login"
                            shorten={!showFullStatusName}
                            warningNumbers={dcStatusSummary.loginError}
                            isWarningStyle={false}
                        />
                    ),
                    width: statusWidth,
                    dataIndex: "loginError",
                    key: "loginError",
                    sorter: (a: IRCDataCenterData, b: IRCDataCenterData) => a.loginError - b.loginError,
                    render: (text: string, record: IRCDataCenterData) => {
                        return (
                            <StatusWrap>
                                {record.loginError > 1 ? (
                                    <WarningOutlined style={{ color: colorMap.error }} />
                                ) : (
                                    <CheckCircleOutlined style={{ color: colorMap.success, opacity: 0.5 }} />
                                )}
                            </StatusWrap>
                        );
                    },
                },
                {
                    title: <StatusHeaderWrap label="CPU" shorten={!showFullStatusName} warningNumbers={dcStatusSummary.cpuWarning} />,
                    width: statusWidth,
                    dataIndex: "cpuWarning",
                    key: "cpuWarning",
                    sorter: (a: IRCDataCenterData, b: IRCDataCenterData) => a.cpuWarning - b.cpuWarning,
                    render: (text: string, record: IRCDataCenterData) => {
                        return (
                            <StatusWrap>
                                {record.cpuWarning > 0 ? (
                                    <WarningOutlined style={{ color: colorMap.warning }} />
                                ) : (
                                    <CheckCircleOutlined style={{ color: colorMap.success, opacity: 0.5 }} />
                                )}
                            </StatusWrap>
                        );
                    },
                },
                {
                    title: <StatusHeaderWrap label="Memory" shorten={!showFullStatusName} warningNumbers={dcStatusSummary.freeMemoryWarning} />,
                    width: statusWidth,
                    dataIndex: "freeMemoryWarning",
                    key: "freeMemoryWarning",
                    sorter: (a: IRCDataCenterData, b: IRCDataCenterData) => a.freeMemoryWarning - b.freeMemoryWarning,
                    render: (text: string, record: IRCDataCenterData) => {
                        return (
                            <StatusWrap>
                                {record.freeMemoryWarning > 0 ? (
                                    <WarningOutlined style={{ color: colorMap.warning }} />
                                ) : (
                                    <CheckCircleOutlined style={{ color: colorMap.success, opacity: 0.5 }} />
                                )}
                            </StatusWrap>
                        );
                    },
                },
                {
                    title: <StatusHeaderWrap label="Disk" shorten={!showFullStatusName} warningNumbers={dcStatusSummary.freeDiskWarning} />,
                    width: statusWidth,
                    dataIndex: "freeDiskWarning",
                    key: "freeDiskWarning",
                    sorter: (a: IRCDataCenterData, b: IRCDataCenterData) => a.freeDiskWarning - b.freeDiskWarning,
                    render: (text: string, record: IRCDataCenterData) => {
                        return (
                            <StatusWrap>
                                {record.freeDiskWarning > 0 ? (
                                    <WarningOutlined style={{ color: colorMap.warning }} />
                                ) : (
                                    <CheckCircleOutlined style={{ color: colorMap.success, opacity: 0.5 }} />
                                )}
                            </StatusWrap>
                        );
                    },
                },
                {
                    title: <StatusHeaderWrap label="NIC" shorten={!showFullStatusName} warningNumbers={dcStatusSummary.nicWarning} />,
                    width: statusWidth,
                    dataIndex: "nicWarning",
                    key: "nicWarning",
                    sorter: (a: IRCDataCenterData, b: IRCDataCenterData) => a.nicWarning - b.nicWarning,
                    render: (text: string, record: IRCDataCenterData) => {
                        return (
                            <StatusWrap>
                                {record.nicWarning > 0 ? (
                                    <WarningOutlined style={{ color: colorMap.warning }} />
                                ) : (
                                    <CheckCircleOutlined style={{ color: colorMap.success, opacity: 0.5 }} />
                                )}
                            </StatusWrap>
                        );
                    },
                },
                {
                    title: <StatusHeaderWrap label="Ping" shorten={!showFullStatusName} warningNumbers={dcStatusSummary.pingTime} />,
                    width: statusWidth,
                    dataIndex: "pingTime",
                    key: "pingTime",
                    sorter: (a: IRCDataCenterData, b: IRCDataCenterData) => a.pingTime - b.pingTime,
                    render: (text: string, record: IRCDataCenterData) => {
                        return (
                            <StatusWrap>
                                {record.pingTime < 0 ? (
                                    <WarningOutlined style={{ color: colorMap.warning }} />
                                ) : (
                                    <CheckCircleOutlined style={{ color: colorMap.success, opacity: 0.5 }} />
                                )}
                            </StatusWrap>
                        );
                    },
                },
                {
                    title: "Last Updated Date Time",
                    dataIndex: "lastUpdate",
                    key: "lastUpdated",
                    sorter: (a: IRCDataCenterData, b: IRCDataCenterData) => a.lastUpdate.localeCompare(b.lastUpdate),
                },
            ].filter((item: any) => !filteredColumns.includes(item.dataIndex)),
        [getNamesFromValue, dcStatusSummary, statusWidth, filteredColumns, showFullStatusName]
    );

    const mapKeyToName = (passKey: string) => {
        switch (passKey) {
            case "loginError":
                return "Login Error";
            case "cpuWarning":
                return "CPU Warning";
            case "freeMemoryWarning":
                return "Free Memory Warning";
            case "freeDiskWarning":
                return "Free Disk Warning";
            case "nicWarning":
                return "NIC Warning";
            case "pingTime":
                return "Ping Warning";
            default:
                return null;
        }
    };

    useEffect(() => {
        if (!isFetching) {
            if (DCList.length >= 1) {
                playSound();
            }
        }
    }, [isFetching]);

    return (
        <>
            <BasicFilter
                titleBarChildren={
                    <Space>
                        <DownloadDataCenterButton />
                        <DownloadDataCenterHistoryButton />
                    </Space>
                }
            >
                <Row gutter={[8, 8]}>
                    <Col span={8} xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 8 }}>
                        <Space style={{ marginLeft: "auto" }}>
                            <Switch
                                size="small"
                                defaultChecked={!showFullStatusName}
                                onChange={(checked: boolean) => {
                                    setShowFullStatusName(!checked);
                                }}
                            />
                            <Text>Shorten Status Name</Text>
                        </Space>
                    </Col>
                    <Col span={8} xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 8 }}>
                        <Space style={{ marginLeft: "auto" }}>
                            <Switch
                                size="small"
                                defaultChecked={!filteredColumns.includes("typeFlag")}
                                onChange={(checked: boolean) => {
                                    setFilteredColumns(prev => {
                                        if (checked) {
                                            return prev.filter(item => item !== "typeFlag");
                                        }
                                        return [...prev, "typeFlag"];
                                    });
                                }}
                            />
                            <Text>Show Server Type</Text>
                        </Space>
                    </Col>
                    <Col span={8} xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 8 }}>
                        <Space style={{ marginLeft: "auto" }}>
                            <Switch
                                size="small"
                                defaultChecked={!filteredColumns.includes("lastUpdate")}
                                onChange={(checked: boolean) => {
                                    setFilteredColumns(prev => {
                                        if (checked) {
                                            return prev.filter(item => item !== "lastUpdate");
                                        }
                                        return [...prev, "lastUpdate"];
                                    });
                                }}
                            />
                            <Text>Show Last Updated Date Time</Text>
                        </Space>
                    </Col>
                </Row>
            </BasicFilter>
            <div style={{ padding: "0 1rem", marginTop: "1rem" }}>
                <Space direction="vertical">
                    <div style={{ display: "flex", flexWrap: "wrap", gap: "4px", alignItems: "center" }}>
                        <Title level={4}>Data Center Status</Title>
                    </div>
                    {isLoading ? (
                        <MessageCard type="info">Loading...</MessageCard>
                    ) : (
                        <>
                            {DCList.length === 0 && <MessageCard type="success">All good</MessageCard>}

                            <Space wrap>
                                {Object.keys(dcStatusSummary)
                                    .filter((x: string) => dcStatusSummary[x] !== 0)
                                    .map((key: string, idx: number) => (
                                        <MessageCard type={key === "loginError" ? "error" : "warning"} key={`ss-${key}-${idx}`}>
                                            {`${dcStatusSummary[key]} ${mapKeyToName(key)}`}
                                        </MessageCard>
                                    ))}
                            </Space>

                            <Input placeholder="Search Server, Data Center" onChange={e => setSearchQuery(e.target.value)} />

                            <Table
                                bordered
                                columns={columns}
                                dataSource={queryResult}
                                rowKey={(record: IRCDataCenterData) => record.serverId + record.dcName}
                                size="small"
                                pagination={false}
                                sticky
                            />
                        </>
                    )}
                </Space>
            </div>
        </>
    );
};

export default DataCenterMonitorTable;
