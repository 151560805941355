import { Col, Input, Row, Space, Switch, Typography, notification } from "antd";
import React, { useState } from "react";
import { matchSorter } from "match-sorter";
import { useRCIssuesLogMutate, useRCIssuesLogNoMoneySettings } from "@/hooks/useRCIssuesLog";
import MessageCard from "@/pages/SystemMonitor/components/MessageCard";
import { colorMap } from "@/pages/SystemMonitor/StatusMonitor/statusUtils";
import LoadingComponent from "@/components/Loading";

export interface NoMoneySettingsProps {
    enableUpdate: boolean;
}

const NoMoneySettings = (props: NoMoneySettingsProps) => {
    const { Title, Text } = Typography;
    const { rcIssuesLogNoMoneySettings, isLoading } = useRCIssuesLogNoMoneySettings();
    const { updateIssueLogNoMoneySettings } = useRCIssuesLogMutate({ type: "NO_MONEY" });

    const totalServers = rcIssuesLogNoMoneySettings.length;
    const totalHighload =
        rcIssuesLogNoMoneySettings && rcIssuesLogNoMoneySettings?.length >= 1
            ? rcIssuesLogNoMoneySettings?.filter((item: any) => item.highLoad).length
            : 0;

    const [searchQuery, setSearchQuery] = useState("");
    // console.log(rcIssuesLogNoMoneySettings);
    const result =
        rcIssuesLogNoMoneySettings?.length >= 1
            ? matchSorter(rcIssuesLogNoMoneySettings ?? [], searchQuery ?? "", {
                keys: ["server"],
            })
            : [];
    const [forceRefreshKey, setForceRefreshKey] = useState(0);

    const handleUpdateSettings = async ({ server, checked }: { server: string; checked: boolean }) => {
        try {
            const resp = await updateIssueLogNoMoneySettings({ server, checked });
            if (resp) {
                notification.success({
                    message: "Updated Settings",
                    description: `${server} settings are updated.`,
                });
            }
        } catch (error) {
            console.log("Error in updating settings", error);
            notification.error({
                message: "Update Failed",
                description: `${server} settings update failed. Error: ${error}`,
            });
            setForceRefreshKey(prev => prev + 1);
        }
    };
    return (
        <div style={{ padding: "1rem" }}>
            <Title level={5}>High-load Server</Title>
            <Space direction="vertical" style={{ width: "100%" }}>
                <MessageCard type="info">
                    {totalServers} servers, {totalHighload} high-load
                </MessageCard>
                <Input
                    placeholder="Search Server"
                    onChange={e => {
                        setSearchQuery(e.target.value);
                    }}
                />
                {isLoading ? (
                    <div className="loading-container">
                        <LoadingComponent tip="Loading..." />
                    </div>
                ) : (
                    <Row gutter={[8, 0]} key={forceRefreshKey} style={{ minWidth: 350 }}>
                        {result?.map((item, i) => {
                            return (
                                <Col xs={{ span: 12 }} md={{ span: 6 }} lg={{ span: 4 }} key={item?.server}>
                                    <Space>
                                        <Switch
                                            defaultChecked={item?.highLoad}
                                            size="small"
                                            disabled={!props.enableUpdate}
                                            onChange={(checked, event) => {
                                                handleUpdateSettings({
                                                    server: item.server,
                                                    checked,
                                                });
                                            }}
                                        />
                                        <Text style={{ color: item?.highLoad ? colorMap.success : "inherit" }}>{item.server}</Text>
                                    </Space>
                                </Col>
                            );
                        })}
                    </Row>
                )}
            </Space>
        </div>
    );
};

export default NoMoneySettings;
