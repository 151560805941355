import { FormComponent } from "@/components/FormComponent";
import LoadingComponent from "@/components/Loading";
import { SUCCESS_FAILED, ComponentType, InnerPageActionMode } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { KeyValuePair, User, RoleType } from "@/constants/type";
import { apiRequest } from "@/services/apiConfig";
import { APIs } from "@/services/apis";
import { SortList } from "@/utils/array";
import { ErrorMessageHandler, ErrorCatchValidator } from "@/utils/Common";
import { Form, message, Row, Col } from "antd";
import { cloneDeep } from "lodash";
import { useState, useMemo, useEffect } from "react";
import { UserCreateEditPageProps } from "./UserCreatePage";
import SitePageHeaderInline, { SitePageHeaderInlineActionType } from "@/components/PageHeader/inlineIndex";
import { defaultIfEmptyOrNull } from "@/utils/string";

interface SelectioOptsProps {
    roles: KeyValuePair[];
    teams: KeyValuePair[];
}

const UserEditPage = (props: UserCreateEditPageProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [roleList, setRoleList] = useState<RoleType[]>([]);
    const [teamList, setTeamList] = useState<string[]>([]);
    const [isFilterLoaded, setIsFilterLoaded] = useState<boolean>(false);

    const [userForm] = Form.useForm();

    const stateInfo = useMemo(() => {
        return {
            isAddAction: props.mode === InnerPageActionMode.CREATE_NEW,
            data: defaultIfEmptyOrNull(props.id, {}),
        };
    }, [props.mode, props.id]);

    const processBackAndRefetch = () => setTimeout(() => props.callback && props.callback(InnerPageActionMode.BACK, { refreshMainList: true }), 400);

    const selectionOpts: SelectioOptsProps = useMemo(() => {
        return {
            roles: SortList(
                roleList.map(x => ({ text: x.role, value: x.id })),
                "text"
            ),
            teams: teamList.map(x => ({ text: x, value: x })),
        };
    }, [roleList, teamList]);

    const dataMarkup = (data: User) => {
        let tmp: any = cloneDeep(data);
        tmp["roleIds"] = (tmp["roleIds"] as string).split(",").map((x: string) => parseInt(x));
        tmp["userId"] = tmp["id"];
        return tmp;
    };

    const onSubmit = (values: any) => {
        try {
            setIsLoading(true);
            values["roleIds"] = (values["roleIds"] as number[]).join(",");

            apiRequest(APIs.UPDATE_USER_ACCOUNT, { ...values })
                .then(() => {
                    ErrorMessageHandler("User data", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                    processBackAndRefetch();
                })
                .catch(error => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("user detail", SUCCESS_FAILED.FAILED_UPDATE_DATA, err)))
                .finally(() => setIsLoading(false));
        } catch (err: any) {
            ErrorMessageHandler(`Error: ${err.toString()}`, SUCCESS_FAILED.OTHERS_FAILED);
        }
    };

    const getUserRoles = () => {
        apiRequest(APIs.GET_USER_ROLE_LIST, { userId: stateInfo.data.id })
            .then((response: any) => {
                let roles = response as RoleType[];
                setRoleList(roles);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => console.log(`Error: ${err}`));
            });
    };

    const getUserTeams = () => {
        apiRequest(APIs.GET_SETTING_LIST, { keys: ["UserTeams"] })
            .then((res: any) => {
                const userTeamsValue = JSON.parse(res.find((item: { key: string }) => item.key === "UserTeams")?.value || "null");
                setTeamList(userTeamsValue);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => console.log("Failed to get latest user teams: ", err));
            })
            .finally(() => setIsFilterLoaded(true));
    };

    useEffect(() => {
        getUserRoles();
        getUserTeams();
        return () => { };
    }, []);

    return (
        <SitePageHeaderInline
            enableSubmit
            title={"Edit User Account"}
            callback={(action: SitePageHeaderInlineActionType) => {
                switch (action) {
                    case SitePageHeaderInlineActionType.OnBack:
                    case SitePageHeaderInlineActionType.OnCancel:
                        props.callback && props.callback(InnerPageActionMode.BACK);
                        return;
                    case SitePageHeaderInlineActionType.OnSubmit:
                        userForm
                            .validateFields()
                            .then(res => onSubmit(res))
                            .catch(err => console.log("form-error:", err));
                        return;
                    default:
                        break;
                }
            }}
            isSubmitting={isLoading}
        >
            <LoadingComponent tip="Loading..." spinning={!isFilterLoaded}>
                {isLoading ? (
                    <div className="loading-container">
                        <LoadingComponent tip="Submitting..." />
                    </div>
                ) : (
                    <Form
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        form={userForm}
                        layout="horizontal"
                        initialValues={dataMarkup(stateInfo.data)}
                        onFinish={onSubmit}
                    >
                        <Row>
                            <Col span={15}>
                                <FormComponent label={""} name={"userId"} extra={{ type: ComponentType.hidden, value: "" }} />
                                <FormComponent
                                    label="Username"
                                    name="username"
                                    extra={{
                                        type: ComponentType.labelOnly,
                                        value: "",
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={15}>
                                <FormComponent
                                    label="First Name"
                                    name="firstName"
                                    extra={{
                                        type: ComponentType.labelOnly,
                                        value: "",
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={15}>
                                <FormComponent
                                    label="Last Name"
                                    name="lastName"
                                    extra={{
                                        type: ComponentType.labelOnly,
                                        value: "",
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={15}>
                                <FormComponent
                                    label="Email"
                                    name="email"
                                    extra={{
                                        type: ComponentType.labelOnly,
                                        value: "",
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={15}>
                                <FormComponent
                                    label="User Role"
                                    name={"roleIds"}
                                    extra={{
                                        type: ComponentType.dropdown,
                                        inputProps: {
                                            style: { width: "100%" },
                                            mode: "multiple",
                                            allowClear: true,
                                        },
                                        value: selectionOpts.roles,
                                        rules: [{ required: true, message: REQUIRED_FIELD }],
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={15}>
                                <FormComponent
                                    label="Team"
                                    name={"team"}
                                    extra={{
                                        type: ComponentType.dropdown,
                                        inputProps: {
                                            style: { width: "100%" },
                                            allowClear: true,
                                        },
                                        value: selectionOpts.teams,
                                    }}
                                />
                            </Col>
                        </Row>
                    </Form>
                )}
            </LoadingComponent>
        </SitePageHeaderInline>
    );
};

export default UserEditPage;
