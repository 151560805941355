import { useAppDispatch } from "@/store/hook";
import { setCurrentKey } from "@/store/reducers/centralizedsettings";
import { HomeOutlined } from "@ant-design/icons";
import { Breadcrumb, Button } from "antd";
import { Route } from "antd/lib/breadcrumb/Breadcrumb";
import React from "react";
import { useNavigate } from "react-router";

export interface PanelContainerProps {
    children?: React.ReactNode;
    title: string | React.ReactNode;
    breadcrumbTitle?: string;
};

const PanelContainer = (props: PanelContainerProps) => {
    let navigate = useNavigate();
    const dispatch = useAppDispatch();

    return (
        <div className="centralized-settings-panel-container">
            <div>
                <Breadcrumb
                    routes={[
                        { path: "/settingspanel", breadcrumbName: "System Settings Panel" },
                        { path: "", breadcrumbName: props.breadcrumbTitle || props.title as string },
                    ]}
                    itemRender={(route: Route) => {
                        return route.path && route.path.length > 0 ? (
                            <Button type="link" onClick={() => {
                                dispatch(setCurrentKey(undefined));
                                navigate("/settingspanel");
                            }}>
                                <HomeOutlined /> {route.breadcrumbName}
                            </Button>
                        ) : (
                            <div className="name-container">{route.breadcrumbName}</div>
                        );
                    }}
                ></Breadcrumb>
            </div>
            <div className="setting-main-container">
                {
                    typeof props.title !== "string" ? (
                        <>{props.title}</>
                    ) : (
                        <>
                            <div className="settings-panel-main-title-container">
                                <div className="title">
                                    <span>{props.title}</span>
                                </div>
                            </div>
                        </>
                    )
                }
                {props.children}
            </div>
        </div>
    );
};

export default PanelContainer;