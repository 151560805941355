import PanelContainer from "../../PanelContainer";
import ServerPerformanceSettingsTable from "./ServerPerformanceSettingsTable";

const TabServerPerformanceSettings = () => {
    return (
        <PanelContainer title="Server Performance Settings">
            <ServerPerformanceSettingsTable />
        </PanelContainer>
    );
};

export default TabServerPerformanceSettings;
