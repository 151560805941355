import React, { useEffect } from "react";
import { useAudioPlayer } from "@/helpers/audioHelper";
import BasicFilter from "@/components/BasicFilter";
import useRCServerPerformance from "@/hooks/useRCServerPerformance";
import useRCServerPerformanceStore from "@/store/useRCServerPerformanceStore";
import { Typography, Row, Col } from "antd";
import TabContent from "../components/TabContent";
import DownloadServerPerformanceButton from "./DownloadServerPerformanceButton";
import IgnoredServersButton from "./IgnoredServersButton";
import ServerPerformanceChart from "./ServerPerformanceChart";
import soundFile from "../../../assets/audios/rc/performance-alert.mp3";
import ServerPerformanceTable from "./ServerPerformanceTable";
import useRCServers from "@/hooks/useRCServers";

const { Text } = Typography;

const SPChart = () => {
    const { rcServers, isLoading } = useRCServers();

    return (
        <div
            style={{
                background: "white",
                position: "relative",
                padding: "1rem",
                borderRadius: "0.5rem",
                // boxShadow: `0px 1.6px 3.6px rgba(0, 0, 0, 0.024), 0px 4.4px 10px rgba(0, 0, 0, 0.035), 0px 10.6px 24.1px rgba(0, 0, 0, 0.046), 0px 35px 80px rgba(0, 0, 0, 0.07)`,
                boxShadow: `
                0px 0.2px 0.7px rgba(0, 0, 0, 0.035),
                0px 0.6px 1.9px rgba(0, 0, 0, 0.05),
                0px 1.5px 4.5px rgba(0, 0, 0, 0.065),
                0px 5px 15px rgba(0, 0, 0, 0.1)
                `,
                marginTop: "2rem",
                minHeight: "85vh",
            }}
        >
            <ServerPerformanceChart isServersLoading={isLoading} servers={rcServers === null ? [] : rcServers} />
        </div>
    );
};

const TabServerPerformance = () => {
    const { playSound } = useAudioPlayer(soundFile);
    const { rcServerPerformance, isFetching } = useRCServerPerformance();
    const ignoreServers = useRCServerPerformanceStore(state => state.ignoredServers);
    const { rcServers, isLoading } = useRCServers();

    useEffect(() => {
        if (!isFetching) {
            const count = (rcServerPerformance?.data?.filter(item => !ignoreServers.includes(item.serverName)) ?? []).length;
            if (count > 0) {
                playSound();
            }
        }
    }, [isFetching]);

    return (
        <div style={{ marginBottom: "3.5rem" }}>
            <TabContent>
                <BasicFilter
                    titleBarChildren={
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                flexWrap: "wrap",
                                gap: "4px",
                                alignItems: "center",
                            }}
                        >
                            <DownloadServerPerformanceButton />
                        </div>
                    }
                >
                    <Row gutter={[8, 8]}>
                        <Col span={8} xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 8 }}>
                            <Text>Ignored Servers</Text>
                            <IgnoredServersButton />
                        </Col>
                    </Row>
                </BasicFilter>
                <ServerPerformanceTable isServersLoading={isLoading} servers={rcServers === null ? [] : rcServers} />
            </TabContent>
            <SPChart />
        </div>
    );
};

export default TabServerPerformance;
