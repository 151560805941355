import { CheckOutlined, CloseOutlined, ExclamationCircleOutlined, EyeOutlined, MessageOutlined } from "@ant-design/icons";
import { Button, Form, List, Modal, Typography } from "antd";
import { useState, useEffect, useRef } from "react";
import CardBox from "../../../components/Common/CardBox";
import FlexiDataTable from "../../../components/FlexiDataTable";
import LoadingComponent from "../../../components/Loading";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../../constants";
import {
    CustomPaginationProps,
    SummaryHistoricalEvent,
    FlexiDataTableCallbackProps,
    EventRuleList,
    BrandsList,
    ServersList,
} from "../../../constants/type";
import { apiRequest } from "../../../services/apiConfig";
import { APIs } from "../../../services/apis";
import {
    currencyRender,
    DataTableColumnRender,
    DTColProps,
    ErrorCatchValidator,
    ErrorMessageHandler,
    getBrandNameByBrandId,
    getServerNameByServerId,
} from "../../../utils/Common";
import { FormComponent } from "../../../components/FormComponent";
import AccountLevelView from "./accountLevelView";
import { findObjectKeys, getAvailableObjectElementKeys, objectRemoveProps } from "../../../utils/object";
import moment from "moment";
import { DateTimeUtil } from "../../../utils/datetime";
import EmptyData from "../../../components/Common/Empty";

export type ModalProps = {
    visible: boolean;
    isShowComment: boolean;
    isShowCommentList: boolean;
};

const SummaryHistoricalEventPage = () => {
    const getColumnConfig = (record: any, eventRuleList: any[]) => {
        return [
            {
                title: "Event Rule ID",
                dataIndex: "eventRuleId",
                key: "eventRuleId",
                options: {
                    visible: false,
                    filter: {
                        type: ComponentType.dropdown,
                        text: "Event Rule",
                        value: eventRuleList,
                    },
                },
            },
            DTColProps.Small({
                title: "Account ID",
                dataIndex: "AccountId",
                key: "AccountId",
                // render: (accId: string, record: any) => {
                //     return (
                //         <Link
                //             to={getNavigationUrl(
                //                 ACCOUNT_PROFILE_FROM_MODULE.HISTORICAL_EVENT_SUMMARY_LIST,
                //                 getAccountProfileParams(
                //                     record.ServerId,
                //                     record.AccountId,
                //                     ACCOUNT_PROFILE_FROM_MODULE.HISTORICAL_EVENT_SUMMARY_LIST,
                //                     "", "", "", "", ""
                //                 )
                //             )}
                //             target="_blank"
                //             style={{ color: "#0e65cc" }}
                //         >
                //             {accId}
                //         </Link>
                //     );
                // },
                options: {
                    filter: {
                        type: ComponentType.text,
                        value: "",
                    },
                },
            }),

            DTColProps.Small({
                title: "Brand",
                dataIndex: "BrandId",
                key: "BrandId",
                render: (brandId: number) => {
                    return getBrandNameByBrandId(brandId, brands);
                },
            }),

            DTColProps.Small({
                title: "Server",
                dataIndex: "ServerId",
                key: "ServerId",
                render: (ServerId: number) => {
                    return getServerNameByServerId(ServerId, servers);
                },
            }),

            DTColProps.DateTime_ServerDateOnly({
                title: "Event Date",
                realTitle: "Event Date",
                dataIndex: "EventDate",
                key: "EventDate",
                options: {
                    filter: {
                        type: ComponentType.daterange,
                        value: "",
                        text: "Event Date",
                    },
                },
                //sortDirections: ["ascend", "descend"],
                sorter: true,
            }),

            DTColProps.Small(
                {
                    title: "Event Count",
                    dataIndex: "EventCount",
                    key: "EventCount",
                    sorter: (a: any, b: any) => a["EventCount"] - b["EventCount"],
                },
                ["text-right"]
            ),
            ...Object.keys(
                objectRemoveProps(record, [
                    "eventRuleId",
                    "AccountId",
                    "BrandId",
                    "ServerId",
                    "EventDate",
                    "EventCount",
                    "LastComment",
                    "LastCommentBy",
                    "LastCommentDateUtc",
                ])
            ).map((x) => {
                let tmp: any = {
                    title: x,
                    dataIndex: x,
                    key: x,
                    ...(typeof record[x] === "number"
                        ? {
                            render: (a: any) => currencyRender(a),
                            sorter: (a: any, b: any) => a[x] - b[x],
                        }
                        : {}),
                };

                return typeof record[x] === "number" ? DTColProps.Currency(tmp) : DTColProps.Middle(tmp);
            }),
            DTColProps.XLarge({
                title: "Last Comment",
                dataIndex: "LastComment",
                key: "LastComment",
                render: (comment: any, record: any) => {
                    return comment && comment.length > 0 ? (
                        <div className="mini-comment-container">
                            <div className="comments">
                                <Typography.Paragraph ellipsis={{ rows: 2 }} style={{ marginBottom: "0" }}>
                                    {comment}
                                </Typography.Paragraph>
                            </div>
                            <div className="info">{`${DataTableColumnRender.DateTime(record["LastCommentDateUtc"])} - ${record["LastCommentBy"]
                                }`}</div>
                        </div>
                    ) : (
                        "-"
                    );
                },
            }),
        ];
    };

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isCommentLoading, setIsCommentLoading] = useState<boolean>(true);
    const [hasCommentValue, setHasCommentValue] = useState<boolean>(false);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [isShowCommentModal, setIsShowCommentModal] = useState<boolean>(false);
    const [isAccountView, setIsAccountView] = useState<boolean>(false);
    const [eventRules, setEventRules] = useState<any[]>([]);
    const [brands, setBrands] = useState<BrandsList[]>([]);
    const [servers, setServers] = useState<ServersList[]>([]);
    const [data, setData] = useState<SummaryHistoricalEvent[]>([]);
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [filterParams, setFilterParams] = useState<any>({});
    const [sorting, setSorting] = useState<string>("");
    const [commentForm] = Form.useForm();

    const [selectedEventRuleId, setSelectedEventRuleId] = useState<number>(0);
    const [selectedEventDetails, setSelectedEventDetails] = useState<any>({});
    const [commentList, setCommentList] = useState<any[]>([]);
    const [dynamicCols, setDynamicCols] = useState<any[]>(getColumnConfig({}, []));
    const commentInputRef = useRef(null);

    const createEventAccountComment = (values: any) => {
        setIsCommentLoading(true);
        apiRequest(APIs.CREATE_MONITOR_EVENT_ACCOUNT_COMMENT, {
            ...selectedEventDetails,
            comment: values,
        })
            .then(() => {
                ErrorMessageHandler("Comment", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                getCommentList(selectedEventDetails);
                commentForm.setFieldsValue({ comment: "" });
            })
            .catch((error) => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("comment", SUCCESS_FAILED.FAILED_CREATE_DATA, err)))
            .finally(() => setIsCommentLoading(false));
    };

    const updateEventAccountComment = (values: any) => {
        setIsCommentLoading(true);
        apiRequest(APIs.UPDATE_MONITOR_EVENT_ACCOUNT_COMMENT, {
            accountCommentId: values.id,
            comment: values.comment,
        })
            .then(() => {
                ErrorMessageHandler("Comment", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                getCommentList(selectedEventDetails);
                commentForm.setFieldsValue({ comment: "", id: "" });
            })
            .catch((error) => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("comment", SUCCESS_FAILED.FAILED_UPDATE_DATA, err)))
            .finally(() => setIsCommentLoading(false));
    };

    const editComment = (values: any) => {
        setIsShowCommentModal(true);
        commentInputRef.current && (commentInputRef.current as any).focus();
        setHasCommentValue(true);
        commentForm.setFieldsValue({ comment: values.comment, id: values.id });
    };

    const deleteComment = (values: any) => {
        commentForm.setFieldsValue({ comment: "", id: "" });
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            title: "Are you sure you want to delete this comment?",
            width: "50%",
            content: (
                <div className="delete-modal-comment">
                    <div className="delete-modal-comment-box">
                        <div className="delete-modal-comment-title">{values?.comment}</div>
                        <div className="delete-modal-comment-description">
                            {DataTableColumnRender.DateTime(values?.lastUpdatedDateUtc)} - {values?.user}
                        </div>
                    </div>
                </div>
            ),
            onOk() {
                apiRequest(APIs.DELETE_MONITOR_EVENT_ACCOUNT_COMMENT, {
                    accountCommentId: values.id,
                })
                    .then(() => {
                        ErrorMessageHandler("Comment", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                        getCommentList(selectedEventDetails);
                    })
                    .catch((error) =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("comment", SUCCESS_FAILED.FAILED_DELETE_DATA, err))
                    );
            },
            onCancel() { },
        });
    };

    const onFinishComment = (values: any) => {
        if (values.id) {
            updateEventAccountComment(values);
        } else {
            createEventAccountComment(values["comment"]);
        }
        setHasCommentValue(false);
    };

    const onValuesChange = (values: any) => {
        if (values["comment"] && values["comment"].length > 0) {
            !hasCommentValue && setHasCommentValue(true);
        } else {
            hasCommentValue && setHasCommentValue(false);
        }
    };

    const getCommentList = (params: any) => {
        apiRequest(APIs.GET_MONITOR_EVENT_ACCOUNT_COMMENTS, params)
            .then((data: any) => {
                setCommentList(data);
                setIsCommentLoading(false);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("account comment", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            });
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                setSorting(
                    FormData.sorter.column && FormData.sorter.order
                        ? `${FormData.sorter.column.key},${FormData.sorter.order === "ascend" ? "asc" : "desc"}`
                        : ""
                );
                setPagination((prev) => ({ ...prev, current: FormData.pagination.current, pageSize: FormData.pagination.pageSize }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let filterParams: any = getAvailableObjectElementKeys(FormData).reduce((filterParams: any, x: any) => {
                    if (x === "EventDate") {
                        filterParams["capturedDateFrom"] = DateTimeUtil.GetOrigin(FormData[x][0]).slice(0, 10);
                        filterParams["capturedDateTo"] = DateTimeUtil.GetOrigin(FormData[x][1]).slice(0, 10);
                    } else {
                        filterParams[x] = FormData[x];
                    }
                    return filterParams;
                }, {});

                setFilterParams(filterParams);
                if (findObjectKeys(filterParams, ["eventRuleId"])) {
                    setSelectedEventRuleId(filterParams["eventRuleId"]);
                    setPagination((prev) => ({ ...prev, current: 1 }));
                    setRunRefetchDataList(true);
                } else {
                    setSelectedEventRuleId(0);
                    setRunRefetchDataList(true);
                }

                break;
            case CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK:
                let commentParams = {
                    eventRuleId: FormData.data.eventRuleId,
                    eventDate: FormData.data.EventDate,
                    accountId: FormData.data.AccountId,
                    brandId: FormData.data.BrandId,
                    serverId: FormData.data.ServerId,
                };
                setSelectedEventDetails(commentParams);
                if (FormData.key === "comment") {
                    commentForm.setFieldsValue({ comment: "", id: "" });
                    getCommentList(commentParams);
                    setIsShowCommentModal(true);
                } else if (FormData.key === "view") {
                    setIsAccountView(true);
                }
                break;
            case CALLBACK_KEY.BACK_TO_PREVIOUS:
                setIsAccountView(false);
                break;
        }
    };

    const getHistoricalEventList = () => {
        setIsLoading(true);
        if (findObjectKeys(filterParams, ["eventRuleId"])) {
            apiRequest(APIs.GET_MONITOR_HISTORICAL_SUMMARY, {
                limit: pagination.pageSize,
                current: pagination.current,
                ...(Object.keys(filterParams).length > 0 && { ...filterParams }),
                ...(sorting.length > 0 && { order: sorting }),
            })
                .then((data: any) => {
                    if (data && data.Result && data.Result.length > 0) {
                        let markupData = data.Result.map((x: SummaryHistoricalEvent) => {
                            x.id = `${x.AccountId}-${x.ServerId}-${x.EventDate}-${filterParams["eventRuleId"] || Math.random()}`;
                            x.eventRuleId = filterParams["eventRuleId"] || 0;
                            return x;
                        });

                        setDynamicCols(getColumnConfig(markupData[0], eventRules));
                        setData(markupData);
                        setPagination((prev) => ({ ...prev, total: data.Total }));
                    } else {
                        setData([]);
                        setPagination((prev) => ({ ...prev, total: 0 }));
                    }
                })
                .catch((error: any) => {
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("historical event summary", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            setDynamicCols(getColumnConfig({}, eventRules));
            setData([]);
            setPagination((prev) => ({ ...prev, total: 0 }));
            setIsLoading(false);
        }
    };

    useEffect(() => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["monitoreventrule", "server", "brand"] })
            .then((data: any) => {
                setEventRules(
                    data.eventRules
                        .filter((x: any) => !x.isDeleted)
                        .map((z: EventRuleList[]) => z)
                        .map((x: any) => ({ text: x.name, value: x.id }))
                );
                setServers(data.servers);
                setBrands(data.brands);
                setRunRefetchDataList(true);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("historical event summary", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            });

        // setSelectedEventDetails({ eventRuleId: 14, eventDate: "2023-05-26T00:00:00", accountId: 991, brandId: 1, serverId: 25 });
        // getCommentList({ eventRuleId: 14, eventDate: "2023-05-26T00:00:00", accountId: 991, brandId: 1, serverId: 25 });
        // commentForm.setFieldsValue({ comment: "", id: "" });
        // setIsShowCommentModal(true);
        // setIsCommentLoading(false);
        return () => { };
    }, []);

    useEffect(() => {
        if (runRefetchDataList) {
            getHistoricalEventList();
            setRunRefetchDataList(false);
        }
        return () => { };
    }, [runRefetchDataList]);

    return (
        <>
            <CardBox title={"Historical Event Summary"}>
                <div className={isAccountView ? "hide" : ""}>
                    {!selectedEventRuleId && (
                        <div style={{ paddingLeft: "0.9765vw", marginBottom: "1.5vh" }}>
                            <span style={{ color: "#f00f00", fontWeight: "bold" }}>** Please choose an Event Rule</span>
                        </div>
                    )}
                    <FlexiDataTable
                        rowKeyProperty="id"
                        title=""
                        columns={dynamicCols}
                        options={{
                            serverFiltering: true,
                            enableFilter: true,
                            separateActionButton: true,
                            rowExtra: () => {
                                return [
                                    {
                                        text: "",
                                        icon: <EyeOutlined />,
                                        value: "view",
                                    },
                                    { text: "", icon: <MessageOutlined />, value: "comment" },
                                ];
                            },
                        }}
                        dataSource={data}
                        callback={componentCallback}
                        loading={isLoading}
                        pagination={pagination}
                        serverSide={true}
                        filterInitialValue={{ EventDate: [moment(), moment()] }}
                    />
                </div>
                {selectedEventDetails && isAccountView && (
                    <AccountLevelView data={selectedEventDetails} serverList={servers} brandList={brands} callback={componentCallback} />
                )}
            </CardBox>

            <Modal
                width={"30%"}
                style={{ top: 10 }}
                bodyStyle={{ padding: "0.3255vw 0.707vh" }}
                destroyOnClose
                maskClosable={false}
                footer={null}
                title={"Comments"}
                open={isShowCommentModal}
                onCancel={() => {
                    setIsShowCommentModal(false);
                    setRunRefetchDataList(true);
                }}
            >
                {isCommentLoading ? (
                    <div className="loading-container">
                        <LoadingComponent tip="Loading..." />
                    </div>
                ) : (
                    <>
                        <div className="comment-container">
                            {commentList.length > 0 ? (
                                <div className={`summary-historical-event-comments-history nice-scrollbar`}>
                                    <List className="comment-list">
                                        {commentList.map((currData: any) => {
                                            let isLineThrough = currData.isDeleted;
                                            return (
                                                <List.Item key={currData?.id}>
                                                    <div className="list-box">
                                                        <div className="list-comment">
                                                            <div className={`list-title ${isLineThrough ? "text-line-throught" : ""}`}>
                                                                {currData?.comment}
                                                            </div>
                                                            <div className={`list-description`}>
                                                                <span className={`${isLineThrough ? "text-line-throught" : ""}`}>
                                                                    {DataTableColumnRender.DateTime(currData?.lastUpdatedDateUtc)} - {currData?.user}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="icon-box">
                                                            {currData.canModify && !currData.isDeleted && (
                                                                <span className="edit-button" onClick={() => editComment(currData)}>
                                                                    Edit
                                                                </span>
                                                            )}
                                                            {currData.canModify && !currData.isDeleted && (
                                                                <span className="delete-button" onClick={() => deleteComment(currData)}>
                                                                    Delete
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </List.Item>
                                            );
                                        })}
                                    </List>
                                </div>
                            ) : (
                                <EmptyData />
                            )}

                            <Form form={commentForm} layout="horizontal" onValuesChange={onValuesChange} onFinish={onFinishComment}>
                                <FormComponent
                                    label=""
                                    name={"id"}
                                    extra={{
                                        type: ComponentType.hidden,
                                        value: "",
                                    }}
                                />
                                <FormComponent
                                    label=""
                                    name={"comment"}
                                    extra={{
                                        type: ComponentType.textarea,
                                        value: "",
                                        inputProps: {
                                            placeholder: "Fill in your comment",
                                            ref: commentInputRef,
                                        },
                                        itemProps: {
                                            style: {
                                                marginBottom: "0",
                                            },
                                        },
                                    }}
                                />
                                {hasCommentValue && (
                                    <div className="footer-button">
                                        <Button
                                            icon={<CloseOutlined />}
                                            type="primary"
                                            danger
                                            onClick={() => {
                                                commentForm.setFieldsValue({ id: "", comment: "" });
                                                setHasCommentValue(false);
                                            }}
                                        ></Button>
                                        <Button icon={<CheckOutlined />} type="primary" className="success" htmlType="submit"></Button>
                                    </div>
                                )}
                            </Form>
                        </div>
                    </>
                )}
            </Modal>
        </>
    );
};

export default SummaryHistoricalEventPage;
