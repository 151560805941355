import { useMemo, useState } from "react";
import CardBox from "@/components/Common/CardBox";
import FlexiDataTable from "@/components/FlexiDataTable";
import { FormComponent } from "@/components/FormComponent";
import { CALLBACK_KEY, ComponentType } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { CarryOutOutlined, UploadOutlined } from "@ant-design/icons";
import { Form, Row, Col, Upload, Button, Divider, UploadFile, UploadProps } from "antd";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps } from "@/constants/type";
import { DTColProps } from "@/utils/Common";

const SymbolTradingHourUpdate = () => {
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.MANAGEMENT_TOOL_SYMBOL_TRADING_HOUR_UPDATE_EDIT);

    const [data, setData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [file, setFile] = useState<File | null>(null);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [previewForm] = Form.useForm();

    const columns: any[] = useMemo(() => [
        DTColProps.Small({
            title: "Symbol",
            dataIndex: "symbol",
            key: "symbol",
            fixed: "left",
            sorter: (a: any, b: any) => a.symbol.localeCompare(b.symbol),
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Small({
            title: "Day",
            dataIndex: "day",
            key: "day",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: [
                        { text: "Monday", value: "Monday" },
                        { text: "Tuesday", value: "Tuesday" },
                        { text: "Wednesday", value: "Wednesday" },
                        { text: "Thursday", value: "Thursday" },
                        { text: "Friday", value: "Friday" },
                        { text: "Saturday", value: "Saturday" },
                        { text: "Sunday", value: "Sunday" },
                    ],
                    inputProps: {
                        mode: "multiple",
                    },
                },
            },
        }),
        DTColProps.Middle({
            title: "Quote Times",
            dataIndex: "quoteTimes",
            key: "quoteTimes",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Middle({
            title: "Trade Times",
            dataIndex: "tradeTimes",
            key: "tradeTimes",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Small({
            title: "Message",
            dataIndex: "message",
            key: "message",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
    ], []);

    const options: FlexiDataTableOptionsProps = useMemo(() => {
        return {
            defaultCollapseFilterPanel: true,
            ...(enableUpdate && {
                extraButtons: () => {
                    return (
                        <div className="extra-header-buttons" key={"mtua-extra-buttons"}>
                            <Button
                                key={"mtua-apply-all"}
                                icon={<CarryOutOutlined />}
                                disabled={data.length === 0 || isLoading}
                                onClick={() => componentCallback(CALLBACK_KEY.OTHERS, "apply-all")}
                            >
                                Apply All
                            </Button>
                        </div>
                    );
                },
            }),
        }
    }, [enableUpdate, data, isLoading]);

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.OTHERS:
                switch (FormData) {
                    case "apply-all":
                        console.log("Apply All clicked: ", FormData);
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        };
    };

    const onFormSubmit = (values: any) => {
        console.log("onFormSubmit: ", values);
    };

    const uploadProps: UploadProps = {
        onRemove: () => {
            setFile(null);
            setFileList([]);
        },
        beforeUpload: file => {
            setFile(file);
            setFileList([
                {
                    uid: "-1",
                    name: file.name,
                    status: "done",
                    url: URL.createObjectURL(file),
                },
            ]);
            return false;
        },
        fileList,
        maxCount: 1,
    };

    return (
        <div className="symbol-trading-hour-update-container">
            <CardBox title="Symbol Trading Hour Update">
                <div className="preview-form-div">
                    <Form
                        form={previewForm}
                        layout="horizontal"
                        initialValues={{}}
                        onFinish={onFormSubmit}
                    >
                        <Row gutter={[12, 12]}>
                            <Col span={6}>
                                <FormComponent
                                    label="Server"
                                    name="server"
                                    extra={{
                                        type: ComponentType.dropdown,
                                        value: [],
                                        rules: [{ required: true, message: REQUIRED_FIELD }],
                                    }}
                                />
                            </Col>
                            <Col span={5}>
                                <Form.Item
                                    label="Login(s)"
                                    name={"logins"}
                                    rules={[{ required: true, message: REQUIRED_FIELD }]}
                                    labelCol={{ span: 6 }}
                                    wrapperCol={{ span: 18 }}
                                >
                                    <Upload  {...uploadProps} >
                                        <Button icon={<UploadOutlined />} style={{ width: 180 }}>Upload CSV File</Button>
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        loading={isLoading}
                                        htmlType="submit"
                                    >
                                        Preview
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <Divider />
                <FlexiDataTable
                    bordered
                    rowKeyProperty="newKey"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={data ?? []}
                    callback={componentCallback}
                    loading={isLoading}
                />
            </CardBox>
        </div>
    );
};

export default SymbolTradingHourUpdate;