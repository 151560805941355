import FlexiDataTable from "@/components/FlexiDataTable"
import { FlexiDataTableOptionsProps } from "@/constants/type";
import { apiRequest, APIs } from "@/services/apiConfig";
import { currencyRender, DataTableColumnRender, DTColProps, scientificToDecimal } from "@/utils/Common";
import { useEffect, useMemo, useState } from "react";
import LPEquityChangeDepositWithdrawalForm from "./LPEquityChangeDepositWithdrawalForm";

interface LPEquityChangeProps {
    isLoading: boolean;
    selectedMRGroup: number[];
    mrGroups: any[];
    submitMRGroup: boolean;
}

interface LPEquityChangeDataProps {
    keyId: string;
    equityChange: number;
    inputInclusive: boolean;
    deposit?: number;
    withdrawal?: number;
    depositWithdrawalInputDate?: string;
    createdDate: string;
}

const LPEquityChange = (props: LPEquityChangeProps) => {
    const [lpEquityMarketGroupIds, setLpEquityMarketGroupIds] = useState<number[]>([]);
    const [data, setData] = useState<LPEquityChangeDataProps[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(true);
    const [selectedMRGroups, setSelectedMRGroups] = useState<any[]>(props.selectedMRGroup);

    const tableColConfig = useMemo(
        () => [
            DTColProps.Currency({
                title: "Today LP Equity Change",
                dataIndex: "equityChange",
                key: "equityChange",
            }),
            DTColProps.Middle({
                title: "Deposit & Withdrawal Inclusive",
                dataIndex: "inputInclusive",
                key: "inputInclusive",
                render: (value: boolean) => {
                    return <div>{value ? 'Yes' : 'No'}</div>;
                }
            }),
            DTColProps.Currency({
                title: "Deposit",
                dataIndex: "deposit",
                key: "deposit",
                render: (value: number) => {
                    return <div>{value ? currencyRender(scientificToDecimal(value), 2) : 'N/A'}</div>;
                }
            }),
            DTColProps.Currency({
                title: "Withdrawal",
                dataIndex: "withdrawal",
                key: "withdrawal",
                render: (value: number) => {
                    return <div>{value ? currencyRender(scientificToDecimal(value), 2) : 'N/A'}</div>;
                }
            }),
            DTColProps.DateTime_ServerTime({
                width: "7vw",
                title: "Inputted Date & Time",
                dataIndex: "depositWithdrawalInputDate",
                key: "depositWithdrawalInputDate",
                sorter: false,
                render: (value: string) => {
                    return <div>{value ? DataTableColumnRender.DateTime_ServerTime(value) : 'N/A'}</div>;
                }
            }),
            DTColProps.DateTime_ServerTime({
                width: "7vw",
                title: "Last Refreshed Date & Time",
                dataIndex: "createdDate",
                key: "createdDate",
                sorter: false
            }),
        ],
        []
    );

    const tableOptions: FlexiDataTableOptionsProps = {
        enableFilter: false
    };

    const getLPEquityChangeConfig = () => {
        apiRequest(APIs.GET_SETTING_LIST, {
            keys: ["LpEquityChangeConfig"]
        }).then((res: any) => {
            if (res && res.length > 0) {
                const config = JSON.parse(res[0].value)
                setLpEquityMarketGroupIds(config.DefaultMarketGroupIds)
            }
        })
    }

    const getLPEquityChangeListing = () => {
        let enabledLPEquityChangeMRGroupIds = null

        if (selectedMRGroups && selectedMRGroups.length > 0) {
            enabledLPEquityChangeMRGroupIds =
                selectedMRGroups.filter(
                    (x: number) => lpEquityMarketGroupIds.includes(x)
                )
            if (enabledLPEquityChangeMRGroupIds.length < 1) {
                setData([]);
                setIsLoading(false);
                return;
            }
        }

        apiRequest(APIs.GET_LP_EQUITY_CHANGE, {
            marketGroupIds: enabledLPEquityChangeMRGroupIds,
        })
            .then((res: any) => {
                if (res && res.length > 0) {
                    setData(res.map((x: any) => ({ ...x, keyId: Math.random().toString(36).substr(2, 9) })));
                }
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getLPEquityChangeConfig()
    }, [])

    useEffect(() => {
        if (runRefetchDataList && lpEquityMarketGroupIds.length > 0) {
            setIsLoading(true);
            getLPEquityChangeListing();
            setRunRefetchDataList(false);
        }
    }, [runRefetchDataList, lpEquityMarketGroupIds]);

    useEffect(() => {
        if (props.isLoading && !runRefetchDataList) {
            setRunRefetchDataList(true);
        }
    }, [props.isLoading]);

    useEffect(() => {
        if (props.submitMRGroup) {
            setSelectedMRGroups(props.selectedMRGroup);
        }
    }, [props.submitMRGroup, props.selectedMRGroup])

    return (
        <div className="half-view half-view-2">
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
                marginBottom: 10
            }}>
                {props.mrGroups
                    .filter((x: any) => lpEquityMarketGroupIds.includes(x.value))
                    .filter((x: any) => !selectedMRGroups.length || selectedMRGroups.includes(x.value))
                    .map((x: any) => (
                        <LPEquityChangeDepositWithdrawalForm key={x.value} title={x.label} marketGroupId={x.value} />
                    ))}
            </div>
            <FlexiDataTable
                rowKeyProperty="keyId"
                title={<span className="flexi-table-small-title-style">Today LP Equity Change</span>}
                columns={tableColConfig}
                options={tableOptions}
                dataSource={data ?? []}
                loading={isLoading}
                pagination={{
                    defaultPageSize: 10,
                }}
                scroll={{
                    x: true,
                }}
            />
        </div>
    )
}

export default LPEquityChange