import React, { useEffect } from "react";
import useRCDataCenter from "../../../hooks/useRCDataCenter";
import { Button, Typography } from "antd";
import TabContent from "../components/TabContent";
import { motion } from "framer-motion";
import DataCenterMonitorTable from "./DataCenterMonitorTable";
import TimeDiff from "../components/TimeDiff";
import useRCDataCenterStore from "../../../store/useRCDataCenterStore";
import { ReloadOutlined } from "@ant-design/icons";

const { Text } = Typography;

const DataCenterMonitor = () => {
    const { rcDataCenter, dataUpdatedAt, refetchRcDataCenter, isFetching } = useRCDataCenter();
    const updateRecords = useRCDataCenterStore(state => state.updateRecords);

    useEffect(() => {
        if (rcDataCenter) {
            updateRecords(rcDataCenter);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataUpdatedAt]);

    return (
        <TabContent>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "4px", alignItems: "center", padding: "1rem" }}>
                {rcDataCenter?.serverTime && <Text>Last updated {rcDataCenter?.serverTime}</Text>}
                {dataUpdatedAt && <TimeDiff timestamp={dataUpdatedAt} />}
                <Button
                    type="ghost"
                    style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 4, marginLeft: "auto" }}
                    onClick={() => {
                        refetchRcDataCenter();
                    }}
                    disabled={isFetching}
                    shape="circle"
                    icon={
                        <motion.div animate={{ rotate: isFetching ? 360 : 0 }}>
                            <ReloadOutlined style={{ fontSize: "0.75rem" }} />
                        </motion.div>
                    }
                />
            </div>
            <DataCenterMonitorTable />
        </TabContent>
    );
};

export default DataCenterMonitor;
