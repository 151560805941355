import { isEmptyOrNull } from "./string";

export const findObjectKeys = (
    obj: any,
    searchText: string[],
    expression: Function = (key: string) => searchText.map(x => `${x}`.toLowerCase()).filter(z => key.indexOf(z) > -1).length > 0,
    getList: boolean = false
) => {
    return getList
        ? Object.keys(obj)
              .map(x => x.toLowerCase())
              .filter(x => expression(x))
        : Object.keys(obj)
              .map(x => x.toLowerCase())
              .some(x => expression(x));
};

export const hasAnyKey = (obj: any) => Object.keys(obj).length > 0;

export const getObjectWithKeys = (obj: any, keys: string[], toLowerCase: boolean = false) =>
    Object.keys(obj)
        .filter((x: string) => keys.some(y => y.toLowerCase() === x.toLowerCase()))
        .reduce((newObj: any, key: string) => {
            newObj[toLowerCase ? key.toLowerCase() : key] = obj[key];
            return newObj;
        }, {});

export const objectRemoveProps = (obj: any, exceptProps: string[]) =>
    Object.keys(obj)
        .filter(x => !exceptProps.some(y => y.toLowerCase() === x.toLowerCase()))
        .reduce((newObj: any, key: any) => {
            newObj[key] = obj[key];
            return newObj;
        }, {});

export const objectToTableColumns = (obj: any, preColumn: any[] = [], excludes: string[] = []) => {
    return Object.keys(obj)
        .filter(x => !excludes.some(y => y.toLowerCase() === x.toLowerCase()))
        .map(x => {
            let findIdx = preColumn.findIndex(y => y.key === x);
            return Object.assign(
                {
                    title: x,
                    dataIndex: x,
                    key: x,
                },
                findIdx > -1 ? preColumn[findIdx] : {}
            );
        });
};

export const objectToArray = (obj: any, exceptProps: string[] = []) => {
    return exceptProps.length > 0
        ? Object.keys(obj)
              .filter(x => !exceptProps.includes(x))
              .reduce((array: any[], x: string) => {
                  array.push(obj[x]);
                  return array;
              }, [])
        : Object.keys(obj).reduce((array: any[], x: string) => {
              array.push(obj[x]);
              return array;
          }, []);
};

export const objectToQueryString = (obj: any, keys: string[] = []) =>
    (keys.length > 0 ? keys : Object.keys(obj))
        .filter(key => !isEmptyOrNull(obj[key]))
        .map(key => `${key}=${encodeURIComponent(obj[key])}`)
        .join("&");

export const findObjectWithElementProps = (obj: any, searchKey: string, searchValue: any, toArray: boolean = false) => {
    if (!searchKey) {
        return toArray ? Object.keys(obj).map(x => obj[x]) : obj;
    }

    if (toArray) {
        return Object.keys(obj)
            .filter(x => obj[x][searchKey] === searchValue)
            .map(x => obj[x]);
    }

    return Object.keys(obj)
        .filter(x => obj[x][searchKey] === searchValue)
        .reduce((tmpObj: any, x: string) => {
            tmpObj[x] = obj[x];
            return tmpObj;
        }, {});
};

export const GetObjectCountByKey2 = (obj: any, key: string, countKey: string, toArray: boolean = false) => {
    let tmpObj: any = { ...obj };
    let finalObject = Object.keys(tmpObj).reduce((final: any, x: string) => {
        if (final?.hasOwnProperty(tmpObj[x][key])) {
            final[tmpObj[x][key]] += 1;
        } else {
            final[tmpObj[x][key]] = 1;
        }
        return final;
    }, {});

    return toArray
        ? Object.keys(finalObject).reduce((finalList: any, item: string) => {
              let tmp: any = {};
              tmp[key] = item;
              tmp[countKey] = finalObject[item];
              finalList.push(tmp);
              return finalList;
          }, [])
        : finalObject;
};

export const getAvailableObjectElementKeys = (values: any) =>
    values
        ? Object.keys(values).filter(
              x =>
                  values[x] !== undefined &&
                  values[x] !== null &&
                  (Array.isArray(values[x]) || typeof values[x] === "string" ? values[x].length > 0 : true)
          )
        : [];

export const getAvailableObjectElement = (values: any, toLowerCase: boolean = false) =>
    values
        ? getAvailableObjectElementKeys(values).reduce((newObj: any, key: string) => {
              newObj[toLowerCase ? key.toLowerCase() : key] = values[key];
              return newObj;
          }, {})
        : {};

export const resetObjectValues = (values: any, defaultValue: any = undefined) =>
    Object.keys(values).reduce((newObj: any, key: any) => {
        newObj[key] = defaultValue;
        return newObj;
    }, {});

export const fillArrayEqually = (object: any, arr: any[]) => {
    const keys = Object.keys(object);
    let obj = { ...object };
    for (let i = 0, count = arr.length; i < count; i++) {
        obj[keys.reduce((minKey, key) => (obj[key].length < obj[minKey].length ? key : minKey))].push(arr[i]);
    }
    return obj;
};

export const DefaultIfEmpty = (obj: any, key: any, defaultValue: any, checkLength: boolean = false) =>
    obj?.hasOwnProperty(key) ? (checkLength ? (obj[key].length > 0 ? obj[key] : defaultValue) : obj[key]) : defaultValue;
