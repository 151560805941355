import { Form, Modal } from "antd";
import { FormComponent } from "@/components/FormComponent";
import { ComponentType, SUCCESS_FAILED } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { useEffect } from "react";
import { DefaultIfEmpty, objectRemoveProps } from "@/utils/object";
import { ConfigDatasProps } from "./reservationSetting";
import { LabelTypes } from "./hthlist";

interface ReservationSettingCreateModalProps {
    isModalVisible: boolean;
    callback: (type: number, data: any) => void;
    configData: ConfigDatasProps;
}

export enum RESERVATION_CREATE_MODE {
    BY_CLIENT_ID = 1,
    BY_GROUP = 2,
}

export enum ReservationSettingCreateModalCallbackKey {
    Close = 0,
    CloseAndRefetch = 1,
}

const ReservationSettingCreateModal = (props: ReservationSettingCreateModalProps) => {
    const [createForm] = Form.useForm();
    const currentMode = Form.useWatch<number>("mode", createForm);

    const submitData = (values: any) => {
        let isClientIdMode = DefaultIfEmpty(values, "mode", RESERVATION_CREATE_MODE.BY_CLIENT_ID) === RESERVATION_CREATE_MODE.BY_CLIENT_ID,
            submitAPi = isClientIdMode
                ? APIs.RISK_TOOL.GET_HUB_TO_HUB_RESERVATION_SETTING_CREATE
                : APIs.RISK_TOOL.GET_HUB_TO_HUB_RESERVATION_SETTING_CREATE_BYGROUP,
            params = objectRemoveProps(
                { ...values, hthStatus: values.hthStatus ? 1 : 0, serverUno: parseInt(values.serverUno) },
                isClientIdMode ? ["mode", "brand", "loginGroup"] : ["mode", "serverUno", "login"]
            );
        plainAxiosInstance
            .post(submitAPi, isClientIdMode ? [params] : params)
            .then((res: any) => {
                if (res.data.status === 0) {
                    ErrorMessageHandler("HTH reservation", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                } else {
                    ErrorMessageHandler("HTH reservation", SUCCESS_FAILED.FAILED_CREATE_DATA);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("HTH reservation", SUCCESS_FAILED.FAILED_CREATE_DATA, err))
            )
            .finally(() => props.callback(ReservationSettingCreateModalCallbackKey.CloseAndRefetch, null));
    };

    useEffect(() => {
        if (props.isModalVisible) {
            createForm.resetFields();
            createForm.setFieldsValue({ mode: RESERVATION_CREATE_MODE.BY_CLIENT_ID, hthStatus: true });
        }
    }, [props.isModalVisible]);

    useEffect(() => {
        if (currentMode === RESERVATION_CREATE_MODE.BY_CLIENT_ID) {
            createForm.setFieldsValue({ brand: "", loginGroup: "" });
        } else if (currentMode === RESERVATION_CREATE_MODE.BY_GROUP) {
            createForm.setFieldsValue({ serverUno: "", login: "" });
        }
    }, [currentMode]);

    return (
        <Modal
            style={{ top: 10 }}
            width={"40vw"}
            title={`Add Client`}
            open={props.isModalVisible}
            onCancel={() => props.callback(ReservationSettingCreateModalCallbackKey.Close, null)}
            maskClosable={false}
            onOk={() => {
                createForm
                    .validateFields()
                    .then((values: any) => {
                        submitData(values);
                    })
                    .catch(error => console.log("error", error));
            }}
        >
            <Form form={createForm} layout="horizontal" labelCol={{ span: 5 }} labelAlign="left" wrapperCol={{ span: 16 }}>
                <FormComponent
                    label="Mode"
                    name="mode"
                    extra={{
                        type: ComponentType.radio,
                        value: [
                            { text: "By Login", value: RESERVATION_CREATE_MODE.BY_CLIENT_ID },
                            { text: "By Group", value: RESERVATION_CREATE_MODE.BY_GROUP },
                        ],
                    }}
                />
                {currentMode === RESERVATION_CREATE_MODE.BY_CLIENT_ID ? (
                    <>
                        <FormComponent
                            label="Server"
                            name="serverUno"
                            extra={{
                                type: ComponentType.dropdown,
                                value: props.configData.servers,
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                            }}
                        />
                        <FormComponent
                            label="Login"
                            name="login"
                            extra={{
                                type: ComponentType.text,
                                value: "",
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                            }}
                        />
                    </>
                ) : (
                    <>
                        <FormComponent
                            label="Brand"
                            name="brand"
                            extra={{
                                type: ComponentType.dropdown,
                                value: props.configData.brands,
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                            }}
                        />
                        <FormComponent
                            label="Group"
                            name="loginGroup"
                            extra={{
                                type: ComponentType.dropdown,
                                value: props.configData.groups,
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                            }}
                        />
                    </>
                )}
                <FormComponent
                    label="Symbol"
                    name="symbol"
                    extra={{
                        type: ComponentType.dropdown,
                        value: props.configData.symbols,
                        rules: [{ required: true, message: REQUIRED_FIELD }],
                    }}
                />
                <FormComponent
                    label="Label"
                    name="label"
                    extra={{
                        type: ComponentType.dropdown,
                        value: LabelTypes,
                        rules: [{ required: true, message: REQUIRED_FIELD }],
                    }}
                />
                <FormComponent
                    label="Comment"
                    name="comment"
                    extra={{
                        type: ComponentType.textarea,
                        value: "",
                        inputProps: {
                            row: 6,
                        },
                    }}
                />
                <FormComponent
                    label="Note"
                    name="note"
                    extra={{
                        type: ComponentType.textarea,
                        value: "",
                        inputProps: {
                            row: 6,
                        },
                    }}
                />
                <FormComponent
                    label="HTH"
                    name="hthStatus"
                    extra={{
                        type: ComponentType.switch,
                        value: ["Disable", "Enable"],
                    }}
                />
            </Form>
        </Modal>
    );
};

export default ReservationSettingCreateModal;
