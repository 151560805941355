import { KeyValuePair, PriceAnalysisAlarmLatestList, PriceAnalysisGroupedSymbols } from "../../../constants/type";
import PAAlarmServerDetail from "./PAAlarmServerDetail";
import { plainAxiosInstance } from "../../../services/axiosSetup";
import { useEffect, useState } from "react";
import { APIs } from "../../../services/apis";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import { Button, Col, Divider, Empty, Form, Row, Typography } from "antd";
import { FormComponent } from "../../../components/FormComponent";
import { isEmptyOrNull } from "../../../utils/string";
import { CALLBACK_KEY, ComponentType, PRICE_ANALYSIS_ALARM_TAB, SUCCESS_FAILED } from "../../../constants";
import { REQUIRED_FIELD } from "../../../constants/errorMessage";
import moment from "moment";
import LoadingComponent from "../../../components/Loading";

export interface TabAlarmHistoryProps { }

const TabAlarmHistory = (props: TabAlarmHistoryProps) => {
    const colsProps = {
        xl: 8,
        lg: 12,
        md: 12,
        sm: 24,
        style: { marginBottom: "1.414vh" },
    };
    const [groupEnabled, setGroupEnabled] = useState<KeyValuePair[]>([]);
    const [groupSymbol, setGroupSymbol] = useState<PriceAnalysisGroupedSymbols[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [filterForm] = Form.useForm();
    const [result, setResult] = useState<PriceAnalysisAlarmLatestList[]>([]);
    const { Title } = Typography;

    const onFinished = async (values: any) => {
        const [baseServerUno, baseServerSymbol] = values.symbol.split("|");
        let tmpFP = {
            groupId: values.group,
            baseServerUno: baseServerUno,
            baseServerSymbol: baseServerSymbol,
            startTime: moment(values.startdate[0]).format("YYYY-MM-DD HH:mm:ss"),
            endTime: moment(values.startdate[1]).format("YYYY-MM-DD HH:mm:ss"),
        };
        onSubmit(tmpFP);
    };

    const onSubmit = async (tmpFP: any) => {
        setIsLoading(true);
        try {
            const response = await plainAxiosInstance.get(
                `${APIs.RC_PRICE_ANALYSIS.GET_PA_ALARM_HISTORY}?groupId=${tmpFP.groupId}&baseServerUno=${tmpFP.baseServerUno}&baseServerSymbol=${tmpFP.baseServerSymbol}&startTime=${tmpFP.startTime}&endTime=${tmpFP.endTime}`
            );
            setResult(response.data);
        } catch (error) {
            ErrorCatchValidator(error, (err: any) =>
                ErrorMessageHandler("Price Analysis Alarm History Enabled Groups", SUCCESS_FAILED.FAILED_LOAD_DATA, err)
            );
        } finally {
            setIsLoading(false);
        }
    };

    const onValuesChanged = (changedValues: any) => {
        if (changedValues?.hasOwnProperty("group")) {
            if (isEmptyOrNull(changedValues.group)) {
                filterForm.setFieldsValue({ symbol: "" });
                setGroupSymbol([]);
            } else {
                filterForm.setFieldsValue({ symbol: "" });
                getGroupSymbols(changedValues.group);
            }
        }
    };

    const getEnabledGroup = async () => {
        try {
            const response = await plainAxiosInstance.get(`${APIs.RC_PRICE_ANALYSIS.GET_PA_GROUP_ENABLED}`);
            setGroupEnabled(response.data);
        } catch (error) {
            ErrorCatchValidator(error, (err: any) =>
                ErrorMessageHandler("Price Analysis Alarm History Enabled Groups", SUCCESS_FAILED.FAILED_LOAD_DATA, err)
            );
        } finally {
            setIsLoading(false);
        }
    };

    const getGroupSymbols = async (groupId: number) => {
        try {
            const response = await plainAxiosInstance.get(`${APIs.RC_PRICE_ANALYSIS.GET_PA_SETTINGS_SYMBOLS}/${groupId}`);
            setGroupSymbol(response.data);
        } catch (error) {
            ErrorCatchValidator(error, (err: any) =>
                ErrorMessageHandler("Price Analysis Alarm History Group Symbols", SUCCESS_FAILED.FAILED_LOAD_DATA, err)
            );
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        filterForm.resetFields();
        setResult([]);
        getEnabledGroup();
    }, []);

    return (
        <div className="tab-alarm-history-container">
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Loading..." />
                </div>
            ) : (
                <div className="container-content">
                    <div className="title-bar">
                        <Title level={2}>Search the alarm history.</Title>
                    </div>
                    <Form form={filterForm} layout="vertical" onFinish={onFinished} onValuesChange={onValuesChanged}>
                        <Row gutter={24} style={{ width: "100%" }}>
                            <Col {...colsProps}>
                                <FormComponent
                                    label="Group"
                                    name="group"
                                    extra={{
                                        type: ComponentType.dropdown,
                                        value: groupEnabled.map((x: any) => ({ value: x.groupId, text: x.groupDn })),
                                        rules: [{ required: true, message: REQUIRED_FIELD }],
                                    }}
                                />
                            </Col>
                            <Col {...colsProps}>
                                <FormComponent
                                    label="Symbol"
                                    name="symbol"
                                    extra={{
                                        type: ComponentType.dropdown,
                                        value: groupSymbol.map((x: any) => ({ value: `${x.serverUno}|${x.symbol}`, text: x.symbol })),
                                        rules: [{ required: true, message: REQUIRED_FIELD }],
                                    }}
                                />
                            </Col>
                            <Col {...colsProps}>
                                <FormComponent
                                    label="Date Time"
                                    name="startdate"
                                    extra={{
                                        type: ComponentType.daterange,
                                        value: "",
                                        rules: [{ required: true, message: REQUIRED_FIELD }],
                                        dateFormat: "YYYY-MM-DD HH:mm:ss",
                                        inputProps: {
                                            showTime: { format: "HH:mm:ss" },
                                        },
                                    }}
                                />
                            </Col>
                        </Row>
                        <Divider orientation="left" className="divider-nogap"></Divider>
                        <Row style={{ width: "100%" }}>
                            <Col span={24} key="flt-f-c-btns" className="flt-f-c-btns">
                                <Button
                                    type="default"
                                    onClick={e => {
                                        e.preventDefault();
                                        filterForm.resetFields();
                                    }}
                                >
                                    Reset
                                </Button>
                                <Button type="primary" htmlType="submit">
                                    Search
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                    <br />
                    {result.length > 0 ? (
                        <PAAlarmServerDetail svData={result} isLoading={isLoading} from={PRICE_ANALYSIS_ALARM_TAB.HISTORY} />
                    ) : (
                        <Empty />
                    )}
                </div>
            )}
        </div>
    );
};

export default TabAlarmHistory;
