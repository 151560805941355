import { useCallback, useEffect, useMemo, useState } from "react";
import SitePageHeaderInline, { SitePageHeaderInlineActionType } from "@/components/PageHeader/inlineIndex";
import { Button, Col, Form, Popconfirm, Row } from "antd";
import { CreateEditInlineFormBased, ToxicClientSourceEventProps } from "@/constants/type";
import { ComponentType, InnerPageActionMode, SUCCESS_FAILED } from "@/constants";
import { apiRequest, APIs } from "@/services/apiConfig";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { isEmptyOrNull } from "@/utils/string";
import LoadingComponent from "@/components/Loading";
import { FormComponent } from "@/components/FormComponent";
import { REQUIRED_FIELD } from "@/constants/errorMessage";

interface ToxicClientSourceCreateEditProps extends CreateEditInlineFormBased {
    callback: (action: InnerPageActionMode, value?: any) => void;
    resetState: number;
}

const CreateEditPage = (props: ToxicClientSourceCreateEditProps) => {
    const isAddAction = useMemo(() => props.mode === InnerPageActionMode.CREATE_NEW, [props.mode]);
    const [tcSourceForm] = Form.useForm();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isDeletable, setIsDeletable] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const fillForm = useCallback((data?: ToxicClientSourceEventProps) => {
        if (isEmptyOrNull(data)) {
            let temp = {
                sourceName: "",
                sourceDescription: "",
                isSourceActive: true,
            };
            tcSourceForm.setFieldsValue(temp);
        } else {
            let currentData = data as ToxicClientSourceEventProps,
                temp: any = {
                    sourceId: currentData.sourceId,
                    sourceName: currentData.sourceName,
                    sourceDescription: currentData.sourceDescription,
                    isSourceActive: currentData.isSourceActive,
                };
            tcSourceForm.setFieldsValue(temp);
        };
    }, [tcSourceForm]);

    const onSubmit = useCallback((values: any) => {
        if (values.sourceId === undefined) delete values.sourceId;
        setIsLoading(true);
        setIsSubmitting(true);
        if (isAddAction) {
            apiRequest(APIs.CREATE_TOXIC_CLIENT_SOURCE, values)
                .then((res) => {
                    ErrorMessageHandler("Toxic client source", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                    setTimeout(() => {
                        props.callback && props.callback(InnerPageActionMode.BACK, { refreshMainList: true })
                        tcSourceForm.resetFields();
                    }, 400);
                })
                .catch((error) =>
                    ErrorCatchValidator(error, (err: any) => {
                        ErrorMessageHandler("toxic client source", SUCCESS_FAILED.FAILED_CREATE_DATA, err);
                    })
                )
                .finally(() => {
                    setIsLoading(false);
                    setIsSubmitting(false);
                });
        } else {
            apiRequest(APIs.UPDATE_TOXIC_CLIENT_SOURCE, values)
                .then((res) => {
                    ErrorMessageHandler(`Toxic client source [ ${values.sourceName} ]`, SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                    setTimeout(() => {
                        props.callback && props.callback(InnerPageActionMode.BACK, { refreshMainList: true })
                        tcSourceForm.resetFields();
                    }, 400);
                })
                .catch((error) =>
                    ErrorCatchValidator(error, (err: any) => {
                        ErrorMessageHandler("toxic client source", SUCCESS_FAILED.FAILED_UPDATE_DATA, err);
                    })
                )
                .finally(() => {
                    setIsLoading(false);
                    setIsSubmitting(false);
                });
        };
    }, [isAddAction, tcSourceForm]);

    const getToxicClientSourceDetails = (id?: number | string | undefined) => {
        if (isEmptyOrNull(id)) {
            fillForm(undefined);
            setIsLoading(false);
            return;
        };

        setIsLoading(true);
        apiRequest(APIs.GET_TOXIC_CLIENT_SOURCE_CONFIG_LIST, { sourceId: id })
            .then((res: ToxicClientSourceEventProps) => {
                fillForm(res);
                setIsDeletable(res.sourceEntryCount === 0 ? true : false);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("toxic client source details", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                props.callback && props.callback(InnerPageActionMode.BACK);
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getToxicClientSourceDetails(props.id);
        return () => { };
    }, [props.resetState, props.id]);

    return (
        <SitePageHeaderInline
            title={`${isAddAction ? "Create New" : "Edit"} Source Event`}
            callback={(action: SitePageHeaderInlineActionType) => {
                switch (action) {
                    case SitePageHeaderInlineActionType.OnBack:
                    case SitePageHeaderInlineActionType.OnCancel:
                        tcSourceForm.resetFields();
                        props.callback && props.callback(InnerPageActionMode.BACK);
                        return;
                    default:
                        break;
                }
            }}
            extraProps={{
                extra: [
                    <Button key={"regular-submit"} type="primary" htmlType="submit" loading={isSubmitting} onClick={() => {
                        tcSourceForm
                            .validateFields()
                            .then((res) => onSubmit(res))
                            .catch((err) => console.log("form-error:", err));
                    }}>
                        Submit
                    </Button>,
                    <Button key={"cancel"} onClick={() => {
                        tcSourceForm.resetFields();
                        props.callback && props.callback(InnerPageActionMode.BACK)
                    }}>
                        Cancel
                    </Button>,
                    isAddAction || !isDeletable ? []
                        : [
                            <Popconfirm
                                key={"cetmp-del"}
                                title="Confirm to delete?"
                                onConfirm={() => {
                                    setIsLoading(true);
                                    apiRequest(APIs.DELETE_TOXIC_CLIENT_SOURCE, { sourceId: props.id })
                                        .then((res: any) => {
                                            ErrorMessageHandler("Toxic client source", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                                            setTimeout(() => {
                                                props.callback && props.callback(InnerPageActionMode.BACK, { refreshMainList: true })
                                                tcSourceForm.resetFields();
                                            }, 400);
                                        })
                                        .catch((error: any) => {
                                            ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("toxic client source", SUCCESS_FAILED.FAILED_DELETE_DATA, err));
                                        })
                                        .finally(() => setIsLoading(false));
                                }}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="primary" danger>
                                    Delete Toxic Client Source
                                </Button>
                            </Popconfirm>,
                        ],
                ],
            }}
        >
            {
                isLoading ? (
                    <div className="loading-container" >
                        <LoadingComponent tip="Loading..." />
                    </div>
                ) : (
                    <div className="toxic-client-source-create-edit-form">
                        <Form
                            form={tcSourceForm}
                            layout="horizontal"
                            initialValues={{}}
                            onFinish={onSubmit}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                        >
                            <FormComponent label={""} name={"sourceId"} extra={{ type: ComponentType.hidden, value: "" }} />
                            <Row>
                                <Col span={15}>
                                    <FormComponent
                                        label="Name"
                                        name="sourceName"
                                        extra={{
                                            type: ComponentType.text,
                                            value: "",
                                            rules: [{ required: true, message: REQUIRED_FIELD }],
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={15}>
                                    <FormComponent
                                        label="Description"
                                        name="sourceDescription"
                                        extra={{
                                            type: ComponentType.text,
                                            value: "",
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={15}>
                                    <FormComponent
                                        label="Status"
                                        name="isSourceActive"
                                        extra={{
                                            type: ComponentType.switch,
                                            value: ["Inactive", "Active"],
                                            rules: [{ required: true, message: REQUIRED_FIELD }],
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </div>
                )
            }
        </SitePageHeaderInline>
    );
};

export default CreateEditPage;