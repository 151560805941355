import { useMemo, useState } from "react";
import CardBox from "@/components/Common/CardBox";
import FlexiDataTable from "@/components/FlexiDataTable";
import { FlexiDataTableCallbackProps, FlexiDataTableOptionsProps } from "@/constants/type";
import { CALLBACK_KEY, ComponentType } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { DTColProps } from "@/utils/Common";
import { CarryOutOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Popconfirm, Row, Space, Tooltip } from "antd";
import { FormComponent } from "@/components/FormComponent";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";

const UnarchiveAccounts = () => {
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.MANAGEMENT_TOOL_UNARCHIVE_ACCOUNTS_EDIT);

    const [data, setData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [previewForm] = Form.useForm();

    const columns: any[] = useMemo(() => [
        DTColProps.Small({
            title: "Login",
            dataIndex: "login",
            key: "login",
            fixed: "left",
            sorter: (a: any, b: any) => a.login - b.login,
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Small({
            title: "Name",
            dataIndex: "name",
            key: "name",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Small({
            title: "Country",
            dataIndex: "country",
            key: "country",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Middle({
            title: "Group",
            dataIndex: "group",
            key: "group",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Middle({
            title: "Status",
            dataIndex: "status",
            key: "status",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.XSmall(
            {
                title: "Unarchived Trades",
                dataIndex: "unarchivedTrades",
                key: "unarchivedTrades",
                sorter: (a: any, b: any) => a.unarchivedTrades - b.unarchivedTrades,
            },
            ["text-center"]
        ),
        DTColProps.XSmall(
            {
                title: "Archived Status",
                dataIndex: "archivedStatus",
                key: "archivedStatus",
                sorter: (a: any, b: any) => a.archivedStatus - b.archivedStatus,
            },
            ["text-center"]
        ),
        DTColProps.XSmall(
            {
                title: "Balance",
                dataIndex: "balance",
                key: "balance",
                sorter: (a: any, b: any) => a.balance - b.balance,
            },
            ["text-center"]
        ),
        DTColProps.Small({
            title: "Result",
            dataIndex: "result",
            key: "result",
        }),
        DTColProps.XXSmall({
            title: "",
            dataIndex: "actions",
            key: "actions",
            fixed: "right",
            render: (_: any, rowData: any) => (
                <Space>
                    {enableUpdate && (
                        <Tooltip title="Apply" placement="top" key={`apply-${rowData.login}`}>
                            <Button
                                type="text"
                                icon={<CarryOutOutlined />}
                                onClick={() =>
                                    componentCallback(CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK, { key: "apply", data: rowData })
                                }
                            />
                        </Tooltip>
                    )}
                    {enableUpdate && (
                        <Tooltip title="Delete" placement="top" key={`delete-ua-${rowData.login}`}>
                            <Popconfirm
                                placement="topRight"
                                title={"Confirm to delete?"}
                                icon={<DeleteOutlined style={{ fontSize: "0.875rem" }} />}
                                onConfirm={() => componentCallback(CALLBACK_KEY.DO_DELETE, rowData)}
                                okText={"Yes"}
                                cancelText={"No"}
                                style={{ width: "15.765vw" }}
                            >
                                <Button
                                    type="text"
                                    icon={<DeleteOutlined />}
                                    onClick={e => {
                                        e.preventDefault();
                                    }}
                                />
                            </Popconfirm>
                        </Tooltip>
                    )}
                </Space>
            ),
        }),
    ], [enableUpdate]);

    const options: FlexiDataTableOptionsProps = useMemo(() => {
        return {
            defaultCollapseFilterPanel: true,
            ...(enableUpdate && {
                extraButtons: () => {
                    return (
                        <div className="extra-header-buttons" key={"mtua-extra-buttons"}>
                            <Button
                                key={"mtua-apply-all"}
                                icon={<CarryOutOutlined />}
                                disabled={data.length === 0 || isLoading}
                                onClick={() => componentCallback(CALLBACK_KEY.OTHERS, "apply-all")}
                            >
                                Apply All
                            </Button>
                        </div>
                    );
                },
            }),
        }
    }, [enableUpdate, data, isLoading]);

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK:
                if (FormData.key === "apply") {
                    console.log("Apply clicked: ", FormData.data);
                } else if (FormData.key === "delete") {
                    console.log("Delete clicked: ", FormData.data);
                };
                break;
            case CALLBACK_KEY.OTHERS:
                switch (FormData) {
                    case "apply-all":
                        console.log("Apply All clicked: ", FormData);
                        break;
                    default:
                        break;
                }
                break;
            // case CALLBACK_KEY.DO_DELETE:
            //     console.log("Delete clicked: ", FormData);
            //     break;
            default:
                break;
        };
    };

    const onFormSubmit = (values: any) => {
        console.log("onFormSubmit: ", values);
    };

    return (
        <div className="unarchive-accounts-container">
            <CardBox title="Unarchive Accounts">

                <div className="preview-form-div">
                    <Form
                        form={previewForm}
                        layout="horizontal"
                        initialValues={{}}
                        onFinish={onFormSubmit}
                    >
                        <Row gutter={[12, 12]}>
                            <Col span={6}>
                                <FormComponent
                                    label="Server"
                                    name="server"
                                    extra={{
                                        type: ComponentType.dropdown,
                                        value: [],
                                        rules: [{ required: true, message: REQUIRED_FIELD }],
                                    }}
                                />
                            </Col>
                            <Col span={9}>
                                <FormComponent
                                    label="Login(s)"
                                    name="logins"
                                    extra={{
                                        type: ComponentType.dropdown,
                                        value: [],
                                        inputProps: {
                                            mode: "tags",
                                            placeholder: "Please insert logins",
                                            tokenSeparators: [",", "，", " "],
                                        },
                                        rules: [{ required: true, message: REQUIRED_FIELD }],
                                    }}
                                />
                            </Col>
                            <Col>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        loading={isLoading}
                                        htmlType="submit"
                                    >
                                        Preview
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <Divider />
                <FlexiDataTable
                    bordered
                    rowKeyProperty="login"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={data ?? []}
                    callback={componentCallback}
                    loading={isLoading}
                />
            </CardBox>
        </div>
    );
};

export default UnarchiveAccounts;