import { Routes, Route, Link } from "react-router-dom";
import {
    DashboardOutlined,
    EuroCircleOutlined,
    FundProjectionScreenOutlined,
    FundViewOutlined,
    LineChartOutlined,
    RadarChartOutlined,
    SettingOutlined,
    ToolOutlined,
    ApartmentOutlined,
    AlertOutlined,
} from "@ant-design/icons";
import { routeProps } from "../constants/type";
import { userInfo } from "../services/api";
import ReportHistoryPage from "../pages/ReportingModule/ReportHistoryTab";
import TemplatesListPage from "../pages/ReportingModule/Templates/index";
import TemplateCreatePage from "../pages/ReportingModule/Templates/createEditTemplate";
import ViewTemplatePage from "../pages/ReportingModule/Templates/ViewTemplate";
import NotFoundPage from "../pages/NotFoundPage";
import Login from "../pages/LoginPage/ssoIndex";
import SSORedirection from "../pages/LoginPage/ssoRedirection";
import AuthLayout from "../layouts/AuthLayout";
import MyAccountPage from "../pages/MyAccountPage";
import MyAccountEditPage from "../pages/MyAccountPage/MyAccountEditPage";
import ViewReportInstancesPage from "../pages/ReportingModule/ViewReportInstancesPage";
import RouteHelper from "../helpers/routeHelper";
import ForbiddenPage from "../pages/Forbidden";
import ResetPasswordPage from "../pages/ResetPasswordPage";
import WrapperLayout from "../layouts/WrapperLayout";
import DashboardPage from "../pages/Dashboard";
import AppConfiguration from "../pages/SiteAdminModule/Configuration";
import { AuthKeys } from "../helpers/authHelper";
import TradeMonitoringPage from "../pages/RealTime/Trade/TradeMonitoring";
import TradeMonitoringCreateEditPage from "../pages/RealTime/Trade/TradeMonitoringCreateEditPage";
import TradeMonitoringPanel from "../pages/RealTime/Trade/TradeMonitoringPanel";
import HistoricalEventPage from "../pages/RealTime/HistoricalEvent";
import NotificationPage from "../pages/NotificationPage/index";
import SummaryHistoricalEvent from "../pages/RealTime/SummaryHistoricalEvent";
import DecayChartSummary from "../pages/RealTime/DecayChart";
import UserManualListing from "../pages/SiteAdminModule/Configuration/UserManualConfiguration";
import ActivityLogsPage from "../pages/ActivityLogsPage";
import UserManualView from "../pages/UserManualView";
import PMIList from "../pages/RealTime/PMI";
import TicketRestoration from "../pages/AdminTools/TicketRestoration";
import CalculateStopOut from "../pages/AdminTools/TicketRestoration/CalculateStopOut";
import HubSwitcher from "../pages/AdminTools/HubSwitcher";
import HubSwitcherPreview from "../pages/AdminTools/HubSwitcher/preview";
import ToxicAccountEventSnapshot from "../pages/RealTime/PMI/eventSnapshot";
import ProblematicClients from "../pages/Analysis/ProblematicClients";
import PreMarketAccModel from "../pages/Analysis/PreMarketAccModel";
import LPMonitorSummary from "../pages/LPMonitor/summary";
import LP360Profile from "../pages/LPMonitor/lp360";
import HubStatusDashboard from "../pages/AdminTools/HubStatusDashboard";
import Development from "../pages/DevelopmentPage";
import IntradayMonitorSearch from "../pages/IntradayMonitor/Search";
import IntradayMonitorSummary from "../pages/IntradayMonitor/Summary/summary";
import ToxicClientCheck from "../pages/Analysis/ToxicClientCheck";
import SpreadReportPage from "../pages/SpreadReportPage";
import StatusMonitor from "../pages/SystemMonitor/StatusMonitor";
import RejectOrder from "../pages/SystemMonitor/RejectOrder";
import MetaTrader from "../pages/SystemMonitor/MetaTrader";
import ServerPerformance from "../pages/SystemMonitor/ServerPerformance";
import AlarmDashboard from "../pages/AlarmModule/alarmDashboard";
import IssuesLog from "../pages/SystemMonitor/IssuesLog";
import AlarmHistoricalPage from "../pages/AlarmModule/alarmHistorical";
import DataCenter from "../pages/SystemMonitor/DataCenter";
import SystemMonitorOverview from "../pages/SystemMonitor/Overview";
import PriceCompare from "../pages/PriceMonitor/PriceCompare";
import PriceAlarm from "../pages/PriceMonitor/PriceAlarm";
import MidBias from "../pages/PriceMonitor/MidBias";
import PriceMonitorSpreadAlarm from "../pages/PriceMonitor/SpreadAlarm";
import LPPriceCompare from "../pages/PriceMonitor/LPPriceCompare";
import PriceAnalysisAlarm from "../pages/PriceMonitor/PriceAnalysisAlarm";
import SwapChargingTool from "../pages/AdminTools/RiskTools/SwapChargingTool";
import LinkedAccountRecord from "../pages/ReportingModule/LinkedAccountRecord";
import LinkedAccQuery from "../pages/ReportingModule/LinkedAccQuery";
import SwapChargingToolProcess from "../pages/AdminTools/RiskTools/SwapChargingToolProcess";
import CommissionRecord from "../pages/ReportingModule/CommissionRecord";
import PriceOutage from "../pages/PriceMonitor/PriceOutage";
import ReadOnlyPage from "../pages/AdminTools/RiskTools/ReadOnly";
import SymbolSwapUpload from "../pages/AdminTools/RiskTools/SymbolSwapUpload";
import HedgeToolsPage from "../pages/AdminTools/RiskTools/HedgeTool";
import HFTReport from "../pages/RealTime/HFTReport";
import OutOfPriceAlarm from "../pages/PriceMonitor/OutOfPriceAlarm";
import GroupTransferTool from "../pages/AdminTools/RiskTools/GroupTransferTool";
import NegativeEquityRecord from "../pages/ReportingModule/NegativeEquityRecord";
import OrderRoutingRecord from "../pages/AdminTools/RiskTools/OrderRoutingRecord";
import ConfigDiff from "../pages/AdminTools/RiskTools/ConfigDiff";
import DailyTickReport from "../pages/ReportingModule/DailyTickReport";
import MTClientSecurity from "@/pages/AdminTools/RiskTools/MTClientSecurity";
import ConfigDiffRecordPage from "../pages/AdminTools/RiskTools/ConfigDiffRecord";
import CADividendMapUpload from "@/pages/AdminTools/RiskTools/CADividendMapUpload";
import SyndicateTrading from "@/pages/RealTime/SyndicateTrading";
import CADividendSeparateProcess from "@/pages/AdminTools/RiskTools/CADividendSeparateProcess";
import WeekendSwapsRecord from "@/pages/AdminTools/RiskTools/WeekendSwapsRecord";
import AverageSpread from "@/pages/ReportingModule/AverageSpread";
import CreditToBalanceUpload from "@/pages/AdminTools/RiskTools/CreditToBalanceUpload";
import DividendUpload from "@/pages/AdminTools/RiskTools/DividendUpload";
import SymbolLeverageTool from "@/pages/AdminTools/RiskTools/SymbolLeverageTool";
import SymbolLeverageStep from "@/pages/AdminTools/RiskTools/SymbolLeverageTool/components/SymbolLeverageStep";
import RollOverTool from "@/pages/AdminTools/RiskTools/RollOverTool";
import CID from "@/pages/AdminTools/RiskTools/CID";
import BalanceUpdatePage from "@/pages/AdminTools/RiskTools/BalanceUpdate";
import StatusConfigurationRestart from "@/pages/AdminTools/RiskTools/StatusConfigurationRestart";
import HoriDifferentRecordsPage from "@/pages/AdminTools/RiskTools/HoriDiffRecords";
import SSOLocalhostMessager from "@/pages/LoginPage/ssoLocalhost";
import PEOrderCompare from "@/pages/RealTime/PEOrderCompare";
import FundingFeeProcessPage from "@/pages/AdminTools/RiskTools/FundingFeeProcess";
import DividendRecordProcessPage from "@/pages/AdminTools/RiskTools/DividendRecord/DividendRecord";
import ClientAnalysis from "@/pages/Analysis/ClientAnalysis";
import CentralizedSettingsPanel from "@/pages/CentralizedSettingsPanel";
import LPMonitorAlarmListing from "@/pages/LPMonitor/Alarm/List";
import IntradayMonitorAlarmListing from "@/pages/IntradayMonitor/Alarm/List";
import SpreadGoogleSheetPage from "@/pages/AdminTools/RiskTools/SpreadGoogleSheet";
import WithdrawalAudit from "@/pages/Analysis/WithdrawalAudit";
import APILogs from "@/pages/Analysis/WithdrawalAudit/APILogs";
import TransactionOrderChecker from "@/pages/AdminTools/RiskTools/TransactionOrderChecker";
import ServerArchivingTool from "@/pages/AdminTools/RiskTools/ServerArchivingTool";
import AdminFeeProcess from "../pages/AdminTools/RiskTools/AdminFeeProcess";
import HubToHubTool from "@/pages/AdminTools/RiskTools/HubToHubTool";

// #region Centralized Settings Panel
import PriceAlarmSettings2 from "@/pages/CentralizedSettingsPanel/components/PriceMonitor/PriceAlarmSettings/newIndex";
import MidPriceSetting from "@/pages/CentralizedSettingsPanel/components/PriceMonitor/MidPriceSettings";
import BrandServerCommission from "@/pages/CentralizedSettingsPanel/components/SystemOthers/CommissionSettings";
import BridgeSettingPage from "@/pages/CentralizedSettingsPanel/components/Systems/BridgeSettings";
import MRGroupingSettingsPage from "@/pages/CentralizedSettingsPanel/components/MRMonitor";
import PriceBackendOptSettingsPage from "@/pages/CentralizedSettingsPanel/components/PriceMonitor/PriceBackendOptionSettings/TabRestartServer";
import PriceAlarmColorSettingPage from "@/pages/CentralizedSettingsPanel/components/PriceMonitor/PriceAlarmColorSettings";
import PriceAnalysisSettingPage from "@/pages/CentralizedSettingsPanel/components/PriceMonitor/PriceAnalysisSettings";
import MidBiasAlarmSettingsPage from "@/pages/CentralizedSettingsPanel/components/PriceMonitor/MidBiasAlarmSettings";
import OutOfPriceSettingsPage from "@/pages/CentralizedSettingsPanel/components/PriceMonitor/OutOfPriceSettings";
import SpreadAlarmSettingPage from "@/pages/CentralizedSettingsPanel/components/PriceMonitor/SpreadAlarmSettings";
import IssuesLogSettingsPanel from "@/pages/CentralizedSettingsPanel/components/SystemMonitor/IssuesLogSettings";
import TabRejectOrderSettings from "@/pages/CentralizedSettingsPanel/components/SystemMonitor/RejectOrderSettings/TabRejectOrderSettings";
import TabServerPerformanceSettings from "@/pages/CentralizedSettingsPanel/components/SystemMonitor/ServerPerformanceSettings/TabServerPerformanceSettings";
import DataCenterSettings from "@/pages/CentralizedSettingsPanel/components/Systems/DataCenterSettings";
import IntradayMonitorAlarmConfiguration from "@/pages/CentralizedSettingsPanel/components/IntradayMonitor/AlarmSettings";
import HFTReportSettingsPanel from "@/pages/CentralizedSettingsPanel/components/TradeMonitor/HFTReportSettings";
import LPMonitorAlarmConfiguration from "@/pages/CentralizedSettingsPanel/components/MRMonitor/AlarmSettings";
import BrandListingPage from "@/pages/CentralizedSettingsPanel/components/Systems/BrandSettings";
import BridgeConfigApiPage from "@/pages/CentralizedSettingsPanel/components/Systems/BridgeConfigApiSettings";
import DataCenterPageSettings from "@/pages/CentralizedSettingsPanel/components/Systems/DataCenterSettings";
import ExternalSystemPage from "@/pages/CentralizedSettingsPanel/components/Systems/ExternalSystemSettings";
import GroupPurposeListPage from "@/pages/CentralizedSettingsPanel/components/Systems/GroupPurposeSettings/GroupPurposeListPage";
import NotificationChannelManagement from "@/pages/CentralizedSettingsPanel/components/Systems/NotificationChannelSettings";
import ServerPageV2 from "@/pages/CentralizedSettingsPanel/components/Systems/ServerSettings";
import MenuMangement from "@/pages/CentralizedSettingsPanel/components/Administration/Menus";
import RolePermissionPage from "@/pages/CentralizedSettingsPanel/components/Administration/RolePermission";
import UsersManagementTab from "@/pages/CentralizedSettingsPanel/components/Administration/UsersPage/UsersManagementTab";
import CleanSymbolList from "@/pages/CentralizedSettingsPanel/components/Symbols/CleanSymbolSettings";
import SymbolMappingPage from "@/pages/CentralizedSettingsPanel/components/Symbols/MTSymbolSettings";
import SymbolAssetTypeList from "@/pages/CentralizedSettingsPanel/components/Symbols/SymbolAssetTypeSettings";
import SymbolInfo from "@/pages/CentralizedSettingsPanel/components/Symbols/SymbolSettings";
import FeatureSettingsPage from "@/pages/CentralizedSettingsPanel/components/SystemOthers/FeatureSettings";
import SettingsTab from "@/pages/CentralizedSettingsPanel/components/Reporting/LinkedAccountReportSettings/SettingsTab";
import ToxicClientSettingsPage from "@/pages/CentralizedSettingsPanel/components/Analysis/ToxicClientSettings";
import JobSchedulers from "@/pages/CentralizedSettingsPanel/components/Reporting/JobSchedulers";
import AdminFeeSettings from "@/pages/CentralizedSettingsPanel/components/ToolsConfiguration/AdminFeeSettings";
import CIDSettingsPage from "@/pages/CentralizedSettingsPanel/components/ToolsConfiguration/CIDSettings";
import FundingFeeSettingPage from "@/pages/CentralizedSettingsPanel/components/ToolsConfiguration/FundingFeeSettings";
import GroupTransferSettingsPage from "@/pages/CentralizedSettingsPanel/components/ToolsConfiguration/GroupTransferToolSettings/setting";
import HubConfigurationPage from "@/pages/CentralizedSettingsPanel/components/ToolsConfiguration/HubFailoverSettings";
import EODPriceReportPage from "@/pages/ReportingModule/EODPrice";
// #endregion

// #region Management Tools
import HolidayUpdater from "@/pages/AdminTools/ManagementTools/HolidayUpdater";
import ArchiveAccounts from "@/pages/AdminTools/ManagementTools/ArchiveAccounts";
import UnarchiveAccounts from "@/pages/AdminTools/ManagementTools/UnarchiveAccounts";
import UserUpdater from "@/pages/AdminTools/ManagementTools/UserUpdater";
import SymbolTradingHourUpdate from "@/pages/AdminTools/ManagementTools/SymbolTradingHourUpdate";
import AsicGroupChange from "@/pages/AdminTools/ManagementTools/AsicGroupChange";
import SwapRatesUpdater from "@/pages/AdminTools/ManagementTools/SwapRatesUpdater";
// #endregion

export const auth_route: routeProps[] = [
    {
        key: "/dashboard",
        title: "Dashboard",
        path: "dashboard",
        icon: (
            <Link to="/dashboard">
                <DashboardOutlined />
            </Link>
        ),
        component: <DashboardPage />,
        order: 1,
        showInNavBar: true,
        shouldShow: true,
    },
    {
        key: "/alarm-center",
        title: "Alarm Center",
        path: "alarm-center",
        icon: <AlertOutlined />,
        component: <DashboardPage />,
        order: 2,
        showInNavBar: true,
        submenu: [
            {
                key: "/alarm-center/dashboard",
                title: "Alarm Dashboard",
                path: "dashboard",
                component: <AlarmDashboard />,
                icon: <Link to="/alarm-center/dashboard" />,
                order: 1,
                showInNavBar: true,
            },
            {
                key: "/alarm-center/historical",
                title: "Historical Alarm Summary",
                path: "historical",
                component: <AlarmHistoricalPage />,
                icon: <Link to="/alarm-center/historical" />,
                order: 2,
                showInNavBar: true,
            },
        ],
    },
    {
        key: "/report",
        title: "Reporting",
        path: "report",
        icon: <LineChartOutlined />,
        order: 3,
        showInNavBar: true,
        submenu: [
            {
                key: "/report/dymr",
                title: "Dynamic Report",
                path: "",
                component: <></>,
                order: 1,
                showInNavBar: true,
                type: "group",
                submenu: [
                    {
                        key: "/report/result",
                        title: "Report Result",
                        path: "result",
                        component: <ReportHistoryPage />,
                        icon: <Link to="/report/result" />,
                        order: 1,
                        showInNavBar: true,
                        submenu: [
                            {
                                key: "/report/result/view/:id",
                                title: "View Report Instances",
                                path: "view/:id",
                                icon: <Link to="/report/result/view/:id" />,
                                component: <ViewReportInstancesPage />,
                                referKey: AuthKeys.REPORT_HISTORY_VIEW,
                            },
                            {
                                key: "/report/result/viewtemplate/:id",
                                title: "View Template",
                                path: "viewtemplate/:id",
                                icon: <Link to="/report/result/viewtemplate/:id" />,
                                component: <ViewTemplatePage />,
                                referKey: AuthKeys.REPORT_HISTORY_VIEW,
                            },
                        ],
                    },
                    {
                        key: "/report/template",
                        title: "Report Template",
                        path: "template",
                        component: <TemplatesListPage />,
                        icon: <Link to="/report/template" />,
                        order: 2,
                        showInNavBar: true,
                        submenu: [
                            {
                                key: "/report/template/create",
                                title: "Create New Template",
                                path: "create",
                                icon: <Link to="/report/template/create" />,
                                component: <TemplateCreatePage />,
                                referKey: AuthKeys.TEMPLATE_EDIT,
                            },
                            {
                                key: "/report/template/edit/:id",
                                title: "Edit Template",
                                path: "edit/:id",
                                icon: <Link to="/report/template/edit/:id" />,
                                component: <TemplateCreatePage />,
                                referKey: AuthKeys.TEMPLATE_EDIT,
                            },
                            {
                                key: "/report/template/view/:id",
                                title: "View Template",
                                path: "view/:id",
                                icon: <Link to="/report/template/view/:id" />,
                                component: <ViewTemplatePage />,
                                referKey: AuthKeys.TEMPLATE_VIEW,
                            },
                        ],
                    },
                ],
            },
            {
                key: "/report/anyr",
                title: "Analysis Report",
                path: "",
                component: <></>,
                order: 2,
                showInNavBar: true,
                type: "group",
                submenu: [
                    {
                        key: "/report/averageSpread",
                        title: "Average Spread",
                        path: "averageSpread",
                        icon: <Link to="/report/averageSpread" />,
                        component: <AverageSpread />,
                        order: 3,
                        showInNavBar: true,
                    },
                    {
                        key: "/report/spreadanalysis",
                        title: "Spread Analysis",
                        path: "spreadanalysis",
                        icon: <Link to="/report/spreadanalysis" />,
                        component: <SpreadReportPage />,
                        order: 4,
                        showInNavBar: true,
                    },
                ],
            },
            {
                key: "/report/otrr",
                title: "Other Report",
                path: "",
                component: <></>,
                order: 3,
                showInNavBar: true,
                type: "group",
                submenu: [
                    {
                        key: "/report/commissionrecord",
                        title: "Commission Record",
                        path: "commissionrecord",
                        icon: <Link to="/report/commissionrecord" />,
                        component: <CommissionRecord />,
                        order: 5,
                        showInNavBar: true,
                    },
                    {
                        key: "/report/eodprice",
                        title: "EOD Price Report",
                        path: "eodprice",
                        icon: <Link to="/report/eodprice" />,
                        component: <EODPriceReportPage />,
                        order: 6,
                        showInNavBar: true,
                    },
                    {
                        key: "/report/linkedaccountquery",
                        title: "Linked Account Query",
                        path: "linkedaccountquery",
                        icon: <Link to="/report/linkedaccountquery" />,
                        component: <LinkedAccQuery />,
                        order: 7,
                        showInNavBar: true,
                    },
                    {
                        key: "/report/linkedaccountrecord",
                        title: "Linked Account Record",
                        path: "linkedaccountrecord",
                        icon: <Link to="/report/linkedaccountrecord" />,
                        component: <LinkedAccountRecord />,
                        order: 8,
                        showInNavBar: true,
                    },
                    {
                        key: "/report/tickReport",
                        title: "MT4/5 Daily Tick Report",
                        path: "tickReport",
                        icon: <Link to="/report/tickReport" />,
                        component: <DailyTickReport />,
                        order: 9,
                        showInNavBar: true,
                    },
                    {
                        key: "/report/negativeequityrecord",
                        title: "Negative Equity Record",
                        path: "negativeequityrecord",
                        icon: <Link to="/report/negativeequityrecord" />,
                        component: <NegativeEquityRecord />,
                        order: 10,
                        showInNavBar: true,
                    },
                ],
            },
        ],
    },
    {
        key: "/pricemonitor",
        title: "Price Monitor",
        path: "/pricemonitor",
        icon: <EuroCircleOutlined />,
        order: 4,
        showInNavBar: true,
        submenu: [
            {
                key: "/pricemonitor/alrms",
                title: "Alarms",
                path: "",
                component: <></>,
                order: 1,
                showInNavBar: true,
                type: "group",
                submenu: [
                    {
                        key: "/pricemonitor/pricealarm",
                        title: "Price Alarm Overview",
                        path: "pricealarm",
                        component: <PriceAlarm />,
                        icon: <Link to="/pricemonitor/pricealarm" />,
                        order: 1,
                        showInNavBar: true,
                    },
                    {
                        key: "/pricemonitor/priceanalysisalarm",
                        title: "Price Analysis Alarm",
                        path: "priceanalysisalarm",
                        component: <PriceAnalysisAlarm />,
                        icon: <Link to="/pricemonitor/priceanalysisalarm" />,
                        order: 2,
                        showInNavBar: true,
                    },
                    {
                        key: "/pricemonitor/priceoutage",
                        title: "Price Outage",
                        path: "priceoutage",
                        component: <PriceOutage />,
                        icon: <Link to="/pricemonitor/priceoutage" />,
                        order: 3,
                        showInNavBar: true,
                    },
                    {
                        key: "/pricemonitor/midbias",
                        title: "Mid Bias Alarm",
                        path: "midbias",
                        component: <MidBias />,
                        icon: <Link to="/pricemonitor/midbias" />,
                        order: 4,
                        showInNavBar: true,
                    },
                    {
                        key: "/pricemonitor/outofprice",
                        title: "Out of Price Alarm",
                        path: "outofprice",
                        component: <OutOfPriceAlarm />,
                        icon: <Link to="/pricemonitor/outofprice" />,
                        order: 5,
                        showInNavBar: true,
                    },
                    {
                        key: "/pricemonitor/spreadalarm",
                        title: "Spread Alarm",
                        path: "spreadalarm",
                        component: <PriceMonitorSpreadAlarm />,
                        icon: <Link to="/pricemonitor/spreadalarm" />,
                        order: 6,
                        showInNavBar: true,
                    },
                ],
            },
            {
                key: "/pricemonitor/cmprs",
                title: "Comparison",
                path: "",
                component: <></>,
                order: 2,
                showInNavBar: true,
                type: "group",
                submenu: [
                    {
                        key: "/pricemonitor/lppricecompare",
                        title: "LP Price Compare",
                        path: "lppricecompare",
                        component: <LPPriceCompare />,
                        icon: <Link to="/pricemonitor/lppricecompare" />,
                        order: 7,
                        showInNavBar: true,
                    },
                    {
                        key: "/pricemonitor/pricecompare",
                        title: "Price Compare",
                        path: "pricecompare",
                        component: <PriceCompare />,
                        icon: <Link to="/pricemonitor/pricecompare" />,
                        order: 8,
                        showInNavBar: true,
                    },
                ],
            },
        ],
    },
    {
        key: "/realtime",
        title: "Trade Monitor",
        path: "realtime",
        icon: <FundProjectionScreenOutlined />,
        order: 5,
        showInNavBar: true,
        submenu: [
            {
                key: "/realtime/decaychart",
                title: "Decay Analysis",
                path: "decaychart",
                icon: <Link to="/realtime/decaychart" />,
                component: <DecayChartSummary />,
                order: 1,
                showInNavBar: true,
                submenu: [
                    {
                        key: "/realtime/decaychart/ticket/:id",
                        title: "Ticket View",
                        path: "ticket/:id",
                        component: <DecayChartSummary />,
                        referKey: AuthKeys.DECAY_ANALYSIS_VIEW,
                    },
                ],
            },
            {
                key: "/realtime/hftReport",
                title: "HFT Report",
                path: "hftReport",
                icon: <Link to="/realtime/hftReport" />,
                component: <HFTReport />,
                order: 2,
                showInNavBar: true,
            },
            {
                key: "/realtime/trademonitor",
                title: "Monitoring Profile",
                path: "trademonitor",
                icon: <Link to="/realtime/trademonitor" />,
                component: <TradeMonitoringPage />,
                order: 3,
                showInNavBar: true,
                submenu: [
                    {
                        key: "/realtime/trademonitor/create",
                        title: "Create New Monitor Profile",
                        path: "create",
                        icon: <Link to="/realtime/trademonitor/create" />,
                        component: <TradeMonitoringCreateEditPage />,
                        referKey: AuthKeys.MONITOR_TRADE_EDIT,
                    },
                    {
                        key: "/realtime/trademonitor/edit",
                        title: "Edit Monitor Profile",
                        path: "edit",
                        icon: <Link to="/realtime/trademonitor/edit" />,
                        component: <TradeMonitoringCreateEditPage />,
                        referKey: AuthKeys.MONITOR_TRADE_EDIT,
                    },
                    {
                        key: "/realtime/trademonitor/launch/:id",
                        title: "Monitor Profile Panel",
                        path: "launch/:id",
                        icon: <Link to="/realtime/trademonitor/launch/:id" />,
                        component: <TradeMonitoringPanel />,
                        referKey: AuthKeys.MONITOR_TRADE_VIEW,
                    },
                ],
            },
            {
                key: "/realtime/peordercompare",
                title: "PE Order Compare",
                path: "peordercompare",
                icon: <Link to="/realtime/peordercompare" />,
                component: <PEOrderCompare />,
                order: 4,
                showInNavBar: true,
            },
            {
                key: "/realtime/toxicaccount",
                title: "Toxic Account",
                path: "toxicaccount",
                icon: <Link to="/realtime/toxicaccount" />,
                component: <PMIList />,
                order: 5,
                showInNavBar: true,
                submenu: [
                    {
                        key: "/realtime/toxicaccount/snapshot/:id",
                        title: "Snapshot View",
                        path: "snapshot/:id",
                        component: <ToxicAccountEventSnapshot />,
                        referKey: AuthKeys.TOXIC_ACCOUNT_VIEW,
                    },
                ],
            },
            {
                key: "/realtime/syndicateTrading",
                title: "Syndicate Trading",
                path: "syndicateTrading",
                icon: <Link to="/realtime/syndicateTrading" />,
                component: <SyndicateTrading />,
                order: 6,
                showInNavBar: true,
            },
            {
                key: "/realtime/hisevt",
                title: "Historical Event",
                path: "",
                component: <></>,
                order: 7,
                showInNavBar: true,
                type: "group",
                submenu: [
                    {
                        key: "/realtime/historicaleventbatches",
                        title: "Event Batches",
                        path: "historicaleventbatches",
                        icon: <Link to="/realtime/historicaleventbatches" />,
                        component: <HistoricalEventPage />,
                        order: 8,
                        showInNavBar: true,
                        submenu: [],
                    },
                    {
                        key: "/realtime/historicaleventsummary",
                        title: "Event Summary",
                        path: "historicaleventsummary",
                        icon: <Link to="/realtime/historicaleventsummary" />,
                        component: <SummaryHistoricalEvent />,
                        order: 9,
                        showInNavBar: true,
                        submenu: [],
                    },
                ],
            },
        ],
    },
    {
        key: "/analysis",
        title: "Analysis",
        path: "/analysis",
        icon: <RadarChartOutlined />,
        order: 6,
        showInNavBar: true,
        submenu: [
            {
                key: "/analysis/premarketAccountModel",
                title: "Account Pre-Market Model",
                path: "premarketAccountModel",
                component: <PreMarketAccModel />,
                icon: <Link to="/analysis/premarketAccountModel" />,
                order: 1,
                showInNavBar: true,
                submenu: [],
            },
            {
                key: "/analysis/clientanalysis",
                title: "Client Analysis",
                path: "clientanalysis",
                icon: <Link to="/analysis/clientanalysis" />,
                component: <ClientAnalysis />,
                order: 2,
                showInNavBar: true,
            },
            {
                key: "/analysis/problematicClients",
                title: "Problematic Clients",
                path: "problematicClients",
                component: <ProblematicClients />,
                icon: <Link to="/analysis/problematicClients" />,
                order: 3,
                showInNavBar: true,
                submenu: [],
            },
            {
                key: "/analysis/toxicClientCheck",
                title: "Toxic Client Check",
                path: "toxicClientCheck",
                icon: <Link to="/analysis/toxicClientCheck" />,
                component: <ToxicClientCheck />,
                order: 4,
                showInNavBar: true,
            },
            {
                key: "/analysis/withdrawalaudit",
                title: "Withdrawal Audit",
                path: "withdrawalaudit",
                icon: <Link to="/analysis/withdrawalaudit" />,
                component: <WithdrawalAudit />,
                order: 5,
                submenu: [
                    {
                        key: "/analysis/withdrawalaudit?tab=logs",
                        title: "API Logs",
                        path: "?tab=logs",
                        icon: <Link to="/analysis/withdrawalaudit?tab=logs" />,
                        component: <APILogs />,
                        referKey: AuthKeys.WITHDRAWAL_AUDIT_VIEW_API_LOGS,
                    },
                ],
                showInNavBar: true,
            },
        ],
    },
    {
        key: "/lpmonitor",
        title: "LP Monitor",
        path: "/lpmonitor",
        icon: <FundViewOutlined />,
        order: 7,
        showInNavBar: true,
        submenu: [
            {
                key: "/lpmonitor/summary",
                title: "Summary",
                path: "summary",
                component: <LPMonitorSummary />,
                icon: <Link to="/lpmonitor/summary" />,
                order: 1,
                showInNavBar: true,
                submenu: [
                    {
                        key: "/lpmonitor/summary/lp360/:id",
                        title: "LP 360",
                        path: "lp360/:id",
                        component: <LP360Profile />,
                        icon: <Link to="/lpmonitor/summary/lp360/:id" />,
                        referKey: AuthKeys.LP360_VIEW,
                    },
                ],
            },
            {
                key: "/lpmonitor/lp360",
                title: "LP 360",
                path: "lp360",
                component: <LP360Profile />,
                icon: <Link to="/lpmonitor/lp360" />,
                order: 2,
                showInNavBar: true,
            },
            {
                key: "/lpmonitor/alarm",
                title: "Alarm",
                path: "alarm",
                component: <LPMonitorAlarmListing />,
                icon: <Link to="/lpmonitor/alarm" />,
                order: 3,
                showInNavBar: true,
            },
        ],
    },
    {
        key: "/intradaymonitor",
        title: "Intraday Monitor",
        path: "/intradaymonitor",
        icon: <FundViewOutlined />,
        order: 8,
        showInNavBar: true,
        submenu: [
            {
                key: "/intradaymonitor/summary",
                title: "Summary",
                path: "summary",
                component: <IntradayMonitorSummary />,
                icon: <Link to="/intradaymonitor/summary" />,
                order: 1,
                showInNavBar: true,
                submenu: [],
            },
            {
                key: "/intradaymonitor/search",
                title: "Search",
                path: "search",
                component: <IntradayMonitorSearch />,
                icon: <Link to="/intradaymonitor/search" />,
                order: 2,
                showInNavBar: true,
                submenu: [],
            },
            {
                key: "/intradaymonitor/alarm",
                title: "Alarm",
                path: "alarm",
                component: <IntradayMonitorAlarmListing />,
                icon: <Link to="/intradaymonitor/alarm" />,
                order: 3,
                showInNavBar: true,
                submenu: [
                    {
                        key: "/intradaymonitor/alarm/:severity",
                        title: "Alarm",
                        path: ":severity",
                        component: <IntradayMonitorAlarmListing />,
                        referKey: AuthKeys.INTRADAY_ALARM_VIEW,
                    },
                    {
                        key: "/intradaymonitor/alarm/:severity/:brand",
                        title: "Alarm",
                        path: ":severity/:brand",
                        component: <IntradayMonitorAlarmListing />,
                        referKey: AuthKeys.INTRADAY_ALARM_VIEW,
                    },
                ],
            },
        ],
    },
    {
        key: "/systemmonitor",
        title: "System Monitor",
        path: "/systemmonitor",
        icon: <ApartmentOutlined />,
        order: 9,
        showInNavBar: true,
        submenu: [
            {
                key: "/systemmonitor/overview",
                title: "Overview",
                path: "overview",
                component: <SystemMonitorOverview />,
                icon: <Link to="/systemmonitor/overview" />,
                order: 1,
                showInNavBar: true,
            },
            {
                key: "/systemmonitor/datacenter",
                title: "Data Center",
                path: "datacenter",
                component: <DataCenter />,
                icon: <Link to="/systemmonitor/datacenter" />,
                order: 2,
                showInNavBar: true,
            },
            {
                key: "/systemmonitor/issueslog",
                title: "Issues Log",
                path: "issueslog",
                component: <IssuesLog />,
                icon: <Link to="/systemmonitor/issueslog" />,
                order: 3,
                showInNavBar: true,
            },
            {
                key: "/systemmonitor/metatrader",
                title: "Meta Trader Server Status",
                path: "metatrader",
                component: <MetaTrader />,
                icon: <Link to="/systemmonitor/metatrader" />,
                order: 4,
                showInNavBar: true,
            },
            {
                key: "/systemmonitor/rejectorder",
                title: "Reject Order",
                path: "rejectorder",
                component: <RejectOrder />,
                icon: <Link to="/systemmonitor/rejectorder" />,
                order: 5,
                showInNavBar: true,
            },
            {
                key: "/systemmonitor/serverperformance",
                title: "Server Performance",
                path: "serverperformance",
                component: <ServerPerformance />,
                icon: <Link to="/systemmonitor/serverperformance" />,
                order: 6,
                showInNavBar: true,
            },
            {
                key: "/systemmonitor/statusmonitor",
                title: "Status Monitor",
                path: "statusmonitor",
                component: <StatusMonitor />,
                icon: <Link to="/systemmonitor/statusmonitor" />,
                order: 7,
                showInNavBar: true,
            },
        ],
    },
    {
        key: "/admintools",
        title: "Admin/RA Tools",
        path: "/admintools",
        icon: <ToolOutlined />,
        order: 10,
        showInNavBar: true,
        submenu: [
            {
                key: "/admintools/hubfor",
                title: "Hub Failover",
                path: "",
                component: <></>,
                order: 1,
                showInNavBar: true,
                type: "group",
                submenu: [
                    {
                        key: "/admintools/hubStatusDashboard",
                        title: "Hub Status Dashboard",
                        path: "hubStatusDashboard",
                        component: <HubStatusDashboard />,
                        icon: <Link to="/admintools/hubStatusDashboard" />,
                        order: 1,
                        showInNavBar: true,
                        submenu: [],
                    },
                    {
                        key: "/admintools/hubSwitcher",
                        title: "Hub Switcher",
                        path: "hubSwitcher",
                        component: <HubSwitcher />,
                        icon: <Link to="/admintools/hubSwitcher" />,
                        order: 2,
                        showInNavBar: true,
                        submenu: [
                            {
                                key: "/admintools/hubSwitcher/preview",
                                title: "Preview",
                                path: "preview",
                                icon: <Link to="/admintools/hubSwitcher/preview" />,
                                component: <HubSwitcherPreview />,
                                referKey: AuthKeys.ADMIN_TOOLS_HUB_SWITCHER_EDIT,
                            },
                            {
                                key: "/admintools/hubSwitcher/preview/:id",
                                title: "Preview",
                                path: "preview/:id",
                                icon: <Link to="/admintools/hubSwitcher/preview/:id" />,
                                component: <HubSwitcherPreview />,
                                referKey: AuthKeys.ADMIN_TOOLS_HUB_SWITCHER_EDIT,
                            },
                        ],
                    },
                ],
            },
            {
                key: "/admintools/adntls",
                title: "Admin Tools",
                path: "",
                component: <></>,
                order: 2,
                showInNavBar: true,
                type: "group",
                submenu: [
                    {
                        key: "/admintools/configdiff",
                        title: "Config Diff",
                        path: "configdiff",
                        component: <ConfigDiff />,
                        icon: <Link to="/admintools/configdiff" />,
                        order: 5,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/configdiffrecord",
                        title: "Config Diff Record",
                        path: "configdiffrecord",
                        component: <ConfigDiffRecordPage />,
                        icon: <Link to="/admintools/configdiffrecord" />,
                        order: 6,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/credittobalanceupload",
                        title: "Credit To Balance Upload",
                        path: "credittobalanceupload",
                        component: <CreditToBalanceUpload />,
                        icon: <Link to="/admintools/credittobalanceupload" />,
                        order: 7,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/horidiffrecords",
                        title: "Hori Diff Records",
                        path: "horidiffrecords",
                        component: <HoriDifferentRecordsPage />,
                        icon: <Link to="/admintools/horidiffrecords" />,
                        order: 8,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/mtClientSecurity",
                        title: "MT Client Security",
                        path: "mtClientSecurity",
                        component: <MTClientSecurity />,
                        icon: <Link to="/admintools/mtClientSecurity" />,
                        order: 9,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/orderRoutingRecord",
                        title: "Order Routing Record",
                        path: "orderRoutingRecord",
                        component: <OrderRoutingRecord />,
                        icon: <Link to="/admintools/orderRoutingRecord" />,
                        order: 10,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/rollover",
                        title: "Roll Over Tool",
                        path: "rollover",
                        component: <RollOverTool />,
                        icon: <Link to="/admintools/rollover" />,
                        order: 11,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/serverarchivingtool",
                        title: "Server Archiving Tool",
                        path: "serverarchivingtool",
                        component: <ServerArchivingTool />,
                        icon: <Link to="/admintools/serverarchivingtool" />,
                        order: 12,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/statusconfigurationrestart",
                        title: "Status Configuration Restart",
                        path: "statusconfigurationrestart",
                        component: <StatusConfigurationRestart />,
                        icon: <Link to="/admintools/statusconfigurationrestart" />,
                        order: 13,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/symbolleverage",
                        title: "Symbol Leverage Tool",
                        path: "symbolLeverage",
                        component: <SymbolLeverageTool />,
                        icon: <Link to="/admintools/symbolleverage" />,
                        order: 14,
                        showInNavBar: true,
                        submenu: [
                            {
                                key: "/admintools/symbolleverage/edit/:id",
                                title: "Edit Batch",
                                path: "edit/:id",
                                icon: <Link to="/admintools/symbolleverage/edit/:id" />,
                                component: <SymbolLeverageStep />,
                                referKey: AuthKeys.RISK_TOOLS_SYMBOL_LEVERAGE_EDIT,
                            },
                        ],
                    },
                    {
                        key: "/admintools/ticket",
                        title: "Ticket Restoration",
                        path: "ticket",
                        component: <TicketRestoration />,
                        icon: <Link to="/admintools/ticket" />,
                        order: 15,
                        showInNavBar: true,
                        submenu: [
                            {
                                key: "/admintools/ticket/calculate",
                                title: "Calculating Stop Out",
                                path: "calculate",
                                icon: <Link to="/admintools/ticket/calculate" />,
                                component: <CalculateStopOut />,
                                referKey: AuthKeys.ADMIN_TOOLS_TICKET_RESTORATION_EDIT,
                            },
                        ],
                    },
                ],
            },
            {
                key: "/admintools/rsktls",
                title: "Risk Tools",
                path: "",
                component: <></>,
                order: 3,
                showInNavBar: true,
                type: "group",
                submenu: [
                    {
                        key: "/admintools/balanceupdate",
                        title: "Balance Update",
                        path: "balanceupdate",
                        component: <BalanceUpdatePage />,
                        icon: <Link to="/admintools/balanceupdate" />,
                        order: 16,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/cid",
                        title: "CID",
                        path: "cid",
                        component: <CID />,
                        icon: <Link to="/admintools/cid" />,
                        order: 17,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/fundingfeeprocess",
                        title: "Funding Fee Process",
                        path: "fundingfeeprocess",
                        component: <FundingFeeProcessPage />,
                        icon: <Link to="/admintools/fundingfeeprocess" />,
                        order: 18,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/grouptransfertool",
                        title: "Group Transfer Tool",
                        path: "grouptransfertool",
                        component: <GroupTransferTool />,
                        icon: <Link to="/admintools/grouptransfertool" />,
                        order: 20,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/hedgetool",
                        title: "Hedge Tool",
                        path: "hedgetool",
                        component: <HedgeToolsPage />,
                        icon: <Link to="/admintools/hedgetool" />,
                        order: 21,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/hubtohub",
                        title: "Hub to Hub Tool",
                        path: "hubtohub",
                        component: <HubToHubTool />,
                        icon: <Link to="/admintools/hubtohub" />,
                        order: 22,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/readOnly",
                        title: "Read Only",
                        path: "readOnly",
                        component: <ReadOnlyPage />,
                        icon: <Link to="/admintools/readOnly" />,
                        order: 23,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/symbolswapupload",
                        title: "Symbol Swap Upload",
                        path: "symbolswapupload",
                        component: <SymbolSwapUpload />,
                        icon: <Link to="/admintools/symbolswapupload" />,
                        order: 24,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/transactionorderchecker",
                        title: "Transaction Order Checker",
                        path: "transactionorderchecker",
                        component: <TransactionOrderChecker />,
                        icon: <Link to="/admintools/transactionorderchecker" />,
                        order: 25,
                        showInNavBar: true,
                    },
                ],
            },
            {
                key: "/admintools/pdtls",
                title: "PD Tools",
                path: "",
                component: <></>,
                order: 4,
                showInNavBar: true,
                type: "group",
                submenu: [
                    {
                        key: "/admintools/adminfeeprocess",
                        title: "Admin Fee Process",
                        path: "adminfeeprocess",
                        component: <AdminFeeProcess />,
                        icon: <Link to="/admintools/adminfeeprocess" />,
                        order: 26,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/cadividendmapupload",
                        title: "CA Dividend Map Upload",
                        path: "cadividendmapupload",
                        component: <CADividendMapUpload />,
                        icon: <Link to="/admintools/cadividendmapupload" />,
                        order: 27,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/cadividendseparateprocess",
                        title: "CA Dividend Separate Process",
                        path: "cadividendseparateprocess",
                        component: <CADividendSeparateProcess />,
                        icon: <Link to="/admintools/cadividendseparateprocess" />,
                        order: 28,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/dividendrecord",
                        title: "Dividend Record",
                        path: "dividendrecord",
                        component: <DividendRecordProcessPage />,
                        icon: <Link to="/admintools/dividendrecord" />,
                        order: 29,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/dividendupload",
                        title: "Dividend Upload",
                        path: "dividendupload",
                        component: <DividendUpload />,
                        icon: <Link to="/admintools/dividendupload" />,
                        order: 30,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/swapchargingtool",
                        title: "Swap Charging Tool",
                        path: "swapchargingtool",
                        component: <SwapChargingTool />,
                        icon: <Link to="/admintools/swapchargingtool" />,
                        order: 31,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/swapchargingtoolprocess",
                        title: "Swap Charging Tool Process",
                        path: "swapchargingtoolprocess",
                        component: <SwapChargingToolProcess />,
                        icon: <Link to="/admintools/swapchargingtoolprocess" />,
                        order: 32,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/weekendswapsrecord",
                        title: "Weekend Swaps Record",
                        path: "weekendswapsrecord",
                        component: <WeekendSwapsRecord />,
                        icon: <Link to="/admintools/weekendswapsrecord" />,
                        order: 33,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/spreadgooglesheet",
                        title: "Spread Google Sheet",
                        path: "spreadgooglesheet",
                        component: <SpreadGoogleSheetPage />,
                        icon: <Link to="/admintools/spreadgooglesheet" />,
                        order: 34,
                        showInNavBar: true,
                    },
                ],
            },
            {
                key: "/admintools/mgttls",
                title: "Management Tools",
                path: "",
                component: <></>,
                order: 5,
                showInNavBar: true,
                type: "group",
                submenu: [
                    {
                        key: "/admintools/archiveaccounts",
                        title: "Archive Accounts",
                        path: "archiveaccounts",
                        component: <ArchiveAccounts />,
                        icon: <Link to="/admintools/archiveaccounts" />,
                        order: 35,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/asicgroupchange",
                        title: "ASIC Group Change",
                        path: "asicgroupchange",
                        component: <AsicGroupChange />,
                        icon: <Link to="/admintools/asicgroupchange" />,
                        order: 36,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/holidayupdater",
                        title: "Holiday Updater",
                        path: "holidayupdater",
                        component: <HolidayUpdater />,
                        icon: <Link to="/admintools/holidayupdater" />,
                        order: 38,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/swapratesupdater",
                        title: "Swap Rates Updater",
                        path: "swapratesupdater",
                        component: <SwapRatesUpdater />,
                        icon: <Link to="/admintools/swapratesupdater" />,
                        order: 41,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/symboltradinghourupdate",
                        title: "Symbol Trading Hour Update",
                        path: "symboltradinghourupdate",
                        component: <SymbolTradingHourUpdate />,
                        icon: <Link to="/admintools/symboltradinghourupdate" />,
                        order: 42,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/unarchiveaccounts",
                        title: "Unarchive Accounts",
                        path: "unarchiveaccounts",
                        component: <UnarchiveAccounts />,
                        icon: <Link to="/admintools/unarchiveaccounts" />,
                        order: 43,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/userupdater",
                        title: "User Updater",
                        path: "userupdater",
                        component: <UserUpdater />,
                        icon: <Link to="/admintools/userupdater" />,
                        order: 44,
                        showInNavBar: true,
                    },
                ],
            },
        ],
    },
    {
        key: "/siteadmin",
        title: "Settings",
        path: "/siteadmin",
        icon: <SettingOutlined />,
        order: 11,
        showInNavBar: false,
        submenu: [
            {
                key: "/siteadmin/appconfig",
                title: "App Configuration",
                path: "appconfig",
                component: <AppConfiguration />,
                order: 5,
                icon: <Link to="/siteadmin/appconfig" />,
                showInNavBar: false,
                submenu: [
                    {
                        key: "/siteadmin/appconfig/usermanualconfig",
                        title: "User Manual Configuration",
                        path: "usermanualconfig",
                        component: <UserManualListing setOpen={() => { }} />,
                        referKey: AuthKeys.APP_CONFIGURATION_EDIT,
                    },
                ],
            },
        ],
    },
    {
        key: "/myaccount",
        title: "My Account",
        path: "/myaccount",
        component: <MyAccountPage />,
        icon: <Link to="/myaccount" />,
        submenu: [
            {
                key: "/myaccount/edit",
                title: "Edit Account",
                path: "edit",
                component: <MyAccountEditPage />,
                icon: <Link to="/myaccount/edit" />,
                shouldShow: true,
            },
        ],
        shouldShow: true,
    },
    {
        key: "/notifications",
        title: "Notification",
        path: "/notifications",
        component: <NotificationPage />,
        icon: <Link to="/notifications" />,
        submenu: [
            {
                key: "/myaccount/edit",
                title: "Edit Account",
                path: "edit",
                component: <MyAccountEditPage />,
                icon: <Link to="/myaccount/edit" />,
            },
        ],
        shouldShow: true,
    },
    {
        key: "/activitylogs",
        title: "Activity Logs",
        path: "/activitylogs",
        component: <ActivityLogsPage />,
        icon: <Link to="/activitylogs" />,
        shouldShow: true,
    },
    {
        key: "/settingspanel",
        title: "Settings Panel",
        path: "/settingspanel",
        component: <CentralizedSettingsPanel />,
        icon: <Link to="/settingspanel" />,
        shouldShow: true,
        submenu: [
            {
                key: "/settingspanel/price-alarm-color-settings",
                title: "Price Alarm Color Settings",
                component: <PriceAlarmColorSettingPage />,
                referKey: AuthKeys.CENTRALIZED_SETTINGS_PRICE_ALARM_COLOR_SETTINGS_VIEW,
                path: "price-alarm-color-settings",
            },
            {
                key: "/settingspanel/price-alarm-settings",
                title: "Price Alarm Settings",
                component: <PriceAlarmSettings2 />,
                referKey: AuthKeys.CENTRALIZED_SETTINGS_PRICE_ALARM_SETTINGS_VIEW,
                path: "price-alarm-settings",
            },
            {
                key: "/settingspanel/price-analysis-settings",
                title: "Price Analysis Settings",
                component: <PriceAnalysisSettingPage />,
                referKey: AuthKeys.CENTRALIZED_SETTINGS_PRICE_ANALYSIS_SETTINGS_VIEW,
                path: "price-analysis-settings",
            },
            {
                key: "/settingspanel/price-backend-opt",
                title: "Price Backend Opt",
                path: "price-backend-opt",
                component: <PriceBackendOptSettingsPage />,
                referKey: AuthKeys.CENTRALIZED_SETTINGS_PRICE_BACKEND_OPT_VIEW,
            },
            {
                key: "/settingspanel/price-outage-settings",
                title: "Out Of Price Settings",
                component: <OutOfPriceSettingsPage />,
                referKey: AuthKeys.CENTRALIZED_SETTINGS_OUT_OF_PRICE_SETTINGS_VIEW,
                path: "out-of-price-settings",
            },
            {
                key: "/settingspanel/mid-bias-alarm-settings",
                title: "Mid Bias Alarm Settings",
                component: <MidBiasAlarmSettingsPage />,
                referKey: AuthKeys.CENTRALIZED_SETTINGS_MID_BIAS_ALARM_SETTINGS_VIEW,
                path: "mid-bias-alarm-settings",
            },
            {
                key: "/settingspanel/mid-price-settings",
                title: "Mid Price Settings",
                component: <MidPriceSetting />,
                referKey: AuthKeys.CENTRALIZED_SETTINGS_MID_PRICE_SETTINGS_VIEW,
                path: "mid-price-settings",
            },
            {
                key: "/settingspanel/spread-alarm-settings",
                title: "Spread Alarm Settings",
                component: <SpreadAlarmSettingPage />,
                referKey: AuthKeys.CENTRALIZED_SETTINGS_SPREAD_ALARM_SETTINGS_VIEW,
                path: "spread-alarm-settings",
            },
            {
                key: "/settingspanel/data-center-settings",
                title: "Data Center Settings",
                component: <DataCenterSettings />,
                referKey: AuthKeys.CENTRALIZED_SETTINGS_DATA_CENTER_SETTINGS_VIEW,
                path: "data-center-settings",
            },
            {
                key: "/settingspanel/issues-log-settings",
                title: "Issues Log Settings",
                component: <IssuesLogSettingsPanel />,
                referKey: AuthKeys.CENTRALIZED_SETTINGS_ISSUES_LOG_SETTINGS_VIEW,
                path: "issues-log-settings",
            },
            {
                key: "/settingspanel/reject-order-settings",
                title: "Reject Order Settings",
                component: <TabRejectOrderSettings />,
                referKey: AuthKeys.CENTRALIZED_SETTINGS_REJECT_ORDER_SETTINGS_VIEW,
                path: "reject-order-settings",
            },
            {
                key: "/settingspanel/server-performance-settings",
                title: "Server Performance Settings",
                component: <TabServerPerformanceSettings />,
                referKey: AuthKeys.CENTRALIZED_SETTINGS_SERVER_PERFORMANCE_SETTINGS_VIEW,
                path: "server-performance-settings",
            },
            {
                key: "/settingspanel/hft-report-settings",
                title: "HFT Report Settings",
                component: <HFTReportSettingsPanel />,
                referKey: AuthKeys.CENTRALIZED_SETTINGS_HFT_REPORT_SETTINGS_VIEW,
                path: "hft-report-settings",
            },
            {
                key: "/settingspanel/mr-alarm-settings",
                title: "MR Alarm Settings",
                component: <LPMonitorAlarmConfiguration />,
                referKey: AuthKeys.CENTRALIZED_SETTINGS_MR_ALARM_SETTINGS_VIEW,
                path: "mr-alarm-settings",
            },
            {
                key: "/settingspanel/mr-group-settings",
                title: "MR Group Settings",
                component: <MRGroupingSettingsPage />,
                referKey: AuthKeys.CENTRALIZED_SETTINGS_MR_GROUP_SETTINGS_VIEW,
                path: "mr-group-settings",
            },
            {
                key: "/settingspanel/intraday-alarm-settings",
                title: "Intraday Alarm Settings",
                component: <IntradayMonitorAlarmConfiguration />,
                referKey: AuthKeys.CENTRALIZED_SETTINGS_INTRADAY_ALARM_SETTINGS_VIEW,
                path: "intraday-alarm-settings",
            },
            {
                key: "/settingspanel/brand-management",
                title: "Brand Management",
                component: <BrandListingPage />,
                referKey: AuthKeys.CENTRALIZED_SETTINGS_BRAND_MANAGEMENT_VIEW,
                path: "brand-management",
            },
            {
                key: "/settingspanel/bridge-config-api-configuration",
                title: "Bridge Config Api Configuration",
                component: <BridgeConfigApiPage />,
                referKey: AuthKeys.CENTRALIZED_SETTINGS_BRIDGE_CONDIF_API_CONFIGURATION_VIEW,
                path: "bridge-config-api-configuration",
            },
            {
                key: "/settingspanel/bridge-management",
                title: "Bridge Management",
                component: <BridgeSettingPage />,
                referKey: AuthKeys.CENTRALIZED_SETTINGS_BRIDGE_MANAGEMENT_VIEW,
                path: "bridge-management",
            },
            {
                key: "/settingspanel/data-center-management",
                title: "Data Center Management",
                component: <DataCenterPageSettings />,
                referKey: AuthKeys.CENTRALIZED_SETTINGS_DATA_CENTER_MANAGEMENT_VIEW,
                path: "data-center-management",
            },
            {
                key: "/settingspanel/external-system-management",
                title: "External System Management",
                component: <ExternalSystemPage />,
                referKey: AuthKeys.CENTRALIZED_SETTINGS_EXTERNAL_SYSTEM_MANAGEMENT_VIEW,
                path: "external-system-management",
            },
            {
                key: "/settingspanel/group-purpose-management",
                title: "Group Purpose Management",
                component: <GroupPurposeListPage />,
                referKey: AuthKeys.CENTRALIZED_SETTINGS_GROUP_PURPOSE_MANAGEMENT_VIEW,
                path: "group-purpose-management",
            },
            {
                key: "/settingspanel/notification-channel-management",
                title: "Notification Channel Management",
                component: <NotificationChannelManagement />,
                referKey: AuthKeys.CENTRALIZED_SETTINGS_NOTIFICATION_CHANNEL_MANAGEMENT_VIEW,
                path: "notification-channel-management",
            },
            {
                key: "/settingspanel/server-management",
                title: "Server Management",
                component: <ServerPageV2 />,
                referKey: AuthKeys.CENTRALIZED_SETTINGS_SERVER_MANAGEMENT_VIEW,
                path: "server-management",
            },
            {
                key: "/settingspanel/clean-symbol-management",
                title: "Clean Symbol Management",
                component: <CleanSymbolList />,
                referKey: AuthKeys.CENTRALIZED_SETTINGS_CLEAN_SYMBOL_MANAGEMENT_VIEW,
                path: "clean-symbol-management",
            },
            {
                key: "/settingspanel/mt-symbol-management",
                title: "MT Symbol Management",
                component: <SymbolMappingPage />,
                referKey: AuthKeys.CENTRALIZED_SETTINGS_MT_SYMBOL_MANAGEMENT_VIEW,
                path: "mt-symbol-management",
            },
            {
                key: "/settingspanel/symbol-asset-type-management",
                title: "Symbol Asset Type Management",
                component: <SymbolAssetTypeList />,
                referKey: AuthKeys.CENTRALIZED_SETTINGS_SYMBOL_ASSET_TYPE_MANAGEMENT_VIEW,
                path: "symbol-asset-type-management",
            },
            {
                key: "/settingspanel/symbol-management",
                title: "Symbol Management",
                component: <SymbolInfo />,
                referKey: AuthKeys.CENTRALIZED_SETTINGS_SYMBOL_MANAGEMENT_VIEW,
                path: "symbol-management",
            },
            {
                key: "/settingspanel/commission-settings",
                title: "Commission Settings",
                component: <BrandServerCommission />,
                referKey: AuthKeys.CENTRALIZED_SETTINGS_COMMISSION_SETTINGS_VIEW,
                path: "commission-settings",
            },
            {
                key: "/settingspanel/feature-settings",
                title: "Feature Settings",
                component: <FeatureSettingsPage />,
                referKey: AuthKeys.CENTRALIZED_SETTINGS_FEATURE_SETTINGS_VIEW,
                path: "feature-settings",
            },
            {
                key: "/settingspanel/users-management",
                title: "Users Management",
                component: <UsersManagementTab />,
                referKey: AuthKeys.CENTRALIZED_SETTINGS_USER_MANAGEMENT_VIEW,
                path: "users-management",
            },
            {
                key: "/settingspanel/role-and-permission",
                title: "Role & Permission",
                component: <RolePermissionPage />,
                referKey: AuthKeys.CENTRALIZED_SETTINGS_ROLE_AND_PERMISSION_VIEW,
                path: "role-and-permission",
            },
            {
                key: "/settingspanel/menu-management",
                title: "Menu Management",
                component: <MenuMangement />,
                referKey: AuthKeys.CENTRALIZED_SETTINGS_MENU_MANAGEMENT_VIEW,
                path: "menu-management",
            },
            {
                key: "/settingspanel/linked-account-report-settings",
                title: "Linked Account Report Settings",
                component: <SettingsTab />,
                referKey: AuthKeys.CENTRALIZED_SETTINGS_LINKED_ACCOUNT_REPORT_SETTINGS_VIEW,
                path: "linked-account-report-settings",
            },
            {
                key: "/settingspanel/job-schedulers",
                title: "Job Schedulers",
                component: <JobSchedulers />,
                referKey: AuthKeys.CENTRALIZED_SETTINGS_JOB_SCHEDULER_VIEW,
                path: "job-schedulers",
            },
            {
                key: "/settingspanel/toxic-client-settings",
                title: "Toxic Client Settings",
                component: <ToxicClientSettingsPage />,
                referKey: AuthKeys.CENTRALIZED_SETTINGS_TOXIC_CLIENT_SETTINGS_VIEW,
                path: "toxic-client-settings",
            },
            {
                key: "/settingspanel/admin-fee-settings",
                title: "Admin Fee Settings",
                component: <AdminFeeSettings />,
                referKey: AuthKeys.CENTRALIZED_SETTINGS_ADMIN_FEE_SETTINGS_VIEW,
                path: "admin-fee-settings",
            },
            {
                key: "/settingspanel/cid-settings",
                title: "CID Settings",
                component: <CIDSettingsPage />,
                referKey: AuthKeys.CENTRALIZED_SETTINGS_CID_SETTINGS_VIEW,
                path: "cid-settings",
            },
            {
                key: "/settingspanel/funding-fee-settings",
                title: "Funding Fee Settings",
                component: <FundingFeeSettingPage />,
                referKey: AuthKeys.CENTRALIZED_SETTINGS_FUNDING_FEE_SETTINGS_VIEW,
                path: "funding-fee-settings",
            },
            {
                key: "/settingspanel/group-transfer-tool-settings",
                title: "Group Transfer Tool Settings",
                component: <GroupTransferSettingsPage />,
                referKey: AuthKeys.CENTRALIZED_SETTINGS_GROUP_TRANSFER_TOOL_SETTINGS_VIEW,
                path: "group-transfer-tool-settings",
            },
            {
                key: "/settingspanel/hub-failover-configuration",
                title: "Hub Failover Configuration",
                component: <HubConfigurationPage />,
                referKey: AuthKeys.CENTRALIZED_SETTINGS_HUB_FAILOVER_CONFIGURATION_VIEW,
                path: "hub-failover-configuration",
            },
        ],
    },
    {
        key: "all",
        title: "Page Not Found",
        path: "*",
        component: <NotFoundPage />,
    },
];

export const public_route: routeProps[] = [
    {
        key: "/login",
        title: "Login",
        path: "/login",
        component: <Login />,
        icon: <Link to="/login" />,
    },
    {
        key: "/development",
        title: "Development",
        path: "/development",
        component: <Development />,
        icon: <Link to="/development" />,
    },
    {
        key: "/ssoredirection",
        title: "SSO Redirection",
        path: "/ssoredirection",
        component: <SSORedirection />,
    },
    {
        key: "/ssolocahost",
        title: "SSO Localhost Redirection",
        path: "/ssolocahost",
        component: <SSOLocalhostMessager />,
    },
    {
        key: "/forbidden",
        title: "Forbidden",
        path: "/forbidden",
        component: <ForbiddenPage />,
    },
    {
        key: "/resetpassword",
        title: "Reset Password",
        path: "/resetpassword",
        component: <ResetPasswordPage />,
        icon: <Link to="/resetpassword" />,
    },
];

export const AppRoute = () => {
    const isLoggedIn = userInfo();
    const routeHelper = new RouteHelper(auth_route);

    return (
        <Routes>
            {isLoggedIn ? (
                <>
                    <Route path={"/"} element={<AuthLayout />}>
                        <Route index element={<DashboardPage />} />
                        {routeHelper.getRoutes()}
                        <Route key={"/system"} path={"/system"} element={<WrapperLayout />}>
                            <Route key={"/system/usermanual"} path={"usermanual"} element={<UserManualView setOpen={() => { }} />} />
                        </Route>
                    </Route>
                </>
            ) : (
                <Route key="*" path="*" element={<Login />} />
            )}
            {public_route.map(x => {
                return <Route key={x.key} path={x.path} element={x.component} />;
            })}
        </Routes>
    );
};

export default AppRoute;
