import TabContent from "../../SystemMonitor/components/TabContent";
import useRCMidBias from "../../../hooks/useRCMidBias";
import { Space, Typography } from "antd";
import TimeDiff from "../../SystemMonitor/components/TimeDiff";
import MidBiasTable from "./MidBiasTable";

const { Title } = Typography;

const TabUnsolved = () => {
    const { isLoading, refetchRcMidBias, dataUpdatedAt, shouldPlayMidBiasAlarm, massagedMidBias } = useRCMidBias({
        duration: "1day",
    });

    return (
        <TabContent>
            <Space direction="vertical" style={{ padding: "1rem", width: "100%" }}>
                <div style={{ display: "flex", alignItems: "center", marginBottom: "1rem", gap: 16 }}>
                    <Space style={{ alignItems: "center" }} wrap>
                        <Title level={2} style={{ padding: 0, margin: 0 }}>
                            Mid Bias Unsolved
                        </Title>
                        <TimeDiff timestamp={dataUpdatedAt} refetch={refetchRcMidBias} isFetching={isLoading} rounded />
                    </Space>
                </div>

                <MidBiasTable data={massagedMidBias} dateRange="1440" refetch={refetchRcMidBias} />
            </Space>
        </TabContent>
    );
};

export default TabUnsolved;
