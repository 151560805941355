import FlexiDataTable from "@/components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "@/constants";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps, KeyValuePair } from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { DTColProps, ErrorMessageHandler } from "@/utils/Common";
import { useState, useEffect } from "react";
import { FileSearchOutlined } from "@ant-design/icons";
import PriceAnalysisGroupSettingModal, { PriceAnalysisGroupSettingModalCallbackKey } from "./CreateEditModal";
import { SortList } from "@/utils/array";
import { DefaultIfEmpty, objectRemoveProps } from "@/utils/object";
import { Button, message, Modal, Popover } from "antd";
import ShowServerComponents from "./showServersComponent";

export interface PriceAnalysisGroupSettingsProps { }

export interface GroupSettingProps {
    groupId: number;
    groupDn: string;
    enable: boolean;
    modifyTime: string;
}

const PriceAnalysisGroupSettings = (props: PriceAnalysisGroupSettingsProps) => {
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<GroupSettingProps[]>([]);
    const [servers, setServers] = useState<KeyValuePair[]>([]);
    const [selectedData, setSelectedData] = useState<GroupSettingProps | undefined>(undefined);
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.CENTRALIZED_SETTINGS_PRICE_ANALYSIS_SETTINGS_EDIT);

    const columns: any[] = [
        {
            title: "Group Name",
            dataIndex: "groupDn",
            key: "groupDn",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        },
        DTColProps.Status({
            width: "7vw",
            title: "Status",
            dataIndex: "enable",
            key: "enable",
        }),
        DTColProps.Middle({
            title: "Last Updated Time",
            dataIndex: "modifyTime",
            key: "modifyTime",
        }),
    ];

    const options: FlexiDataTableOptionsProps = {
        separateActionButton: true,
        refresh: {
            timer: false,
            enablePeriodSelection: false,
        },
        edit: enableUpdate,
        //customExtraActionButton: [{ text: "", icon: <FileSearchOutlined />, value: "servers" }],
        customExtraActionButton: (record: GroupSettingProps) => {
            return (
                <Popover
                    content={<ShowServerComponents data={record} />}
                    placement="left"
                    title="Pickup Servers"
                    trigger="click"
                    className="price-analysis-servers-olli-popover"
                >
                    <Button type="text" icon={<FileSearchOutlined />}></Button>
                </Popover>
            );
        },
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, FormData) => {
        switch (type) {
            case CALLBACK_KEY.DO_EDIT:
                setSelectedData(FormData);
                setIsModalVisible(true);
                break;
            case CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK:
                if (FormData.key === "servers") {
                    // setSelectedData(FormData.data);
                    // setIsModalVisible(true);
                }
                break;
            case CALLBACK_KEY.REFRESH:
                setRunRefetchDataList(true);
                break;
            default:
                break;
        }
    };

    const submitForm = (values: any) => {
        setIsLoading(true);
        let grpId = DefaultIfEmpty(values, "groupId", 0),
            selectedServer = parseInt(DefaultIfEmpty(values, "baseServer", "-1")),
            serversOptions = DefaultIfEmpty(values, "pickupServers", []).map((x: any) => ({
                groupId: grpId,
                serverUno: x,
                isBaseServer: x === selectedServer,
            }));
        plainAxiosInstance
            .post(APIs.RC_PRICE_SETTINGS.UPDATE_PRICE_ANALYSIS_GROUP_SETTING, [objectRemoveProps(values, ["baseServer", "pickupServers"])])
            .finally(() => {
                if (serversOptions.length > 0) {
                    serversOptions.length > 0 &&
                        plainAxiosInstance.post(APIs.RC_PRICE_SETTINGS.UPDATE_PRICE_ANALYSIS_SERVER_SETTING, serversOptions).finally(() => {
                            ErrorMessageHandler(`Group Settings updated successfully.`, SUCCESS_FAILED.OTHERS_SUCCESS);
                            Modal.info({
                                title: "Notice: Restart the price analysis tool and reload the new settings",
                                content: "Please restart setting from [ System Settings -> Price Monitor -> Price Backed Opt ]",
                            });
                            setRunRefetchDataList(true);
                        });
                } else {
                    ErrorMessageHandler(`Group Settings updated successfully.`, SUCCESS_FAILED.OTHERS_SUCCESS);
                    Modal.info({
                        title: "Notice: Restart the price analysis tool and reload the new settings",
                        content: "Please restart setting from [ System Settings -> Price Monitor -> Price Backed Opt ]",
                    });
                    setRunRefetchDataList(true);
                }
            });
    };

    const getPriceAnalysisSettings = () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(APIs.RC_PRICE_SETTINGS.GET_PRICE_ANALYSIS_GROUP_SETTING_LIST)
            .then((res: any) => {
                setData(res.data && res.data.length > 0 ? res.data : []);
            })
            .finally(() => setIsLoading(false));
    };

    const getConfig = () => {
        plainAxiosInstance.get(APIs.RC_PRICE_SETTINGS.GET_PRICE_ANALYSIS_GROUP_SERVERS).then((res: any) => {
            if (res.status === 200 && Array.isArray(res.data) && res.data.length > 0) {
                setServers(
                    SortList(res.data, "order")
                        .filter((x: any) => (parseInt(x.type) & 0x09) > 0)
                        .map((x: any) => ({ text: x.name, value: x.serverUno }))
                );
            }
        });
    };

    useEffect(() => {
        if (runRefetchDataList) {
            getPriceAnalysisSettings();
            setRunRefetchDataList(false);
        }
    }, [runRefetchDataList]);

    useEffect(() => {
        getConfig();
    }, []);

    return (
        <>
            <div className="price-analysis-group-setting-container">
                <FlexiDataTable
                    bordered
                    rowKeyProperty="groupId"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={data}
                    callback={componentCallback}
                    loading={isLoading}
                />
            </div>
            <PriceAnalysisGroupSettingModal
                servers={servers}
                data={selectedData}
                isModalVisible={isModalVisible}
                modalTitle={"Edit Group Settings"}
                callback={(type: number, data: any) => {
                    switch (type) {
                        case PriceAnalysisGroupSettingModalCallbackKey.Close:
                            setIsModalVisible(false);
                            break;
                        case PriceAnalysisGroupSettingModalCallbackKey.FormSubmit:
                            submitForm(data);
                            setIsModalVisible(false);
                            break;
                        default:
                            break;
                    }
                }}
            />
        </>
    );
};

export default PriceAnalysisGroupSettings;
