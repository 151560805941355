import { CloseCircleOutlined, DeleteOutlined, HomeOutlined, PlusOutlined } from "@ant-design/icons";
import { Row, Col, Anchor, Form, Button, Empty, Tag, Checkbox, Modal, Divider, message, Popconfirm } from "antd";
import cronstrue from "cronstrue";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
// import CustomTransfer from "../../../components/CustomTransfer";
import { FormComponent } from "../../../components/FormComponent";
import LoadingComponent from "../../../components/Loading";
import SitePageHeader from "../../../components/PageHeader";
import {
    ADHOC_TYPE,
    BOOLEAN_TYPE,
    ComponentType,
    DATA_TYPES_ENUM,
    FILTER_OPERATOR_TYPE_ENUM,
    SHARING_RULES,
    SORTING,
    STATUS_TYPE,
    SUCCESS_FAILED,
} from "../../../constants";
import { MIN, PERMISSION_DENIED, REQUIRED_FIELD } from "../../../constants/errorMessage";
import {
    AccountTypes,
    Brands3,
    CreateTemplate,
    CustomFilterOption,
    CustomSortOption,
    MetricInfo,
    MetricSort,
    OptionType,
    ReportTemplateSetUp,
    SchedulerInfo,
    Tags,
    TransferItemGroupProps,
    TransferItemProps,
} from "../../../constants/type";
import AuthHelper, { AuthKeys } from "../../../helpers/authHelper";
import { apiRequest, APIs } from "../../../services/apiConfig";
import { GetOperatorList, StringFormat, ToOptionTypeList } from "../../../utils/array";
import {
    convertCreateTemplateToReportTemplateSetUpData,
    convertReportTemplateSetUpDataToCreateTemplate,
    getScheduledCronDescription,
    ErrorMessageHandler,
    DataTableColumnRender,
    ErrorCatchValidator,
} from "../../../utils/Common";
import ScheduledSetupTemplate from "../Components/scheduledSetupTemplate";
import "../Templates/createEditTemplate.less";

export type TemplateState = {
    action: string;
    reportId: string;
    reportName?: string;
};

const getReportInfo = (state: string): TemplateState | undefined => {
    let rInfo = atob(state).split("|");
    return rInfo.length === 3
        ? ({
            reportId: rInfo[0],
            reportName: rInfo[1],
            action: rInfo[2],
        } as TemplateState)
        : undefined;
};

const initialValue: CreateTemplate = {
    name: "",
    description: "",
    status: 1,
    sharingRules: 0,
    adHocRun: true,
    snapshotDate: "",
    metricType: {
        typeId: 0,
        name: "",
    },
    metricSelected: [],
    metricFilters: [],
    metricSorts: [],
    schedulers: [],
    emails: [],
    brands: [],
    tags: [],
    accountTypes: [],
    accountIds: [],
    symbols: [],
    editable: false,
};

const initialComValue: ReportTemplateSetUp = {
    name: "",
    description: "",
    status: "",
    sharingRules: "",
    adHocRun: "",
    snapshotDate: "",
    metricType: "",
    metricSelected: [],
    metricFilters: [],
    metricSorts: [],
    schedulers: [],
    emails: [],
    currentEmail: "",
    cbemailto: false,
    cblimitrecords: false,
    brands: [],
    tags: [],
    accountTypes: [],
    accountIds: [],
    symbols: [],
    cbtagging: false,
};

const initialMetricInfoValue: MetricInfo = {
    metric: [],
    metricType: [],
    brands: [],
    tags: [],
    accountTypes: [],
    categories: [],
};

export const deleteTemplate = (reportId: string, navigator: NavigateFunction) => {
    apiRequest(APIs.DELETE_TEMPLATE, { reportId })
        .then(data => {
            ErrorMessageHandler("The template", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
            navigator("/report/template");
        })
        .catch(error => ErrorCatchValidator(error, (res: any) => ErrorMessageHandler("template", SUCCESS_FAILED.FAILED_DELETE_DATA, error)));
};

const CreateTemplatePage = () => {
    const authHp = new AuthHelper();

    let navigate = useNavigate();
    let { id } = useParams();
    const reportInfo = getReportInfo(id || "") || {
        reportId: "",
        reportName: "",
        action: "add",
    };

    const [isAddAction] = useState<boolean>(reportInfo.action === "add");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
    const [templateForm] = Form.useForm();
    const [scheduledform] = Form.useForm();
    const [selectedMetric, setSelectedMetric] = useState<string[]>([]);
    const [showAddSchedulerModal, setShowAddSchedulerModal] = useState<boolean>(false);
    const [enableLimitRecord, setEnableLimitRecord] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string[]>([]);

    const isAdHocRun = Form.useWatch("adHocRun", templateForm);
    const selectedMetricType = Form.useWatch("metricType", templateForm);
    const selectedMetrics = Form.useWatch<string[]>("metricSelected", templateForm);

    const [metricInfo, setMetricInfo] = useState<MetricInfo>({ ...initialMetricInfoValue });
    const [symbols, setSymbols] = useState<string[]>([]);
    const [templateInfo, setTemplateInfo] = React.useState<CreateTemplate>({ ...initialValue });

    let initialData = convertCreateTemplateToReportTemplateSetUpData(initialValue, { ...initialComValue }, metricInfo);
    const [enableEmailToReceipiant, setEnableEmailToReceipiant] = useState<boolean>(initialData.cbemailto || false);
    const [enableTagging, setEnableTagging] = useState<boolean>(initialData.cbtagging || false);

    const [accountTypes, setAccountTypes] = useState<AccountTypes[]>([]);

    const getAvailableMetricList = (): TransferItemGroupProps[] => {
        if (selectedMetricType === undefined || selectedMetricType === "" || metricInfo.categories === undefined) return [];

        let tempMetricsArr = metricInfo.metric.filter(x => x.metricTypeId === selectedMetricType);
        metricInfo.categories?.sort((a, b) => a.orderSeq - b.orderSeq);
        let finalItemList = metricInfo.categories?.map<TransferItemGroupProps>(currCategory => {
            let newMetricsArr = tempMetricsArr
                .filter(currMetric => currMetric.categories.indexOf(currCategory.id) > -1)
                .map<TransferItemProps>(currMetricc => {
                    return {
                        id: currMetricc.metricId.toString(),
                        text: currMetricc.displayName,
                        description: currMetricc.description,
                    };
                });
            return {
                id: currCategory.id.toString(),
                title: currCategory.name,
                children: newMetricsArr,
                description: currCategory.description,
            };
        });
        return finalItemList;
    };

    const getSelectedMetricList = (idAndNameOnly: boolean) => {
        let selectedList = selectedMetrics ? selectedMetrics.map(x => parseInt(x)) : [];
        return metricInfo.metric
            .filter(x => selectedList.includes(x.metricId))
            .map<OptionType>(x => ({
                text: x.displayName,
                value: x.metricId + "|" + x.metricName + (idAndNameOnly ? "" : "|" + x.dataTypeId),
            }));
    };

    const onFormValueChange = (componentName: any, triggerOnChange = true) => {
        Object.keys(componentName).map(x => {
            const value = componentName[x];

            switch (x) {
                case "metricFilters":
                    let currentMetricFilters: CustomFilterOption[] = [...templateForm.getFieldValue("metricFilters")];
                    for (const key in value) {
                        let updatedIndex = parseInt(key);
                        const element: CustomFilterOption = value[updatedIndex];

                        if (element?.hasOwnProperty("metricDd") && element.metricDd.length > 0) {
                            let selectedMetric = element.metricDd.split("|");
                            if (selectedMetric.length > 0) {
                                currentMetricFilters[updatedIndex] = {
                                    ...currentMetricFilters[updatedIndex],
                                    metricId: parseInt(selectedMetric[0]),
                                    name: selectedMetric[1],
                                    dataType: parseInt(selectedMetric[2]),
                                    ...(!element?.hasOwnProperty("metricId") &&
                                        !element?.hasOwnProperty("name") &&
                                        !element?.hasOwnProperty("dataType") && {
                                        operator: "",
                                        valueobj: "",
                                    }),
                                };
                            }
                        } else if (element?.hasOwnProperty("operator")) {
                            currentMetricFilters[updatedIndex] = {
                                ...currentMetricFilters[updatedIndex],
                                operator: element.operator,
                                valueobj: "",
                            };
                        }
                    }
                    templateForm.setFieldsValue({ metricFilters: currentMetricFilters });
                    break;
                case "metricSorts":
                    let currentMetricSorts: CustomSortOption[] = [...templateForm.getFieldValue("metricSorts")];
                    for (const key in value) {
                        let updatedIndex = parseInt(key);
                        const element: CustomSortOption = value[updatedIndex];

                        if (element?.hasOwnProperty("metricDd") && element.metricDd.length > 0) {
                            let selectedMetric = element.metricDd.split("|");
                            if (selectedMetric.length > 0) {
                                currentMetricSorts[updatedIndex] = {
                                    ...currentMetricSorts[updatedIndex],
                                    metricId: parseInt(selectedMetric[0]),
                                    name: selectedMetric[1],
                                };
                            }
                        } else if (element?.hasOwnProperty("order")) {
                            currentMetricSorts[updatedIndex] = {
                                ...currentMetricSorts[updatedIndex],
                                order: element.order,
                            };
                        }
                    }
                    templateForm.setFieldsValue({ metricSorts: currentMetricSorts });
                    break;
                case "cblimitrecords":
                    setEnableLimitRecord(value);
                    templateForm.setFieldsValue({ limitRecords: "" });
                    break;
                case "metricType":
                    templateForm.setFieldsValue({
                        metricSelected: [],
                        metricFilters: [],
                        metricSorts: [],
                        ...(value !== 2 &&
                            value !== 3 && {
                            symbols: [],
                        }),
                    });
                    break;
                default:
                    break;
            }

            return false;
        });
    };

    const onFormSubmit = (values: any) => {
        let finalModel = convertReportTemplateSetUpDataToCreateTemplate(values as ReportTemplateSetUp, templateInfo, metricInfo);
        setTemplateInfo(finalModel);
        sendCreateTemplateRequest(finalModel);
    };

    const getFilterConfigList = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["accounttype", "symbol"] })
            .then((data: any) => {
                setSymbols(data.symbols.map((x: any) => x.name));
                setAccountTypes(data.accountTypes);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => console.log(err));
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getFilterConfigList();

        /**
         *  27/3/2025 - Xin
         *  removed api as it has been removed from Insight backend
         *  but leaving code here for future uses
         */
        // apiRequest(APIs.GET_METRIC_INFO, {})
        //     .then((data: any) => {
        //         let responseData: MetricInfo = data as MetricInfo;
        //         responseData.categories = responseData.categories || [];
        //         responseData.brands = responseData.brands || [];
        //         responseData.tags = responseData.tags || [];
        //         setMetricInfo(responseData);

        //         if (!isAddAction) {
        //             apiRequest(APIs.GET_REPORT_TEMPLATE_BY_ID, {
        //                 reportId: reportInfo.reportId,
        //                 reportName: reportInfo.reportName,
        //             })
        //                 .then((data: any) => {
        //                     let current_data: CreateTemplate = data as CreateTemplate;
        //                     let convertedData = convertCreateTemplateToReportTemplateSetUpData(current_data, { ...initialComValue }, metricInfo);
        //                     let current_template_data = Object.assign(templateInfo, current_data, {
        //                         metricType: {
        //                             typeId: current_data.metricType.typeId,
        //                             name: current_data.metricType.name,
        //                         },
        //                         sharingRules: current_data.sharingRules,
        //                         adHocRun: current_data.schedulers.length === 0,
        //                         limitRecords: current_data.limitRecords === null ? undefined : current_data.limitRecords,
        //                         brands: (current_data.brands as Brands3[]).map(x => x.id),
        //                         tags: (current_data.tags as Tags[]).map(x => x.id),
        //                     });
        //                     setTemplateInfo(current_template_data);
        //                     setEnableLimitRecord(current_data.limitRecords ? true : false);
        //                     setEnableEmailToReceipiant(current_data.emails.length > 0 ? true : false);
        //                     setEnableTagging(current_data.tags.length > 0 ? true : false);
        //                     setSelectedMetric(convertedData.metricSelected);
        //                     templateForm.setFieldsValue(
        //                         convertCreateTemplateToReportTemplateSetUpData(current_template_data, { ...initialComValue }, responseData)
        //                     );
        //                 })
        //                 .catch((error: any) => {
        //                     ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("report template", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
        //                     navigate("/report/template");
        //                 })
        //                 .finally(() => setIsLoading(false));
        //         } else {
        //             setIsLoading(false);
        //         }
        //     })
        //     .catch((error: any) => {
        //         ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("metric info", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
        //         navigate("/report/template");
        //     });
        return () => { };
    }, []);

    const sendCreateTemplateRequest = (finalModel: CreateTemplate) => {
        if (!authHp.isAuthorized(AuthKeys.TEMPLATE_EDIT)) {
            ErrorMessageHandler(PERMISSION_DENIED, SUCCESS_FAILED.OTHERS_FAILED);
            return;
        }
        // final Model convertion for create & edit
        if (isAddAction) {
            setIsBtnLoading(true);
            apiRequest(APIs.CREATE_TEMPLATE, finalModel)
                .then(data => {
                    setErrorMessage([]);
                    ErrorMessageHandler("New template", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                    navigate("/report/template");
                })
                .catch(error =>
                    ErrorCatchValidator(error, (res: any) => {
                        let errorMessage: string[] = [];
                        Object.keys(error.data).map(x => {
                            errorMessage.push(error.data[x].toString());

                            return false;
                        });
                        setErrorMessage(errorMessage);
                        ErrorMessageHandler("new template", SUCCESS_FAILED.FAILED_CREATE_DATA, error);
                        window.scrollTo(0, 0);
                    })
                )
                .finally(() => setIsBtnLoading(false));
        } else if (reportInfo?.action === "edit" || reportInfo?.action === "duplicate") {
            // console.log("edit/duplicate template finalModel: ", finalModel);
            setIsBtnLoading(true);
            apiRequest(APIs.UPDATE_TEMPLATE, finalModel)
                .then(data => {
                    setErrorMessage([]);
                    ErrorMessageHandler("Existing template", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                    navigate("/report/template");
                })
                .catch(error =>
                    ErrorCatchValidator(error, (res: any) => {
                        let errorMessage: string[] = [];
                        Object.keys(error.data).map(x => {
                            errorMessage.push(error.data[x].toString());

                            return false;
                        });
                        setErrorMessage(errorMessage);
                        ErrorMessageHandler("existing template", SUCCESS_FAILED.FAILED_UPDATE_DATA, error);
                        window.scrollTo(0, 0);
                    })
                )
                .finally(() => setIsBtnLoading(false));
        }
    };

    return (
        <SitePageHeader
            title={isAddAction ? "Create New Template" : reportInfo.action === "duplicate" ? "Duplicate Template" : "Edit Template"}
            routes={[
                {
                    path: "/report/template",
                    breadcrumbName: "Report Template",
                    icon: <HomeOutlined />,
                },
                {
                    path: "",
                    breadcrumbName: isAddAction ? "Create New Template" : reportInfo.action === "duplicate" ? "Duplicate Template" : "Edit Template",
                },
            ]}
            onBack={() => navigate("/report/template")}
            extraProps={{
                extra: isAddAction
                    ? []
                    : [
                        <Popconfirm
                            key={"cetmp-del"}
                            title="Confirm to delete?"
                            onConfirm={() => deleteTemplate(reportInfo.reportId, navigate)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="primary" danger>
                                Delete Template
                            </Button>
                        </Popconfirm>,
                    ],
            }}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Loading..." />
                </div>
            ) : (
                <div className="TemplateSetupContainer">
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                        }}
                    >
                        <div className="anchor-container">
                            <Anchor
                                offsetTop={10}
                                onClick={(
                                    e: React.MouseEvent<HTMLElement>,
                                    link: {
                                        title: React.ReactNode;
                                        href: string;
                                    }
                                ) => {
                                    e.preventDefault();
                                    e.preventDefault();
                                }}
                            >
                                <Anchor.Link href="#basic" title="Basic" />
                                <Anchor.Link href="#metrics" title="Metrics" />
                                <Anchor.Link href="#filterAndSortingRules" title="Filter and Sorting Rules" />
                                <Anchor.Link href="#scheduler" title="Scheduler" />
                                <Anchor.Link href="#postReportProcess" title="Post Report Process" />
                                <Anchor.Link href="#submit" title="Submit" />
                            </Anchor>
                        </div>
                        <Col flex={8}>
                            {errorMessage.length > 0 && (
                                <div className="error-container">
                                    <ul style={{ listStyle: "none", paddingLeft: 0 }}>
                                        {errorMessage.map((errMsg, index) => (
                                            <li key={`err-desc-${index}`}>
                                                <CloseCircleOutlined style={{ color: "#f00f00", marginRight: "0.651vw" }} />
                                                {errMsg}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                            <Form
                                form={templateForm}
                                initialValues={convertCreateTemplateToReportTemplateSetUpData(templateInfo, { ...initialComValue }, metricInfo)}
                                onValuesChange={(changedComponent, allvalue) => onFormValueChange(changedComponent, allvalue)}
                                onFinish={onFormSubmit}
                            >
                                <Row>
                                    <h1 id="basic" className="divider-title">
                                        Basic
                                    </h1>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <FormComponent
                                            label="Template Name"
                                            name="name"
                                            extra={{
                                                type: ComponentType.text,
                                                itemProps: {
                                                    labelAlign: "left",
                                                    labelCol: { span: 4 },
                                                },
                                                inputProps: {
                                                    style: { width: "90%" },
                                                },
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: REQUIRED_FIELD,
                                                    },
                                                ],
                                                value: "",
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <FormComponent
                                            label="Description"
                                            name="description"
                                            extra={{
                                                type: ComponentType.text,
                                                itemProps: {
                                                    labelAlign: "left",
                                                    labelCol: { span: 4 },
                                                },
                                                inputProps: {
                                                    style: { width: "90%" },
                                                },
                                                value: "",
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <FormComponent
                                            label="Sharing"
                                            name="sharingRules"
                                            extra={{
                                                type: ComponentType.dropdown,
                                                itemProps: {
                                                    labelAlign: "left",
                                                    labelCol: { span: 8 },
                                                },
                                                inputProps: {
                                                    style: { width: "90%" },
                                                },
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: REQUIRED_FIELD,
                                                    },
                                                ],
                                                value: ToOptionTypeList(SHARING_RULES),
                                            }}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <FormComponent
                                            label="Status"
                                            name="status"
                                            extra={{
                                                type: ComponentType.dropdown,
                                                itemProps: {
                                                    labelAlign: "left",
                                                    labelCol: { span: 4 },
                                                },
                                                inputProps: {
                                                    style: { width: "79%" },
                                                },
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: REQUIRED_FIELD,
                                                    },
                                                ],
                                                value: ToOptionTypeList(STATUS_TYPE),
                                            }}
                                        />
                                    </Col>
                                </Row>
                                {isAddAction ? null : (
                                    <Row>
                                        <Col span={4}>Created By:</Col>
                                        <Col span={8} style={{ textAlign: "left" }}>
                                            {templateInfo.ownerName}
                                            {/* <div style={{ marginBottom: 20 }}>
                                                <span style={{ margin: "0px 20px 30px 0px" }}>Created By: {templateInfo.ownerName}</span>
                                                <span style={{ margin: "0px 20px 30px 30px" }}>
                                            Created At: {DataTableColumnRender.DateTime(templateInfo.createdDateUtc)}
                                             </span>
                                                {<span>Last Updated At: {templateInfo.}</span>}
                                            </div> */}
                                        </Col>
                                        <Col span={2}>Created At:</Col>
                                        <Col span={10} style={{ textAlign: "left" }}>
                                            {DataTableColumnRender.DateTime(templateInfo.createdDateUtc)}
                                        </Col>
                                    </Row>
                                )}
                                <Row>
                                    <h1 id="metrics" className="divider-title">
                                        Metrics
                                    </h1>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <FormComponent
                                            label="Metric Type"
                                            name={"metricType"}
                                            extra={{
                                                type: ComponentType.dropdown,
                                                itemProps: {
                                                    labelAlign: "left",
                                                    labelCol: { span: 4 },
                                                },
                                                inputProps: {
                                                    style: { width: "90%" },
                                                    onChange: () => {
                                                        setSelectedMetric([]);
                                                        templateForm.setFieldsValue({ metricSelected: [] });
                                                    },
                                                },
                                                value: metricInfo.metricType.map(x => ({
                                                    text: x.name,
                                                    value: x.typeId,
                                                })),
                                                rules: [{ required: true, message: REQUIRED_FIELD }],
                                            }}
                                        />
                                    </Col>
                                </Row>
                                {/* <Row>
                                    <Col span={22}>
                                        <CustomTransfer
                                            form={templateForm}
                                            name={"metricSelected"}
                                            itemList={getAvailableMetricList()}
                                            selectedList={selectedMetric}
                                            onChange={selectedOption => {
                                                setSelectedMetric(selectedOption);
                                                templateForm.setFieldsValue({ metricSelected: selectedOption });
                                                // Force update CustomfilterList because selected metric in filter getting removed
                                                let current_filter_option: CustomFilterOption[] = templateForm.getFieldValue("metricFilters");
                                                if (current_filter_option) {
                                                    let current_selectedMetric: number[] = selectedOption.map(x => parseInt(x));
                                                    let new_current_filter_option: CustomFilterOption[] = [];
                                                    for (let index = 0; index < current_filter_option.length; index++) {
                                                        const element = current_filter_option[index];
                                                        if (current_selectedMetric.includes(element.metricId)) {
                                                            new_current_filter_option.push({ ...element });
                                                        }
                                                    }
                                                    templateForm.setFieldsValue({
                                                        metricFilters: new_current_filter_option,
                                                    });
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row> */}
                                <Row>
                                    <h1 id="filterAndSortingRules" className="divider-title">
                                        Filter and Sorting Rules
                                    </h1>
                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <FormComponent
                                            label=""
                                            name={"limitRecords"}
                                            extra={{
                                                type: ComponentType.number,
                                                value: 0,
                                                inputProps: {
                                                    disabled: !enableLimitRecord,
                                                    addonBefore: "Select first ",
                                                    addonAfter: " record(s)",
                                                },
                                                rules: enableLimitRecord
                                                    ? [
                                                        { required: true, message: REQUIRED_FIELD },
                                                        {
                                                            type: "number",
                                                            min: 1,
                                                            message: StringFormat(MIN, 1),
                                                            transform: value => (Number(value) ? Number(value) : 0),
                                                        },
                                                    ]
                                                    : [],
                                            }}
                                        />
                                    </Col>
                                    <Col span={12} style={{ paddingLeft: "1.302vw" }}>
                                        <FormComponent
                                            label={""}
                                            name={"cblimitrecords"}
                                            extra={{
                                                type: ComponentType.checkbox,
                                                value: "Enable limit record selection",
                                            }}
                                        />
                                    </Col>
                                </Row>
                                {["2", "3"].indexOf((selectedMetricType || "").toString()) > -1 && (
                                    <Row>
                                        <Col span={22}>
                                            <FormComponent
                                                label="Symbols"
                                                name={"symbols"}
                                                extra={{
                                                    type: ComponentType.dropdown,
                                                    itemProps: {
                                                        labelAlign: "left",
                                                        labelCol: { span: 4 },
                                                    },
                                                    inputProps: {
                                                        style: { width: "100%" },
                                                        mode: "multiple",
                                                        allowClear: true,
                                                    },
                                                    value: symbols.map(x => ({ text: x, value: x })),
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                )}

                                <Form.List name={"metricFilters"}>
                                    {(fields, { add, remove }) => (
                                        <>
                                            <Row style={{ marginBottom: "1.414vh" }}>
                                                <Col span={24}>
                                                    <Row>
                                                        <Col span={7} className="bold-title">
                                                            Custom Filters
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>

                                            {fields.map((field, index) => {
                                                let currentFilterList: CustomFilterOption[] = templateForm.getFieldValue("metricFilters");
                                                const curr_item = currentFilterList[index];
                                                return (
                                                    <Row key={field.key}>
                                                        <Col span={7}>
                                                            <FormComponent
                                                                label=""
                                                                name={[field.name, "metricDd"]}
                                                                extra={{
                                                                    type: ComponentType.dropdown,
                                                                    value: getSelectedMetricList(false),
                                                                    rules: [
                                                                        {
                                                                            required: true,
                                                                            message: REQUIRED_FIELD,
                                                                        },
                                                                    ],
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col span={6}>
                                                            <FormComponent
                                                                label=""
                                                                name={[field.name, "operator"]}
                                                                extra={{
                                                                    type: ComponentType.dropdown,
                                                                    value:
                                                                        curr_item.dataType === DATA_TYPES_ENUM.Empty
                                                                            ? []
                                                                            : GetOperatorList(curr_item.dataType),
                                                                    rules: [
                                                                        {
                                                                            required: true,
                                                                            message: REQUIRED_FIELD,
                                                                        },
                                                                    ],
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col span={9}>
                                                            {curr_item.operator === FILTER_OPERATOR_TYPE_ENUM.Range ? (
                                                                curr_item.dataType === DATA_TYPES_ENUM.Date ||
                                                                    curr_item.dataType === DATA_TYPES_ENUM.DateTime ? (
                                                                    <FormComponent
                                                                        label=""
                                                                        name={[field.name, "valueobj"]}
                                                                        extra={{
                                                                            type: ComponentType.daterange,
                                                                            value: "",
                                                                            inputProps: {
                                                                                showTime: curr_item.dataType === DATA_TYPES_ENUM.DateTime,
                                                                            },
                                                                            rules: [
                                                                                {
                                                                                    required: true,
                                                                                    message: REQUIRED_FIELD,
                                                                                },
                                                                            ],
                                                                            dateFormat:
                                                                                curr_item.dataType === DATA_TYPES_ENUM.DateTime
                                                                                    ? "YYYY-MM-DD HH:mm:ss"
                                                                                    : "YYYY-MM-DD",
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <Row>
                                                                        <Col span={12}>
                                                                            <FormComponent
                                                                                label=""
                                                                                name={[field.name, "valueobj", 0]}
                                                                                extra={{
                                                                                    type: ComponentType.text,
                                                                                    value: "",
                                                                                    rules: [
                                                                                        {
                                                                                            required: true,
                                                                                            message: REQUIRED_FIELD,
                                                                                        },
                                                                                    ],
                                                                                    inputProps: {
                                                                                        placeholder: "From",
                                                                                    },
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                        <Col span={12}>
                                                                            <FormComponent
                                                                                label=""
                                                                                name={[field.name, "valueobj", 1]}
                                                                                extra={{
                                                                                    type: ComponentType.text,
                                                                                    value: "",
                                                                                    rules: [
                                                                                        {
                                                                                            required: true,
                                                                                            message: REQUIRED_FIELD,
                                                                                        },
                                                                                    ],
                                                                                    inputProps: {
                                                                                        placeholder: "To",
                                                                                    },
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                )
                                                            ) : curr_item.dataType === DATA_TYPES_ENUM.Boolean ? (
                                                                <FormComponent
                                                                    label=""
                                                                    name={[field.name, "valueobj"]}
                                                                    extra={{
                                                                        type: ComponentType.dropdown,
                                                                        value: ToOptionTypeList(BOOLEAN_TYPE),
                                                                        rules: [
                                                                            {
                                                                                required: true,
                                                                                message: REQUIRED_FIELD,
                                                                            },
                                                                        ],
                                                                    }}
                                                                />
                                                            ) : curr_item.dataType === DATA_TYPES_ENUM.Date ||
                                                                curr_item.dataType === DATA_TYPES_ENUM.DateTime ? (
                                                                <FormComponent
                                                                    label=""
                                                                    name={[field.name, "valueobj"]}
                                                                    extra={{
                                                                        type: ComponentType.date,
                                                                        value: "",
                                                                        rules: [
                                                                            {
                                                                                required: true,
                                                                                message: REQUIRED_FIELD,
                                                                            },
                                                                        ],
                                                                        inputProps: {
                                                                            showTime: curr_item.dataType === DATA_TYPES_ENUM.DateTime,
                                                                        },
                                                                    }}
                                                                />
                                                            ) : (
                                                                <FormComponent
                                                                    label=""
                                                                    name={[field.name, "valueobj"]}
                                                                    extra={{
                                                                        type: ComponentType.text,
                                                                        value: "",
                                                                        rules: [
                                                                            {
                                                                                required: true,
                                                                                message: REQUIRED_FIELD,
                                                                            },
                                                                        ],
                                                                    }}
                                                                />
                                                            )}
                                                        </Col>
                                                        <Col span={1}>
                                                            <Button type="link" danger icon={<DeleteOutlined />} onClick={() => remove(index)} />
                                                        </Col>
                                                    </Row>
                                                );
                                            })}

                                            <Button
                                                disabled={selectedMetric && selectedMetric.length === 0}
                                                type="dashed"
                                                onClick={() => {
                                                    add({
                                                        metricDd: "",
                                                        metricId: 0,
                                                        dataType: DATA_TYPES_ENUM.Empty,
                                                        name: "",
                                                        operator: "",
                                                        value: "",
                                                    } as CustomFilterOption);
                                                }}
                                                style={{ width: "92%" }}
                                                icon={<PlusOutlined />}
                                            >
                                                Add custom filters
                                            </Button>
                                        </>
                                    )}
                                </Form.List>
                                <Form.List name={"metricSorts"}>
                                    {(fields, { add, remove }) => {
                                        return (
                                            <>
                                                <Row style={{ marginTop: "2.828vh", marginBottom: "1.414vh" }}>
                                                    <Col span={24}>
                                                        <Row>
                                                            <Col span={7} className="bold-title">
                                                                Custom Sorting
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>

                                                {fields.map((field, index) => {
                                                    return (
                                                        <Row key={field.key}>
                                                            <Col span={16}>
                                                                <FormComponent
                                                                    label=""
                                                                    name={[field.name, "metricDd"]}
                                                                    extra={{
                                                                        type: ComponentType.dropdown,
                                                                        value: getSelectedMetricList(true),
                                                                        rules: [
                                                                            {
                                                                                required: true,
                                                                                message: REQUIRED_FIELD,
                                                                            },
                                                                        ],
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col span={6}>
                                                                <FormComponent
                                                                    label=""
                                                                    name={[field.name, "order"]}
                                                                    extra={{
                                                                        type: ComponentType.dropdown,
                                                                        value: Object.keys(SORTING).map(
                                                                            x => ({ text: SORTING[x], value: x } as OptionType)
                                                                        ),
                                                                        rules: [
                                                                            {
                                                                                required: true,
                                                                                message: REQUIRED_FIELD,
                                                                            },
                                                                        ],
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col span={1}>
                                                                <Button type="link" danger icon={<DeleteOutlined />} onClick={() => remove(index)} />
                                                            </Col>
                                                        </Row>
                                                    );
                                                })}

                                                <Button
                                                    disabled={selectedMetric && selectedMetric.length === 0}
                                                    type="dashed"
                                                    onClick={() => {
                                                        add({
                                                            metricId: 0,
                                                            name: "",
                                                            order: "",
                                                        } as MetricSort);
                                                    }}
                                                    style={{ width: "92%" }}
                                                    icon={<PlusOutlined />}
                                                >
                                                    Add custom sorts
                                                </Button>
                                            </>
                                        );
                                    }}
                                </Form.List>

                                <Row style={{ marginTop: "2.828vh", marginBottom: "1.414vh" }}>
                                    <Col span={24}>
                                        <Row>
                                            <Col span={7} className="bold-title">
                                                Account Filter
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <FormComponent label={""} name={"accountTypes"} extra={{ type: ComponentType.hidden, value: "" }} />
                                <Row>
                                    {/* <Col span={24}>
                                        <FormComponent
                                            label="Account Types"
                                            name={"accountTypes"}
                                            extra={{
                                                type: ComponentType.dropdown,
                                                itemProps: {
                                                    labelAlign: "left",
                                                    labelCol: { span: 4 },
                                                },
                                                inputProps: {
                                                    style: { width: "90%" },
                                                    mode: "multiple",
                                                    allowClear: true,
                                                },
                                                value: accountTypes.map((x) => ({ text: x.name, value: x.id })),
                                            }}
                                        />
                                    </Col> */}
                                    <Col span={24}>
                                        <FormComponent
                                            label="Account IDs"
                                            name={"accountIds"}
                                            extra={{
                                                type: ComponentType.dropdown,
                                                itemProps: {
                                                    labelAlign: "left",
                                                    labelCol: { span: 4 },
                                                },
                                                inputProps: {
                                                    style: { width: "90%" },
                                                    mode: "tags",
                                                    allowClear: true,
                                                    tokenSeparators: [",", "，", " "],
                                                },
                                                value: [],
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Row>
                                            <Col span={7} className="bold-title">
                                                Brand Filter
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <FormComponent
                                            label="Brands"
                                            name={"brands"}
                                            extra={{
                                                type: ComponentType.dropdown,
                                                itemProps: {
                                                    labelAlign: "left",
                                                    labelCol: { span: 4 },
                                                },
                                                inputProps: {
                                                    style: { width: "90%" },
                                                    mode: "multiple",
                                                    allowClear: true,
                                                },
                                                value: metricInfo.brands.map(x => ({
                                                    text: x.brand,
                                                    value: x.id,
                                                })),
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <h1 id="scheduler" className="divider-title">
                                        Scheduler
                                    </h1>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <FormComponent
                                            label="Scheduler Setting"
                                            name="adHocRun"
                                            extra={{
                                                type: ComponentType.dropdown,
                                                itemProps: {
                                                    labelAlign: "left",
                                                    labelCol: { span: 4 },
                                                    // wrapperCol: { span: 12 },
                                                },
                                                inputProps: {
                                                    style: { width: "90%" },
                                                },
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: REQUIRED_FIELD,
                                                    },
                                                ],
                                                value: isAddAction
                                                    ? ToOptionTypeList(ADHOC_TYPE)
                                                    : ToOptionTypeList(ADHOC_TYPE)
                                                        .filter(x => x.value !== 2)
                                                        .map((currObj: any) => {
                                                            currObj.text =
                                                                currObj.text.toLowerCase() === "adhoc" ? (currObj.text = "None") : currObj.text;
                                                            return currObj;
                                                        }),
                                            }}
                                        />
                                    </Col>
                                </Row>
                                {isAdHocRun === 2 && (
                                    <FormComponent
                                        label="Date"
                                        name="snapshotDate"
                                        extra={{
                                            type: ComponentType.date,
                                            inputProps: {
                                                style: { width: "90%" },
                                                format: (value: any) => value.format("YYYY-MM-DD"),
                                                disabledDate: (current: any) => {
                                                    return current && current > moment().add(-1, "days").endOf("day");
                                                },
                                            },
                                            itemProps: {
                                                labelAlign: "left",
                                                labelCol: { span: 4 },
                                                // wrapperCol: { span: 12 },
                                            },
                                            rules: [
                                                {
                                                    required: true,
                                                    message: REQUIRED_FIELD,
                                                },
                                            ],
                                            value: "",
                                        }}
                                    />
                                )}
                                {isAdHocRun === 0 && (
                                    <Col span={22}>
                                        <Form.List name={"schedulers"}>
                                            {(fields, { add, remove }) => (
                                                <div className="scheduled-list">
                                                    <Row>
                                                        <Col className="scheduled-list-title" span={24}>
                                                            <h1>
                                                                Scheduler List <span style={{ color: "#f00f00" }}>(Based on Server Time Zone)</span>
                                                            </h1>
                                                            <Button
                                                                type="dashed"
                                                                onClick={() => setShowAddSchedulerModal(true)}
                                                                icon={<PlusOutlined />}
                                                            >
                                                                Add scheduler
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col span={24}>
                                                            <div className="list-item-div">
                                                                {fields.length === 0 && (
                                                                    <div className="empty-list-item">
                                                                        <Empty />
                                                                    </div>
                                                                )}
                                                                {fields.map((field, index) => {
                                                                    let currentSchedulerList: SchedulerInfo[] =
                                                                        templateForm.getFieldValue("schedulers");
                                                                    return (
                                                                        <div key={field.key} className="list-item-style">
                                                                            <span>
                                                                                {cronstrue.toString(currentSchedulerList[index].cronExpression, {
                                                                                    throwExceptionOnParseError: false,
                                                                                    use24HourTimeFormat: true,
                                                                                })}
                                                                                {currentSchedulerList[index].snapshot && (
                                                                                    <Tag
                                                                                        key={`sc-tg-${index}`}
                                                                                        style={{
                                                                                            padding: "0 0.651vw 0 0.651vw",
                                                                                            marginLeft: "0.651vw",
                                                                                        }}
                                                                                    >
                                                                                        T-1 snapshot
                                                                                    </Tag>
                                                                                )}
                                                                            </span>
                                                                            <Button
                                                                                type="link"
                                                                                danger
                                                                                icon={<DeleteOutlined />}
                                                                                onClick={() => remove(index)}
                                                                            />
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )}
                                        </Form.List>
                                    </Col>
                                )}
                                <Row>
                                    <h1 id="postReportProcess" className="divider-title">
                                        Post Report Process
                                    </h1>
                                </Row>
                                <Row>
                                    <Col span={22}>
                                        <div className="receipiant-div">
                                            <Form.Item name={"cbemailto"} valuePropName="checked" className="cb-email">
                                                <Checkbox
                                                    onChange={e => {
                                                        templateForm.setFieldsValue({ currentEmail: "", emails: [] });
                                                        setEnableEmailToReceipiant(e.target.checked);
                                                    }}
                                                >
                                                    Email to receipiant(s)
                                                </Checkbox>
                                            </Form.Item>

                                            {enableEmailToReceipiant && (
                                                // <Form.List
                                                //     name={"emails"}
                                                //     rules={[
                                                //         {
                                                //             validator: async (_, value) => {
                                                //                 if (value.length === 0) {
                                                //                     return Promise.reject(new Error("At least 1 email address required"));
                                                //                 }
                                                //                 return Promise.resolve();
                                                //             },
                                                //         },
                                                //     ]}
                                                // >
                                                //     {(fields, { add, remove }, { errors }) => (
                                                //         <div className="email-input">
                                                //             <div className="email-list">
                                                //                 {fields.map((field, index) => {
                                                //                     let currentEmails: string[] = templateForm.getFieldValue("emails");
                                                //                     return (
                                                //                         <Tag
                                                //                             key={field.key}
                                                //                             closable
                                                //                             onClose={(e) => {
                                                //                                 e.preventDefault();
                                                //                                 remove(index);
                                                //                             }}
                                                //                         >
                                                //                             {currentEmails[index]}
                                                //                         </Tag>
                                                //                     );
                                                //                 })}
                                                //                 <FormComponent
                                                //                     label={""}
                                                //                     name={"currentEmail"}
                                                //                     extra={{
                                                //                         type: ComponentType.text,
                                                //                         value: "",
                                                //                         itemProps: {
                                                //                             className: "email-input-element",
                                                //                         },
                                                //                         inputProps: {
                                                //                             className: "no-border",
                                                //                             placeholder: "Please fill in email address",
                                                //                             onPressEnter: (e: any) => {
                                                //                                 e.preventDefault();
                                                //                                 if (e.target.value.length > 0) {
                                                //                                     if (
                                                //                                         /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                                                //                                             e.target.value
                                                //                                         )
                                                //                                     ) {
                                                //                                         add(e.target.value);
                                                //                                         templateForm.setFieldsValue({ currentEmail: "" });
                                                //                                         templateForm.validateFields(["currentEmail"]);
                                                //                                     }
                                                //                                 }
                                                //                             },
                                                //                         },
                                                //                         rules: [{ type: "email", message: "Invalid email format" }],
                                                //                     }}
                                                //                 />

                                                //                 <Form.ErrorList errors={errors} />
                                                //             </div>
                                                //         </div>
                                                //     )}
                                                // </Form.List>
                                                <FormComponent
                                                    label={""}
                                                    name={"emails"}
                                                    extra={{
                                                        type: ComponentType.dropdown,
                                                        value: [],
                                                        rules: [
                                                            {
                                                                validator: async (_, value) => {
                                                                    if (value.length === 0) {
                                                                        return Promise.reject(new Error("At least 1 email address required"));
                                                                    } else if (
                                                                        value.filter((x: string) =>
                                                                            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(x)
                                                                        ).length < value.length
                                                                    ) {
                                                                        return Promise.reject(new Error("Invalid email format"));
                                                                    }
                                                                    return Promise.resolve();
                                                                },
                                                            },
                                                        ],
                                                        itemProps: {
                                                            labelAlign: "left",
                                                            labelCol: { span: 0 },
                                                            wrapperCol: { span: 24 },
                                                        },
                                                        inputProps: {
                                                            style: { width: "100%" },
                                                            mode: "tags",
                                                            placeholder: "Please fill in email address",
                                                        },
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={22}>
                                        <div className="tagging-div">
                                            <Form.Item name={"cbtagging"} valuePropName="checked" className="cb-tagging">
                                                <Checkbox
                                                    onChange={e => {
                                                        templateForm.setFieldsValue({ tags: [] });
                                                        setEnableTagging(e.target.checked);
                                                    }}
                                                >
                                                    Add Tags
                                                </Checkbox>
                                            </Form.Item>

                                            {enableTagging && (
                                                <FormComponent
                                                    label=""
                                                    name={"tags"}
                                                    extra={{
                                                        type: ComponentType.dropdown,
                                                        itemProps: {
                                                            labelAlign: "left",
                                                            labelCol: { span: 5 },
                                                        },
                                                        inputProps: {
                                                            style: { width: "100%" },
                                                            mode: "multiple",
                                                            allowClear: true,
                                                        },
                                                        value: metricInfo.tags.map(x => ({
                                                            text: x.name,
                                                            value: x.id,
                                                        })),
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                                <Col>
                                    <Divider orientationMargin={10} />
                                </Col>
                                <Col flex={1} style={{ marginTop: "2.121vh" }}>
                                    <Row>
                                        <Button
                                            id="submit"
                                            loading={isBtnLoading}
                                            type="primary"
                                            htmlType="button"
                                            style={{ width: "auto" }}
                                            onClick={() => {
                                                templateForm
                                                    .validateFields()
                                                    .then(values => {
                                                        onFormSubmit(values);
                                                    })
                                                    .catch(error => {
                                                        templateForm.submit();
                                                    });
                                            }}
                                        >
                                            Submit
                                        </Button>
                                    </Row>
                                </Col>
                            </Form>
                        </Col>
                        <Modal
                            destroyOnClose
                            maskClosable={false}
                            title="Add Scheduler"
                            open={showAddSchedulerModal}
                            onOk={() => {
                                scheduledform
                                    .validateFields()
                                    .then(values => {
                                        scheduledform.resetFields();
                                        let tmp = getScheduledCronDescription(values);
                                        let schedulers = [
                                            ...templateForm.getFieldValue("schedulers"),
                                            { cronExpression: tmp.cronExpression, snapshot: tmp.snapshot },
                                        ];
                                        templateForm.setFieldsValue({ schedulers });
                                        setShowAddSchedulerModal(false);
                                    })
                                    .catch(info => {
                                        console.log("Validate Failed:", info);
                                    });
                            }}
                            onCancel={() => {
                                scheduledform.resetFields();
                                setShowAddSchedulerModal(false);
                            }}
                            bodyStyle={{ padding: "0px" }}
                        >
                            <ScheduledSetupTemplate form={scheduledform} />
                        </Modal>
                    </div>
                </div>
            )}
        </SitePageHeader>
    );
};

export default CreateTemplatePage;
