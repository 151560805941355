import FlexiDataTable from "@/components/FlexiDataTable";
import { ComponentType, CALLBACK_KEY, SUCCESS_FAILED } from "@/constants";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps } from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { ErrorMessageHandler, ErrorCatchValidator, DTColProps } from "@/utils/Common";
import { Tag } from "antd";
import { useState, useEffect } from "react";
import FundingFeeSettingsCreditEditModal, { FundingFeeSettingsCreditEditModalCallbackKey } from "./fundingFeeSettingsCreateEditModal";
import PanelContainer from "../../PanelContainer";

export interface FundingFeeSettingPageProps { }

export interface FundingFeeSettingProps {
    symbol: string;
    symbolMap: string;
    receiveHour: number;
    mapType: number;
}

const FundingFeeSettingPage = (props: FundingFeeSettingPageProps) => {
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [editableData, setEditableData] = useState<undefined | FundingFeeSettingProps>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<FundingFeeSettingProps[]>([]);

    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.CENTRALIZED_SETTINGS_FUNDING_FEE_SETTINGS_EDIT);

    const columns: any[] = [
        DTColProps.Middle({
            title: "Symbol",
            dataIndex: "symbol",
            key: "symbol",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Middle({
            title: "Exchange Symbol",
            dataIndex: "symbolMap",
            key: "symbolMap",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        {
            title: "Receive Hour",
            dataIndex: "receiveHour",
            key: "receiveHour",
            render: (text: number) =>
                [text]
                    .reduce((arr: string[], curr: number) => {
                        for (let i = 0; i < 24; i++) {
                            if ((text >> i) & 1) {
                                arr.push(`${i.toString().padStart(2, "0")}:00`);
                            }
                        }
                        return arr;
                    }, [])
                    .map((x: string, idx: number) => <Tag key={`rhtg-${x}-${idx}`}>{x}</Tag>),
        },
    ];

    const options: FlexiDataTableOptionsProps = {
        separateActionButton: true,
        add: enableUpdate,
        edit: enableUpdate,
        delete: enableUpdate,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, BrandData) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                setIsModalVisible(true);
                setEditableData(undefined);
                break;
            case CALLBACK_KEY.DO_EDIT:
                setIsModalVisible(true);
                setEditableData(BrandData);
                break;
            case CALLBACK_KEY.DO_DELETE:
                deleteFundingFeeSetting(BrandData);
                break;
            default:
                break;
        }
    };

    const deleteFundingFeeSetting = (item: FundingFeeSettingProps) => {
        setIsLoading(true);
        plainAxiosInstance
            .delete(
                `${APIs.RISK_TOOL.GET_FUNDING_FEE_SETTINGS}?symbol=${encodeURIComponent(item.symbol)}&mapType=${encodeURIComponent(item.mapType)}`
            )
            .then((res: any) => {
                if (res.data === "SUCCESS") {
                    ErrorMessageHandler("funding fee settings", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                } else {
                    ErrorMessageHandler("funding fee settings", SUCCESS_FAILED.FAILED_DELETE_DATA);
                }
            })
            .catch(error =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("funding fee settings", SUCCESS_FAILED.FAILED_DELETE_DATA, err))
            )
            .finally(() => getFundingFeeSettingList());
    };

    const getFundingFeeSettingList = () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(APIs.RISK_TOOL.GET_FUNDING_FEE_SETTINGS, {})
            .then((res: any) => {
                setData(res.data && res.data.length > 0 ? res.data : []);
            })
            .catch(error =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("funding fee settings", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getFundingFeeSettingList();
    }, []);

    return (
        <PanelContainer title="Funding Fee Settings">
            <div className="funding-settings-page-container">
                <FlexiDataTable
                    bordered
                    rowKeyProperty="symbol"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={data}
                    callback={componentCallback}
                    loading={isLoading}
                />
            </div>
            <FundingFeeSettingsCreditEditModal
                isModalVisible={isModalVisible}
                callback={(type: number, data: any) => {
                    switch (type) {
                        case FundingFeeSettingsCreditEditModalCallbackKey.Close:
                            setIsModalVisible(false);
                            break;
                        case FundingFeeSettingsCreditEditModalCallbackKey.SubmitForm:
                            setIsModalVisible(false);
                            setIsLoading(true);
                            break;
                        case FundingFeeSettingsCreditEditModalCallbackKey.SubmitDone:
                            getFundingFeeSettingList();
                            break;
                        default:
                            break;
                    }
                }}
                data={editableData}
            />
        </PanelContainer>
    );
};

export default FundingFeeSettingPage;
