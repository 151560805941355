import CardBox from "@/components/Common/CardBox";
import { FormComponent } from "@/components/FormComponent";
import { ComponentType, SUCCESS_FAILED } from "@/constants";
import { ContainerOutlined, DownloadOutlined } from "@ant-design/icons";
import { Button, Form } from "antd";
import { useEffect, useState } from "react";
import ReportBatchModal, { ReportBatchModalCallbackKey } from "../ReportBatch";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import moment from "moment";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { APIs } from "@/services/apis";
import { ErrorMessageHandler } from "@/utils/Common";

interface EODPriceReportPageProps {}

const EODPriceReportPage = (props: EODPriceReportPageProps) => {
    const [isReportModalVisible, setIsReportModalVisible] = useState<boolean>(false);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [executeForm] = Form.useForm();

    const downloadReport = () => {
        executeForm
            .validateFields()
            .then((values: any) => {
                setIsDownloading(true);
                const formData = new FormData();
                formData.append("tradeDate", values["tradeDate"].format("YYYY-MM-DD"));

                plainAxiosInstance
                    .post(APIs.RC_REPORT.DOWNLOAD_EOD_REPORT, formData)
                    .then((res: any) => {
                        if (res.data === 0) {
                            ErrorMessageHandler("Add to batch successed. Please download from【Report Listing】.", SUCCESS_FAILED.OTHERS_SUCCESS);
                        } else {
                            ErrorMessageHandler(`Add to batch failed.`, SUCCESS_FAILED.OTHERS_FAILED);
                        }
                    })
                    .catch((error: any) => ErrorMessageHandler(`Add to batch failed: "${error.message}".`, SUCCESS_FAILED.OTHERS_FAILED))
                    .finally(() => setIsDownloading(false));
            })
            .catch(() => console.log("error"));
    };

    useEffect(() => {
        executeForm.setFieldsValue({ tradeDate: moment().subtract(1, "days") });
    }, []);

    return (
        <>
            <div className="report-eodprice-listing">
                <CardBox title={"EOD Price"}>
                    <Form form={executeForm} layout="inline">
                        <div className="main-container">
                            <FormComponent
                                label="Report Date"
                                name="tradeDate"
                                extra={{
                                    type: ComponentType.date,
                                    value: "",
                                    dateFormat: "YYYY-MM-DD",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                    itemProps: { className: "no-margin" },
                                    inputProps: {
                                        disabledDate: (current: any) => !current || current.isAfter(moment().format("YYYY-MM-DD")),
                                    },
                                }}
                            />
                            <Button
                                htmlType="button"
                                type="primary"
                                icon={<DownloadOutlined style={{ fontSize: "0.875rem" }} />}
                                onClick={() => downloadReport()}
                                loading={isDownloading}
                            >
                                {isDownloading ? "Downloading" : "Download"}
                            </Button>
                            <Button
                                icon={<ContainerOutlined />}
                                onClick={event => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    setIsReportModalVisible(true);
                                }}
                            >
                                Report Listing
                            </Button>
                        </div>
                    </Form>
                </CardBox>
            </div>
            <ReportBatchModal
                isModalVisible={isReportModalVisible}
                modalTitle={"Download EOD Price Report"}
                callback={(type: number) => {
                    switch (type) {
                        case ReportBatchModalCallbackKey.Close:
                            setIsReportModalVisible(false);
                            break;
                        default:
                            break;
                    }
                }}
                filterCallback={(record: any) => record.batchName === "EodPrice"}
            />
        </>
    );
};

export default EODPriceReportPage;
