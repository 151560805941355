import FlexiDataTable from "@/components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "@/constants";
import { FlexiDataTableCallbackProps, FlexiDataTableOptionsProps, KeyValuePair } from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler, getFileNameFromResponseHeader } from "@/utils/Common";
import { defaultIfEmptyOrNull } from "@/utils/string";
import { DownloadOutlined, EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Badge, Form, Modal } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import ReservationSettingCreateModal, { ReservationSettingCreateModalCallbackKey } from "./reservationCreateModal";
import { FormComponent } from "@/components/FormComponent";
import { LabelTypes } from "./hthlist";
import { SortList, ToObjectWithKey } from "@/utils/array";
import moment from "moment";

interface ReservationSettingPagesProps {}

interface ReservationSettingItemProps {
    id: string;
    serverUno: number;
    serverName: string;
    login: string;
    loginGroup: string;
    symbol: string;
    hthStatus: number;
    comment: string;
    note: string;
    label: string;
    updateTime: string;
    inspector: string;
}

export interface ConfigDatasProps {
    brands: KeyValuePair[];
    servers: KeyValuePair[];
    symbols: KeyValuePair[];
    groups: KeyValuePair[];
}

interface SubmitDataProps {
    batchDeletes: any[];
    batchUpdates: any[];
}

const ReservationSettingPages = (props: ReservationSettingPagesProps) => {
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [isCreateModalVisible, setIsCreateModalVisible] = useState<boolean>(false);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [configData, setConfigData] = useState<ConfigDatasProps>({ brands: [], servers: [], symbols: [], groups: [] });
    const [data, setData] = useState<ReservationSettingItemProps[]>([]);
    const [deletedRowKeys, setDeletedRowKeys] = useState<string[]>([]);

    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.RISK_TOOLS_HUB_TO_HUB_EDIT);
    const [tableForm] = Form.useForm();

    const filteredDatas = useMemo(() => data.filter(x => !deletedRowKeys.includes(x.id)), [data, deletedRowKeys]);

    const prepareBatchEdit = useCallback(() => {
        setIsEditMode(prev => !prev);

        data.forEach((x: ReservationSettingItemProps) => {
            tableForm.setFieldsValue({
                [`${x.id}-symbol`]: x.symbol,
                [`${x.id}-comment`]: x.comment,
                [`${x.id}-note`]: x.note,
                [`${x.id}-label`]: x.label,
                [`${x.id}-hthStatus`]: x.hthStatus === 1,
            });
        });
    }, [data]);

    const columns = useMemo(
        () => [
            DTColProps.XSmall({
                title: "Server",
                dataIndex: "serverName",
                key: "serverName",
            }),
            DTColProps.XSmall({
                title: "Login",
                dataIndex: "login",
                key: "login",
            }),
            {
                title: "Bridge",
                dataIndex: "bridgeId",
                key: "bridgeId",
            },
            {
                title: "Group",
                dataIndex: "loginGroup",
                key: "loginGroup",
            },
            DTColProps.Small({
                title: "Symbol",
                dataIndex: "symbol",
                key: "symbol",
                ...(isEditMode && {
                    render: (text: string, rowData: ReservationSettingItemProps) => (
                        <FormComponent
                            label=""
                            name={`${rowData.id}-symbol`}
                            extra={{ type: ComponentType.dropdown, value: configData.symbols, itemProps: { className: "no-margin" } }}
                        />
                    ),
                }),
            }),
            {
                title: "Inspector",
                dataIndex: "inspector",
                key: "inspector",
            },
            DTColProps.XXSmall({
                width: "4.5vw",
                title: "Date",
                dataIndex: "updateTime",
                key: "updateTime",
                render: (text: string) => moment(text).format("YYYY-MM-DD"),
            }),
            {
                title: "Comment",
                dataIndex: "comment",
                key: "comment",
                ...(isEditMode && {
                    render: (text: string, rowData: ReservationSettingItemProps) => (
                        <FormComponent
                            label=""
                            name={`${rowData.id}-comment`}
                            extra={{ type: ComponentType.text, value: "", itemProps: { className: "no-margin" } }}
                        />
                    ),
                }),
            },
            {
                title: "Note",
                dataIndex: "note",
                key: "note",
                ...(isEditMode && {
                    render: (text: string, rowData: ReservationSettingItemProps) => (
                        <FormComponent
                            label=""
                            name={`${rowData.id}-note`}
                            extra={{ type: ComponentType.text, value: "", itemProps: { className: "no-margin" } }}
                        />
                    ),
                }),
            },
            {
                title: "Label",
                dataIndex: "label",
                key: "label",
                ...(isEditMode && {
                    render: (text: string, rowData: ReservationSettingItemProps) => (
                        <FormComponent
                            label=""
                            name={`${rowData.id}-label`}
                            extra={{ type: ComponentType.dropdown, value: LabelTypes, itemProps: { className: "no-margin" } }}
                        />
                    ),
                }),
            },
            DTColProps.XSmall({
                title: "Action",
                dataIndex: "hthStatus",
                key: "hthStatus",
                render: isEditMode
                    ? (text: number, rowData: ReservationSettingItemProps) => (
                          <FormComponent
                              label=""
                              name={`${rowData.id}-hthStatus`}
                              extra={{ type: ComponentType.switch, value: ["Disabled", "Enable"], itemProps: { className: "no-margin" } }}
                          />
                      )
                    : (text: number) =>
                          text === 1 ? (
                              <Badge status="success" text="Enable" className="badge-blinking" />
                          ) : (
                              <Badge status="default" text={<span style={{ color: "#a5a5a5" }}>Disable</span>} />
                          ),
            }),
        ],
        [isEditMode, LabelTypes, configData]
    );

    const options: FlexiDataTableOptionsProps = useMemo(
        () => ({
            enableFilter: false,
            separateActionButton: true,
            hideRowSelectionsSummary: true,
            ...(enableUpdate && {
                delete: isEditMode,
                add: {
                    text: "Add Client",
                },
                extraButtons: [
                    ...(isEditMode
                        ? [
                              { text: "Submit Changes", icon: <EditOutlined />, value: "btnSaveTable", type: "primary" },
                              { text: "Cancel", icon: <EditOutlined />, value: "btnCancelTable", type: "primary", extras: { danger: true } },
                          ]
                        : [{ text: "Batch Edit", icon: <EditOutlined />, value: "batchEdit", type: "primary", extras: { danger: true } }]),

                    { text: "Download Template", icon: <DownloadOutlined />, value: "downloadtemplate", extras: { loading: isDownloading } },
                ],
                upload: {
                    name: "file",
                    multiple: false,
                    accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                    showUploadList: false,
                    onChange: (info: any) => {
                        if (info.file.status === "error") {
                            ErrorMessageHandler(`${info.file.name} file upload failed.`, SUCCESS_FAILED.OTHERS_FAILED);
                        }
                    },
                    customRequest: ({ file, onSuccess }: any) =>
                        setTimeout(() => {
                            onSuccess("ok");
                        }, 0),
                    beforeUpload: (file: any) => handleSubmit(file),
                },
            }),
        }),
        [enableUpdate, isDownloading, isEditMode]
    );

    const componentCallback: FlexiDataTableCallbackProps = (type: number, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                setIsCreateModalVisible(true);
                break;
            case CALLBACK_KEY.DO_DELETE:
                tableForm.setFieldsValue({ [`${FormData.id}-deleted`]: true });
                setDeletedRowKeys(prev => [...prev, FormData.id]);
                break;
            case CALLBACK_KEY.OTHERS:
                if (FormData === "downloadtemplate") {
                    downloadTemplate();
                } else if (FormData === "batchEdit") {
                    prepareBatchEdit();
                } else if (FormData === "btnSaveTable") {
                    submitChanges();
                } else if (FormData === "btnCancelTable") {
                    setIsEditMode(false);
                    setDeletedRowKeys([]);
                }
                break;
            default:
                break;
        }
    };

    const getSubmitData = useCallback(
        (values: any): SubmitDataProps => {
            let oriDataWithKey = ToObjectWithKey(data, "id"),
                dataWithKey = Object.keys(values).reduce((acc: any, key: string) => {
                    let tmpKeyArr = key.split("-"),
                        realKey = tmpKeyArr.slice(0, 4).join("-");
                    if (!acc.hasOwnProperty(realKey)) acc[realKey] = { symbol: "", comment: "", label: "", note: "", hthStatus: 0 };

                    acc[realKey][tmpKeyArr[4]] = tmpKeyArr[4] === "hthStatus" ? (values[key] ? 1 : 0) : values[key];
                    return acc;
                }, {}),
                deletedRows = data.filter(x => dataWithKey.hasOwnProperty(x.id) && dataWithKey[x.id].hasOwnProperty("deleted")),
                editRows = Object.keys(dataWithKey).reduce((arr: any[], key: string) => {
                    let oriFoundData = oriDataWithKey[key];

                    if (
                        !Object.keys(dataWithKey[key]).includes("deleted") &&
                        Object.keys(dataWithKey[key]).some(x => `${dataWithKey[key][x]}` !== `${oriFoundData[x]}`)
                    ) {
                        arr.push({ ...oriFoundData, ...dataWithKey[key] });
                    }
                    return arr;
                }, []);

            return { batchDeletes: deletedRows, batchUpdates: editRows };
        },
        [data]
    );

    const submitChanges = useCallback(() => {
        Modal.confirm({
            title: "Are you sure you want to submit changes?",
            onOk() {
                setIsLoading(true);
                setIsEditMode(false);
                setDeletedRowKeys([]);
                let changes = getSubmitData(tableForm.getFieldValue(undefined)),
                    requestArr = [];
                if (changes.batchUpdates.length > 0) {
                    requestArr.push(plainAxiosInstance.post(APIs.RISK_TOOL.GET_HUB_TO_HUB_RESERVATION_SETTING_BATCH_UPDATE, changes.batchUpdates));
                }
                if (changes.batchDeletes.length > 0) {
                    requestArr.push(
                        plainAxiosInstance.delete(APIs.RISK_TOOL.GET_HUB_TO_HUB_RESERVATION_SETTING_BATCH_DELETE, { data: changes.batchDeletes })
                    );
                }
                Promise.all(requestArr)
                    .then((res: any[]) => {
                        if (res.filter(x => x.data.status !== 0).length > 0) {
                            ErrorMessageHandler("Error during batch update.", SUCCESS_FAILED.OTHERS_FAILED);
                        } else {
                            ErrorMessageHandler("Batch update successfully.", SUCCESS_FAILED.OTHERS_SUCCESS);
                        }
                    })
                    .catch((error: any) =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("Error during batch update.", SUCCESS_FAILED.OTHERS_FAILED, err))
                    )
                    .finally(() => {
                        getListing();
                        tableForm.resetFields();
                    });
            },
        });
    }, [data]);

    const handleSubmit = (file: any) => {
        try {
            let fileExtension: string[] = defaultIfEmptyOrNull(/\.[^\.]+/.exec(file.name), [""]),
                isLt5M = file.size / 1024 / 1024 < 5;

            if (fileExtension[0] !== ".csv") {
                ErrorMessageHandler("Please check file type. Only .csv files are allowed.", SUCCESS_FAILED.OTHERS_FAILED);
                return;
            } else if (!isLt5M) {
                ErrorMessageHandler("Please check file size less than 5 MB.", SUCCESS_FAILED.OTHERS_FAILED);
                return;
            }

            Modal.confirm({
                icon: <ExclamationCircleOutlined />,
                title: "Are you sure you want to import?",
                width: "30%",
                onOk() {
                    var formData = new FormData();
                    formData.append("file", file);

                    plainAxiosInstance
                        .post(APIs.RISK_TOOL.GET_HUB_TO_HUB_RESERVATION_SETTING_UPLOAD, formData)
                        .then(res => {
                            if (res.data.status === 0) {
                                ErrorMessageHandler(`File upload successfully.`, SUCCESS_FAILED.OTHERS_SUCCESS);
                            } else {
                                ErrorMessageHandler(`${res.data}.`, SUCCESS_FAILED.OTHERS_FAILED);
                            }
                        })
                        .catch((error: any) =>
                            ErrorCatchValidator(error, (err: any) => ErrorMessageHandler(`File upload failed:`, SUCCESS_FAILED.OTHERS_FAILED, err))
                        )
                        .finally(() => getListing());
                },
                onCancel() {},
            });
        } catch (error) {
            ErrorMessageHandler(`Error during uploading file. Please try again.`, SUCCESS_FAILED.OTHERS_FAILED);
        }
    };

    const downloadTemplate = () => {
        setIsDownloading(true);
        try {
            plainAxiosInstance
                .get(APIs.RISK_TOOL.GET_HUB_TO_HUB_RESERVATION_SETTING_DOWNLOAD_TEMPLATE, {
                    headers: {
                        Accept: "application/octet-stream, */*",
                    },
                    responseType: "blob",
                })
                .then(response => {
                    const contentType = response.headers["content-type"];
                    const fileName = getFileNameFromResponseHeader(response, `hth_upload_template.xlsx`);
                    if (
                        contentType === "application/octet-stream" ||
                        contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    ) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", fileName);
                        document.body.appendChild(link);
                        link.click();
                        // Clean up
                        window.URL.revokeObjectURL(url);
                    } else {
                        ErrorMessageHandler(`Received non-file response. Error: ${response}`, SUCCESS_FAILED.OTHERS_FAILED);
                    }
                })
                .catch((error: any) => ErrorMessageHandler(`Error occured during download: "${error.message}"`, SUCCESS_FAILED.OTHERS_FAILED))
                .finally(() => setIsDownloading(false));
        } catch (e: any) {
            ErrorMessageHandler(`Error occured during download: "${e.message}"`, SUCCESS_FAILED.OTHERS_FAILED);
            setIsDownloading(false);
        }
    };

    const getListing = () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(APIs.RISK_TOOL.GET_HUB_TO_HUB_RESERVATION_SETTING_LISTING)
            .then((res: any) => {
                setData(
                    res.data.status === 0 && res.data.data.length > 0
                        ? res.data.data.map((x: any, idx: number) => ({ ...x, id: `${x.serverUno}-${x.login}-${x.symbol}-${x.loginGroup}${idx}` }))
                        : []
                );
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("HTH reservation list", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    };

    const getConfigs = () => {
        plainAxiosInstance
            .get(APIs.RISK_TOOL.GET_HUB_TO_HUB_LISTING_CONFIGS)
            .then((res: any) => {
                if (res.data.status === 0) {
                    setConfigData(prev => ({
                        ...prev,
                        brands: SortList(
                            Object.keys(res.data.data.brands).map((key: string) => ({ text: res.data.data.brands[key], value: key })),
                            "text"
                        ),
                        servers: SortList(
                            Object.keys(res.data.data.servers).map((key: string) => ({ text: res.data.data.servers[key], value: key })),
                            "text"
                        ),
                        symbols: SortList(
                            res.data.data.symbols.map((item: any) => ({ text: item, value: item })),
                            "text"
                        ),
                        groups: SortList(
                            res.data.data.groups.map((item: any) => ({ text: item, value: item })),
                            "text"
                        ),
                    }));
                } else {
                    ErrorMessageHandler(res.data.msg, SUCCESS_FAILED.OTHERS_FAILED);
                }
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("HTH config", SUCCESS_FAILED.FAILED_LOAD_DATA, err)))
            .finally(() => getListing());
    };

    useEffect(() => {
        getConfigs();
    }, []);

    return (
        <>
            <div className="reservation-settings-page-container">
                <FlexiDataTable
                    bordered
                    rowKeyProperty="id"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={filteredDatas}
                    callback={componentCallback}
                    loading={isLoading}
                    tableFormInstance={tableForm}
                />
            </div>
            <ReservationSettingCreateModal
                isModalVisible={isCreateModalVisible}
                callback={(type: number, data: any) => {
                    switch (type) {
                        case ReservationSettingCreateModalCallbackKey.Close:
                            setIsCreateModalVisible(false);
                            break;
                        case ReservationSettingCreateModalCallbackKey.CloseAndRefetch:
                            setIsCreateModalVisible(false);
                            getListing();
                            break;
                        default:
                            break;
                    }
                }}
                configData={configData}
            />
        </>
    );
};

export default ReservationSettingPages;
