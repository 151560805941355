import { useEffect, useMemo, useState } from "react";
import { apiRequest } from "../../../services/apiConfig";
import { APIs } from "../../../services/apis";
import { DTColProps, DataTableColumnRender, ErrorCatchValidator } from "../../../utils/Common";
import LoadingComponent from "../../../components/Loading";
import { useParams } from "react-router-dom";
import { DateTimeUtil } from "../../../utils/datetime";
import FlexiDataTable from "../../../components/FlexiDataTable";
import moment from "moment";
import TinyAreaChart from "../../../components/Charts/SubComponents/TinyArea";
import { ComponentType } from "../../../constants";
import { HightlightExpression, ServersList } from "../../../constants/type";
import { rangeArrayInit } from "../../../utils/array";
import DecayAnalysisAccountSummary from "../DecayChart/Components/DecayAnalysisAccountSummary";
import { TagOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

export interface ToxicAccountEventSnapshotProps { }

interface ToxicAccountStatsProps {
    toxicAccountEventStats: ToxicAccountEventStats;
    decayAnalysisAccountStatsSnapshot: DecayAnalysisAccountStats;
    decayAnalysisTradeStatsSnapshot: ToxicAccountDecayAnalysisTicketStats[];
}

interface ToxicAccountEventStats {
    id: number;
    capturedTime: string;
    accountId: number;
    serverId: number;
    server: string;
    preMarketScoringMode: string;
    preMarketScoringWeightage: number;
    preMarketScore: number;
    preMarketWeightedScore: number;
    decayScoringMode: string;
    decayScoringWeightage: number;
    decayScore: number;
    decayWeightedScore: number;
    volumeMAScoringMode: string;
    volumeMAScoringWeightage: number;
    volumeMAScore: number;
    volumeMAWeightedScore: number;
    finalScore: number;
}

export interface DecayAnalysisAccountStats {
    maxDpmIn20Min?: number;
    maxProfitIn20Min?: number;
    dpmSlopeIn20Min?: number;
    dpmRSquaredIn20Min?: number;
    decayScore?: number;
    accountId: number;
    serverId: number;
    server: string;
    todayOpenedTradeCount?: number;
    todayClosedTradeCount?: number;
    todayClosedPnl: number;
    lastTradeOpenedTime?: string;
    lastTradeClosedTime?: string;
    dpm60: number;
    dpm120: number;
    dpm180: number;
    dpm240: number;
    dpm300: number;
    dpm360: number;
    dpm420: number;
    dpm480: number;
    dpm540: number;
    dpm600: number;
    dpm660: number;
    dpm720: number;
    dpm780: number;
    dpm840: number;
    dpm900: number;
    dpm960: number;
    dpm1020: number;
    dpm1080: number;
    dpm1140: number;
    dpm1200: number;
    profit60: number;
    profit120: number;
    profit180: number;
    profit240: number;
    profit300: number;
    profit360: number;
    profit420: number;
    profit480: number;
    profit540: number;
    profit600: number;
    profit660: number;
    profit720: number;
    profit780: number;
    profit840: number;
    profit900: number;
    profit960: number;
    profit1020: number;
    profit1080: number;
    profit1140: number;
    profit1200: number;
}

interface ToxicAccountDecayAnalysisTicketStats {
    isTriggedByVolumeMAModel: boolean;
    lowerBoundaryBucket: string;
    peakBucket: string;
    upperBoundaryBucket: string;
    accountId: number;
    serverId: number;
    server: string;
    ticket: number;
    positionId: number;
    symbol: string;
    contractSize: number;
    action: string;
    entry: string;
    lotSize: number;
    openTime: string;
    dpm60: number;
    dpm120: number;
    dpm180: number;
    dpm240: number;
    dpm300: number;
    dpm360: number;
    dpm420: number;
    dpm480: number;
    dpm540: number;
    dpm600: number;
    dpm660: number;
    dpm720: number;
    dpm780: number;
    dpm840: number;
    dpm900: number;
    dpm960: number;
    dpm1020: number;
    dpm1080: number;
    dpm1140: number;
    dpm1200: number;
    profit60: number;
    profit120: number;
    profit180: number;
    profit240: number;
    profit300: number;
    profit360: number;
    profit420: number;
    profit480: number;
    profit540: number;
    profit600: number;
    profit660: number;
    profit720: number;
    profit780: number;
    profit840: number;
    profit900: number;
    profit960: number;
    profit1020: number;
    profit1080: number;
    profit1140: number;
    profit1200: number;
}

const ToxicAccountEventSnapshot = (props: ToxicAccountEventSnapshotProps) => {
    let { id } = useParams();

    const [data, setData] = useState<ToxicAccountStatsProps | undefined>(undefined);
    const [servers, setServers] = useState<ServersList[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const columnsConfig = () => {
        const hightExpression: HightlightExpression = (value: number) => value > 0;
        return [
            DTColProps.Small({
                title: "Ticket",
                dataIndex: "ticket",
                key: "ticket",
                fixed: "left",
                render: (ticket: number, rowData: ToxicAccountDecayAnalysisTicketStats) => {
                    if (rowData.isTriggedByVolumeMAModel) {
                        return (
                            <div>
                                <span>{ticket}</span>
                                <Tooltip
                                    overlayClassName="triggerMode-custom"
                                    title={
                                        <div className="triggerMode-container">
                                            <div>
                                                <span>Peak Bucket</span>
                                                <span>
                                                    :
                                                    {rowData.peakBucket
                                                        ? DataTableColumnRender.DateTime_ServerTime(rowData.peakBucket, "YYYY-MM-DD HH:mm:ss")
                                                        : ""}
                                                </span>
                                            </div>
                                            <div>
                                                <span>Lower Boundary Bucket</span>
                                                <span>
                                                    :
                                                    {rowData.lowerBoundaryBucket
                                                        ? DataTableColumnRender.DateTime_ServerTime(
                                                            rowData.lowerBoundaryBucket,
                                                            "YYYY-MM-DD HH:mm:ss"
                                                        )
                                                        : ""}
                                                </span>
                                            </div>
                                            <div>
                                                <span>Upper Boundary Bucket</span>
                                                <span>
                                                    :
                                                    {rowData.upperBoundaryBucket
                                                        ? DataTableColumnRender.DateTime_ServerTime(
                                                            rowData.upperBoundaryBucket,
                                                            "YYYY-MM-DD HH:mm:ss"
                                                        )
                                                        : ""}
                                                </span>
                                            </div>
                                        </div>
                                    }
                                >
                                    <TagOutlined style={{ cursor: "pointer", marginLeft: "0.3vw", color: "#f00f00", fontSize: "0.75rem" }} />
                                </Tooltip>
                            </div>
                        );
                    }
                    return ticket;
                },
                options: {
                    filter: {
                        type: ComponentType.number,
                    },
                },
            }),
            DTColProps.XSmall({
                title: "Position ID",
                dataIndex: "positionId",
                key: "positionId",
            }),
            DTColProps.XSmall({
                title: "Symbol",
                dataIndex: "symbol",
                key: "symbol",
            }),

            DTColProps.DateTime_ServerTime({
                title: "Open Time (Server)",
                dataIndex: "openTime",
                key: "openTime",
                sorter: (a: any, b: any) => (moment(a.openTime) > moment(b.openTime) ? -1 : 1),
                render: (value: string) => (value ? DataTableColumnRender.DateTime_ServerTime(value, "YYYY-MM-DD HH:mm:ss.SSS") : ""),
                options: {
                    filter: {
                        type: ComponentType.daterange,
                        value: "",
                        inputProps: {
                            showTime: { format: "HH:mm:ss" },
                        },
                    },
                },
            }),
            DTColProps.Small({
                width: "5vw",
                title: "Contract Size",
                dataIndex: "contractSize",
                key: "contractSize",
                sorter: (a: any, b: any) => a.contractSize - b.contractSize,
            }),
            DTColProps.XSmall({
                width: "3.8vw",
                title: "Lot Size",
                dataIndex: "lotSize",
                key: "lotSize",
                sorter: (a: any, b: any) => a.lotSize - b.lotSize,
            }),
            DTColProps.XSmall({
                width: "3.2vw",
                title: "Action",
                dataIndex: "action",
                key: "action",
            }),
            DTColProps.XSmall({
                width: "3vw",
                title: "Entry",
                dataIndex: "entry",
                key: "entry",
            }),
            {
                title: "DPM",
                children: [
                    DTColProps.SCurrency(
                        {
                            title: "1min",
                            dataIndex: "dpm60",
                            key: "dpm60",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.dpm60 - b.dpm60,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "2min",
                            dataIndex: "dpm120",
                            key: "dpm120",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.dpm120 - b.dpm120,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "3min",
                            dataIndex: "dpm180",
                            key: "dpm180",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.dpm180 - b.dpm180,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "4min",
                            dataIndex: "dpm240",
                            key: "dpm240",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.dpm240 - b.dpm240,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "5min",
                            dataIndex: "dpm300",
                            key: "dpm300",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.dpm300 - b.dpm300,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "6min",
                            dataIndex: "dpm360",
                            key: "dpm360",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.dpm360 - b.dpm360,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "7min",
                            dataIndex: "dpm420",
                            key: "dpm420",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.dpm420 - b.dpm420,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "8min",
                            dataIndex: "dpm480",
                            key: "dpm480",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.dpm480 - b.dpm480,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "9min",
                            dataIndex: "dpm540",
                            key: "dpm540",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.dpm540 - b.dpm540,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "10min",
                            dataIndex: "dpm600",
                            key: "dpm600",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.dpm600 - b.dpm600,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "11min",
                            dataIndex: "dpm660",
                            key: "dpm660",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.dpm660 - b.dpm660,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "12min",
                            dataIndex: "dpm720",
                            key: "dpm720",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.dpm720 - b.dpm720,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "13min",
                            dataIndex: "dpm780",
                            key: "dpm780",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.dpm780 - b.dpm780,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "14min",
                            dataIndex: "dpm840",
                            key: "dpm840",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.dpm840 - b.dpm840,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "15min",
                            dataIndex: "dpm900",
                            key: "dpm900",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.dpm900 - b.dpm900,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "16min",
                            dataIndex: "dpm960",
                            key: "dpm960",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.dpm960 - b.dpm960,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "17min",
                            dataIndex: "dpm1020",
                            key: "dpm1020",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.dpm1020 - b.dpm1020,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "18min",
                            dataIndex: "dpm1080",
                            key: "dpm1080",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.dpm1080 - b.dpm1080,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "19min",
                            dataIndex: "dpm1140",
                            key: "dpm1140",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.dpm1140 - b.dpm1140,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "20min",
                            dataIndex: "dpm1200",
                            key: "dpm1200",
                            width: "3.5vw",
                            sorter: (a: any, b: any) => a.dpm1200 - b.dpm1200,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                ],
            },
            {
                title: "Profit",
                children: [
                    DTColProps.SCurrency(
                        {
                            title: "1min",
                            dataIndex: "profit60",
                            key: "profit60",
                            width: "5.5vw",
                            sorter: (a: any, b: any) => a.profit60 - b.profit60,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "2min",
                            dataIndex: "profit120",
                            key: "profit120",
                            width: "5.5vw",
                            sorter: (a: any, b: any) => a.profit120 - b.profit120,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "3min",
                            dataIndex: "profit180",
                            key: "profit180",
                            width: "5.5vw",
                            sorter: (a: any, b: any) => a.profit180 - b.profit180,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "4min",
                            dataIndex: "profit240",
                            key: "profit240",
                            width: "5.5vw",
                            sorter: (a: any, b: any) => a.profit240 - b.profit240,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "5min",
                            dataIndex: "profit300",
                            key: "profit300",
                            width: "5.5vw",
                            sorter: (a: any, b: any) => a.profit300 - b.profit300,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "6min",
                            dataIndex: "profit360",
                            key: "profit360",
                            width: "5.5vw",
                            sorter: (a: any, b: any) => a.profit360 - b.profit360,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "7min",
                            dataIndex: "profit420",
                            key: "profit420",
                            width: "5.5vw",
                            sorter: (a: any, b: any) => a.profit420 - b.profit420,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "8min",
                            dataIndex: "profit480",
                            key: "profit480",
                            width: "5.5vw",
                            sorter: (a: any, b: any) => a.profit480 - b.profit480,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "9min",
                            dataIndex: "profit540",
                            key: "profit540",
                            width: "5.5vw",
                            sorter: (a: any, b: any) => a.profit540 - b.profit540,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "10min",
                            dataIndex: "profit600",
                            key: "profit600",
                            width: "5.5vw",
                            sorter: (a: any, b: any) => a.profit600 - b.profit600,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "11min",
                            dataIndex: "profit660",
                            key: "profit660",
                            width: "5.5vw",
                            sorter: (a: any, b: any) => a.profit660 - b.profit660,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "12min",
                            dataIndex: "profit720",
                            key: "profit720",
                            width: "5.5vw",
                            sorter: (a: any, b: any) => a.profit720 - b.profit720,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "13min",
                            dataIndex: "profit780",
                            key: "profit780",
                            width: "5.5vw",
                            sorter: (a: any, b: any) => a.profit780 - b.profit780,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "14min",
                            dataIndex: "profit840",
                            key: "profit840",
                            width: "5.5vw",
                            sorter: (a: any, b: any) => a.profit840 - b.profit840,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "15min",
                            dataIndex: "profit900",
                            key: "profit900",
                            width: "5.5vw",
                            sorter: (a: any, b: any) => a.profit900 - b.profit900,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "16min",
                            dataIndex: "profit960",
                            key: "profit960",
                            width: "5.5vw",
                            sorter: (a: any, b: any) => a.profit960 - b.profit960,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "17min",
                            dataIndex: "profit1020",
                            key: "profit1020",
                            width: "5.5vw",
                            sorter: (a: any, b: any) => a.profit1020 - b.profit1020,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "18min",
                            dataIndex: "profit1080",
                            key: "profit1080",
                            width: "5.5vw",
                            sorter: (a: any, b: any) => a.profit1080 - b.profit1080,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "19min",
                            dataIndex: "profit1140",
                            key: "profit1140",
                            width: "5.5vw",
                            sorter: (a: any, b: any) => a.profit1140 - b.profit1140,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                    DTColProps.SCurrency(
                        {
                            title: "20min",
                            dataIndex: "profit1200",
                            key: "profit1200",
                            width: "5.5vw",
                            sorter: (a: any, b: any) => a.profit1200 - b.profit1200,
                        },
                        [],
                        -1,
                        hightExpression
                    ),
                ],
            },
            DTColProps.Middle({
                title: "Trend",
                dataIndex: "isTriggedByVolumeMAModel",
                key: "isTriggedByVolumeMAModel",
                fixed: "right",
                render: (isTriggedByVolumeMAModel: boolean, rowData: any) => {
                    let ftmp: number[] = rangeArrayInit(20, 1).reduce((finalArr: number[], x: number) => {
                        if (rowData?.hasOwnProperty(`dpm${x * 60}`) && typeof rowData[`dpm${x * 60}`] === "number")
                            finalArr.push(rowData[`dpm${x * 60}`] as number);
                        return finalArr;
                    }, []);
                    let checkColorIdx = ftmp.length - 1;
                    for (; checkColorIdx > 0; checkColorIdx--) {
                        if (ftmp[checkColorIdx] !== null && ftmp[checkColorIdx] !== undefined) break;
                    }
                    return (
                        <TinyAreaChart
                            data={ftmp}
                            config={
                                ftmp[checkColorIdx] >= 0
                                    ? {
                                        width: 220,
                                        color: "#9bdab5",
                                        line: {
                                            color: "#009F42",
                                        },
                                    }
                                    : {
                                        width: 220,
                                        color: "#ec9892",
                                        line: {
                                            color: "#f00f00",
                                        },
                                    }
                            }
                        />
                    );
                },
            }),
        ];
    };

    const getSnapShotData = (eventId: string) => {
        setIsLoading(true);
        apiRequest(APIs.GET_TOXIC_ACCOUNT_SNAPSHOT, { eventId })
            .then((data: any) => setData(data))
            .catch(error => ErrorCatchValidator(error, (err: any) => console.log(err)))
            .finally(() => setIsLoading(false));
    };

    const getServers = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["server"] })
            .then((data: any) => {
                setServers(data.servers);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => console.log(err));
            });
    };

    useEffect(() => {
        getServers();
    }, []);

    useEffect(() => {
        if (id) getSnapShotData(id as string);
    }, [id]);

    const getServerName = useMemo(() => {
        if (data !== undefined && data?.toxicAccountEventStats.serverId) {
            let fidx: number = servers.findIndex(x => x.id === data?.toxicAccountEventStats.serverId);
            if (fidx > -1) {
                return servers[fidx].server;
            }
        }
        return data?.toxicAccountEventStats.server || "";
    }, [data, servers]);

    return (
        <>
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Loading..." />
                </div>
            ) : (
                data && (
                    <div className="toxic-account-snapshot-container">
                        <div className="main-title">Toxic Account Event Summary</div>
                        <div className="event-details-panel">
                            <div className="event-details">
                                <div className="tag">
                                    <div className="tag-div">Event Details</div>
                                </div>
                                <div className="details">
                                    <div>
                                        <span className="label">Account Id</span>
                                        <span>
                                            {/* <Link
                                                to={getNavigationUrl(
                                                    ACCOUNT_PROFILE_FROM_MODULE.TOXIC_ACCOUNT,
                                                    getAccountProfileParams(
                                                        data.toxicAccountEventStats.serverId,
                                                        data.toxicAccountEventStats.accountId,
                                                        ACCOUNT_PROFILE_FROM_MODULE.TOXIC_ACCOUNT,
                                                        "",
                                                        "",
                                                        "",
                                                        "",
                                                        ""
                                                    )
                                                )}
                                                target="_blank"
                                                style={{ color: "#0e65cc" }}
                                            >
                                                {data.toxicAccountEventStats.accountId}
                                            </Link> */}
                                            {data.toxicAccountEventStats.accountId}
                                        </span>
                                    </div>
                                    <div>
                                        <span className="label">Server</span>
                                        <span>{getServerName}</span>
                                    </div>
                                    <div>
                                        <span className="label">Captured Time (Server)</span>
                                        <span>{DateTimeUtil.GetUTC(data.toxicAccountEventStats.capturedTime, "YYYY-MM-DD HH:mm:ss")}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="right-panel">
                                <div className="score-panel">
                                    <div className="title">
                                        Pre-Market Score
                                        <span style={{ fontSize: "12px" }}>{` (${data.toxicAccountEventStats.preMarketScoringMode})`}</span>
                                    </div>
                                    <div className="details">
                                        <div>
                                            <span className="label">Score</span>
                                            <span>{data.toxicAccountEventStats.preMarketScore}</span>
                                        </div>
                                        <div>
                                            <span className="label">{`${data.toxicAccountEventStats.preMarketScoringMode} (%)`}</span>
                                            <span>{data.toxicAccountEventStats.preMarketScoringWeightage}</span>
                                        </div>
                                        <div>
                                            <span className="label">Weighted Score</span>
                                            <span>{data.toxicAccountEventStats.preMarketWeightedScore}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="score-panel">
                                    <div className="title">
                                        Decay Score<span style={{ fontSize: "12px" }}>{` (${data.toxicAccountEventStats.decayScoringMode})`}</span>
                                    </div>
                                    <div className="details">
                                        <div>
                                            <span className="label">Score</span>
                                            <span>{data.toxicAccountEventStats.decayScore}</span>
                                        </div>
                                        <div>
                                            <span className="label">{`${data.toxicAccountEventStats.decayScoringMode} (%)`}</span>
                                            <span>{data.toxicAccountEventStats.decayScoringWeightage}</span>
                                        </div>
                                        <div>
                                            <span className="label">Weighted Score</span>
                                            <span>{data.toxicAccountEventStats.decayWeightedScore}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="score-panel">
                                    <div className="title">
                                        Volume MA Score
                                        <span style={{ fontSize: "12px" }}>{` (${data.toxicAccountEventStats.volumeMAScoringMode})`}</span>
                                    </div>
                                    <div className="details">
                                        <div>
                                            <span className="label">Score</span>
                                            <span>{data.toxicAccountEventStats.volumeMAScore}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="final-score-panel">
                                    <div className="title">Final Score</div>
                                    <div className="details">
                                        <span className="score">{data.toxicAccountEventStats.finalScore}</span>
                                        <span className="total-score">/ 100</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="account-view-panel">
                            <DecayAnalysisAccountSummary accountStats={data.decayAnalysisAccountStatsSnapshot ?? {}} />
                        </div>
                        <div className="ticket-view-panel">
                            <div className="title-panel">
                                <div className="left">
                                    <span className="title">Decay Analysis Ticket Summary</span>
                                    <span>
                                        <TagOutlined style={{ cursor: "pointer", marginLeft: "0.3vw", color: "#f00f00", fontSize: "0.75rem" }} />-
                                        Ticket triggered by Volume MA Model
                                    </span>
                                </div>
                                <div className="right">
                                    <span>
                                        Volume MA Model Triggered Count :{" "}
                                        {data.decayAnalysisTradeStatsSnapshot.filter((x: any) => x.isTriggedByVolumeMAModel).length} /{" "}
                                        {data.decayAnalysisTradeStatsSnapshot.length}
                                    </span>
                                </div>
                            </div>
                            <div className="content">
                                <FlexiDataTable
                                    rowKeyProperty="ticket"
                                    title={false}
                                    columns={columnsConfig()}
                                    options={{
                                        enableFilter: false,
                                        showHideColumns: false,
                                    }}
                                    dataSource={data.decayAnalysisTradeStatsSnapshot ?? []}
                                    callback={() => { }}
                                    loading={isLoading}
                                    tableProps={{
                                        bordered: true,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                )
            )}
        </>
    );
};

export default ToxicAccountEventSnapshot;
