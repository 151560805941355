import FlexiDataTable from "@/components/FlexiDataTable";
import { FormComponent } from "@/components/FormComponent";
import LoadingComponent from "@/components/Loading";
import { InnerPageActionMode, SUCCESS_FAILED, ComponentType, CALLBACK_KEY, SYMBOL_SCOPE_TYPE } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { CreateEditInlineFormBased, SymbolAssetTypesList, FlexiDataTableOptionsProps, FlexiDataTableCallbackProps } from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { apiRequest } from "@/services/apiConfig";
import { APIs } from "@/services/apis";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler, DataTableColumnRender } from "@/utils/Common";
import { DefaultIfEmpty, getAvailableObjectElementKeys } from "@/utils/object";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { Form, Modal, Button, Upload, Row, Col, Tabs } from "antd";
import { useState, useMemo, useCallback, useEffect } from "react";
import CleanSymbolCreateEditPage from "./CleanSymbolCreateEditPage";
import * as XLSX from "xlsx";
import { ToObjectWithKey } from "@/utils/array";
import PanelContainer from "../../PanelContainer";

export interface CleanSymbolListProps { }

interface CleanSymbol {
    cleanSymbol: string;
    symbolAssetTypeId: number | null;
    symbolAssetType: string;
    updateBy: string | number | null;
    updateByText?: string;
    updateTimeUtc: string | null;
}

interface MarkupDataProps {
    data: CleanSymbol[];
}

interface SymbolScopeProps {
    symbolScope: number;
}

const CleanSymbolList = (props: CleanSymbolListProps) => {
    const [isFirstOpt, setIsFirstOpt] = useState<boolean>(true);
    const [formObject, setFormObject] = useState<CreateEditInlineFormBased>({ mode: InnerPageActionMode.CREATE_NEW });
    const [refetch, setRefetch] = useState<number>(0);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [currTab, setCurrTab] = useState<string>(`${SYMBOL_SCOPE_TYPE.PRODUCT}`);
    const [symbolAssetType, setSymbolAssetType] = useState<SymbolAssetTypesList[]>([]);
    const [data, setData] = useState<CleanSymbol[]>([]);
    const [downloadForm] = Form.useForm();
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [isExporting, setIsExporting] = useState<boolean>(false);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [useObj, setUseObj] = useState<{ [key: number]: string }>({});

    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.CENTRALIZED_SETTINGS_CLEAN_SYMBOL_MANAGEMENT_EDIT);

    const symbolScopeProps: SymbolScopeProps = useMemo(() => ({ symbolScope: parseInt(currTab) }), [currTab]);

    const markupData: MarkupDataProps = useMemo(() => {
        return {
            data: data.map(x => ({ ...x, updateByText: DefaultIfEmpty(useObj, x.updateBy, x.updateBy) })),
        };
    }, [data, useObj]);

    const symbolAssetTypeOptions = useMemo(
        () => symbolAssetType.map((x: SymbolAssetTypesList) => ({ text: x.name, value: x.id })),
        [symbolAssetType]
    );

    const showPreviewError = (data: any) => {
        Modal.error({
            title: "Error Preview",
            width: "52.08vw",
            bodyStyle: { padding: "1vw 2vh" },
            content: (
                <div className="upload-error-div">
                    <FlexiDataTable
                        title={false}
                        loading={false}
                        columns={[
                            DTColProps.XXSmall({
                                title: "Row",
                                dataIndex: "rowNum",
                                key: "rowNum",
                            }),
                            DTColProps.Small({
                                title: "Clean Symbol",
                                dataIndex: "cleanSymbol",
                                key: "cleanSymbol",
                            }),
                            {
                                title: "Remarks",
                                dataIndex: "remarks",
                                key: "remarks",
                            },
                        ]}
                        options={{
                            enableFilter: false,
                            showHideColumns: false,
                        }}
                        dataSource={data}
                        scroll={{ x: "max-content" }}
                        bordered
                        {...(data.length > 10 ? { pagination: { pageSize: 10 } } : { pagination: false })}
                    />
                </div>
            ),
        });
    };

    const convertExcelToJSON = useCallback(
        (uploadedFile: any) => {
            if (!uploadedFile) return null;
            if (uploadedFile.size > 5000000) {
                ErrorMessageHandler(`Maximum file size is 5 mb.`, SUCCESS_FAILED.OTHERS_FAILED);
                return null;
            }

            /* Boilerplate to set up FileReader */
            const reader = new FileReader();
            const rABS = !!reader.readAsBinaryString;

            // Set up callback for when FileReader is done loading
            reader.onload = (event: any) => {
                /* Parse data */
                const bstr = event.target.result;
                const wb = XLSX.read(bstr, {
                    type: rABS ? "binary" : "array",
                    bookVBA: true,
                    raw: true,
                });

                /* Get first worksheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];

                /* Convert array of arrays */
                const excelRows: any[] = XLSX.utils.sheet_to_json(ws);

                if (excelRows.length === 0) {
                    ErrorMessageHandler(`File is empty.`, SUCCESS_FAILED.OTHERS_FAILED);
                    return null;
                }

                setIsUploading(true);

                let formData1 = new FormData();
                formData1.append("IsPreviewOnly", "true");
                formData1.append("File", uploadedFile);
                formData1.append("SymbolScope", `${symbolScopeProps.symbolScope}`);
                apiRequest(APIs.UPLOAD_CLEAN_SYMBOL_TEMPLATE, formData1, "POST", "json", {
                    Accept: "text/plain",
                    "Content-type": "multipart/form-data",
                })
                    .then((data: any) => {
                        if (data.length > 0) {
                            showPreviewError(data);
                        } else {
                            let formData = new FormData();
                            formData.append("IsPreviewOnly", "false");
                            formData.append("File", uploadedFile);
                            formData.append("SymbolScope", `${symbolScopeProps.symbolScope}`);

                            apiRequest(APIs.UPLOAD_CLEAN_SYMBOL_TEMPLATE, formData, "POST", "json", {
                                Accept: "text/plain",
                                "Content-type": "multipart/form-data",
                            })
                                .then((data: any) => {
                                    ErrorMessageHandler(`Clean symbols upload successfully.`, SUCCESS_FAILED.OTHERS_SUCCESS);
                                    setRunRefetchDataList(true);
                                })
                                .catch((err: any) =>
                                    ErrorCatchValidator(err, (err: any) => {
                                        if (err.status === -3) {
                                            Modal.warning({
                                                title: "Warning!",
                                                content: err.message,
                                            });
                                        } else {
                                            ErrorMessageHandler(`Failed to upload clean symbols: ${err.message}.`, SUCCESS_FAILED.OTHERS_FAILED);
                                        }
                                    })
                                );
                        }
                    })
                    .catch((err: any) =>
                        ErrorCatchValidator(err, (err: any) => {
                            if (err.status === -3) {
                                Modal.warning({
                                    title: "Warning!",
                                    content: err.message,
                                });
                            } else {
                                ErrorMessageHandler(`Failed to upload clean symbols: ${err.message}.`, SUCCESS_FAILED.OTHERS_FAILED);
                            }
                        })
                    )
                    .finally(() => setIsUploading(false));
            };

            // Call FileReader
            if (rABS) {
                reader.readAsBinaryString(uploadedFile);
            } else {
                reader.readAsArrayBuffer(uploadedFile);
            }
        },
        [symbolScopeProps]
    );

    const exportToExcel = useCallback(
        (filterParams: any = {}) => {
            setIsExporting(true);
            apiRequest(APIs.EXPORT_CLEAN_SYMBOL, { ...filterParams, ...symbolScopeProps }, "POST", "arraybuffer")
                .then((res: any) => {
                    const fileName = res.headers["x-filename"];
                    const url = window.URL.createObjectURL(new Blob([res.data])); // Create blob link to download
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", fileName); // or any other extension
                    // Append to html link element page
                    document.body.appendChild(link);
                    // start download
                    link.click();
                    // Clean up and remove the link
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                })
                .catch(error => {
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("clean symbols excel", SUCCESS_FAILED.FAILED_DOWNLOAD_DATA, err));
                })
                .finally(() => setIsExporting(false));
        },
        [symbolScopeProps]
    );

    const columns: any[] = useMemo(
        () => [
            {
                title: "Clean Symbol",
                dataIndex: "cleanSymbol",
                key: "cleanSymbol",
                options: {
                    filter: {
                        type: ComponentType.text,
                        value: "",
                    },
                },
            },
            DTColProps.Middle({
                title: "Symbol Asset Type",
                dataIndex: "symbolAssetTypeId",
                key: "symbolAssetTypeId",
                render: (value: string, rowData: CleanSymbol) => rowData.symbolAssetType,
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: symbolAssetTypeOptions,
                        inputProps: {
                            mode: "multiple",
                        },
                        callback: (filterValue: any, rowData: CleanSymbol) => {
                            if (rowData.symbolAssetTypeId === null) {
                                return false;
                            } else {
                                return filterValue.some((value: number) => rowData.symbolAssetTypeId === value);
                            }
                        },
                    },
                },
            }),
            DTColProps.Middle(
                {
                    title: "Updated By",
                    dataIndex: "updateBy",
                    key: "updateBy",
                    render: (text: number, rowData: CleanSymbol) => (text === 0 ? "System" : rowData.updateByText),
                },
                ["text-center"]
            ),
            DTColProps.DateTime({
                width: "12.5vw",
                title: "Last Updated Time (Local)",
                dataIndex: "updateTimeUtc",
                key: "updateTimeUtc",
                render: (value: string, rowData: CleanSymbol) =>
                    rowData.updateTimeUtc !== null ? DataTableColumnRender.DateTime(rowData.updateTimeUtc as string) : "",
                sorter: (a: any, b: any) => (a.updateTimeUtc < b.updateTimeUtc ? -1 : 1),
            }),
        ],
        [symbolAssetTypeOptions]
    );

    const options: FlexiDataTableOptionsProps = {
        separateActionButton: true,
        add: enableUpdate,
        edit: enableUpdate,
        delete: enableUpdate,
        export: {
            text: "Export as Excel",
        },
        ...(enableUpdate && {
            extraButtons: () => (
                <>
                    {enableUpdate && (
                        <>
                            <Button
                                key={`btn-sy-dt-${Math.random()}`}
                                htmlType="button"
                                style={{ marginLeft: "0.651vw", width: "auto" }}
                                onClick={event => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    downloadForm.setFieldsValue({ downloadMode: "", symbolAssetTypeIds: [] });
                                    setIsModalVisible(true);
                                }}
                                icon={<DownloadOutlined />}
                                loading={isDownloading}
                                disabled={isUploading}
                            >
                                Download Template
                            </Button>
                            <Upload
                                name="file"
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                showUploadList={false}
                                onChange={(info: any) => {
                                    if (info.file.status === "error") {
                                        ErrorMessageHandler(`${info.file.name} file upload failed.`, SUCCESS_FAILED.OTHERS_FAILED);
                                    }
                                }}
                                beforeUpload={(file: any) => {
                                    convertExcelToJSON(file);
                                }}
                                customRequest={(info: any) => { }}
                            >
                                <Button
                                    htmlType="button"
                                    icon={<UploadOutlined />}
                                    style={{ marginLeft: "0.651vw" }}
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                    }}
                                    loading={isUploading}
                                >
                                    Upload Excel
                                </Button>
                            </Upload>
                        </>
                    )}
                </>
            ),
        }),
    };

    const componentCallback: FlexiDataTableCallbackProps = useCallback(
        (type, cleanSymbol) => {
            switch (type) {
                case CALLBACK_KEY.CREATE_NEW:
                    setIsFirstOpt(prev => !prev);
                    setFormObject({ mode: InnerPageActionMode.CREATE_NEW, id: undefined });
                    setRefetch(prev => prev + 1);
                    break;
                case CALLBACK_KEY.DO_EDIT:
                    setIsFirstOpt(prev => !prev);
                    setFormObject({ mode: InnerPageActionMode.EDIT, id: cleanSymbol.cleanSymbol });
                    setRefetch(prev => prev + 1);
                    break;
                case CALLBACK_KEY.DO_DELETE:
                    apiRequest(APIs.DELETE_CLEAN_SYMBOL, {
                        cleanSymbol: cleanSymbol.cleanSymbol,
                        ...symbolScopeProps,
                    })
                        .then(data => {
                            ErrorMessageHandler(cleanSymbol.cleanSymbol, SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                            setRunRefetchDataList(true);
                        })
                        .catch(error =>
                            ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("clean symbol", SUCCESS_FAILED.FAILED_DELETE_DATA, err))
                        );
                    break;
                case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                    exportToExcel(
                        getAvailableObjectElementKeys(cleanSymbol).reduce((tmpObj: any, x: string) => {
                            if (x === "cleanSymbol") {
                                tmpObj["cleanSymbolWildcard"] = cleanSymbol[x];
                            } else if (x === "symbolAssetTypeId") {
                                tmpObj["symbolAssetTypeIds"] = cleanSymbol[x];
                            } else {
                                tmpObj[x] = cleanSymbol[x];
                            }

                            return tmpObj;
                        }, {})
                    );
                    break;
                default:
                    break;
            }
        },
        [symbolScopeProps]
    );

    const downloadTemplate = useCallback(
        (values: any) => {
            setIsDownloading(true);
            apiRequest(APIs.DOWNLOAD_CLEAN_SYMBOL_TEMPLATE, { ...values, ...symbolScopeProps }, "POST", "arraybuffer")
                .then((res: any) => {
                    const fileName = res.headers["x-filename"];
                    const url = window.URL.createObjectURL(new Blob([res.data])); // Create blob link to download
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", fileName); // or any other extension
                    // Append to html link element page
                    document.body.appendChild(link);
                    // start download
                    link.click();
                    // Clean up and remove the link
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                })
                .catch(error => {
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("clean symbol template", SUCCESS_FAILED.FAILED_DOWNLOAD_DATA, err));
                })
                .finally(() => setIsDownloading(false));
        },
        [symbolScopeProps]
    );

    const getListing = useCallback(() => {
        setIsLoading(true);
        apiRequest(APIs.GET_CLEAN_SYMBOL_LISTING, symbolScopeProps)
            .then((res: CleanSymbol[]) => {
                setData(res);
            })
            .catch(error => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("clean symbol", SUCCESS_FAILED.FAILED_UPDATE_DATA, err));
            })
            .finally(() => setIsLoading(false));
    }, [symbolScopeProps]);

    const getConfigList = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["symbolassettype", "user"] })
            .then(res => {
                setSymbolAssetType(res.symbolAssetTypes);
                setUseObj(ToObjectWithKey(res.users, "id", "name"));
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("config info", SUCCESS_FAILED.FAILED_LOAD_DATA, err)))
            .finally(() => setRunRefetchDataList(true));
    };

    useEffect(() => {
        if (runRefetchDataList) {
            getListing();
            setRunRefetchDataList(false);
        }
    }, [runRefetchDataList]);

    useEffect(() => {
        getConfigList();

        return () => { };
    }, []);

    return (
        <PanelContainer title="Clean Symbol Management">
            <div className="tab-container">
                <Tabs
                    activeKey={currTab}
                    onChange={(activeKey: string) => {
                        setCurrTab(activeKey);
                        setRunRefetchDataList(true);
                    }}
                    type="card"
                    style={{ padding: "0.5rem 0.5rem 0 0.5rem" }}
                    tabBarStyle={{ marginBottom: 0 }}
                    items={[
                        { label: "Product", key: `${SYMBOL_SCOPE_TYPE.PRODUCT}` },
                        { label: "Pricing", key: `${SYMBOL_SCOPE_TYPE.PRICING}` },
                    ]}
                />
            </div>
            <div className="single-page">
                <div className={`${isFirstOpt ? "active" : ""}`}>
                    <div className="clean-symbol-main-content">
                        <div className="clean-symbol-main-container">
                            <LoadingComponent tip={"Uploading file..."} spinning={isUploading}>
                                <FlexiDataTable
                                    bordered
                                    rowKeyProperty="cleanSymbol"
                                    title=""
                                    columns={columns}
                                    options={options}
                                    dataSource={markupData.data}
                                    callback={componentCallback}
                                    loading={isLoading}
                                    exporting={isExporting}
                                />
                            </LoadingComponent>
                        </div>
                    </div>
                </div>
                <div className={`${isFirstOpt ? "" : "active"}`}>
                    <CleanSymbolCreateEditPage
                        {...formObject}
                        callback={(action: InnerPageActionMode, value: any) => {
                            switch (action) {
                                case InnerPageActionMode.BACK:
                                    setIsFirstOpt(prev => !prev);
                                    if (value?.hasOwnProperty("refreshMainList") && value["refreshMainList"]) {
                                        setRunRefetchDataList(true);
                                    }
                                    break;
                            }
                        }}
                        resetState={refetch}
                        symbolScope={symbolScopeProps.symbolScope}
                        symbolAssetTypeOptions={symbolAssetTypeOptions}
                    />
                </div>
            </div>
            <Modal
                width={600}
                title="Download Template"
                open={isModalVisible}
                cancelButtonProps={{ style: { display: "none" } }}
                onCancel={() => setIsModalVisible(false)}
                onOk={() => {
                    downloadForm
                        .validateFields()
                        .then(values => {
                            downloadTemplate(values);
                            setIsModalVisible(false);
                        })
                        .catch(errorInfo => console.log(errorInfo));
                }}
            >
                <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} form={downloadForm} layout="horizontal" initialValues={{}}>
                    <Row>
                        <Col span={24}>
                            <FormComponent
                                label="Mode"
                                name="downloadMode"
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: [
                                        {
                                            text: "With Existing Clean Symbols",
                                            value: 2,
                                        },
                                        {
                                            text: "Blank Template",
                                            value: 1,
                                        },
                                    ],
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <FormComponent
                                label="Symbol Asset Type"
                                name="symbolAssetTypeIds"
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: symbolAssetTypeOptions,
                                    inputProps: {
                                        mode: "multiple",
                                    },
                                }}
                            />
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </PanelContainer>
    );
};

export default CleanSymbolList;
