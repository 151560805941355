import {
    ApartmentOutlined,
    BorderlessTableOutlined,
    ControlOutlined,
    DesktopOutlined,
    EuroCircleOutlined,
    FundProjectionScreenOutlined,
    FundViewOutlined,
    LineChartOutlined,
    RadarChartOutlined,
    ToolOutlined,
    UserOutlined,
} from "@ant-design/icons";
import React, { useMemo } from "react";
import MidPriceSetting from "./components/PriceMonitor/MidPriceSettings";
import PriceAlarmColorSettingPage from "./components/PriceMonitor/PriceAlarmColorSettings";
import PriceAnalysisSettingPage from "./components/PriceMonitor/PriceAnalysisSettings";
import PriceBackendOptSettingsPage from "./components/PriceMonitor/PriceBackendOptionSettings/TabRestartServer";
import SpreadAlarmSettingPage from "./components/PriceMonitor/SpreadAlarmSettings";
import DataCenterSettings from "./components/SystemMonitor/DataCenterSettings/DataCenterSettings";
import IssuesLogSettingsPanel from "./components/SystemMonitor/IssuesLogSettings";
import TabRejectOrderSettings from "./components/SystemMonitor/RejectOrderSettings/TabRejectOrderSettings";
import TabServerPerformanceSettings from "./components/SystemMonitor/ServerPerformanceSettings/TabServerPerformanceSettings";
import HFTReportSettingsPanel from "./components/TradeMonitor/HFTReportSettings";
import LPMonitorAlarmConfiguration from "./components/MRMonitor/AlarmSettings";
import MRGroupConfig from "./components/MRMonitor/MRGroupSettings";
import IntradayMonitorAlarmConfiguration from "./components/IntradayMonitor/AlarmSettings";
import BrandServerCommission from "./components/SystemOthers/CommissionSettings";
import SettingsTab from "./components/Reporting/LinkedAccountReportSettings/SettingsTab";
import ToxicClientSettingsPage from "./components/Analysis/ToxicClientSettings";
import CIDSettingsPage from "./components/ToolsConfiguration/CIDSettings";
import FundingFeeSettingPage from "./components/ToolsConfiguration/FundingFeeSettings";
import GroupTransferSettingsPage from "./components/ToolsConfiguration/GroupTransferToolSettings/setting";
import HubConfigurationPage from "./components/ToolsConfiguration/HubFailoverSettings";
import NotificationChannelManagement from "./components/Systems/NotificationChannelSettings";
import BrandListingPage from "./components/Systems/BrandSettings";
import BridgeConfigApiPage from "./components/Systems/BridgeConfigApiSettings";
import BridgeSettingPage from "./components/Systems/BridgeSettings";
import DataCenterPageSettings from "./components/Systems/DataCenterSettings";
import ExternalSystemPage from "./components/Systems/ExternalSystemSettings";
import ServerPageV2 from "./components/Systems/ServerSettings";
import CleanSymbolList from "./components/Symbols/CleanSymbolSettings";
import FeatureSettingsPage from "./components/SystemOthers/FeatureSettings";
import SymbolMappingPage from "./components/Symbols/MTSymbolSettings";
import SymbolAssetTypeList from "./components/Symbols/SymbolAssetTypeSettings";
import SymbolInfo from "./components/Symbols/SymbolSettings";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import GroupPurposeListPage from "./components/Systems/GroupPurposeSettings/GroupPurposeListPage";
import MidBiasAlarmSettingsPage from "./components/PriceMonitor/MidBiasAlarmSettings";
import OutOfPriceSettingsPage from "./components/PriceMonitor/OutOfPriceSettings";
import UsersManagementTab from "./components/Administration/UsersPage/UsersManagementTab";
import MenuMangement from "./components/Administration/Menus";
import JobSchedulers from "./components/Reporting/JobSchedulers";
import RolePermissionPage from "./components/Administration/RolePermission";
import AdminFeeSettings from "./components/ToolsConfiguration/AdminFeeSettings";
import { useAppDispatch, useAppSelector } from "@/store/hook";
import { SettingsProps } from "@/store/reducers/centralizedsettings";
import { isEmptyOrNull } from "@/utils/string";
import { setCurrentKey } from "@/store/reducers/centralizedsettings";
import MRGroupingSettingsPage from "./components/MRMonitor";
import PriceAlarmSettings2 from "./components/PriceMonitor/PriceAlarmSettings/newIndex";
import { useNavigate } from "react-router";

export interface CentralizedSettingsPanelProps { }

interface CentralizedSettingProps {
    title: string;
    items?: CentralizedSettingItemProps[];
}

interface CentralizedSettingItemProps {
    title: string;
    icon: React.ReactNode;
    items?: CentralizedSettingNavItemProps[];
}

export interface CentralizedSettingNavItemProps {
    title: string;
    component: React.ReactNode;
    authKey: string;
    panelKey?: string;
}

const CentralizedSettings: CentralizedSettingProps[] = [
    {
        title: "Monitoring",
        items: [
            {
                title: "Price Monitor",
                icon: <EuroCircleOutlined />,
                items: [
                    {
                        title: "Price Alarm Color Settings",
                        component: <PriceAlarmColorSettingPage />,
                        authKey: AuthKeys.CENTRALIZED_SETTINGS_PRICE_ALARM_COLOR_SETTINGS_VIEW,
                        panelKey: "price-alarm-color-settings",
                    },
                    {
                        title: "Price Alarm Settings",
                        component: <PriceAlarmSettings2 />,
                        authKey: AuthKeys.CENTRALIZED_SETTINGS_PRICE_ALARM_SETTINGS_VIEW,
                        panelKey: "price-alarm-settings",
                    },
                    {
                        title: "Price Analysis Settings",
                        component: <PriceAnalysisSettingPage />,
                        authKey: AuthKeys.CENTRALIZED_SETTINGS_PRICE_ANALYSIS_SETTINGS_VIEW,
                        panelKey: "price-analysis-settings",
                    },
                    {
                        title: "Price Backend Opt",
                        component: <PriceBackendOptSettingsPage />,
                        authKey: AuthKeys.CENTRALIZED_SETTINGS_PRICE_BACKEND_OPT_VIEW,
                        panelKey: "price-backend-opt",
                    },
                    {
                        title: "Out Of Price Settings",
                        component: <OutOfPriceSettingsPage />,
                        authKey: AuthKeys.CENTRALIZED_SETTINGS_OUT_OF_PRICE_SETTINGS_VIEW,
                        panelKey: "out-of-price-settings",
                    },
                    {
                        title: "Mid Bias Alarm Settings",
                        component: <MidBiasAlarmSettingsPage />,
                        authKey: AuthKeys.CENTRALIZED_SETTINGS_MID_BIAS_ALARM_SETTINGS_VIEW,
                        panelKey: "mid-bias-alarm-settings",
                    },
                    {
                        title: "Mid Price Settings",
                        component: <MidPriceSetting />,
                        authKey: AuthKeys.CENTRALIZED_SETTINGS_MID_PRICE_SETTINGS_VIEW,
                        panelKey: "mid-price-settings",
                    },
                    {
                        title: "Spread Alarm Settings",
                        component: <SpreadAlarmSettingPage />,
                        authKey: AuthKeys.CENTRALIZED_SETTINGS_SPREAD_ALARM_SETTINGS_VIEW,
                        panelKey: "spread-alarm-settings",
                    },
                ],
            },
            {
                title: "System Monitor",
                icon: <ApartmentOutlined />,
                items: [
                    {
                        title: "Data Center Settings",
                        component: <DataCenterSettings />,
                        authKey: AuthKeys.CENTRALIZED_SETTINGS_DATA_CENTER_SETTINGS_VIEW,
                        panelKey: "data-center-settings",
                    },
                    {
                        title: "Issues Log Settings",
                        component: <IssuesLogSettingsPanel />,
                        authKey: AuthKeys.CENTRALIZED_SETTINGS_ISSUES_LOG_SETTINGS_VIEW,
                        panelKey: "issues-log-settings",
                    },
                    {
                        title: "Reject Order Settings",
                        component: <TabRejectOrderSettings />,
                        authKey: AuthKeys.CENTRALIZED_SETTINGS_REJECT_ORDER_SETTINGS_VIEW,
                        panelKey: "reject-order-settings",
                    },
                    {
                        title: "Server Performance Settings",
                        component: <TabServerPerformanceSettings />,
                        authKey: AuthKeys.CENTRALIZED_SETTINGS_SERVER_PERFORMANCE_SETTINGS_VIEW,
                        panelKey: "server-performance-settings",
                    },
                ],
            },
            {
                title: "Trade Monitor",
                icon: <FundProjectionScreenOutlined />,
                items: [
                    {
                        title: "HFT Report Settings",
                        component: <HFTReportSettingsPanel />,
                        authKey: AuthKeys.CENTRALIZED_SETTINGS_HFT_REPORT_SETTINGS_VIEW,
                        panelKey: "hft-report-settings",
                    },
                ],
            },
            {
                title: "MR Monitor",
                icon: <FundViewOutlined />,
                items: [
                    {
                        title: "MR Alarm Settings",
                        component: <LPMonitorAlarmConfiguration />,
                        authKey: AuthKeys.CENTRALIZED_SETTINGS_MR_ALARM_SETTINGS_VIEW,
                        panelKey: "mr-alarm-settings",
                    },
                    {
                        title: "MR Group Settings",
                        component: <MRGroupingSettingsPage />,
                        authKey: AuthKeys.CENTRALIZED_SETTINGS_MR_GROUP_SETTINGS_VIEW,
                        panelKey: "mr-group-settings",
                    },
                ],
            },
            {
                title: "Intraday Monitor",
                icon: <FundViewOutlined />,
                items: [
                    {
                        title: "Intraday Alarm Settings",
                        component: <IntradayMonitorAlarmConfiguration />,
                        authKey: AuthKeys.CENTRALIZED_SETTINGS_INTRADAY_ALARM_SETTINGS_VIEW,
                        panelKey: "intraday-alarm-settings",
                    },
                ],
            },
        ],
    },
    {
        title: "System Configuration",
        items: [
            {
                title: "System",
                icon: <DesktopOutlined />,
                items: [
                    {
                        title: "Brand Management",
                        component: <BrandListingPage />,
                        authKey: AuthKeys.CENTRALIZED_SETTINGS_BRAND_MANAGEMENT_VIEW,
                        panelKey: "brand-management",
                    },
                    {
                        title: "Bridge Config Api Configuration",
                        component: <BridgeConfigApiPage />,
                        authKey: AuthKeys.CENTRALIZED_SETTINGS_BRIDGE_CONDIF_API_CONFIGURATION_VIEW,
                        panelKey: "bridge-config-api-configuration",
                    },
                    {
                        title: "Bridge Management",
                        component: <BridgeSettingPage />,
                        authKey: AuthKeys.CENTRALIZED_SETTINGS_BRIDGE_MANAGEMENT_VIEW,
                        panelKey: "bridge-management",
                    },
                    {
                        title: "Data Center Management",
                        component: <DataCenterPageSettings />,
                        authKey: AuthKeys.CENTRALIZED_SETTINGS_DATA_CENTER_MANAGEMENT_VIEW,
                        panelKey: "data-center-management",
                    },
                    {
                        title: "External System Management",
                        component: <ExternalSystemPage />,
                        authKey: AuthKeys.CENTRALIZED_SETTINGS_EXTERNAL_SYSTEM_MANAGEMENT_VIEW,
                        panelKey: "external-system-management",
                    },
                    {
                        title: "Group Purpose Management",
                        component: <GroupPurposeListPage />,
                        authKey: AuthKeys.CENTRALIZED_SETTINGS_GROUP_PURPOSE_MANAGEMENT_VIEW,
                        panelKey: "group-purpose-management",
                    },
                    {
                        title: "Notification Channel Management",
                        component: <NotificationChannelManagement />,
                        authKey: AuthKeys.CENTRALIZED_SETTINGS_NOTIFICATION_CHANNEL_MANAGEMENT_VIEW,
                        panelKey: "notification-channel-management",
                    },
                    {
                        title: "Server Management",
                        component: <ServerPageV2 />,
                        authKey: AuthKeys.CENTRALIZED_SETTINGS_SERVER_MANAGEMENT_VIEW,
                        panelKey: "server-management",
                    },
                ],
            },
            {
                title: "Symbol",
                icon: <BorderlessTableOutlined />,
                items: [
                    {
                        title: "Clean Symbol Management",
                        component: <CleanSymbolList />,
                        authKey: AuthKeys.CENTRALIZED_SETTINGS_CLEAN_SYMBOL_MANAGEMENT_VIEW,
                        panelKey: "clean-symbol-management",
                    },
                    {
                        title: "MT Symbol Management",
                        component: <SymbolMappingPage />,
                        authKey: AuthKeys.CENTRALIZED_SETTINGS_MT_SYMBOL_MANAGEMENT_VIEW,
                        panelKey: "mt-symbol-management",
                    },
                    {
                        title: "Symbol Asset Type Management",
                        component: <SymbolAssetTypeList />,
                        authKey: AuthKeys.CENTRALIZED_SETTINGS_SYMBOL_ASSET_TYPE_MANAGEMENT_VIEW,
                        panelKey: "symbol-asset-type-management",
                    },
                    {
                        title: "Symbol Management",
                        component: <SymbolInfo />,
                        authKey: AuthKeys.CENTRALIZED_SETTINGS_SYMBOL_MANAGEMENT_VIEW,
                        panelKey: "symbol-management",
                    },
                ],
            },
            {
                title: "Others",
                icon: <ControlOutlined />,
                items: [
                    {
                        title: "Commission Settings",
                        component: <BrandServerCommission />,
                        authKey: AuthKeys.CENTRALIZED_SETTINGS_COMMISSION_SETTINGS_VIEW,
                        panelKey: "commission-settings",
                    },
                    {
                        title: "Feature Settings",
                        component: <FeatureSettingsPage />,
                        authKey: AuthKeys.CENTRALIZED_SETTINGS_FEATURE_SETTINGS_VIEW,
                        panelKey: "feature-settings",
                    },
                ],
            },
            {
                title: "Administration",
                icon: <UserOutlined />,
                items: [
                    {
                        title: "Users Management",
                        component: <UsersManagementTab />,
                        authKey: AuthKeys.CENTRALIZED_SETTINGS_USER_MANAGEMENT_VIEW,
                        panelKey: "users-management",
                    },
                    {
                        title: "Role & Permission",
                        component: <RolePermissionPage />,
                        authKey: AuthKeys.CENTRALIZED_SETTINGS_ROLE_AND_PERMISSION_VIEW,
                        panelKey: "role-and-permission",
                    },
                    {
                        title: "Menu Management",
                        component: <MenuMangement />,
                        authKey: AuthKeys.CENTRALIZED_SETTINGS_MENU_MANAGEMENT_VIEW,
                        panelKey: "menu-management",
                    },
                ],
            },
        ],
    },
    {
        title: "Reporting & Analysis",
        items: [
            {
                title: "Reporting",
                icon: <LineChartOutlined />,
                items: [
                    {
                        title: "Linked Account Report Settings",
                        component: <SettingsTab />,
                        authKey: AuthKeys.CENTRALIZED_SETTINGS_LINKED_ACCOUNT_REPORT_SETTINGS_VIEW,
                        panelKey: "linked-account-report-settings",
                    },
                    {
                        title: "Job Schedulers",
                        component: <JobSchedulers />,
                        authKey: AuthKeys.CENTRALIZED_SETTINGS_JOB_SCHEDULER_VIEW,
                        panelKey: "job-schedulers",
                    },
                ],
            },
            {
                title: "Analysis",
                icon: <RadarChartOutlined />,
                items: [
                    {
                        title: "Toxic Client Settings",
                        component: <ToxicClientSettingsPage />,
                        authKey: AuthKeys.CENTRALIZED_SETTINGS_TOXIC_CLIENT_SETTINGS_VIEW,
                        panelKey: "toxic-client-settings",
                    },
                ],
            },
        ],
    },
    {
        title: "Admin/RA Tools",
        items: [
            {
                title: "Tools Configuration",
                icon: <ToolOutlined />,
                items: [
                    {
                        title: "Admin Fee Settings",
                        component: <AdminFeeSettings />,
                        authKey: AuthKeys.CENTRALIZED_SETTINGS_ADMIN_FEE_SETTINGS_VIEW,
                        panelKey: "admin-fee-settings",
                    },
                    {
                        title: "CID Settings",
                        component: <CIDSettingsPage />,
                        authKey: AuthKeys.CENTRALIZED_SETTINGS_CID_SETTINGS_VIEW,
                        panelKey: "cid-settings",
                    },
                    {
                        title: "Funding Fee Settings",
                        component: <FundingFeeSettingPage />,
                        authKey: AuthKeys.CENTRALIZED_SETTINGS_FUNDING_FEE_SETTINGS_VIEW,
                        panelKey: "funding-fee-settings",
                    },
                    {
                        title: "Group Transfer Tool Settings",
                        component: <GroupTransferSettingsPage />,
                        authKey: AuthKeys.CENTRALIZED_SETTINGS_GROUP_TRANSFER_TOOL_SETTINGS_VIEW,
                        panelKey: "group-transfer-tool-settings",
                    },
                    {
                        title: "Hub Failover Configuration",
                        component: <HubConfigurationPage />,
                        authKey: AuthKeys.CENTRALIZED_SETTINGS_HUB_FAILOVER_CONFIGURATION_VIEW,
                        panelKey: "hub-failover-configuration",
                    },
                ],
            },
        ],
    },
];

const CentralizedSettingsPanel = (props: CentralizedSettingsPanelProps) => {
    const dispatch = useAppDispatch();
    const centralizedSettingsState: SettingsProps = useAppSelector((state: any) => state.centralizedsettings);
    //const [selectedComponent, setSelectedComponent] = useState<CentralizedSettingNavItemProps | undefined>(undefined);
    const authHp = new AuthHelper();
    let navigate = useNavigate();

    const getSinglePanelTemplate = (item: CentralizedSettingProps, index: number) => {
        if (item.items?.length === 0) return null;
        if (
            !item.items?.some((x: CentralizedSettingItemProps) =>
                x.items?.some((y: CentralizedSettingNavItemProps) => authHp.isAuthorized(y.authKey))
            )
        )
            return null;

        return (
            <div key={`mp-${index}`} className="setting-panel">
                <div className="setting-panel-title">
                    <span>{item.title}</span>
                </div>
                <div className="setting-panel-content">
                    {item.items?.map((x: CentralizedSettingItemProps, xIdx: number) => getSingleItemTemplate(x, xIdx, `mp-${index}`))}
                </div>
            </div>
        );
    };

    const getSingleItemTemplate = (item: CentralizedSettingItemProps, index: number, pkey: string) => {
        if (item.items?.filter((x: CentralizedSettingNavItemProps) => authHp.isAuthorized(x.authKey)).length === 0) return null;

        return (
            <div key={`mi-${pkey}-${index}`} className="setting-item-panel">
                <div className="icon-panel">{item.icon}</div>
                <div className="list-panel">
                    <div className="list-panel-title">
                        <span>{item.title}</span>
                    </div>
                    <div className="list-panel-content">
                        {item.items && item.items.length > 0 && (
                            <ul>
                                {item.items
                                    ?.filter((x: CentralizedSettingNavItemProps) => authHp.isAuthorized(x.authKey))
                                    .map((x: CentralizedSettingNavItemProps, xIdx: number) => (
                                        <li key={`mi-${pkey}-${index}-${xIdx}`} onClick={() => {
                                            let newLocation = `/settingspanel/${x.panelKey}`;
                                            dispatch(setCurrentKey(x.authKey));
                                            navigate(newLocation);
                                        }}>
                                            {x.title}
                                        </li>
                                    ))}
                            </ul>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const authFilteredSettings = useMemo(() => {
        return CentralizedSettings.filter((x: CentralizedSettingProps) =>
            x.items?.some((y: CentralizedSettingItemProps) => y.items?.some((z: CentralizedSettingNavItemProps) => authHp.isAuthorized(z.authKey)))
        );
    }, [CentralizedSettings]);

    const availableComponentByKey: CentralizedSettingNavItemProps | undefined = useMemo(() => {
        let components: CentralizedSettingNavItemProps | undefined = undefined;
        if (isEmptyOrNull(centralizedSettingsState.currentKey)) return components;

        let found = false;
        authFilteredSettings.forEach((x: CentralizedSettingProps) => {
            if (found) return;
            x.items?.forEach((y: CentralizedSettingItemProps) => {
                if (found) return;
                y.items?.forEach((z: CentralizedSettingNavItemProps) => {
                    if (z.authKey === centralizedSettingsState.currentKey) {
                        components = z;
                        found = true;
                    }
                });
            });
        });
        return components;
    }, [centralizedSettingsState, authFilteredSettings]);

    return (
        <div className="centralized-settings-panel-container">
            <div className="settings-container">
                <div className="title-panel">
                    <span>System Settings Panel</span>
                </div>
                <div className="content-panel">
                    {authFilteredSettings.map((x: CentralizedSettingProps, xIdx: number) => getSinglePanelTemplate(x, xIdx))}
                </div>
            </div>
        </div>
    );
};

export default CentralizedSettingsPanel;
