import { Badge, Button, Col, Divider, Row, Space, Tag, Typography, notification } from "antd";
import { useAudioPlayer } from "@/helpers/audioHelper";
import { isEmptyOrNull } from "@/utils/string";
import soundFile from "@/assets/audios/rc/price-stop.mp3";
import soundFile2 from "@/assets/audios/rc/server-stop.mp3";
import useRCDownloads from "@/hooks/useRCDownloads";
import useRCPriceAlarm, { useRCPriceAlarmMiscStop, useRCPriceAlarmServerStop } from "@/hooks/useRCPriceAlarm";
import FloatingMenu from "@/pages/SystemMonitor/components/FloatingMenu";
import ServerTimeWidget from "@/pages/SystemMonitor/components/ServerTimeWidget";
import { colorMap } from "@/pages/SystemMonitor/StatusMonitor/statusUtils";
import useRCPriceAlarmStore from "@/store/useRCPriceAlarmStore";
import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import useMeasure from "react-use-measure";
import DownloadsModal from "../Downloads/DownloadsModal";
import GenerateReportModal from "./DownloadPriceAlarmModal";
import PriceOverviewStatusCard from "./components/PriceOverviewStatusCard";
import PriceOverviewToolCard from "./components/PriceOverviewToolCard";
import { CloseOutlined, ExpandOutlined, HomeFilled, RedoOutlined } from "@ant-design/icons";

const { Text, Title } = Typography;

const PriceAlarmMenuData = [
    {
        label: "Price Alarm",
        key: "PRICE_ALARM",
    },
    {
        label: "Server Stop",
        key: "SERVER_STOP",
    },
    {
        label: "LP Price Alarm",
        key: "LP_PRICE_ALARM",
    },
    {
        label: "Price Connector Stop",
        key: "CONNECTOR",
    },
    {
        label: "Price Calculate Tool Stop",
        key: "CALCULATE_TOOL",
    },
];

const PriceAlarmMenu = () => {
    const [ref, bounds] = useMeasure();
    const { playSound } = useAudioPlayer(soundFile);
    const { playSound: ServerStopPlaySound } = useAudioPlayer(soundFile2);

    const {
        rcPriceAlarmShouldPlaySound,
        rcPriceAlarmSummary,
        rcPriceAlarmSummaryLP,
        refetchRcPriceAlarm,
        dataUpdatedAt: priceAlarmLastUpdated,
        isFetching: priceAlarmSummaryFetching,
    } = useRCPriceAlarm();
    const {
        rcPriceAlarmMiscShouldPlaySound,
        rcPriceAlarmMiscStopConnector,
        rcPriceAlarmMiscStopCalculateTool,
        isFetching: miscStopFetching,
        refetchRcPriceAlarmMiscStop,
        dataUpdatedAt: miscStopLastUpdated,
    } = useRCPriceAlarmMiscStop();
    const {
        rcPriceAlarmServerStopShouldPlaySound,
        rcPriceAlarmServerStop,
        isFetching: serverStopFetching,
        refetchRcPriceAlarmServerStop,
        dataUpdatedAt: serverStopLastUpdated,
    } = useRCPriceAlarmServerStop();
    const { rcDownloads } = useRCDownloads();
    const [api, contextHolder] = notification.useNotification();
    const inView = useRCPriceAlarmStore(state => state.inView);

    const [refreshing, setRefreshing] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
    const [fullScreen, setFullScreen] = useState(false);

    const totalWarningCount = {
        PRICE_ALARM: rcPriceAlarmSummary?.length || 0,
        LP_PRICE_ALARM: rcPriceAlarmSummaryLP?.length || 0,
        SERVER_STOP: rcPriceAlarmServerStop?.data?.length || 0,
        CONNECTOR: rcPriceAlarmMiscStopConnector?.length || 0,
        CALCULATE_TOOL: rcPriceAlarmMiscStopCalculateTool?.length || 0,
    };

    const handleHTMLScroll = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
            const elementPosition = element.getBoundingClientRect().top + window?.scrollY;
            const offsetPosition = elementPosition - (bounds.height + 56);
            window?.scrollTo({
                top: offsetPosition,
                behavior: "smooth",
            });
        }
        // scroll to the element with the id, and +200px offset
        // example the id is at 200 Y, i need to scroll to 400Y
        // document.getElementById(id)?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    };

    const handleRefetchAll = () => {
        setRefreshing(true);
        api.open({
            key: "refresh",
            message: `Refreshing Price Alarm`,
            description: `A moment please...`,
        });

        Promise.all([refetchRcPriceAlarm(), refetchRcPriceAlarmMiscStop(), refetchRcPriceAlarmServerStop()])
            .then(() => {
                api.open({
                    key: "refresh",
                    message: `Success`,
                    description: `All price alarms has been refreshed`,
                });
            })
            .catch(error => {
                api.open({
                    key: "refresh",
                    message: `Error`,
                    description: `An error occurred while refreshing Price Alarm`,
                });
                console.error("An error occurred while refreshing Price Alarm:", error);
            })
            .finally(() => setRefreshing(false));
    };

    useEffect(() => {
        if (!priceAlarmSummaryFetching) {
            if (rcPriceAlarmShouldPlaySound) {
                playSound();
            }
        }
    }, [priceAlarmSummaryFetching]);

    useEffect(() => {
        if (!miscStopFetching) {
            if (rcPriceAlarmMiscShouldPlaySound) {
                ServerStopPlaySound();
            }
        }
    }, [miscStopFetching]);

    useEffect(() => {
        if (!serverStopFetching) {
            if (rcPriceAlarmServerStopShouldPlaySound) {
                ServerStopPlaySound();
            }
        }
    }, [serverStopFetching]);

    return (
        <>
            {fullScreen && (
                <motion.div
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: "white",
                        zIndex: 998,
                        height: "100vh",
                        width: "100vw",
                    }}
                />
            )}
            <motion.div
                id="OVERVIEW"
                style={{ background: "white", padding: "1rem", minHeight: "80vh" }}
                animate={
                    fullScreen
                        ? {
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            zIndex: 999,
                            width: "100vw",
                            height: "100vh",
                        }
                        : {
                            position: "relative",
                            top: "auto",
                            left: "auto",
                            right: "auto",
                            bottom: "auto",
                            zIndex: "auto",
                            width: "auto",
                            height: "auto",
                        }
                }
            >
                <div style={{ display: "flex", alignItems: "center", marginBottom: "1rem", gap: 16 }}>
                    <Space style={{ alignItems: "center" }} wrap>
                        <Title level={2} style={{ padding: 0, margin: 0 }}>
                            Price Alarm Overview
                        </Title>
                        <Button
                            type="ghost"
                            shape="circle"
                            icon={<RedoOutlined style={{ fontSize: "0.75rem" }} />}
                            style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 4 }}
                            onClick={() => handleRefetchAll()}
                            loading={refreshing}
                            disabled={refreshing}
                        />
                        <Button
                            type="ghost"
                            shape="circle"
                            style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 4 }}
                            onClick={() => setFullScreen(!fullScreen)}
                            icon={
                                <motion.div>
                                    {!fullScreen ? <ExpandOutlined style={{ fontSize: "0.75rem" }} /> : <CloseOutlined style={{ fontSize: "0.75rem" }} />}
                                </motion.div>
                            }
                        />
                    </Space>
                    {/* <div style={{ marginLeft: "auto" }}>
                        <ServerTimeWidget />
                    </div> */}
                </div>
                <Row gutter={[4, 4]}>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                        <PriceOverviewStatusCard
                            title="Price Alarm"
                            index={1}
                            value={totalWarningCount.PRICE_ALARM}
                            callback={() => handleHTMLScroll("PRICE_ALARM")}
                            lastUpdated={priceAlarmLastUpdated}
                        >
                            <Space wrap style={{ marginTop: "0.5rem", width: "100%" }} size={2}>
                                {rcPriceAlarmSummary
                                    ?.sort((a, b) =>
                                        // sort by each showtime alphabetically, revert to latest
                                        a.showTime.localeCompare(b.showTime)
                                    )
                                    ?.reverse()
                                    .map((each, index) => (
                                        <motion.div
                                            key={index}
                                            style={{
                                                borderRadius: 8,
                                                padding: "0.375rem 0.5rem",
                                                backgroundColor: "rgba(0,0,0,0.5)",
                                            }}
                                        >
                                            <Text style={{ fontSize: 18, color: "white", lineHeight: 1 }}>
                                                <small>{each.brand}</small>
                                                <br />
                                                <strong style={{ fontWeight: 800 }}>{each.group}</strong>
                                                <br />
                                                <Tag>{each.showTime}</Tag>
                                            </Text>
                                        </motion.div>
                                    ))}
                            </Space>
                        </PriceOverviewStatusCard>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                        <PriceOverviewStatusCard
                            title="Server Stop"
                            lastUpdated={serverStopLastUpdated}
                            index={3}
                            value={totalWarningCount.SERVER_STOP}
                            callback={() => handleHTMLScroll("SERVER_STOP")}
                        >
                            {rcPriceAlarmServerStop?.data !== undefined && rcPriceAlarmServerStop?.data?.length >= 1 && (
                                <Space wrap style={{ marginTop: "0.5rem", width: "100%" }} size={2}>
                                    {rcPriceAlarmServerStop?.data?.map((each, index) => (
                                        <motion.div
                                            key={index}
                                            style={{
                                                borderRadius: 8,
                                                padding: "0.375rem 0.5rem",
                                                backgroundColor: "rgba(0,0,0,0.5)",
                                            }}
                                        >
                                            <Text style={{ fontSize: 18, color: "white" }}>
                                                <strong>{each.serverName}</strong> <small>{each.duration}</small>
                                            </Text>
                                        </motion.div>
                                    ))}
                                </Space>
                            )}
                        </PriceOverviewStatusCard>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 6 }}>
                        <PriceOverviewStatusCard
                            title="LP Price Alarm"
                            lastUpdated={priceAlarmLastUpdated}
                            index={2}
                            value={totalWarningCount.LP_PRICE_ALARM}
                            callback={() => handleHTMLScroll("LP_PRICE_ALARM")}
                        // href="/systemmonitor/rejectorder"
                        >
                            {" "}
                            <Space wrap style={{ marginTop: "0.5rem", width: "100%" }} size={2}>
                                {rcPriceAlarmSummaryLP
                                    ?.sort((a, b) =>
                                        // sort by each group alphabetically
                                        a.showTime.localeCompare(b.showTime)
                                    )
                                    ?.reverse()
                                    .slice(0, 18)
                                    .map((each, index) => (
                                        <motion.div
                                            key={index}
                                            style={{
                                                borderRadius: 8,
                                                padding: "0.375rem 0.5rem",
                                                backgroundColor: "rgba(0,0,0,0.5)",
                                            }}
                                        >
                                            <Text style={{ fontSize: 18, color: "white", lineHeight: 1 }}>
                                                <small>{each.brand}</small>
                                                <br />
                                                <strong style={{ fontWeight: 800 }}>{each.group}</strong>
                                                <br />
                                                <Tag>{each.showTime}</Tag>
                                            </Text>
                                        </motion.div>
                                    ))}
                                {rcPriceAlarmSummaryLP?.length > 18 && (
                                    <motion.div
                                        style={{
                                            borderRadius: 8,
                                            padding: "0.375rem 0.5rem",
                                            backgroundColor: "rgba(0,0,0,0.5)",
                                        }}
                                    >
                                        <Text style={{ fontSize: 18, color: "white" }}>
                                            And{" "}
                                            {
                                                // count how many more
                                                rcPriceAlarmSummaryLP?.length - 18
                                            }{" "}
                                            more...
                                        </Text>
                                    </motion.div>
                                )}
                            </Space>
                        </PriceOverviewStatusCard>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 6 }}>
                        <PriceOverviewStatusCard
                            title="Price Connector Stop"
                            lastUpdated={miscStopLastUpdated}
                            index={4}
                            value={totalWarningCount.CONNECTOR}
                            callback={() => handleHTMLScroll("CONNECTOR")}
                        >
                            {!isEmptyOrNull(rcPriceAlarmMiscStopConnector) && (
                                <Space wrap style={{ marginTop: "0.5rem", width: "100%" }} size={2}>
                                    {rcPriceAlarmMiscStopConnector.map((each, index) => (
                                        <motion.div
                                            key={index}
                                            style={{
                                                borderRadius: 8,
                                                padding: "0.15rem 0.5rem",
                                                backgroundColor: "rgba(0,0,0,0.5)",
                                            }}
                                        >
                                            <Text style={{ fontSize: 14, color: "white" }}>
                                                <strong>{each.appName}</strong> <small>{each.showStopTime}</small>
                                            </Text>
                                        </motion.div>
                                    ))}
                                </Space>
                            )}
                        </PriceOverviewStatusCard>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 6 }}>
                        <PriceOverviewStatusCard
                            title="Price Calculate Tool Stop"
                            lastUpdated={miscStopLastUpdated}
                            index={5}
                            value={totalWarningCount.CALCULATE_TOOL}
                            callback={() => handleHTMLScroll("CALCULATE_TOOL")}
                        >
                            {!isEmptyOrNull(rcPriceAlarmMiscStopCalculateTool) && (
                                <Space wrap style={{ marginTop: "0.5rem", width: "100%" }} size={2}>
                                    {rcPriceAlarmMiscStopCalculateTool.map((each, index) => (
                                        <motion.div
                                            key={index}
                                            style={{
                                                borderRadius: 8,
                                                padding: "0.15rem 0.5rem",
                                                backgroundColor: "rgba(0,0,0,0.5)",
                                            }}
                                        >
                                            <Text style={{ fontSize: 14, color: "white" }}>
                                                <strong>{each.appName}</strong> <small>{each.showStopTime}</small>
                                            </Text>
                                        </motion.div>
                                    ))}
                                </Space>
                            )}
                        </PriceOverviewStatusCard>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 6 }}>
                        <PriceOverviewToolCard title="Tools" index={6}>
                            <Space wrap style={{ marginTop: "0.5rem", width: "100%" }} size={2}>
                                <Button type="primary" style={{ borderRadius: 8 }} onClick={() => setIsModalOpen(true)}>
                                    Generate Report
                                </Button>

                                <Button
                                    type="primary"
                                    style={{ borderRadius: 8, display: "flex", gap: 6, alignItems: "center" }}
                                    onClick={() => setIsDownloadModalOpen(true)}
                                >
                                    <span>Download Report</span>
                                    {rcDownloads?.[1] !== undefined &&
                                        rcDownloads?.[1]?.filter(each => each?.status?.toLowerCase() === "completed")?.length >= 1 && (
                                            <Badge
                                                count={rcDownloads?.[1]?.filter(each => each?.status?.toLowerCase() === "completed")?.length}
                                                color={colorMap.success}
                                                size="small"
                                            />
                                        )}
                                </Button>
                            </Space>
                        </PriceOverviewToolCard>
                    </Col>
                </Row>
            </motion.div>
            <div style={{ height: "1rem" }} />
            {contextHolder}
            <FloatingMenu>
                <div ref={ref} style={{ display: "flex", justifyContent: "flex-start", gap: 4, flexWrap: "wrap", alignItems: "center" }}>
                    <Button style={{ display: "flex", alignItems: "center" }} onClick={() => handleHTMLScroll("OVERVIEW")}>
                        <HomeFilled />
                    </Button>
                    {PriceAlarmMenuData.map((item, index) => {
                        return (
                            <Button
                                // @ts-ignore
                                type={inView[item.key] ? "primary" : "default"}
                                key={item.key + index}
                                style={{ display: "flex", alignItems: "center", gap: 2 }}
                                onClick={() => handleHTMLScroll(item.key)}
                            >
                                {item.label}
                                {/* @ts-ignore */}
                                {totalWarningCount?.[item.key] !== undefined && (
                                    // @ts-ignore
                                    <Badge count={totalWarningCount?.[item.key]} overflowCount={999999} />
                                )}
                            </Button>
                        );
                    })}
                    <Button style={{ display: "flex", alignItems: "center", gap: 2 }} onClick={() => setIsModalOpen(true)}>
                        Generate Report
                    </Button>
                    <Button style={{ display: "flex", alignItems: "center", gap: 2 }} onClick={() => setIsDownloadModalOpen(true)}>
                        Download Report
                    </Button>

                    <Divider type="vertical" />
                    <Button
                        type="ghost"
                        style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 4 }}
                        onClick={() => {
                            handleRefetchAll();
                        }}
                        disabled={refreshing}
                        shape="circle"
                        icon={
                            <motion.div
                                animate={{
                                    rotate: refreshing ? 180 : 0,
                                }}
                            >
                                <RedoOutlined style={{ fontSize: "0.75rem" }} />
                            </motion.div>
                        }
                    />
                </div>
            </FloatingMenu>
            <GenerateReportModal open={isModalOpen} handleModalOpen={(open: boolean) => setIsModalOpen(open)} />
            <DownloadsModal
                open={isDownloadModalOpen}
                handleModalOpen={(open: boolean) => setIsDownloadModalOpen(open)}
                filterName={"PriceAlarmSummary"}
            />
        </>
    );
};

export default PriceAlarmMenu;
