import { IRCMidBias } from "@/hooks/useRCMidBias";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { CheckOutlined, WhatsAppOutlined, LineChartOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { useCallback, useState } from "react";
import MidBiasLineChart from "./MidBiasLineChart";
import FlexiDataTable from "@/components/FlexiDataTable";
import { FlexiDataTableOptionsProps } from "@/constants/type";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { SUCCESS_FAILED } from "@/constants";
import LoadingComponent from "@/components/Loading";

const MidBiasTable = ({ data, dateRange, refetch }: { data: IRCMidBias[]; dateRange: string; refetch: () => void }) => {
    const [fetchingLineData, setFetchingLineData] = useState<boolean>(false);
    const [biasLineCompareData, setBiasLineCompareData] = useState(null);
    const [currentMidBiasLineData, setCurrentMidBiasLineData] = useState<any | undefined>(undefined);
    const [biasLineTitle, setBiasLineTitle] = useState("");

    const showBiasLine = (data: any) => {
        setFetchingLineData(true);
        const formData = new FormData();
        formData.append(
            "keyStr",
            `${data["dateTimeStr"].substring(0, data["dateTimeStr"].length - 3)},${data["server"]},${data["symbol"]},${data["compareServer"]},${
                data["compareSymbol"]
            },1,${data["serverUno"]}`
        );

        plainAxiosInstance
            .post(`${APIs.RC_MIDBIAS.GET_MIDBIAS_CHART}`, formData)
            .then((res: any) => {
                if (res?.data) {
                    setBiasLineCompareData(res.data);
                    setCurrentMidBiasLineData(data);
                    setBiasLineTitle(
                        `${data["server"]}-${data["symbol"]} vs ${data["compareServer"]}-${data["compareSymbol"]} ${data["dateTimeStr"]}`
                    );
                } else {
                    ErrorMessageHandler("Search data fail.", SUCCESS_FAILED.OTHERS_FAILED);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("Search data fail: ", SUCCESS_FAILED.OTHERS_FAILED, err))
            )
            .finally(() => setFetchingLineData(false));
    };

    const groupSolvedMid = (data: any) => {
        const formData = new FormData();
        formData.append("keyStr", `${data["symbol"]},1`);
        plainAxiosInstance
            .post(`${APIs.RC_MIDBIAS.POST_GROUPSOLVED}/20/${dateRange}`, formData)
            .then(resp => {
                if (resp.data > 0) {
                    refetch();
                } else {
                    ErrorMessageHandler("Issue resolved failed. Please try again.", SUCCESS_FAILED.OTHERS_FAILED);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) =>
                    ErrorMessageHandler("Issue resolved failed. Please try again.", SUCCESS_FAILED.OTHERS_FAILED, err)
                )
            );
    };

    const dateGroupSolvedMid = (line: any, index: any) => {
        const formData = new FormData();
        formData.append("keyStr", `${line["dateTimeStr"]},${line["cleansymbol"]},${line["ourServerListStr"]},1`);
        plainAxiosInstance
            .post(APIs.RC_MIDBIAS.POST_DATAGROUPSOLVED, formData)
            .then(resp => {
                if (resp.data > 0) {
                    refetch();
                } else {
                    ErrorMessageHandler("Issue resolved failed. Please try again.", SUCCESS_FAILED.OTHERS_FAILED);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) =>
                    ErrorMessageHandler("Issue resolved failed. Please try again.", SUCCESS_FAILED.OTHERS_FAILED, err)
                )
            );
    };

    const solvedMid = async (line: any, index: any) => {
        const formData = new FormData();
        formData.append("keyStr", `${line["dateTimeStr"]},${line["serverUno"]},${line["symbol"]},${line["cserverUno"]},${line["compareSymbol"]},1`);
        plainAxiosInstance
            .post(APIs.RC_MIDBIAS.POST_SOLVED, formData)
            .then(resp => {
                if (resp.data > 0) {
                    refetch();
                } else {
                    ErrorMessageHandler("Issue resolved failed. Please try again.", SUCCESS_FAILED.OTHERS_FAILED);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) =>
                    ErrorMessageHandler("Issue resolved failed. Please try again.", SUCCESS_FAILED.OTHERS_FAILED, err)
                )
            );
    };

    const sendToWhatsApp = (line: any, index: any) => {
        const formData = new FormData();
        formData.append("keyStr", `${line["brandId"]};${line["firstAppearStr"]};${line["lastAppearStr"]};${line["cleansymbol"]};${line["cserv"]}`);
        plainAxiosInstance.post(APIs.RC_MIDBIAS.POST_WHATSAPP, formData).then(resp => {
            if (resp.status === 200 && resp.data === "OK") {
                ErrorMessageHandler("Notify Successfully.", SUCCESS_FAILED.OTHERS_SUCCESS);
            } else {
                ErrorMessageHandler("Notify Failed.", SUCCESS_FAILED.OTHERS_FAILED);
            }
        });
    };

    const columns1: any = [
        DTColProps.Middle({
            title: "Our Symbol",
            dataIndex: "symbol",
            key: "symbol",
        }),
        DTColProps.Middle({
            title: "First Appear",
            dataIndex: "firstAppearStr",
            key: "firstAppearStr",
        }),
        DTColProps.Middle(
            {
                title: "Our Servers",
                dataIndex: "ourServerListStr",
                key: "ourServerListStr",
            },
            ["text-center"]
        ),
        {
            title: "Competitor Server",
            dataIndex: "cserv",
            key: "cserv",
        },
        DTColProps.Small(
            {
                title: "Count",
                dataIndex: "count",
                key: "count",
            },
            ["text-center"]
        ),
        DTColProps.XSmall(
            {
                title: "Solved",
                dataIndex: "Action",
                key: "Action",
                render: (text: any, rowData: any) => (
                    <Button className="m-1" type="primary" icon={<CheckOutlined />} onClick={e => groupSolvedMid(rowData)} />
                ),
            },
            ["text-center"]
        ),
    ];

    const brandColumns1: any = [
        {
            title: "Brand",
            dataIndex: "brandId",
            key: "brandId",
        },
        DTColProps.Small(
            {
                title: "Count",
                dataIndex: "count",
                key: "count",
            },
            ["text-center"]
        ),
        DTColProps.XSmall(
            {
                title: "Send",
                dataIndex: "Action",
                key: "Action",
                render: (text: any, data: any, index: any) => (
                    <Button className="m-1" type="primary" icon={<WhatsAppOutlined />} onClick={e => sendToWhatsApp(data, index)} />
                ),
            },
            ["text-center"]
        ),
    ];

    const dateColumns1: any = [
        DTColProps.XSmall(
            {
                title: "Line",
                dataIndex: "Action",
                key: "Action",
                render: (text: any, data: any) => (
                    <Button className="m-1" type="primary" icon={<LineChartOutlined />} onClick={e => showBiasLine(data)} />
                ),
            },
            ["text-center"]
        ),
        DTColProps.Middle({
            title: "Datetime",
            dataIndex: "dateTimeStr",
            key: "dateTimeStr",
            defaultSortOrder: "ascend",
        }),
        DTColProps.Middle({
            title: "Symbol",
            dataIndex: "symbolListStr",
            key: "symbolListStr",
        }),
        {
            title: "Max Score Range",
            dataIndex: "maxScoreRange",
            key: "maxScoreRange",
            align: "right",
        },
        {
            title: "Score Sum Range",
            dataIndex: "scoreSumRange",
            key: "scoreSumRange",
            align: "right",
        },
        {
            title: "Correlation Range",
            dataIndex: "correlationRange",
            key: "correlationRange",
            align: "right",
        },
        DTColProps.XSmall(
            {
                title: "Count",
                dataIndex: "count",
            },
            ["text-center"]
        ),
        DTColProps.XSmall(
            {
                title: "Solved",
                align: "center",
                dataIndex: "Action",
                render: (text: any, data: any, index: any) => (
                    <Button className="m-1" type="primary" icon={<CheckOutlined />} onClick={e => dateGroupSolvedMid(data, index)} />
                ),
            },
            ["text-center"]
        ),
    ];

    const detailColumns1: any = [
        DTColProps.XSmall(
            {
                title: "Line",
                dataIndex: "Action",
                key: "Action",
                render: (text: any, data: any) => (
                    <Button className="m-1" type="primary" icon={<LineChartOutlined />} onClick={e => showBiasLine(data)} />
                ),
            },
            ["text-center"]
        ),
        DTColProps.Small({
            title: "Datetime",
            dataIndex: "dateTimeStr",
            key: "dateTimeStr",
        }),
        DTColProps.Small({
            title: "Server",
            dataIndex: "server",
            key: "server",
        }),

        DTColProps.Small({
            title: "Symbol",
            dataIndex: "symbol",
            key: "symbol",
        }),
        DTColProps.Small({
            title: "Compare Server",
            dataIndex: "compareServer",
            key: "compareServer",
        }),
        {
            title: "Max score",
            dataIndex: "showMaxScore",
            key: "showMaxScore",
            align: "right",
        },
        {
            title: "Score Sum",
            dataIndex: "showScoreSum",
            key: "showScoreSum",
            align: "right",
        },
        {
            title: "Correlation",
            dataIndex: "showPPMCC",
            key: "showPPMCC",
            align: "right",
            // defaultSortOrder: 'ascend',
            // sorter: (a, b) => numberSort(a, b, "showPPMCC"),
        },
        {
            title: "ScoreRank",
            dataIndex: "scoreRank",
            key: "scoreRank",
            align: "right",
            defaultSortOrder: "ascend",
            // sorter: (a, b) => numberSort(a, b, "scoreRank"),
        },
        DTColProps.XSmall(
            {
                title: "Solved",
                dataIndex: "Action",
                key: "Action",
                render: (text: any, data: any, index: number) => (
                    <>
                        <Button className="m-1" type="primary" icon={<CheckOutlined />} onClick={e => solvedMid(data, index)} />
                    </>
                ),
            },
            ["text-center"]
        ),
    ];

    const tableOptions: FlexiDataTableOptionsProps = {
        showHideColumns: false,
        enableFilter: false,
        expandable: {
            expandedRowRender: (record: any) => (
                <div>
                    <FlexiDataTable
                        bordered
                        rowKeyProperty="brandId"
                        title={false}
                        columns={brandColumns1}
                        dataSource={record.detail ?? []}
                        options={{
                            showHideColumns: false,
                            enableFilter: false,
                            expandable: {
                                expandedRowRender: (dateRecord: any) => (
                                    <div>
                                        <FlexiDataTable
                                            bordered
                                            rowKeyProperty="rowKey"
                                            title={false}
                                            columns={dateColumns1}
                                            dataSource={dateRecord.detail ?? []}
                                            options={{
                                                showHideColumns: false,
                                                enableFilter: false,
                                                expandable: {
                                                    expandedRowRender: (lastRecord: any) => (
                                                        <div>
                                                            <FlexiDataTable
                                                                bordered
                                                                rowKeyProperty="rowKey"
                                                                title={false}
                                                                columns={detailColumns1}
                                                                dataSource={lastRecord.detail ?? []}
                                                                options={{
                                                                    showHideColumns: false,
                                                                    enableFilter: false,
                                                                    recordRowClassName: (lastR: any) =>
                                                                        lastR.ppmcc < 80 || lastR.scoreMax > 15
                                                                            ? "bg-red-800"
                                                                            : lastR.ppmcc < 95 &&
                                                                              lastR.ppmcc > 80 &&
                                                                              (lastR.scoreSum > 50 || lastR.scoreMax > 8)
                                                                            ? "bg-yellow-700"
                                                                            : "",
                                                                }}
                                                                pagination={false}
                                                                loading={false}
                                                            />
                                                        </div>
                                                    ),
                                                },
                                            }}
                                            pagination={false}
                                            loading={false}
                                        />
                                    </div>
                                ),
                            },
                        }}
                        pagination={false}
                        loading={false}
                    />
                </div>
            ),
        },
    };

    return (
        <div>
            <FlexiDataTable
                bordered
                rowKeyProperty="symbol"
                title={false}
                columns={columns1}
                dataSource={data ?? []}
                options={tableOptions}
                pagination={{ defaultPageSize: 50 }}
                loading={false}
            />
            <Modal
                open={fetchingLineData || biasLineCompareData !== null}
                closable={true}
                onCancel={() => {
                    setFetchingLineData(false);
                    setBiasLineCompareData(null);
                    setBiasLineTitle("");
                }}
                width={1280}
                footer={null}
                title={biasLineTitle}
            >
                {fetchingLineData ? (
                    <LoadingComponent />
                ) : (
                    biasLineCompareData && (
                        <MidBiasLineChart biaslineCompareData={biasLineCompareData} currentMidBiasData={currentMidBiasLineData} refetch={refetch} />
                    )
                )}
            </Modal>
        </div>
    );
};

export default MidBiasTable;
