import { Modal } from "antd";
import { GroupTransferModalCallbackKey } from "../../GroupTransferTool/components/grouptransferModal";
import { useEffect, useState } from "react";
import { HTHListItemProps } from "./hthlist";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { APIs } from "@/services/apis";
import { SUCCESS_FAILED } from "@/constants";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { objectToQueryString } from "@/utils/object";
import { isEmptyOrNull } from "@/utils/string";
import LoadingComponent from "@/components/Loading";
import EmptyData from "@/components/Common/Empty";

//APIs Enhance Note: Activity Log item missing "status" params

interface HubToHubActivityLogModalProps {
    isModalVisible: boolean;
    callback: (type: number, data: any) => void;
    data: undefined | HTHListItemProps;
}

export enum HubToHubActivityLogModalCallbackKey {
    Close = 0,
}

interface ActivityLogProps {
    datetime: number; // yyyyMMddHHmmss
    serverUno: number;
    login: String;
    bridgeId: String;
    loginGroup: String;
    symbol: String;
    actionType: string;
    inspector: String;
}

const HubToHubActivityLogModal = (props: HubToHubActivityLogModalProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<ActivityLogProps[]>([]);

    const getTemplate = (x: ActivityLogProps, idx: number) => {
        let contentTemplate = <></>;
        switch (x.actionType) {
            case "SOLVED":
                contentTemplate = <span>{` resolved from the conflict list.`}</span>;
                break;
            case "ADD":
                contentTemplate = <span>{` added ${x.symbol} into the list.`}</span>;
                break;
            case "REMOVE":
                contentTemplate = <span>{` removed ${x.symbol} from the list.`}</span>;
                break;
            default:
                return <></>;
        }
        return (
            <div key={`al-i-${idx}`} className="activity-log-item">
                <span className="datetime-inspector">{`${`${x.datetime}`.replace(
                    /(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,
                    "$1-$2-$3 $4:$5:$6"
                )} <${x.inspector}>`}</span>
                {contentTemplate}
            </div>
        );
    };

    const getActivityLog = (filterParam: any) => {
        if (filterParam) {
            setIsLoading(true);
            let qString = objectToQueryString(filterParam, ["serverUno", "bridgeId", "login", "loginGroup", "symbol"]);
            plainAxiosInstance
                .get(`${APIs.RISK_TOOL.GET_HUB_TO_HUB_LISTING_ACTIVITY_LOG}${isEmptyOrNull(qString) ? "" : `?${qString}`}`)
                .then((res: any) => {
                    setData(res.data.status === 0 && res.data.data.length > 0 ? res.data.data : []);
                })
                .catch((error: any) =>
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("activity log", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
                )
                .finally(() => setIsLoading(false));
        } else {
            setData([]);
        }
    };

    useEffect(() => {
        if (props.isModalVisible) {
            getActivityLog(props.data);
        }
    }, [props.isModalVisible]);

    return (
        <Modal
            style={{ top: 10 }}
            width={"40vw"}
            title={"Activity Log"}
            open={props.isModalVisible}
            okButtonProps={{ style: { display: "none" } }}
            onCancel={() => props.callback(GroupTransferModalCallbackKey.Close, null)}
            maskClosable={false}
        >
            <div className="hth-activity-log-container">
                {isLoading ? (
                    <LoadingComponent />
                ) : (
                    <div className="activity-log-list">
                        {data.length > 0 ? <div>{data.map((x: ActivityLogProps, idx: number) => getTemplate(x, idx))}</div> : <EmptyData />}
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default HubToHubActivityLogModal;
