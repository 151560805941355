import { motion } from "framer-motion";
import { Tooltip, notification } from "antd";
import { CopyOutlined } from "@ant-design/icons";
const CodeBlock = ({ code }: { code: string }) => {
    const lines = code.split("\n");

    return (
        <div style={{ background: "#131313", padding: "0.5rem", borderRadius: 4, overflowY: "auto", maxHeight: "88vh" }} className="nice-scrollbar">
            {/* copy button */}
            <div style={{ display: "flex", justifyContent: "flex-end", position: "sticky", top: 4, float: "right" }}>
                <Tooltip title="Copy">
                    <motion.div
                        style={{
                            fontSize: 16,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            width: 24,
                            height: 24,
                            borderRadius: 4,
                            color: "white",
                        }}
                        onClick={e => {
                            navigator.clipboard.writeText(code);
                            notification.success({
                                message: "Copied to clipboard",
                            });
                        }}
                        whileHover={{ backgroundColor: "rgba(255,255,255,0.1)" }}
                    >
                        <CopyOutlined />
                    </motion.div>
                </Tooltip>
            </div>
            {lines.map((line, index) => (
                <motion.div
                    style={{ display: "flex", color: "rgba(255,255,255,0.8)", background: "rgba(255,255,255,0)", cursor: "pointer" }}
                    key={index}
                    whileHover={{ background: "rgba(255,255,255,0.1)", color: "rgba(255,255,255,1)" }}
                >
                    <div style={{ textAlign: "right", userSelect: "none", pointerEvents: "none", color: "gray", width: 46, paddingRight: 10 }}>
                        <small>{index + 1}</small>
                    </div>
                    <div
                        style={{
                            whiteSpace: "pre-wrap",
                            width: "calc(100% - 80px)",
                            margin: 0,
                            wordBreak: "break-word",
                            fontFamily: "monospace",
                            overflowWrap: "break-word",
                            wordWrap: "break-word",
                            flexGrow: 1,
                        }}
                    >
                        {line}
                    </div>
                </motion.div>
            ))}
        </div>
    );
};

export default CodeBlock;
