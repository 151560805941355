import BasicFilter from "@/components/BasicFilter";
import SystemBreadCrumbs from "../components/SystemBreadCrumbs";
import DownloadMetaTraderButton from "./DownloadMetaTraderButton";
import MetaTraderFilter from "./MetaTraderFilter";
import MetaTraderMonitorTable from "./MetaTraderMonitorTable";
import MetaTraders from "./MetaTraders";
import soundMetaTrader from "../../../assets/audios/rc/machineCall.mp3";
import useRCMetaTraderMonitors from "@/hooks/useRCMetaTraderMonitors";
import { defaultIfEmptyOrNull } from "@/utils/string";
import { useAudioPlayer } from "@/helpers/audioHelper";
import { useEffect } from "react";

export const massageData = (res: any) => {
    const nowRes = res;

    const uniqType = Array.from(new Set(nowRes.map((item: any) => item.type)));

    // 依據Type塞資料
    const typeData = uniqType.map(type => {
        const tempData = nowRes.filter((data2: any) => data2.type === type);

        let danger = 0,
            success = 0;

        tempData.forEach((data: any) => {
            if (data.status === 0) success++;
            if (data.status === 1) danger++;
        });

        return {
            type: type,
            data: tempData,
            danger: danger,
            success: success,
        };
    });
    return typeData;
};

const MetaTrader = () => {
    const { playSound } = useAudioPlayer(soundMetaTrader);
    const { rcMetaTraderMonitors, isFetching } = useRCMetaTraderMonitors();
    const activeSound = defaultIfEmptyOrNull(rcMetaTraderMonitors, []).filter((item: any) => item.checkTime === null).length;

    useEffect(() => {
        if (!isFetching) {
            if (activeSound) {
                playSound();
            }
        }
    }, [isFetching]);

    return (
        <>
            <SystemBreadCrumbs currMenu="Meta Trader" />
            <div style={{ paddingBottom: "1rem", background: "white" }}>
                <BasicFilter
                    titleBarChildren={
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                flexWrap: "wrap",
                                gap: "4px",
                                alignItems: "center",
                            }}
                        >
                            <DownloadMetaTraderButton />
                        </div>
                    }
                >
                    <MetaTraderFilter />
                </BasicFilter>
                <MetaTraders />
                <MetaTraderMonitorTable />
            </div>
        </>
    );
};

export default MetaTrader;
