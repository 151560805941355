import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import PriceCompareQuery from "./PriceCompareQuery";
import { Input, Space, Typography } from "antd";
import BasicFilter from "../../../components/BasicFilter";
import PriceCompareAGGrid from "./PriceCompareAGGrid";
import { plainAxiosInstance } from "../../../services/axiosSetup";
import { APIs } from "../../../services/apis";
import { useQueries } from "@tanstack/react-query";
import useRCPriceCompareStore from "../../../store/useRCPriceCompareStore";
import { AllCommunityModule, ClientSideRowModelModule, ModuleRegistry, ValidationModule, ValueFormatterParams } from "ag-grid-community";
import AGSymbolCell from "./AGSymbolCell";
import { matchSorter } from "match-sorter";
import Modal from "antd/lib/modal/Modal";
import PriceMonitorBreadCrumbs from "../components/PriceMonitorBreadCrumbs";

// Register shared Modules globally
ModuleRegistry.registerModules([ClientSideRowModelModule, ValidationModule /* Development Only */, AllCommunityModule]);

const PriceCompare = () => {
    return (
        <>
            <PriceMonitorBreadCrumbs currMenu="Price Compare" />
            <Space direction="vertical" style={{ width: "100%", marginBottom: "2rem" }}>
                <BasicFilter>
                    <PriceCompareQuery />
                </BasicFilter>
                <DataFetcher />
            </Space>
        </>
    );
};

function numberCellFormatter(params: ValueFormatterParams) {
    if (isNaN(parseFloat(params.value))) return "-";
    return params.value;
    // return params.value.toFixed(5);
}
// function numberCellFormatterWithout

const fetchServerData = async (serverName: string) => {
    const response = await plainAxiosInstance.get(`${APIs.RC_PRICE_COMPARE.GET_COMPARE_OUR_SERVERS}?serverName=${serverName}`, {
        headers: { Accept: "application/json" },
    });
    return response.data;
};

const fetchCompetitorData = async (serverName: string, competitorName: string) => {
    const response = await plainAxiosInstance.get(
        `${APIs.RC_PRICE_COMPARE.GET_COMPARE_COMPETITOR}?serverName=${serverName}&competitorServerName=${competitorName}`,
        { headers: { Accept: "application/json" } }
    );
    return response.data;
};

const DataFetcher = () => {
    // const [columnsDef, setColumnsDef] = useState<any[]>([]);
    // const [rowData, setRowData] = useState<any[]>([]);
    const rowDataRef = useRef<any[]>([]);
    const selectedSymbolRowDataRef = useRef<any[]>([]);
    const mainServer = useRCPriceCompareStore(state => state.mainServer);
    const comparisonList = useRCPriceCompareStore(state => state.comparisonList);
    const selectedSymbols = useRCPriceCompareStore(state => state.selectedSymbols);
    const comparisonQueries = useQueries({
        queries: comparisonList?.map(item => ({
            queryKey: [item.type, item.value, mainServer],
            queryFn: item.type === "server" ? () => fetchServerData(item.value) : () => fetchCompetitorData(mainServer || "", item.value),
            refetchInterval: 2000,
            enabled: !!mainServer || !!item.value,
            onError: (error: any) => {
                console.error(`Error fetching data for ${item.value}:`, error);
            },
        })),
    });
    // console.log("comparisonQueries", comparisonQueries);
    const allData = useMemo(() => {
        // ensure all queries are resolved
        if (comparisonQueries?.some(query => query.isLoading)) return {};
        return comparisonQueries?.reduce((acc, query, index) => {
            if (query?.data !== undefined) {
                acc[comparisonList?.[index]?.value] = query.data;
            }
            return acc;
        }, {} as Record<string, any>);
    }, [comparisonQueries, comparisonList]);

    const getColumnDefs = useCallback(() => {
        if (Object.keys(allData).length === 0) return [];

        return [
            {
                headerName: "Symbol",
                field: "symbol",
                pinned: "left",
                width: 120,
                // i want this to be clickable, and call setSelectedSymbols
                cellRenderer: AGSymbolCell,
            },
            ...Object.keys(allData).map(serverName => ({
                headerName: serverName,
                children: [
                    {
                        headerName: "Bid",
                        field: `${serverName}_bid`,
                        // cellRenderer: "agAnimateShowChangeCellRenderer",
                        valueFormatter: numberCellFormatter,
                        cellClass: "number ag-right-aligned-cell",
                        type: "rightAligned",
                        width: 150,
                    },
                    {
                        headerName: "Mid",
                        field: `${serverName}_mid`,
                        // cellRenderer: "agAnimateShowChangeCellRenderer",
                        valueFormatter: numberCellFormatter,
                        cellClass: "number ag-right-aligned-cell",
                        type: "rightAligned",
                        width: 150,
                    },
                    {
                        headerName: "Ask",
                        field: `${serverName}_ask`,
                        // cellRenderer: "agAnimateShowChangeCellRenderer",
                        valueFormatter: numberCellFormatter,
                        cellClass: "number ag-right-aligned-cell",
                        type: "rightAligned",
                        width: 150,
                    },
                    {
                        headerName: "Spread",
                        field: `${serverName}_spread`,
                        // cellRenderer: "agAnimateShowChangeCellRenderer",
                        // valueFormatter: numberCellFormatter,
                        cellClass: "number ag-right-aligned-cell",
                        type: "rightAligned",
                    },
                    {
                        headerName: "Time",
                        field: `${serverName}_time`,
                        valueFormatter: (params: any) => {
                            if (!params.value) return "-";
                            if (isNaN(params.value)) return "-";
                            return new Date(params.value).toLocaleTimeString();
                        },
                        width: 100,
                    },
                ],
            })),
        ];
    }, [allData]);

    const getColumnDefsSelectedSymbol = useCallback(() => {
        if (selectedSymbols?.length === 0) return [];
        if (Object.keys(allData).length === 0) return [];

        return [
            {
                headerName: "Server/Competitor",
                field: "server",
                pinned: "left",
                width: 120,
            },
            {
                headerName: "Bid",
                field: `bid`,
                // cellRenderer: "agAnimateShowChangeCellRenderer",
                valueFormatter: numberCellFormatter,
                cellClass: "number ag-right-aligned-cell",
                type: "rightAligned",
                width: 120,
            },
            {
                headerName: "Mid",
                field: `mid`,
                // cellRenderer: "agAnimateShowChangeCellRenderer",
                valueFormatter: numberCellFormatter,
                cellClass: "number ag-right-aligned-cell",
                type: "rightAligned",
                width: 120,
            },
            {
                headerName: "Ask",
                field: `ask`,
                // cellRenderer: "agAnimateShowChangeCellRenderer",
                valueFormatter: numberCellFormatter,
                cellClass: "number ag-right-aligned-cell",
                type: "rightAligned",
                width: 120,
            },
            {
                headerName: "Spread",
                field: `spread`,
                // cellRenderer: "agAnimateShowChangeCellRenderer",
                // valueFormatter: numberCellFormatter,
                cellClass: "number ag-right-aligned-cell",
                type: "rightAligned",
                width: 120,
            },
            {
                headerName: "Time",
                field: `time`,
                valueFormatter: (params: any) => {
                    // console.log(params.value);
                    if (!params.value) return "-";
                    if (isNaN(params.value)) return "-";
                    return new Date(params.value).toLocaleTimeString();
                },
                width: 120,
            },
        ];
    }, [selectedSymbols, allData]);

    useEffect(() => {
        if (Object.keys(allData)?.length === 0) return;

        const firstDataSet = allData?.[Object.keys(allData)?.[0]];
        const symbols = Object.keys(firstDataSet?.data);
        const updates = symbols.map(symbol => {
            const row: any = { symbol };
            const eachServerName = comparisonList.map(item => item.value);
            eachServerName.forEach(serverName => {
                const serverData = allData[serverName];
                // console.log("serverName", serverName);
                // console.log("serverData", serverData);
                if (!serverData || !serverData?.data?.[symbol]) {
                    row[`${serverName}_bid`] = null;
                    row[`${serverName}_ask`] = null;
                    row[`${serverName}_mid`] = null;
                    row[`${serverName}_spread`] = null;
                    row[`${serverName}_time`] = null;
                } else {
                    row[`${serverName}_bid`] = serverData.d_idx.bid ? serverData.data?.[symbol].flat(Infinity)?.[serverData.d_idx.bid] : null;
                    row[`${serverName}_ask`] = serverData.d_idx.ask ? serverData.data?.[symbol].flat(Infinity)?.[serverData.d_idx.ask] : null;
                    row[`${serverName}_mid`] = serverData.d_idx.mid ? serverData.data?.[symbol].flat(Infinity)?.[serverData.d_idx.mid] : null;
                    row[`${serverName}_spread`] = serverData.d_idx.spread
                        ? serverData.data?.[symbol].flat(Infinity)?.[serverData.d_idx.spread]
                        : null;
                    row[`${serverName}_time`] = serverData.d_idx.ticktime
                        ? serverData.data?.[symbol].flat(Infinity)?.[serverData.d_idx.ticktime]
                        : null;
                }
            });
            return row;
        });
        // console.log("updates", updates);

        if (selectedSymbols?.length > 0) {
            // only 1 selected symbol available, so we can just show that symbol
            // show header for server, bid, ask, mid, spread, time
            const eachServerName = comparisonList.map(item => item.value);
            const selectedSymbolRowUpdates = eachServerName.map(serverName => {
                const serverData = allData[serverName];
                if (!serverData || !serverData?.data?.[selectedSymbols[0]]) {
                    return {
                        server: serverName,
                        bid: null,
                        ask: null,
                        mid: null,
                        spread: null,
                        time: null,
                    };
                }
                return {
                    server: serverName,
                    bid: serverData.d_idx.bid ? serverData.data?.[selectedSymbols[0]].flat(Infinity)?.[serverData.d_idx.bid] : null,
                    ask: serverData.d_idx.ask ? serverData.data?.[selectedSymbols[0]].flat(Infinity)?.[serverData.d_idx.ask] : null,
                    mid: serverData.d_idx.mid ? serverData.data?.[selectedSymbols[0]].flat(Infinity)?.[serverData.d_idx.mid] : null,
                    spread: serverData.d_idx.spread ? serverData.data?.[selectedSymbols[0]].flat(Infinity)?.[serverData.d_idx.spread] : null,
                    time: serverData.d_idx.ticktime ? serverData.data?.[selectedSymbols[0]].flat(Infinity)?.[serverData.d_idx.ticktime] : null,
                };
            });
            // console.log("selectedSymbolRowUpdates", selectedSymbolRowUpdates);
            selectedSymbolRowDataRef.current = selectedSymbolRowUpdates;
        } else {
            selectedSymbolRowDataRef.current = [];
        }

        if (updates.length === 0) return;
        rowDataRef.current = updates;
        // setRowData(updates);
        // gridRef.current.api.applyTransactionAsync({ update: updates });
    }, [allData]);

    const [searchQuery, setSearchQuery] = useState("");

    const result = matchSorter(rowDataRef.current, searchQuery, {
        keys: ["symbol"],
    });

    const [isMounted, setIsMounted] = useState(false);
    useEffect(() => {
        setIsMounted(true);
        return () => {
            setIsMounted(false);
        };
    }, []);

    // console.log("getColumnDefs", getColumnDefs());
    if (!isMounted || rowDataRef.current.length === 0 || getColumnDefs().length === 0) return null;

    try {
        return (
            <Space direction="vertical" style={{ width: "100%" }}>
                <Input value={searchQuery} onChange={e => setSearchQuery(e.target.value)} placeholder="Search symbol" />
                <PriceCompareAGGrid columnDefs={getColumnDefs()} data={result} key={comparisonList?.map(item => item.value).join(",")} />
                {selectedSymbols?.length > 0 && (
                    <Modal
                        open={selectedSymbols.length > 0}
                        footer={null}
                        onCancel={() => {
                            useRCPriceCompareStore.getState().setSelectedSymbols([]);
                        }}
                        width={800}
                        title={selectedSymbols.join(", ")}
                    >
                        {/* <p>Selected Symbols: {selectedSymbols.join(", ")}</p> */}
                        <PriceCompareAGGrid
                            columnDefs={getColumnDefsSelectedSymbol()}
                            data={selectedSymbolRowDataRef.current}
                            key={selectedSymbols.join(",")}
                            rowID="server"
                            height={300}
                        />
                    </Modal>
                )}
            </Space>
        );
    } catch (e) {
        console.log("error", e);
        return null;
    }
};

export default PriceCompare;
