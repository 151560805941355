import Counter from "@/components/Motion/Counter";
import TimeDiff from "@/pages/SystemMonitor/components/TimeDiff";
import { colorMap } from "@/pages/SystemMonitor/StatusMonitor/statusUtils";
import { CheckOutlined, RightCircleFilled } from "@ant-design/icons";
import { Typography, Space, Badge } from "antd";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const { Title, Text } = Typography;

interface PriceOverviewStatusCardProps {
    title: string;
    value: number;
    href?: string;
    callback?: () => void;
    subSection?: {
        title: string;
        value: number;
        href?: string;
    }[];
    type?: "success" | "error" | "warning" | "info" | "disabled";
    index?: number;
    lastUpdated?: number;
    children?: React.ReactNode;
}

const PriceOverviewStatusCard = ({
    title,
    value,
    subSection,
    type = "info",
    href = "",
    callback,
    index = 0,
    lastUpdated = 0,
    children,
}: PriceOverviewStatusCardProps) => {
    if (value === 0) {
        type = "success";
    } else {
        type = "error";
    }
    return (
        <motion.div
            style={{ width: "100%", height: "100%", borderRadius: 16, padding: "1.5rem" }}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0, background: colorMap[type], transition: { delay: index * 0.05, type: "spring" } }}
        >
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <div style={{ alignItems: "flex-start", gap: "1rem", display: "flex" }}>
                    <Title level={2} style={{ marginBottom: 0, lineHeight: 1, paddingBottom: 0, color: type === "error" ? "white" : "black" }}>
                        {title}
                    </Title>
                    <motion.div style={{ lineHeight: 1, marginLeft: "auto", opacity: 0.85 }} whileHover={{ opacity: 1, scale: 1.2 }}>
                        {href !== "" ? (
                            <Link to={href} style={{ lineHeight: 1, marginLeft: "auto" }}>
                                <RightCircleFilled style={{ fontSize: 30, color: type === "error" ? "white" : "black" }} />
                            </Link>
                        ) : callback ? (
                            <div onClick={callback} style={{ lineHeight: 1, marginLeft: "auto", cursor: "pointer" }}>
                                <RightCircleFilled style={{ fontSize: 30, color: type === "error" ? "white" : "black" }} />
                            </div>
                        ) : null}
                    </motion.div>
                </div>
                {value === 0 ? (
                    <div style={{ alignItems: "center", gap: type === "success" ? "0.4rem" : "0.4rem", display: "flex" }}>
                        <CheckOutlined style={{ color: "white" }} />
                        <Text style={{ fontSize: 16, color: "white" }}> All Good</Text>
                    </div>
                ) : (
                    <>
                        <Text style={{ fontSize: 16, color: "white" }}>
                            <Counter value={value} digits={value.toString().length} fontSize={84} />
                        </Text>
                    </>
                )}
                {subSection && (
                    <Space wrap style={{ marginTop: "0.5rem", width: "100%" }}>
                        {subSection.map((each, index) => (
                            <Link to={each.href || href} key={index + each.title}>
                                <Badge count={each.value} style={{ backgroundColor: "red", zIndex: 99 }} overflowCount={99999}>
                                    <motion.div
                                        key={index}
                                        style={{
                                            borderRadius: 8,
                                            padding: "0.75rem 1rem",
                                            backgroundColor: "rgba(0,0,0,0.5)",
                                            opacity: each.value === 0 ? 0.25 : 0.85,
                                        }}
                                        whileHover={{ opacity: 1, scale: 1.05 }}
                                    >
                                        <Text style={{ fontSize: 18, color: "white" }}>{each.title}</Text>
                                    </motion.div>
                                </Badge>
                            </Link>
                        ))}
                    </Space>
                )}
                <>{children}</>
                {lastUpdated !== 0 && (
                    <div style={{ marginTop: "auto", paddingTop: "1rem" }}>
                        <TimeDiff
                            timestamp={lastUpdated}
                            styles={{
                                borderRadius: 16,
                                background: "rgba(255,255,255,0.5)",
                                border: "none",
                            }}
                        />
                    </div>
                )}
            </div>
        </motion.div>
    );
};

export default PriceOverviewStatusCard;
