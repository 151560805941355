import CardBox from "@/components/Common/CardBox";
import FlexiDataTable from "@/components/FlexiDataTable";
import { FormComponent } from "@/components/FormComponent";
import { METRIC_DATA_TYPE_ENUM, LP_BREACH_SEVERITY, SUCCESS_FAILED, ComponentType, CALLBACK_KEY } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import {
    LPHistoricalAlarmGroupDetailsProps,
    LPCommentProgressionProps,
    LPHistoricalAlarmProps,
    CustomPaginationProps,
    LPAlarmComment,
    StatusListResponseProps,
    FlexiDataTableOptionsProps,
    LPAlarmProps,
    FlexiDataTableCallbackProps,
} from "@/constants/type";
import { filterType } from "@/pages/CentralizedSettingsPanel/components/MRMonitor/AlarmSettings/CreateEditPage";
import { getMetricColumnRender, getFilterRulesColumnRender } from "@/pages/IntradayMonitor/Alarm/List";
import { apiRequest } from "@/services/apiConfig";
import { APIs } from "@/services/apis";
import { getProfile } from "@/services/localStorage";
import { ToObjectWithKey, ToOptionTypeList } from "@/utils/array";
import { currencyRender, ErrorCatchValidator, ErrorMessageHandler, DTColProps, DataTableColumnRender } from "@/utils/Common";
import { DateTimeUtil } from "@/utils/datetime";
import { DefaultIfEmpty, getAvailableObjectElement } from "@/utils/object";
import { convertValueBasedOnDataType, isEmptyOrNull } from "@/utils/string";
import { CheckCircleOutlined, CloseCircleOutlined, ClockCircleOutlined, UserOutlined, CommentOutlined } from "@ant-design/icons";
import { Form, Tooltip, Typography, Popover, Timeline, Button, Modal, Row, Col } from "antd";
import moment from "moment";
import { useState, useCallback, useMemo, useEffect } from "react";

export interface LPMonitorAlarmListingProps { }

export const markupText = (type: number, value: any) => {
    switch (type) {
        case METRIC_DATA_TYPE_ENUM.SMALL_INT:
        case METRIC_DATA_TYPE_ENUM.INTEGER:
        case METRIC_DATA_TYPE_ENUM.BIG_INT:
            return currencyRender(value);
        case METRIC_DATA_TYPE_ENUM.DOUBLE:
        case METRIC_DATA_TYPE_ENUM.DECIMAL:
            return currencyRender(value, 2);
        default:
            return value;
    }
};

export const getCombineSeverityProgressionAndComments = (
    groupDetails: LPHistoricalAlarmGroupDetailsProps[],
    comments: LPCommentProgressionProps[],
    type1: number,
    type2: number
) => {
    const concated = [
        ...groupDetails.map(item => ({
            ...item,
            date: item.createdDateUtc,
            type: type1,
        })),
        ...comments.map(item => ({
            ...item,
            date: item.createdDateUtc,
            type: type2,
        })),
    ];
    return concated.sort((a, b) => moment.utc(b.date).diff(moment.utc(a.date)));
};

const LPMonitorAlarmListing = () => {
    const uPr: any = getProfile();
    const serverTime_UTCOffset: number | undefined = uPr?.hasOwnProperty("stom") ? uPr.stom : undefined;
    const priorityObj = ToObjectWithKey(ToOptionTypeList(LP_BREACH_SEVERITY), "value");
    const LPFilterType = ToObjectWithKey(
        filterType.map((x: any) => ({ ...x, DimensionLevel: x.DimensionLevel.toLowerCase() })),
        "DimensionLevel"
    );
    const [metricProps, setMetricProps] = useState<any>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(true);
    const [alarmModuleAuditLogs, setAlarmModuleAuditLogs] = useState<LPHistoricalAlarmProps[]>([]);
    const [selectedEventIds, setSelectedEventIds] = useState<number[]>([]);
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [filterParams, setFilterParams] = useState<any>({ isResolved: false });
    const [sorting, setSorting] = useState<string>("");
    // const [refreshRate, setRefreshRate] = useState<number>(60);
    const [commentForm] = Form.useForm();
    const [visible, setVisible] = useState<boolean>(false);
    const [comData] = useState<LPAlarmComment>();
    const [alarmStatusList, setAlarmStatusList] = useState<any>({});

    const getLPAlarmStatus = () => {
        apiRequest(APIs.GET_ALARM_DASHBOARD_STATUS_LIST, {
            clientApps: ["Insight"],
            moduleCodes: ["MR"],
        })
            .then((res: StatusListResponseProps[]) => {
                if (res && res.length > 0) {
                    setAlarmStatusList(
                        res[0].status.map(status => ({
                            value: status.statusCode,
                            text: status.statusDescription,
                        }))
                    );
                }
            })
            .catch(error => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("alarm status", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            })
            .finally(() => setIsLoading(false));
    };

    const onClickResolved = useCallback(() => {
        setIsLoading(true);
        apiRequest(APIs.UPDATE_LP_MONITOR_ALARM_RESOLVED_STATUS, {
            alarmEventIds: selectedEventIds,
        })
            .then(() => {
                ErrorMessageHandler("alarm resolved status", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                setRunRefetchDataList(true);
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("alarm resolved status", SUCCESS_FAILED.FAILED_UPDATE_DATA, err))
            )
            .finally(() => setIsLoading(false));
    }, [selectedEventIds]);

    const columns = useMemo(
        () => [
            DTColProps.DateTime_UTC_To_ServerTime(
                {
                    title: "Captured Time (Server)",
                    dataIndex: "createdDateUtc",
                    key: "createdDateUtc",
                    options: {
                        filter: {
                            type: ComponentType.daterange,
                            value: "",
                            inputProps: {
                                showTime: { format: "HH:mm:ss" },
                            },
                        },
                    },
                    defaultSortOrder: "descend",
                    sorter: true,
                },
                serverTime_UTCOffset
            ),
            DTColProps.XSmall(
                {
                    title: "Severity",
                    dataIndex: "breachSeverity",
                    key: "breachSeverity",
                    render: (value: number) => {
                        return (
                            <span className={`priority-tag priority-color-${value}`}>{DefaultIfEmpty(priorityObj, value, { text: "" }).text}</span>
                        );
                    },
                },
                ["text-center"]
            ),
            DTColProps.Large(
                {
                    width: "15vw",
                    title: "Dimension",
                    dataIndex: "eventHeader",
                    key: "eventHeader",
                    render: (value: string, rowData: LPHistoricalAlarmProps) => {
                        try {
                            let tmpObj = JSON.parse(value);
                            return (
                                <div className="event-info-container" key={`tr-mfl-${rowData["eventHashKey"]}-eic`}>
                                    {Object.keys(tmpObj).map((x: string, idx: number) => {
                                        return (
                                            <div key={`tr-edl-${rowData["alarmEventId"]}-${idx}`} className="item">
                                                <span>{DefaultIfEmpty(LPFilterType, x.toLowerCase(), { title: x }).title}</span>
                                                <span>{tmpObj[x]}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        } catch (error) { }

                        return <></>;
                    },
                },
                ["vertical-top"]
            ),
            DTColProps.Large(
                {
                    width: "18vw",
                    title: "Metrics",
                    dataIndex: "alertMessage",
                    key: "alertMessage",
                    render: (value: string, rowData: LPHistoricalAlarmProps) => {
                        try {
                            let eventDetails = getAvailableObjectElement(JSON.parse(value as string), true),
                                metricInfo = JSON.parse(rowData.metricInnerFilters as string).map((x: any) => ({
                                    ...x,
                                    metricName: x.metricName.toLowerCase(),
                                    filterValue: [7].includes(x.filterOp)
                                        ? x.filterValue
                                        : convertValueBasedOnDataType(x.metricDataType, x.filterValue),
                                })),
                                metricNameArr = metricInfo.map((x: any) => x.metricName as string),
                                metricInfoByKey = ToObjectWithKey(metricInfo, "metricName"),
                                markupMetricInfo = [{ metricInnerFilters: metricInfo }];
                            Object.keys(eventDetails)
                                .filter(x => metricNameArr.includes(x))
                                .forEach(x => {
                                    eventDetails[x] = convertValueBasedOnDataType(metricInfoByKey[x].metricDataType, eventDetails[x]);
                                });
                            return getMetricColumnRender(`${rowData.alarmEventId}`, eventDetails, markupMetricInfo, {
                                metricName: metricProps,
                            });
                        } catch (error) {
                            return <>{value}</>;
                        }

                        return <></>;
                    },
                },
                ["vertical-top"]
            ),
            {
                title: "Alarm Name",
                dataIndex: "alarmName",
                key: "alarmName",
                className: "vertical-top",
                render: (value: string, rowData: LPHistoricalAlarmProps) => {
                    return (
                        <div className="alarm-info-container">
                            <div className="title">{value}</div>
                            {rowData.alarmDescription && <div className="desc">{rowData.alarmDescription}</div>}
                        </div>
                    );
                },
            },
            DTColProps.Middle(
                {
                    title: "Last Comment",
                    dataIndex: "lastComment",
                    key: "lastComment",
                    render: (lastComment: string, rowData: any) => {
                        return (
                            <div className="flex-row-between">
                                {rowData.comments.length > 0 && rowData.comments[0] ? (
                                    <div className="mini-comment-container">
                                        <div className="comments">
                                            <Tooltip title={rowData.comments[0].comment}>
                                                <Typography.Paragraph ellipsis={{ rows: 2 }} style={{ marginBottom: "0" }}>
                                                    {rowData.comments[0].comment}
                                                </Typography.Paragraph>
                                            </Tooltip>
                                        </div>
                                        <div className="info">
                                            {`${DataTableColumnRender.DateTime_UTC_TO_ServerTime(
                                                rowData.comments[0].createdDateUtc,
                                                serverTime_UTCOffset
                                            )}`}
                                            <br />
                                            <div className="user">
                                                <UserOutlined />
                                                <span className="user-name">{rowData.comments[0].commentedBy}</span>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="mini-comment-container">-</div>
                                )}
                            </div>
                        );
                    },
                },
                ["vertical-top"]
            ),
            DTColProps.XSmall({
                title: "Resolved",
                dataIndex: "isResolved",
                key: "isResolved",
                align: "center",
                render: (isResolved: boolean) => {
                    if (isResolved) {
                        return <CheckCircleOutlined style={{ color: "#0ab76e", fontSize: "1.375rem" }} />;
                    } else {
                        return <CloseCircleOutlined style={{ color: "#f00f00", fontSize: "1.375rem" }} />;
                    }
                },
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: [
                            {
                                text: "Yes",
                                value: true,
                            },
                            {
                                text: "No",
                                value: false,
                            },
                        ],
                    },
                },
            }),
            {
                title: "Alarm Name",
                dataIndex: "alarmName",
                key: "alarmName",
                options: {
                    filter: {
                        type: ComponentType.text,
                        value: "",
                    },
                    visible: false,
                },
            },
        ],
        [metricProps]
    );

    const options: FlexiDataTableOptionsProps = useMemo(
        () => ({
            view: (record: LPHistoricalAlarmProps, button: React.ReactNode) => {
                let combinedArray = getCombineSeverityProgressionAndComments(record["groupDetails"], record["comments"], 1, 2);
                if (isEmptyOrNull(combinedArray)) return <></>;

                try {
                    return (
                        <Popover
                            placement="leftTop"
                            overlayClassName="alarm-historical-popover-content"
                            content={
                                <div className="alarm-historical-popover-timeline-container nice-scrollbar">
                                    <Timeline>
                                        {combinedArray.map((y: any, xIdx: number) => {
                                            let eventDetails =
                                                y.alertMessage && getAvailableObjectElement(JSON.parse(y.alertMessage as string), true),
                                                metricInfo =
                                                    y.metricInnerFilters &&
                                                    JSON.parse(y.metricInnerFilters as string).map((x: any) => ({
                                                        ...x,
                                                        metricName: x.metricName.toLowerCase(),
                                                        filterValue: [7].includes(x.filterOp)
                                                            ? x.filterValue
                                                            : convertValueBasedOnDataType(x.metricDataType, x.filterValue),
                                                    })),
                                                metricNameArr = metricInfo && metricInfo.map((x: any) => x.metricName as string),
                                                metricInfoByKey = metricInfo && ToObjectWithKey(metricInfo, "metricName"),
                                                markupMetricInfo = [{ metricInnerFilters: metricInfo }];
                                            eventDetails &&
                                                Object.keys(eventDetails)
                                                    .filter(x => metricNameArr.includes(x))
                                                    .forEach(x => {
                                                        eventDetails[x] = convertValueBasedOnDataType(
                                                            metricInfoByKey[x].metricDataType,
                                                            eventDetails[x]
                                                        );
                                                    });
                                            let timelineKey = `${Math.random()}`;
                                            return (
                                                <Timeline.Item key={`tli-${timelineKey}-${xIdx}`} color={y.type === 1 ? "#004b57" : "green"}>
                                                    {y.type === 2 ? (
                                                        <div className="alarm-popover-detail-container shadow-light">
                                                            <div className="title-container">
                                                                <div className="left">
                                                                    <div className="comment-by">
                                                                        <UserOutlined />
                                                                        <span className="user-name">{y.commentedBy}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="right">
                                                                    <ClockCircleOutlined />{" "}
                                                                    {DataTableColumnRender.DateTime_UTC_TO_ServerTime(y.date, serverTime_UTCOffset)}
                                                                </div>
                                                            </div>
                                                            <div className="comment-content">
                                                                <Typography.Paragraph>{y.comment}</Typography.Paragraph>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="alarm-popover-detail-container shadow-light">
                                                            <div className="title-container">
                                                                <div className="left">
                                                                    <span className={`priority-div priority-color-${y.breachSeverity}`}>
                                                                        {DefaultIfEmpty(priorityObj, y.breachSeverity, { text: "" }).text}
                                                                    </span>
                                                                </div>
                                                                <div className="right">
                                                                    <ClockCircleOutlined />{" "}
                                                                    {DataTableColumnRender.DateTime_UTC_TO_ServerTime(y.date, serverTime_UTCOffset)}
                                                                </div>
                                                            </div>
                                                            <div className="content-container">
                                                                <div className="left">
                                                                    <div className="theader">Metrics</div>
                                                                    <div className="content">
                                                                        {getMetricColumnRender(`${y.alarmEventId}`, eventDetails, markupMetricInfo, {
                                                                            metricName: metricProps,
                                                                        })}
                                                                    </div>
                                                                </div>
                                                                <div className="right">
                                                                    <div className="theader">Filter Rule(s)</div>
                                                                    <div className="content">
                                                                        {getFilterRulesColumnRender(
                                                                            `${y.alarmEventId}`,
                                                                            eventDetails,
                                                                            markupMetricInfo,
                                                                            {
                                                                                metricName: metricProps,
                                                                            }
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Timeline.Item>
                                            );
                                        })}
                                    </Timeline>
                                </div>
                            }
                            title={<span className="alarm-historical-popover-title">Breach Severity Progression</span>}
                        >
                            {button}
                        </Popover>
                    );
                } catch (error) {
                    console.log("Error: ", error);
                }
                return <></>;
            },
            refresh: {
                timer: false,
                enablePeriodSelection: false,
            },
            serverFiltering: true,
            enableRowSelection: true,
            showHideColumns: false,
            hideRowSelectionsSummary: true,
            rowSelectionData: {
                rowSelectionType: "checkbox",
                selectedRowKeys: [],
                options: {
                    getCheckboxProps: (record: LPAlarmProps) => ({
                        disabled: record.isResolved,
                    }),
                },
            },
            ...(selectedEventIds.length > 0 && {
                extraButtons: [{ text: "Resolved", value: "Resolved" }],
            }),
            customExtraActionButton: (record: any, callback: FlexiDataTableCallbackProps) => {
                if (isEmptyOrNull(record.alarmCenterEventId)) return undefined;

                return (
                    <Button
                        key={`r-${record.id}-cex`}
                        type="text"
                        icon={<CommentOutlined style={{ cursor: "pointer" }} />}
                        onClick={(e: any) => {
                            e.preventDefault();
                            callback(CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK, { key: "commenting", data: record });
                        }}
                    />
                );
            },
        }),
        [selectedEventIds, serverTime_UTCOffset, metricProps]
    );

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                setSorting(
                    FormData.sorter.column && FormData.sorter.order
                        ? `${FormData.sorter.column.key},${FormData.sorter.order === "ascend" ? "asc" : "desc"}`
                        : ""
                );
                setPagination(prev => ({ ...prev, current: FormData.pagination.current, pageSize: FormData.pagination.pageSize }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let alarmFilterParams: any = {};
                Object.keys(FormData)
                    .filter(x => FormData[x] !== undefined && FormData[x].toString().length > 0)
                    .map(x => {
                        if (x === "id") {
                            alarmFilterParams["ids"] = FormData[x];
                        } else if (x === "createdDateUtc") {
                            alarmFilterParams["serverDateTimeFrom"] = DateTimeUtil.GetOrigin(FormData[x][0]);
                            alarmFilterParams["serverDateTimeTo"] = DateTimeUtil.GetOrigin(FormData[x][1]);
                        } else {
                            alarmFilterParams[x] = FormData[x];
                        }
                        return x;
                    });
                setFilterParams(alarmFilterParams);
                setPagination(prev => ({ ...prev, current: 1 }));
                setRunRefetchDataList(true);
                break;

            case CALLBACK_KEY.ROW_SELECTION_CALLBACK:
                setSelectedEventIds(FormData.selectedRowKeys.map((x: string) => parseInt(x)));
                break;
            case CALLBACK_KEY.OTHERS:
                if (FormData === "Resolved") {
                    onClickResolved();
                }
                break;
            case CALLBACK_KEY.REFRESH:
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK:
                if (FormData.key === "commenting") {
                    setVisible(true);
                    commentForm.setFieldsValue({
                        alarmCenterEventId: FormData.data.alarmCenterEventId,
                        correlationHashKey: FormData.data.correlationHashKey,
                        comment: "",
                        statusCode: FormData.data.isResolved ? "RSLV" : "URSLV",
                        isResolved: FormData.data.isResolved,
                    });
                }
                break;
            default:
                break;
        }
    };

    const getAlarmModuleAuditLogs = useCallback(() => {
        apiRequest(APIs.GET_LP_MONITOR_ALARM_LISTING, {
            limit: pagination.pageSize,
            current: pagination.current,
            ...(Object.keys(filterParams).length > 0 && { ...filterParams }),
            ...(sorting.length > 0 && { order: sorting }),
        })
            .then((res: any) => {
                if (res && res.result && res.result.length > 0) {
                    setAlarmModuleAuditLogs(res.result);
                    setPagination(prev => ({ ...prev, total: res.total }));
                } else {
                    setAlarmModuleAuditLogs([]);
                    setPagination(prev => ({ ...prev, total: 0 }));
                }
            })
            .finally(() => setIsLoading(false));
    }, [pagination, filterParams, sorting]);

    const getConfigList = () => {
        apiRequest(APIs.GET_LP_ALARM_CONFIG, {})
            .then((res: any) => {
                if (res.length > 0) {
                    setMetricProps(
                        res.reduce((obj: any, x: any) => {
                            x.dataSources.forEach((y: any) => {
                                obj[y.propertyName.toLowerCase()] = { value: y.propertyName, text: y.propertyDisplayName, type: y.dataType };
                            });
                            return obj;
                        }, {})
                    );
                }
            })
            .catch(error => ErrorCatchValidator(error, (err: any) => console.log(err)));
    };

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            setSelectedEventIds([]);
            getAlarmModuleAuditLogs();
            setRunRefetchDataList(false);
        }
        return () => { };
    }, [runRefetchDataList]);

    useEffect(() => {
        getLPAlarmStatus();
        getConfigList();
    }, []);

    return (
        <CardBox title={"MR Alarm Listing"}>
            <div className="lp-alarm-listing-container">
                <FlexiDataTable
                    bordered
                    rowKeyProperty="alarmEventId"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={alarmModuleAuditLogs}
                    callback={componentCallback}
                    loading={isLoading}
                    serverSide={true}
                    pagination={pagination}
                    filterInitialValue={{ isResolved: false }}
                />
                <Modal
                    open={visible}
                    className="LPAlarmCommentModal"
                    title={"Create New Comment"}
                    onCancel={() => {
                        commentForm.resetFields();
                        setVisible(false);
                    }}
                    onOk={() => {
                        commentForm
                            .validateFields()
                            .then((values: any) => {
                                const alarmComment = {
                                    alarmEventId: `${values.alarmCenterEventId}`,
                                    comment: values.comment,
                                    ...(!values.isResolved && { correlationHashKey: values.correlationHashKey }),
                                    statusCode: values.statusCode,
                                };
                                commentForm.resetFields();
                                return apiRequest(APIs.UPDATE_ALARM_STATUS, alarmComment);
                            })
                            .then((data: any) => {
                                ErrorMessageHandler("New comment", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                                setRunRefetchDataList(true);
                                setVisible(false);
                            })
                            .catch((error: any) => {
                                console.log("error", error);
                            });
                    }}
                >
                    <Form form={commentForm} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} layout="horizontal" initialValues={comData}>
                        <FormComponent label={""} name={"alarmCenterEventId"} extra={{ type: ComponentType.hidden, value: "" }} />
                        <FormComponent label={""} name={"correlationHashKey"} extra={{ type: ComponentType.hidden, value: "" }} />
                        <FormComponent label={""} name={"isResolved"} extra={{ type: ComponentType.hidden, value: "" }} />
                        {commentForm.getFieldValue("isResolved") === true ? (
                            <>
                                <Row className="box">
                                    <Col span={6} className="label">
                                        <span>*</span> Status:
                                    </Col>
                                    <Col span={18} className="value">
                                        Resolved
                                    </Col>
                                </Row>
                            </>
                        ) : (
                            <FormComponent
                                label="Status"
                                name="statusCode"
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: alarmStatusList,
                                    rules: [
                                        {
                                            required: true,
                                            message: REQUIRED_FIELD,
                                        },
                                    ],
                                }}
                            />
                        )}

                        <FormComponent
                            label="Comment"
                            name={"comment"}
                            extra={{
                                type: ComponentType.textarea,
                                value: "",
                                rules: [
                                    {
                                        required: true,
                                        message: REQUIRED_FIELD,
                                    },
                                ],
                                inputProps: {
                                    showCount: true,
                                    rows: 5,
                                },
                            }}
                        />
                    </Form>
                </Modal>
            </div>
        </CardBox>
    );
};

export default LPMonitorAlarmListing;
