import { FormComponent } from "@/components/FormComponent";
import LoadingComponent from "@/components/Loading";
import SitePageHeaderInline, { SitePageHeaderInlineActionType } from "@/components/PageHeader/inlineIndex";
import { ComponentType, InnerPageActionMode, SUCCESS_FAILED } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { CreateEditInlineFormBased, PKMarketBrand } from "@/constants/type";
import { apiRequest } from "@/services/apiConfig";
import { APIs } from "@/services/apis";
import { ErrorMessageHandler, ErrorCatchValidator } from "@/utils/Common";
import { isEmptyOrNull } from "@/utils/string";
import { Col, Form, message, Row } from "antd";
import { useMemo, useState, useCallback, useEffect } from "react";

interface MRServerEditPageProps extends CreateEditInlineFormBased {
    callback: (action: InnerPageActionMode, value?: any) => void;
    resetState: number;
}

const MRServerEditPage = (props: MRServerEditPageProps) => {
    const isAddAction = useMemo(() => props.mode === InnerPageActionMode.CREATE_NEW, [props.mode]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [configForm] = Form.useForm();
    const [configData, setConfigData] = useState<any>({ mrPKServers: [] });
    const [pkMarketBrand, setPKMarketBrand] = useState<PKMarketBrand[]>([]);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const getPKMarketBrands = () => {
        apiRequest(APIs.GET_MR_PK_LIST_V2, {})
            .then(data => {
                if (data && data?.length > 0) {
                    setPKMarketBrand(data.map((x: any) => ({
                        ...x,
                        pkServers: {
                            showName: x.pkServers
                        }
                    })))
                }
            })
            .catch(err =>
                message.error('Market brand failed to retrieve')
            );
    }

    const onSubmit = useCallback(
        (values: any) => {
            setIsLoading(true);
            setIsSubmitting(true);
            if (isAddAction) {
                //Temporary not support create function, will be added later
            } else {
                Promise.all([
                    apiRequest(APIs.UPDATE_MR_SERVER, {
                        id: values.id,
                        name: values.name,
                        description: values.description,
                        isEnabled: values.isEnabled
                    }),
                    apiRequest(APIs.UPDATE_REPORT_DATA_SOURCE, {
                        reportId: values.id,
                        marketBrand: values.marketBrand,
                        serverIncluded: values.serverIncluded,
                        serverExcluded: values.serverExcluded
                    })
                ])
                    .then(res => {
                        ErrorMessageHandler("Report(s) Servers setting", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                        setTimeout(() => {
                            props.callback && props.callback(InnerPageActionMode.BACK, { refreshMainList: true });
                            setIsSubmitting(false);
                        }, 400);
                    })
                    .catch(error =>
                        ErrorCatchValidator(error, (err: any) => {
                            ErrorMessageHandler("Report(s) Servers setting", SUCCESS_FAILED.FAILED_UPDATE_DATA, err);
                            setIsLoading(false);
                            setIsSubmitting(false);
                        })
                    );
            }
        },
        [isAddAction, props.callback]
    );

    const getMRConfigDetails = (id?: number | string | undefined) => {
        if (isEmptyOrNull(id)) {
            configForm.resetFields();
            setIsLoading(false);
            return;
        }

        setIsLoading(true);

        Promise.all([
            apiRequest(APIs.GET_MR_SERVER_LIST, { id: [id as number] }),
            apiRequest(APIs.GET_REPORT_DATA_SOURCE_LIST, { reportId: id })
        ])
            .then((res: any) => {
                const firstResponse = res[0];
                const secondResponse = res[1];

                if (firstResponse.length > 0 && secondResponse) {
                    configForm.setFieldsValue({
                        id: firstResponse[0].id,
                        name: firstResponse[0].name,
                        description: firstResponse[0].description,
                        isEnabled: firstResponse[0].isEnabled,
                        marketBrand: secondResponse.marketBrand?.map((x: any) => x.marketBrandId) || [],
                        serverIncluded: secondResponse.serverIncluded?.map((x: any) => x.serverName) || [],
                        serverExcluded: secondResponse.serverExcluded?.map((x: any) => x.serverName) || []
                    });
                } else {
                    ErrorMessageHandler("Report(s) Servers setting", SUCCESS_FAILED.FAILED_LOAD_DATA);
                }
            })
            .catch(error => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("Report(s) Servers setting", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                props.callback && props.callback(InnerPageActionMode.BACK);
            })
            .finally(() => setIsLoading(false));
    };

    const getConfigList = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, {
            filterType: ["marketriskpkserver"],
        })
            .then((data: any) => {
                setConfigData({
                    mrPKServers: data.mrPkServer
                        .sort((a: { showName: string }, b: { showName: string }) => a.showName.localeCompare(b.showName))
                        .map((x: any) => ({ text: `${x.showName}`, value: `${x.serverName}` })),
                });
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => console.log("Failed to get filter config list: ", err)))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => getMRConfigDetails(props.id), [props.resetState, props.id]);
    useEffect(() => {
        getConfigList();
        getPKMarketBrands();
    }, []);

    return (
        <SitePageHeaderInline
            enableSubmit
            title={isAddAction ? "Create New Report(s) Servers" : "Edit Report(s) Servers"}
            callback={(action: SitePageHeaderInlineActionType) => {
                switch (action) {
                    case SitePageHeaderInlineActionType.OnBack:
                    case SitePageHeaderInlineActionType.OnCancel:
                        props.callback && props.callback(InnerPageActionMode.BACK);
                        configForm.resetFields();
                        return;
                    case SitePageHeaderInlineActionType.OnSubmit:
                        configForm
                            .validateFields()
                            .then(res => onSubmit(res))
                            .catch(err => console.log("form-error:", err));
                        return;
                    default:
                        break;
                }
            }}
            isSubmitting={isSubmitting}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Loading..." />
                </div>
            ) : (
                <div className="lpmonitor-create-edit-form">
                    <Form labelAlign="left" labelCol={{ span: 7 }} form={configForm} layout="horizontal" initialValues={{}} onFinish={onSubmit}>
                        <FormComponent label="" name="id" extra={{ type: ComponentType.hidden, value: "" }} />
                        <Row>
                            <Col span={13} className="main-panel-left">
                                <FormComponent
                                    label="Report Name"
                                    name="name"
                                    extra={{ type: ComponentType.text, value: "", disabled: true, rules: [{ required: true, message: REQUIRED_FIELD }] }}
                                />
                                <FormComponent
                                    label="Description"
                                    name="description"
                                    extra={{ type: ComponentType.text, value: "", rules: [{ required: true, message: REQUIRED_FIELD }] }}
                                />
                                <FormComponent
                                    label="Sub-brands"
                                    name="marketBrand"
                                    extra={{
                                        type: ComponentType.dropdown,
                                        value: pkMarketBrand.map((x: any) => ({
                                            text: x.marketBrandName,
                                            value: x.marketBrandId,
                                        })),
                                        inputProps: {
                                            mode: "multiple",
                                        },
                                    }}
                                />
                                <FormComponent
                                    label="Additional Server(s)"
                                    name="serverIncluded"
                                    extra={{
                                        type: ComponentType.dropdown,
                                        value: configData.mrPKServers,
                                        inputProps: {
                                            mode: "multiple",
                                        },
                                    }}
                                />
                                <FormComponent
                                    label="Excluded Server(s)"
                                    name="serverExcluded"
                                    extra={{
                                        type: ComponentType.dropdown,
                                        value: configData.mrPKServers,
                                        inputProps: {
                                            mode: "multiple",
                                        },
                                    }}
                                />
                                <FormComponent
                                    label="Status"
                                    name="isEnabled"
                                    extra={{ type: ComponentType.switch, value: ["Inactive", "Active"] }}
                                />
                            </Col>
                        </Row>
                    </Form>
                </div>
            )}
        </SitePageHeaderInline>
    );
};

export default MRServerEditPage;
