import FlexiDataTable from "@/components/FlexiDataTable";
import { CALLBACK_KEY, SUCCESS_FAILED } from "@/constants";
import { FlexiDataTableCallbackProps, FlexiDataTableOptionsProps } from "@/constants/type";
import { useState, useEffect, useCallback, useMemo } from "react";
import { HTHListItemProps } from "./hthlist";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { CheckOutlined } from "@ant-design/icons";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { ToObjectWithKey } from "@/utils/array";
import { DefaultIfEmpty } from "@/utils/object";
import { Badge, Modal } from "antd";

interface ConflictListPageProps {}

interface DataProps {
    data: HTHListItemProps[];
    dataByKey: { [key: string]: HTHListItemProps };
}

const ConflictListPage = (props: ConflictListPageProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<HTHListItemProps[]>([]);
    const [selectedKey, setSelectedKey] = useState<string[]>([]);

    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.RISK_TOOLS_HUB_TO_HUB_EDIT);

    const dataByKey: DataProps = useMemo(() => {
        let tmpData = data.map((x: HTHListItemProps) => ({
            ...x,
            id: `${x.processDate}-${x.serverUno}-${x.bridgeId}-${x.login}-${x.loginGroup}-${x.symbol}`,
        }));
        return {
            data: tmpData,
            dataByKey: ToObjectWithKey(tmpData, "id"),
        };
    }, [data]);

    const columns = [
        DTColProps.XSmall({
            title: "Server",
            dataIndex: "serverName",
            key: "serverName",
        }),
        DTColProps.XSmall({
            title: "Login",
            dataIndex: "login",
            key: "login",
        }),
        {
            title: "Bridge",
            dataIndex: "bridgeId",
            key: "bridgeId",
        },
        {
            title: "Group",
            dataIndex: "loginGroup",
            key: "loginGroup",
        },
        DTColProps.XSmall({
            title: "Symbol",
            dataIndex: "symbol",
            key: "symbol",
        }),
        {
            title: "Inspector",
            dataIndex: "inspector",
            key: "inspector",
        },
        DTColProps.XXSmall({
            width: "4.5vw",
            title: "Date",
            dataIndex: "processDate",
            key: "processDate",
            render: (text: number) => `${text}`.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3"),
        }),
        {
            title: "Comment",
            dataIndex: "comment",
            key: "comment",
        },
        {
            title: "Note",
            dataIndex: "note",
            key: "note",
        },
        {
            title: "Label",
            dataIndex: "label",
            key: "label",
        },
        DTColProps.XSmall(
            {
                width: "6vw",
                title: "Bridge Status",
                dataIndex: "hthStatus",
                key: "hthStatus",
                render: (text: number) => <span className="text-color-error">{text === 1 ? "Disable" : "Enable"}</span>,
            },
            ["text-center"]
        ),
        DTColProps.XSmall({
            title: "Action",
            dataIndex: "hthStatus",
            key: "hthStatus",
            render: (text: number) =>
                text === 1 ? (
                    <Badge status="success" text="Enable" className="badge-blinking" />
                ) : (
                    <Badge status="default" text={<span style={{ color: "#a5a5a5" }}>Disable</span>} />
                ),
        }),
    ];

    const options: FlexiDataTableOptionsProps = {
        separateActionButton: true,
        enableFilter: false,
        ...(enableUpdate &&
            data.length > 0 && {
                enableRowSelection: true,
                hideRowSelectionsSummary: true,
                rowSelectionData: {
                    rowSelectionType: "checkbox",
                    selectedRowKeys: selectedKey,
                },
                ...(selectedKey.length > 0 && {
                    extraButtons: [{ text: "Fix", icon: <CheckOutlined />, value: "fixconflict", type: "primary" }],
                }),
            }),
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: number, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.OTHERS:
                if (FormData === "fixconflict") {
                    submitFix();
                }
                break;
            case CALLBACK_KEY.ROW_SELECTION_CALLBACK:
                setSelectedKey(FormData.selectedRowKeys);
                break;
            default:
                break;
        }
    };

    const submitFix = useCallback(() => {
        Modal.confirm({
            title: "Are you sure you want to submit changes?",
            onOk() {
                setIsLoading(true);
                let params = selectedKey.reduce((acc: any, key: string) => {
                    if (dataByKey.dataByKey.hasOwnProperty(key)) {
                        acc.push(dataByKey.dataByKey[key]);
                    }
                    return acc;
                }, []);
                plainAxiosInstance
                    .post(APIs.RISK_TOOL.GET_HUB_TO_HUB_CONFLICT_LISTING_RESOLVED, params)
                    .then((res: any) => {
                        if (res.data.status === 0) {
                            reSubmitToCreateNew(params);
                        } else {
                            ErrorMessageHandler("hub to hub conflict cannot be resolve.", SUCCESS_FAILED.FAILED_UPDATE_DATA, res.data.msg);
                            setIsLoading(false);
                        }
                    })
                    .catch((error: any) => {
                        ErrorCatchValidator(error, (err: any) =>
                            ErrorMessageHandler("hub to hub conflict cannot be resolve.", SUCCESS_FAILED.FAILED_UPDATE_DATA, err)
                        );
                        setIsLoading(false);
                    });
            },
        });
    }, [selectedKey, dataByKey]);

    const reSubmitToCreateNew = (resolvedList: HTHListItemProps[]) => {
        plainAxiosInstance
            .post(APIs.RISK_TOOL.GET_HUB_TO_HUB_RESERVATION_SETTING_CREATE, resolvedList)
            .then((res: any) => {
                if (res.data.status === 0) {
                    ErrorMessageHandler("hub to hub conflict resolve successfully.", SUCCESS_FAILED.OTHERS_SUCCESS);
                    setSelectedKey([]);
                    getListing();
                } else {
                    ErrorMessageHandler("hub to hub conflict cannot be resolve.", SUCCESS_FAILED.FAILED_UPDATE_DATA, res.data.msg);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) =>
                    ErrorMessageHandler("hub to hub conflict cannot be resolve.", SUCCESS_FAILED.FAILED_CREATE_DATA, err)
                )
            )
            .finally(() => setIsLoading(false));
    };

    const getListing = () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(APIs.RISK_TOOL.GET_HUB_TO_HUB_CONFLICT_LISTING)
            .then((res: any) => {
                setData(
                    res.data.status === 0 && res.data.data.length > 0
                        ? res.data.data.map((x: any, idx: number) => ({
                              ...x,
                              id: `${x.serverUno}-${x.login}-${x.symbol}-${x.loginGroup}-${x.bridgeId}${idx}`,
                          }))
                        : []
                );
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("hub to hub conflict", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getListing();
    }, []);

    return (
        <>
            <div className="hth-conflict-list-page-container">
                <FlexiDataTable
                    bordered
                    rowKeyProperty="id"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={dataByKey.data}
                    callback={componentCallback}
                    loading={isLoading}
                />
            </div>
        </>
    );
};

export default ConflictListPage;
