import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { APIs } from "../../../../services/apis";
import { plainAxiosInstance } from "../../../../services/axiosSetup";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../../../utils/Common";
import { PriceMonitorSpreadAlarmDetailsProps } from "../../../../constants/type";
import LoadingComponent from "../../../../components/Loading";
import CanvasJSReact from "@canvasjs/react-charts";
import { SUCCESS_FAILED } from "@/constants";

export interface SpreadLineChartProps {
    data: PriceMonitorSpreadAlarmDetailsProps | undefined;
}

interface SpreadLineRespDataProps {
    x: number;
    y: number;
    mark: boolean;
}

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const SpreadLineChart = (props: SpreadLineChartProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [spreadLineData, setSpreadLineData] = useState<any>({});
    const charts = useRef<any[]>([]);

    const showLineTooltip = useCallback(
        (e: any) => {
            for (let i = 0, count = charts.current.length; i < count; i++) {
                if (charts.current[i] !== e.chart) {
                    charts.current[i].toolTip.showAtX(e.entries[0].xValue);
                }
            }
        },
        [charts]
    );

    const hideLineTooltip = useCallback(
        (e: any) => {
            for (let i = 0, count = charts.current.length; i < count; i++) {
                if (charts.current[i] !== e.chart) {
                    charts.current[i].toolTip.hide();
                }
            }
        },
        [charts]
    );

    const showLineCrosshair = useCallback(
        (e: any) => {
            for (let i = 0, count = charts.current.length; i < count; i++) {
                if (charts.current[i] !== e.chart) {
                    charts.current[i].axisX[0].crosshair.showAt(e.value);
                }
            }
        },
        [charts]
    );

    const hideLineCrosshair = useCallback(
        (e: any) => {
            for (let i = 0, count = charts.current.length; i < count; i++) {
                if (charts.current[i] !== e.chart) {
                    charts.current[i].axisX[0].crosshair.hide();
                }
            }
        },
        [charts]
    );

    const markupData = useMemo(() => {
        return ["ask", "bid", "spread", "threshold", "lowThreshold"].reduce((obj: any, key: string) => {
            obj[`${key}Datas`] =
                spreadLineData?.hasOwnProperty(key) && spreadLineData[key].length > 0
                    ? spreadLineData[key].map((x: SpreadLineRespDataProps) =>
                          x.mark
                              ? {
                                    x: x.x,
                                    y: x.y,
                                    indexLabel: "*",
                                    indexLabelFontWeight: "bolder",
                                    indexLabelFontSize: 30,
                                    indexLabelFontColor: "red",
                                }
                              : {
                                    x: x.x,
                                    y: x.y,
                                }
                      )
                    : [];
            return obj;
        }, {});
    }, [spreadLineData]);

    const chartOptions = useMemo(() => {
        return {
            askBidOpts: {
                exportEnabled: true,
                zoomEnabled: true,
                title: {
                    text: "Ask Bid對比圖",
                },
                toolTip: {
                    shared: true,
                    updated: showLineTooltip,
                    hidden: hideLineTooltip,
                },
                axisX: {
                    valueFormatString: " ",
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true,
                        updated: showLineCrosshair,
                        hidden: hideLineCrosshair,
                    },
                },
                axisY: {
                    //valueFormatString: " ",
                },
                data: [
                    {
                        type: "line",
                        name: "Ask",
                        showInLegend: true,
                        dataPoints: markupData.askDatas,
                    },
                    {
                        type: "line",
                        name: "Bid",
                        showInLegend: true,
                        dataPoints: markupData.bidDatas,
                    },
                ],
            },
            SpreadOpts: {
                exportEnabled: true,
                zoomEnabled: true,
                title: {
                    text: "Spread與閥值對比圖",
                },
                toolTip: {
                    shared: true,
                    updated: showLineTooltip,
                    hidden: hideLineTooltip,
                },
                axisX: {
                    valueFormatString: " ",
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true,
                        updated: showLineCrosshair,
                        hidden: hideLineCrosshair,
                    },
                },
                axisY: {
                    //valueFormatString: " ",
                },
                data: [
                    {
                        type: "line",
                        name: "Spread",
                        showInLegend: true,
                        dataPoints: markupData.spreadDatas,
                    },
                    {
                        type: "line",
                        name: "Threshold",
                        showInLegend: true,
                        dataPoints: markupData.thresholdDatas,
                    },
                    {
                        type: "line",
                        name: "Low Threshold",
                        showInLegend: true,
                        dataPoints: markupData.lowThresholdDatas,
                    },
                ],
            },
        };
    }, [markupData]);

    const getSpreadData = (data: any) => {
        const formData = new FormData();
        formData.append("keyStr", `${data.dateTimeStr.substring(0, data.dateTimeStr.length - 3)},${data.server},${data.symbol},${data.serverUno}`);

        plainAxiosInstance
            .post(APIs.RC_PRICE_MONITOR.SHOW_SPREAD_ALARM_LINE, formData)
            .then((resp: any) => {
                if (resp.status === 200) {
                    setSpreadLineData({
                        ask: resp.data.ask === null ? [] : resp.data.ask,
                        bid: resp.data.bid === null ? [] : resp.data.bid,
                        spread: resp.data.spread === null ? [] : resp.data.spread,
                        threshold: resp.data.threshold === null ? [] : resp.data.threshold,
                        lowThreshold: resp.data.lowThreshold === null ? [] : resp.data.lowThreshold,
                    });
                } else {
                    setSpreadLineData({
                        ask: [],
                        bid: [],
                        spread: [],
                        threshold: [],
                        lowThreshold: [],
                    });
                    ErrorMessageHandler(`Error during show spread line: ${resp.statusText}.`, SUCCESS_FAILED.OTHERS_FAILED);
                }
            })
            .catch(error => {
                setSpreadLineData({
                    ask: [],
                    bid: [],
                    spread: [],
                    threshold: [],
                    lowThreshold: [],
                });
                ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler(
                        `Error during show spread line. ${err.response.data.message ? `${err.response.data.message}` : `${err.message}`}`,
                        SUCCESS_FAILED.OTHERS_FAILED
                    );
                });
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (props.data === undefined) {
            setIsLoading(true);
            setSpreadLineData({});
            charts.current = [];
        } else {
            setIsLoading(true);
            getSpreadData(props.data);
        }
    }, [props.data]);

    return (
        <div className="spread-line-chart-modal-container">
            {isLoading ? (
                <LoadingComponent />
            ) : (
                <>
                    <CanvasJSChart
                        options={chartOptions.askBidOpts}
                        onRef={(ref: any) => {
                            charts.current[0] = ref;
                        }}
                    />
                    <CanvasJSChart
                        options={chartOptions.SpreadOpts}
                        onRef={(ref: any) => {
                            charts.current[1] = ref;
                        }}
                    />
                </>
            )}
        </div>
    );
};

export default SpreadLineChart;
