import FlexiDataTable from "@/components/FlexiDataTable";
import { FormComponent } from "@/components/FormComponent";
import LoadingComponent from "@/components/Loading";
import { SUCCESS_FAILED, ComponentType, CALLBACK_KEY, InnerPageActionMode, SYMBOL_SCOPE_TYPE } from "@/constants";
import { SymbolAssetTypesList, FlexiDataTableOptionsProps, FlexiDataTableCallbackProps, CreateEditInlineFormBased } from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { apiRequest } from "@/services/apiConfig";
import { APIs } from "@/services/apis";
import { DataTableColumnRender, DTColProps, ErrorCatchValidator, ErrorMessageHandler, getFileNameFromResponseHeader } from "@/utils/Common";
import { DefaultIfEmpty, getAvailableObjectElementKeys } from "@/utils/object";
import { isEmptyOrNull } from "@/utils/string";
import { CheckCircleOutlined, CloseCircleOutlined, DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { Form, Modal, Badge, Button, Upload, Segmented, Row, Col, Tabs } from "antd";
import { SegmentedValue } from "antd/lib/segmented";
import { useState, useMemo, useCallback, useEffect } from "react";
import * as XLSX from "xlsx";
import SymbolMapCreateEditPage from "./SymbolMapCreateEditPage";
import { ToObjectWithKey } from "@/utils/array";
import PanelContainer from "../../PanelContainer";
import { plainAxiosInstance } from "@/services/axiosSetup";
import moment from "moment";

export interface SymbolMappingPageProps {}

interface SymbolMap {
    cleanSymbol: string | null;
    isTestSymbol: boolean;
    lowerSymbol: string | null;
    suffix: string | null;
    symbol: string;
    symbolAssetType: string | null;
    symbolAssetTypeId: number | null;
    updateBy: number | null;
    updateByText?: string;
    updateTimeUtc: string | null;
    upperSymbol: string | null;
}

interface SymbolScopeProps {
    symbolScope: number;
}

const SymbolMappingPage = (props: SymbolMappingPageProps) => {
    const [isFirstOpt, setIsFirstOpt] = useState<boolean>(true);
    const [formObject, setFormObject] = useState<CreateEditInlineFormBased>({ mode: InnerPageActionMode.CREATE_NEW });
    const [refetch, setRefetch] = useState<number>(0);

    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [currTab, setCurrTab] = useState<string>(`${SYMBOL_SCOPE_TYPE.PRODUCT}`);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<SymbolMap[]>([]);
    const [symbolAssetType, setSymbolAssetType] = useState<SymbolAssetTypesList[]>([]);
    const [productCleanSymbol, setProductCleanSymbol] = useState<string[]>([]);
    const [pricingCleanSymbol, setPricingCleanSymbol] = useState<string[]>([]);
    const [currentType, setCurrentType] = useState<string>("1");
    const [downloadForm] = Form.useForm();
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [isExporting, setIsExporting] = useState<boolean>(false);
    const [isDownloadingTemplate, setIsDownloadingTemplate] = useState<boolean>(false);
    const [isDownloadingSymbolInfo, setIsDownloadingSymbolInfo] = useState<boolean>(false);
    const [useObj, setUseObj] = useState<{ [key: number]: string }>({});

    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.CENTRALIZED_SETTINGS_MT_SYMBOL_MANAGEMENT_EDIT);

    const symbolScopeProps: SymbolScopeProps = useMemo(() => ({ symbolScope: parseInt(currTab) }), [currTab]);

    const cleanSymbol = useMemo(
        () => (symbolScopeProps.symbolScope === SYMBOL_SCOPE_TYPE.PRODUCT ? productCleanSymbol : pricingCleanSymbol),
        [symbolScopeProps, productCleanSymbol, pricingCleanSymbol]
    );

    const filterOptions = useMemo(() => {
        return {
            symbolAssetType: symbolAssetType.map(x => ({ text: x.name, value: x.id })),
            cleanSymbol: cleanSymbol.map(x => ({ text: x, value: x })),
        };
    }, [cleanSymbol, symbolAssetType]);

    const showPreviewError = (data: any) => {
        Modal.error({
            title: "Error Preview",
            width: "52.08vw",
            bodyStyle: { padding: "1vw 2vh" },
            content: (
                <div className="upload-error-div">
                    <FlexiDataTable
                        title={false}
                        loading={false}
                        columns={[
                            DTColProps.XXSmall({
                                title: "Row",
                                dataIndex: "rowNum",
                                key: "rowNum",
                            }),
                            DTColProps.Small({
                                title: "Clean Symbol",
                                dataIndex: "cleanSymbol",
                                key: "cleanSymbol",
                            }),
                            {
                                title: "Remarks",
                                dataIndex: "remarks",
                                key: "remarks",
                            },
                        ]}
                        options={{
                            enableFilter: false,
                            showHideColumns: false,
                        }}
                        dataSource={data}
                        scroll={{ x: "max-content" }}
                        bordered
                        {...(data.length > 10 ? { pagination: { pageSize: 10 } } : { pagination: false })}
                    />
                </div>
            ),
        });
    };

    const convertExcelToJSON = useCallback(
        (uploadedFile: any) => {
            if (!uploadedFile) return null;
            if (uploadedFile.size > 5000000) {
                ErrorMessageHandler(`Maximum file size is 5 mb.`, SUCCESS_FAILED.OTHERS_FAILED);
                return null;
            }

            /* Boilerplate to set up FileReader */
            const reader = new FileReader();
            const rABS = !!reader.readAsBinaryString;

            // Set up callback for when FileReader is done loading
            reader.onload = (event: any) => {
                /* Parse data */
                const bstr = event.target.result;
                const wb = XLSX.read(bstr, {
                    type: rABS ? "binary" : "array",
                    bookVBA: true,
                    raw: true,
                });

                /* Get first worksheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];

                /* Convert array of arrays */
                const excelRows: any[] = XLSX.utils.sheet_to_json(ws);

                if (excelRows.length === 0) {
                    ErrorMessageHandler(`File is empty.`, SUCCESS_FAILED.OTHERS_FAILED);
                    return null;
                }

                setIsUploading(true);

                let formData1 = new FormData();
                formData1.append("IsPreviewOnly", "true");
                formData1.append("File", uploadedFile);
                formData1.append("SymbolScope", `${symbolScopeProps.symbolScope}`);
                apiRequest(APIs.UPLOAD_MT_SYMBOL_TEMPLATE, formData1, "POST", "json", {
                    Accept: "text/plain",
                    "Content-type": "multipart/form-data",
                })
                    .then((data: any) => {
                        if (data.length > 0) {
                            showPreviewError(data);
                        } else {
                            let formData = new FormData();
                            formData.append("IsPreviewOnly", "false");
                            formData.append("File", uploadedFile);
                            formData.append("SymbolScope", `${symbolScopeProps.symbolScope}`);

                            apiRequest(APIs.UPLOAD_MT_SYMBOL_TEMPLATE, formData, "POST", "json", {
                                Accept: "text/plain",
                                "Content-type": "multipart/form-data",
                            })
                                .then((data: any) => {
                                    ErrorMessageHandler(`MT symbols upload successfully.`, SUCCESS_FAILED.OTHERS_SUCCESS);
                                    setRunRefetchDataList(true);
                                })
                                .catch((error: any) =>
                                    ErrorCatchValidator(error, (err: any) => {
                                        if (err.status === -3) {
                                            Modal.warning({
                                                title: "Warning!",
                                                content: err.message,
                                            });
                                        } else {
                                            ErrorMessageHandler(`Failed to upload MT symbols`, SUCCESS_FAILED.OTHERS_FAILED, err);
                                        }
                                    })
                                );
                        }
                    })
                    .catch((error: any) =>
                        ErrorCatchValidator(error, (err: any) => {
                            if (err.status === -3) {
                                Modal.warning({
                                    title: "Warning!",
                                    content: err.message,
                                });
                            } else {
                                ErrorMessageHandler(`Failed to upload MT symbols`, SUCCESS_FAILED.OTHERS_FAILED, err);
                            }
                        })
                    )
                    .finally(() => setIsUploading(false));
            };

            // Call FileReader
            if (rABS) {
                reader.readAsBinaryString(uploadedFile);
            } else {
                reader.readAsArrayBuffer(uploadedFile);
            }
        },
        [symbolScopeProps]
    );

    const exportToExcel = useCallback(
        (filterParams: any = {}) => {
            setIsExporting(true);
            apiRequest(
                APIs.EXPORT_MT_SYMBOL,
                {
                    ...(currentType === "2" && {
                        isConfigured: false,
                    }),
                    ...filterParams,
                    ...symbolScopeProps,
                },
                "POST",
                "arraybuffer"
            )
                .then((res: any) => {
                    const fileName = res.headers["x-filename"];
                    const url = window.URL.createObjectURL(new Blob([res.data])); // Create blob link to download
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", fileName); // or any other extension
                    // Append to html link element page
                    document.body.appendChild(link);
                    // start download
                    link.click();
                    // Clean up and remove the link
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                })
                .catch(error => {
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("MT symbols list excel", SUCCESS_FAILED.FAILED_DOWNLOAD_DATA, err));
                })
                .finally(() => setIsExporting(false));
        },
        [currentType, symbolScopeProps]
    );

    const downloadTemplate = useCallback(
        (values: any) => {
            setIsDownloadingTemplate(true);
            apiRequest(
                APIs.DOWNLOAD_MT_SYMBOL_TEMPLATE,
                {
                    ...values,
                    downloadMode: 2,
                    ...symbolScopeProps,
                },
                "POST",
                "arraybuffer"
            )
                .then((res: any) => {
                    const fileName = res.headers["x-filename"];
                    const url = window.URL.createObjectURL(new Blob([res.data])); // Create blob link to download
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", fileName); // or any other extension
                    // Append to html link element page
                    document.body.appendChild(link);
                    // start download
                    link.click();
                    // Clean up and remove the link
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                })
                .catch(error => {
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("MT symbol template", SUCCESS_FAILED.FAILED_DOWNLOAD_DATA, err));
                })
                .finally(() => setIsDownloadingTemplate(false));
        },
        [symbolScopeProps]
    );

    const downloadSymbolInfo = () => {
        setIsDownloadingSymbolInfo(true);

        const formData = new FormData();
        formData.append("tradeDate", moment().format("YYYY-MM-DD"));

        plainAxiosInstance
            .post(APIs.RISK_TOOL.POST_SYMBOL_INFO_DOWNLOAD, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Accept: "application/octet-stream, */*",
                },
                responseType: "blob",
            })
            .then(response => {
                const fileName = getFileNameFromResponseHeader(response, `symbol_info.xlsx`);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();
                // Clean up
                window.URL.revokeObjectURL(url);
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("Symbol Info", SUCCESS_FAILED.FAILED_DOWNLOAD_DATA, err))
            )
            .finally(() => setIsDownloadingSymbolInfo(false));
    };

    const filteredDatas = useMemo(() => {
        if (currentType === "1") {
            return {
                data: data.map(x => ({ ...x, updateByText: DefaultIfEmpty(useObj, x.updateBy, x.updateBy) })),
                countResult: {
                    All: data.length,
                    Unconfigured: data.filter(x => isEmptyOrNull(x.cleanSymbol) && !x.isTestSymbol).length,
                },
            };
        }

        let unconfigList = data
            .filter(x => isEmptyOrNull(x.cleanSymbol) && !x.isTestSymbol)
            .map(x => ({ ...x, updateByText: DefaultIfEmpty(useObj, x.updateBy, x.updateBy) }));
        return {
            data: unconfigList,
            countResult: {
                All: data.length,
                Unconfigured: unconfigList.length,
            },
        };
    }, [currentType, data, useObj]);

    const SegmentOpt = useMemo(() => {
        return [
            {
                label: (
                    <div className="segment-container">
                        <span>All</span>
                        {filteredDatas.countResult.All > 0 && (
                            <Badge count={<div className="badge-count-customize">{filteredDatas.countResult.All}</div>} />
                        )}
                    </div>
                ),
                value: "1",
            },
            {
                label: (
                    <div className="segment-container">
                        <span>Unconfigured</span>
                        {filteredDatas.countResult.Unconfigured > 0 && (
                            <Badge count={<div className="badge-count-customize">{filteredDatas.countResult.Unconfigured}</div>} />
                        )}
                    </div>
                ),
                value: "2",
            },
        ];
    }, [filteredDatas]);

    const columns: any[] = useMemo(
        () => [
            {
                title: "Symbol",
                dataIndex: "symbol",
                key: "symbol",
                options: {
                    filter: {
                        type: ComponentType.text,
                        value: "",
                    },
                },
            },

            DTColProps.Middle({
                title: "Clean Symbol",
                dataIndex: "cleanSymbol",
                key: "cleanSymbol",
                render: (value: string | null) => value || "",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: filterOptions.cleanSymbol,
                        inputProps: {
                            mode: "multiple",
                        },
                        callback: (filterValue: any, rowData: any) => {
                            if (rowData.cleanSymbol === null) {
                                return false;
                            } else return filterValue.some((v: string) => rowData.cleanSymbol === v);
                        },
                    },
                },
            }),
            DTColProps.XSmall({
                title: "Suffix",
                dataIndex: "suffix",
                key: "suffix",
            }),

            DTColProps.Middle({
                title: "Symbol Asset Type",
                dataIndex: "symbolAssetTypeId",
                key: "symbolAssetTypeId",
                render: (value: string, rowData: SymbolMap) => rowData.symbolAssetType || "",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: filterOptions.symbolAssetType,
                        inputProps: {
                            mode: "multiple",
                        },
                        callback: (filterValue: any, rowData: any) => {
                            if (rowData.symbolAssetTypeId === null) {
                                return false;
                            }

                            return filterValue.some((value: number) => rowData.symbolAssetTypeId === value);
                        },
                    },
                },
            }),
            DTColProps.XSmall(
                {
                    title: "Is Test Symbol",
                    dataIndex: "isTestSymbol",
                    key: "isTestSymbol",
                    render: (text: boolean) =>
                        text ? (
                            <CheckCircleOutlined className="text-color-success" style={{ fontSize: "1.25rem" }} />
                        ) : (
                            <CloseCircleOutlined className="text-color-error" style={{ fontSize: "1.25rem" }} />
                        ),
                    options: {
                        filter: {
                            type: ComponentType.dropdown,
                            value: [
                                { text: "Yes", value: true },
                                { text: "No", value: false },
                            ],
                        },
                    },
                },
                ["text-center"]
            ),
            DTColProps.Middle(
                {
                    title: "Updated By",
                    dataIndex: "updateBy",
                    key: "updateBy",
                    render: (text: number, rowData: SymbolMap) => (text === 0 ? "System" : rowData.updateByText),
                },
                ["text-center"]
            ),
            DTColProps.DateTime({
                width: "12.5vw",
                title: "Last Updated Time (Local)",
                dataIndex: "updateTimeUtc",
                key: "updateTimeUtc",
                render: (value: string, rowData: SymbolMap) =>
                    rowData.updateTimeUtc !== null ? DataTableColumnRender.DateTime(rowData.updateTimeUtc as string) : "",
                sorter: (a: any, b: any) => (a.updateTimeUtc < b.updateTimeUtc ? -1 : 1),
            }),
        ],
        [filterOptions, useObj]
    );

    const options: FlexiDataTableOptionsProps = {
        separateActionButton: true,
        edit: enableUpdate,
        export: {
            text: "Export as Excel",
        },
        extraButtons: () => (
            <>
                <Button
                    key={`btn-sy-dt-${Math.random()}`}
                    htmlType="button"
                    style={{ marginLeft: "0.651vw", width: "auto" }}
                    onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();
                        downloadSymbolInfo();
                    }}
                    icon={<DownloadOutlined />}
                    loading={isDownloadingSymbolInfo}
                >
                    Download Symbol Info
                </Button>
                {enableUpdate && (
                    <>
                        <Button
                            key={`btn-sy-dt-${Math.random()}`}
                            htmlType="button"
                            style={{ marginLeft: "0.651vw", width: "auto" }}
                            onClick={event => {
                                event.preventDefault();
                                event.stopPropagation();
                                downloadForm.setFieldsValue({ downloadMode: "", cleanSymbols: [], symbolAssetTypeIds: [] });
                                setIsModalVisible(true);
                            }}
                            icon={<DownloadOutlined />}
                            disabled={isUploading}
                            loading={isDownloadingTemplate}
                        >
                            Download Template
                        </Button>
                        <Upload
                            name="file"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            showUploadList={false}
                            onChange={(info: any) => {
                                if (info.file.status === "error") {
                                    ErrorMessageHandler(`${info.file.name} file upload failed.`, SUCCESS_FAILED.OTHERS_FAILED);
                                }
                            }}
                            beforeUpload={(file: any) => {
                                convertExcelToJSON(file);
                            }}
                            customRequest={(info: any) => {}}
                        >
                            <Button
                                htmlType="button"
                                icon={<UploadOutlined />}
                                style={{ marginLeft: "0.651vw" }}
                                onClick={(e: any) => {
                                    e.preventDefault();
                                }}
                                loading={isUploading}
                            >
                                Upload Excel
                            </Button>
                        </Upload>
                    </>
                )}
            </>
        ),
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, symbolItem) => {
        switch (type) {
            case CALLBACK_KEY.DO_EDIT:
                setIsFirstOpt(prev => !prev);
                setFormObject({ mode: InnerPageActionMode.EDIT, id: symbolItem.symbol });
                setRefetch(prev => prev + 1);
                break;
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                exportToExcel(
                    getAvailableObjectElementKeys(symbolItem).reduce((tmpObj: any, x: string) => {
                        if (x === "symbol") {
                            tmpObj["symbolWildcard"] = symbolItem[x];
                        } else if (x === "cleanSymbol") {
                            tmpObj["cleanSymbols"] = symbolItem[x];
                        } else if (x === "symbolAssetTypeId") {
                            tmpObj["symbolAssetTypeIds"] = symbolItem[x];
                        } else {
                            tmpObj[x] = symbolItem[x];
                        }

                        return tmpObj;
                    }, {})
                );
                break;
            default:
                break;
        }
    };

    const getListing = useCallback(() => {
        setIsLoading(true);
        apiRequest(APIs.GET_MT_SYMBOL_LIST, symbolScopeProps)
            .then((res: SymbolMap[]) => {
                setData(res);
            })
            .catch(error => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("symbols", SUCCESS_FAILED.FAILED_UPDATE_DATA, err));
            })
            .finally(() => setIsLoading(false));
    }, [symbolScopeProps]);

    const getConfigList = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, {
            filterType: ["symbolassettype", "cleansymbol", "pricingcleansymbol", "user"],
        })
            .then(res => {
                setSymbolAssetType(res.symbolAssetTypes);
                setProductCleanSymbol(res.cleanSymbols.map((x: any) => x.name));
                setPricingCleanSymbol(res.pricingCleanSymbols.map((x: any) => x.name));
                setUseObj(ToObjectWithKey(res.users, "id", "name"));
                setRunRefetchDataList(true);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("config info", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            });
    };

    useEffect(() => {
        if (runRefetchDataList) {
            getListing();
            setRunRefetchDataList(false);
        }
    }, [runRefetchDataList]);

    useEffect(() => {
        getConfigList();
        return () => {};
    }, []);

    return (
        <PanelContainer title="MT Symbol Management">
            <div className="tab-container">
                <Tabs
                    activeKey={currTab}
                    onChange={(activeKey: string) => {
                        setCurrTab(activeKey);
                        setRunRefetchDataList(true);
                    }}
                    type="card"
                    style={{ padding: "0.5rem 0.5rem 0 0.5rem" }}
                    tabBarStyle={{ marginBottom: 0 }}
                    items={[
                        { label: "Product", key: `${SYMBOL_SCOPE_TYPE.PRODUCT}` },
                        { label: "Pricing", key: `${SYMBOL_SCOPE_TYPE.PRICING}` },
                    ]}
                />
            </div>
            <div className="single-page">
                <div className={`${isFirstOpt ? "active" : ""}`}>
                    <div className="clean-symbol-main-content">
                        <LoadingComponent tip={"Uploading file..."} spinning={isUploading}>
                            <div className="symbol-list clean-symbol-main-container">
                                <div className="segment-div">
                                    <Segmented
                                        size="middle"
                                        options={SegmentOpt}
                                        onChange={(value: SegmentedValue) => setCurrentType(value as string)}
                                        value={currentType}
                                    />
                                </div>
                                <FlexiDataTable
                                    bordered
                                    rowKeyProperty="symbol"
                                    title={false}
                                    columns={columns}
                                    options={options}
                                    dataSource={filteredDatas.data}
                                    callback={componentCallback}
                                    loading={isLoading}
                                    exporting={isExporting}
                                />
                            </div>
                        </LoadingComponent>
                    </div>
                </div>
                <div className={`${isFirstOpt ? "" : "active"}`}>
                    <SymbolMapCreateEditPage
                        {...formObject}
                        callback={(action: InnerPageActionMode, value: any) => {
                            switch (action) {
                                case InnerPageActionMode.BACK:
                                    setIsFirstOpt(prev => !prev);
                                    if (value?.hasOwnProperty("refreshMainList") && value["refreshMainList"]) {
                                        setRunRefetchDataList(true);
                                    }
                                    break;
                            }
                        }}
                        resetState={refetch}
                        cleanSymbols={cleanSymbol}
                        symbolScope={symbolScopeProps.symbolScope}
                    />
                </div>
            </div>
            <Modal
                width={600}
                title="Download Template"
                open={isModalVisible}
                cancelButtonProps={{ style: { display: "none" } }}
                onCancel={() => setIsModalVisible(false)}
                onOk={() => {
                    downloadForm
                        .validateFields()
                        .then(values => {
                            downloadTemplate(values);
                            setIsModalVisible(false);
                        })
                        .catch(errorInfo => console.log(errorInfo));
                }}
            >
                <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} form={downloadForm} layout="horizontal" initialValues={{}}>
                    <Row>
                        <Col span={24}>
                            <FormComponent
                                label="Clean Symbol"
                                name="cleanSymbols"
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: filterOptions.cleanSymbol,
                                    inputProps: { mode: "multiple" },
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <FormComponent
                                label="Symbol Asset Type"
                                name="symbolAssetTypeIds"
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: filterOptions.symbolAssetType,
                                    inputProps: { mode: "multiple" },
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <FormComponent
                                label="Is Configured"
                                name="isConfigured"
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: [
                                        { text: "Yes", value: true },
                                        { text: "No", value: false },
                                    ],
                                }}
                            />
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </PanelContainer>
    );
};

export default SymbolMappingPage;
