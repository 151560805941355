import { useMemo, useState } from "react";
import CardBox from "@/components/Common/CardBox";
import FlexiDataTable from "@/components/FlexiDataTable";
import { FormComponent } from "@/components/FormComponent";
import { CALLBACK_KEY, ComponentType } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { CarryOutOutlined, DeleteOutlined, InfoCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { Form, Row, Col, Upload, Button, Divider, Popconfirm, Space, Tooltip, UploadFile, UploadProps } from "antd";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps } from "@/constants/type";
import { DTColProps } from "@/utils/Common";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";

const UserUpdater = () => {
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.MANAGEMENT_TOOL_USER_UPDATER_EDIT);

    const [data, setData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [file, setFile] = useState<File | null>(null);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [previewForm] = Form.useForm();

    const columns: any[] = useMemo(() => [
        DTColProps.Small({
            title: "Login",
            dataIndex: "login",
            key: "login",
            fixed: "left",
            sorter: (a: any, b: any) => a.login - b.login,
            options: {
                filter: {
                    type: ComponentType.number,
                    value: "",
                },
            },
        }),
        DTColProps.Small({
            title: "Comment",
            dataIndex: "comment",
            key: "comment",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Middle({
            title: "Group",
            dataIndex: "group",
            key: "group",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Small({
            title: "ID Number",
            dataIndex: "idNumber",
            key: "idNumber",
            options: {
                filter: {
                    type: ComponentType.number,
                    value: "",
                },
            },
        }),
        DTColProps.XSmall(
            {
                title: <span>Read Only  <Tooltip
                    placement="right"
                    title={"1: Enabled, 0: Disabled"}
                >
                    <span>
                        <InfoCircleOutlined />
                    </span>
                </Tooltip></span>,
                dataIndex: "readOnly",
                key: "readOnly",
                sorter: (a: any, b: any) => a.readOnly - b.readOnly,
            },
            ["text-center"]
        ),
        DTColProps.XSmall(
            {
                title: "Leverage",
                dataIndex: "leverage",
                key: "leverage",
            },
            ["text-center"]
        ),
        DTColProps.Small({
            title: "Status",
            dataIndex: "status",
            key: "status",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Small({
            title: "Agent Account",
            dataIndex: "agentAccount",
            key: "agentAccount",
        }),
        DTColProps.Small({
            title: "Result",
            dataIndex: "result",
            key: "result",
        }),
    ], []);

    const options: FlexiDataTableOptionsProps = useMemo(() => {
        return {
            defaultCollapseFilterPanel: true,
            ...(enableUpdate && {
                extraButtons: () => {
                    return (
                        <div className="extra-header-buttons" key={"mtua-extra-buttons"}>
                            <Button
                                key={"mtua-apply-all"}
                                icon={<CarryOutOutlined />}
                                disabled={data.length === 0 || isLoading}
                                onClick={() => componentCallback(CALLBACK_KEY.OTHERS, "apply-all")}
                            >
                                Apply All
                            </Button>
                        </div>
                    );
                },
            }),
        }
    }, [enableUpdate, data, isLoading]);

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.OTHERS:
                switch (FormData) {
                    case "apply-all":
                        console.log("Apply All clicked: ", FormData);
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        };
    };

    const onFormSubmit = (values: any) => {
        console.log("onFormSubmit: ", values);
    };

    const uploadProps: UploadProps = {
        onRemove: () => {
            setFile(null);
            setFileList([]);
        },
        beforeUpload: file => {
            setFile(file);
            setFileList([
                {
                    uid: "-1",
                    name: file.name,
                    status: "done",
                    url: URL.createObjectURL(file),
                },
            ]);
            return false;
        },
        fileList,
        maxCount: 1,
    };

    return (
        <div className="user-updater-container">
            <CardBox title="User Updater">
                <div className="preview-form-div">
                    <Form
                        form={previewForm}
                        layout="horizontal"
                        initialValues={{}}
                        onFinish={onFormSubmit}
                    >
                        <Row gutter={[12, 12]}>
                            <Col span={6}>
                                <FormComponent
                                    label="Server"
                                    name="server"
                                    extra={{
                                        type: ComponentType.dropdown,
                                        value: [],
                                        rules: [{ required: true, message: REQUIRED_FIELD }],
                                    }}
                                />
                            </Col>
                            <Col span={5}>
                                <Form.Item
                                    label="Login(s)"
                                    name={"logins"}
                                    rules={[{ required: true, message: REQUIRED_FIELD }]}
                                    labelCol={{ span: 6 }}
                                    wrapperCol={{ span: 18 }}
                                >
                                    <Upload  {...uploadProps} >
                                        <Button icon={<UploadOutlined />} style={{ width: 150 }}>Upload CSV File</Button>
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        loading={isLoading}
                                        htmlType="submit"
                                    >
                                        Preview
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <Divider />
                <FlexiDataTable
                    bordered
                    rowKeyProperty="login"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={data ?? []}
                    callback={componentCallback}
                    loading={isLoading}
                />
            </CardBox>
        </div>
    );
};

export default UserUpdater;