import { useState } from "react";
import { Segmented } from "antd";
import { SegmentedValue } from "antd/lib/segmented";
import NsExcludeTab from "./NsExcludeTab";
import PanelContainer from "../../PanelContainer";

const AdminFeeSettings = () => {
    const [segmentVal, setSegmentVal] = useState<string>("NS Exclude Swap Free List");

    return (
        <PanelContainer title="Admin Fee Settings">
            <div className="adminfee-settings-container">
                <div className="top-panel">
                    <Segmented
                        options={["NS Exclude Swap Free List"]}
                        value={segmentVal}
                        onChange={(value: SegmentedValue) => setSegmentVal(value as string)}
                    />
                </div>
                <div className="container">
                    {segmentVal === "NS Exclude Swap Free List" && <NsExcludeTab />}
                </div>
            </div>
        </PanelContainer>
    );
};

export default AdminFeeSettings;